export const budgetEn = {
  activateBudget: 'Create a budget for this category',
  showOnBankStats: 'Feature on the main screen',
  monthlyAmount: 'Monthly Budget',
  notifyMe: 'Activate notifications for this category',
  budgetBySubcategory: 'Budget by sub-category',
  dynamic: 'Dynamic',
  lastMonth: 'last month',
  lastYear: 'last 12 months',
  thisMonth: 'this month',
  outcome: 'Expenses',
  income: 'Income',
  budgetCompleted: 'Congratulations! You now have a budget!',
  accountConnectedTitle: 'Congratulations! Your account is connected!',
  accountConnectedSubTitle: 'Synching in progress',
  makeMyBudget: 'Set my budget',
  budgetPercentage: 'Percentage of your monthly income',
  of: 'out of',
  perMonth: 'per month',
  available: 'available',
  budgetExceed: 'too much',
  budgetTab: 'My budget',
  transactionsTab: 'My transactions',
  monthly: 'Monthly',
  twelveMonths: '12 months',
  avg: 'avg',
  perWeek: '/ week',
  add: 'Add',
  addYourAccount: 'Add an account to show your transactions',
  reconnectAccountTitle: 'Oops, no transactions to show !',
  reconnectAccountDescription: 'Please reconnect your account.',
  reconnectAccountButton: 'Reconnect account',
  reconnectInAnHour: 'It looks like you just connected an account. It might take up to one hour for your transactions to be imported. Please come back later.',
  addYourTransactions: 'Add your transactions!',
  addYourTransactionsDescription: 'Connect your bank account to automatically add your transactions',
  connectYourBankAccount: 'Connect your bank account',
  connectYourBankAccountDesc: 'By connecting your account and your credit cards, your transactions will be entered and categorized for you.',
  noTransactionInfo: 'Oops, no transactions to show !',
  noTransactionInfoDesc: 'Either you account is new, or you haven\'t yet made any transactions in the selected accounts and categories.',
  noTransactionInfoAction: 'Select another account',
  noTransactionInfoAction2: 'Connect another account',
  target: 'Target',
  spent: 'Spent',
  earned: 'Earned',
  youAreOverBudget: 'You are {{amount}} over budget',
  youAreOk: 'You still have {{amount}}'
};

export const budgetFr = {
  activateBudget: 'Établir un budget pour cette catégorie',
  showOnBankStats: "Affichez sur l'écran principal",
  monthlyAmount: 'Budget mensuel',
  notifyMe: 'Activer les notifications pour cette catégorie',
  budgetBySubcategory: 'Budget par sous-catégorie',
  dynamic: 'Dynamique',
  lastMonth: 'le dernier mois',
  lastYear: 'les 12 derniers mois',
  thisMonth: 'ce mois-ci',
  outcome: 'Dépenses',
  income: 'Revenu',
  budgetCompleted: 'Félicitations ! Vous avez maintenant un budget !',
  accountConnectedTitle: 'Félicitations ! Votre compte est connecté!',
  accountConnectedSubTitle: 'La synchronisation est en cours.',
  makeMyBudget: 'Déterminer mon budget',
  budgetPercentage: 'Pourcentage de vos revenus budgétés',
  of: 'sur',
  perMonth: 'par mois',
  available: 'disponible',
  budgetExceed: 'en trop',
  budgetTab: 'Mon budget',
  transactionsTab: 'Mes transactions',
  monthly: 'Mensuel',
  twelveMonths: '12 mois',
  avg: 'moy',
  perWeek: '/ sem',
  addYourAccount: 'Ajoutez un compte pour faire apparaître vos transactions',
  reconnectAccountTitle: 'Oups, pas de transactions !',
  reconnectAccountDescription: 'Veuillez connecter votre compte à nouveau.',
  reconnectAccountButton: 'Reconnectez votre compte',
  reconnectInAnHour: "Il semble que vous venez de connecter un compte. L'importation de vos transactions peut prendre jusqu'à une heure. Merci de revenir plus tard.",
  addYourTransactions: 'Ajoutez vos transactions !',
  addYourTransactionsDescription: 'Connectez votre compte bancaire pour ajouter vos transactions automatiquement',
  connectYourBankAccount: 'Connectez votre compte bancaire',
  connectYourBankAccountDesc: 'En connectant votre compte et vos cartes de crédit, vos transactions seront entrées et catégorisées pour vous.',
  noTransactionInfo: 'Oups, pas de transactions !',
  noTransactionInfoDesc: 'Soit votre compte est nouveau, soit vous n\'avez encore effectué aucune transaction dans les comptes ou catégories sélectionnés.',
  noTransactionInfoAction: 'Sélectionner un autre compte',
  noTransactionInfoAction2: 'Connecter un autre compte',
  target: 'Objectif',
  spent: 'Dépensés',
  earned: 'Gagnés',
  youAreOverBudget: 'Vous avez dépensé {{amount}} en trop',
  youAreOk: 'Il vous reste {{amount}}'
};
