import React, { ReactComponentElement, useState, useRef, useEffect } from 'react';
import ModalState, { modalAnimationDelay } from '../model/ModalState';
import { HardbaconButton } from './HardbaconButton';

/* eslint-disable no-unused-vars */
enum HardbaconModalStyle {
    alert,
    action,
    info,
    none
}
/* eslint-disable no-unused-vars */

type HardbaconModalProps = {
    title?: string;
    description?: string;
    positiveText?: string;
    negativeText: string;
    positiveAction?: (payload?: any) => void;
    dismissAction?: (payload?: any) => void;
    disabled?: boolean;
    customHeader?: ReactComponentElement<any>;
    style: HardbaconModalStyle;
    singleLineButton?: boolean;
    children?: any;
    open?: boolean;
    payload?: any;
}

const HardbaconModal = (props: HardbaconModalProps) => {
  const [modalState, setModalState] = useState(ModalState.closed);
  const [payload, setPayload] = useState(undefined);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      dismiss();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  });

  /**
   * Show the modal.
   * @param payload associate an object with the modal that will be
   *        passed back when the modal is either positively or negatively dismissed
   */
  const show = (payload?: any) => {
    setModalState(ModalState.opening);
    setPayload(payload);
    setTimeout(() => {
      setModalState(ModalState.opened);
    }, modalAnimationDelay);
  };

  const hide = () => {
    setModalState(ModalState.closing);
    setTimeout(() => {
      setModalState(ModalState.closed);
    }, modalAnimationDelay);
  };

  const dismiss = () => {
    if (props.dismissAction) {
      props.dismissAction(payload);
    }
  };

  useEffect(() => {
    if (props.open) {
      show(props.payload);
    } else {
      hide();
    }
  }, [props.open, props.payload]);

  const positiveAction = () => {
    if (props.positiveAction) {
      props.positiveAction(payload);
    } else {
      hide();
    }
  };

  if (modalState === ModalState.closed) {
    return null;
  }

  const { positiveText, negativeText, style, children, title, description, disabled, customHeader, singleLineButton } = props;
  const isCustomHeader = customHeader !== undefined;

  return (
      <div className={'fixed z-10 inset-0 overflow-y-auto'}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                  className={modalState === ModalState.opened ? 'ease-out duration-300 opacity-100 fixed inset-0 transition-opacity' : 'ease-in duration-200 opacity-0 fixed inset-0 transition-opacity'}
                  aria-hidden="true">
                  <div className="absolute inset-0 bg-gray-500 opacity-75"/>
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">&#8203;</span>
              <div
                  className={(modalState === ModalState.opened ? 'ease-out duration-300 opacity-100 translate-y-0 sm:scale-100 inline-block align-bottom bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full' : 'ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full') + (!isCustomHeader ? ' sm:p-6 px-4 pt-5 pb-4' : '')}
                  role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                  ref={wrapperRef}>
                  {style === HardbaconModalStyle.alert && !isCustomHeader &&
                  <div className="sm:flex sm:items-start">
                      <div
                          className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                               viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                          </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                              {title}
                          </h3>
                          <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                  {description}
                              </p>
                          </div>
                      </div>
                  </div>}
                  {(style === HardbaconModalStyle.action || style === HardbaconModalStyle.info) && !isCustomHeader &&
                  <div className="mt-3 text-center sm:mt-5">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                          {title}
                      </h3>
                  </div>}
                  {isCustomHeader ? customHeader : <div/>}
                  <div className={(isCustomHeader ? 'sm:pb-6 sm:px-6 px-4 pb-4' : '')}>
                      {children}
                      {style === HardbaconModalStyle.action &&
                      <div className={(!singleLineButton ? 'sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense' : '') + ' mt-5 sm:mt-6 flex justify-center gap-x-6'}>
                          <HardbaconButton name="positiveAction"
                              onPress={() => !disabled && positiveAction()} disabled={disabled} title={positiveText ?? ''} type="button" />
                          <HardbaconButton name="negativeAction" className={(!singleLineButton ? 'sm:mt-0' : '')}
                                           inverse={true} onPress={() => dismiss()} title={negativeText} type="button" />
                      </div>}
                      {style === HardbaconModalStyle.alert &&
                      <div className="mt-5 sm:mt-6 text-center">
                        <HardbaconButton
                              name={'dismiss'}
                              inverse={true}
                              onPress={() => dismiss()}
                              type="button"
                              title={negativeText} />
                      </div>}
                      {style === HardbaconModalStyle.info &&
                      <div className="mt-5 sm:mt-6 text-center">
                          <HardbaconButton
                              name={'accept'}
                              onPress={() => dismiss()}
                              type="button"
                              title={positiveText ?? ''} />
                      </div>}
                  </div>
              </div>
          </div>
      </div>
  );
};

export default HardbaconModal;

export {
  HardbaconModalStyle
};
