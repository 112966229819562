import React, { useState, useEffect } from 'react';
import styles from './../assets/styles/modules/addSelectedBudgetCategory.module.css';
import { useTranslation } from 'react-i18next';
import { TransactionCategory } from '../model/TransactionCategory';
import HardbaconButton from './HardbaconButton';
import _ from 'lodash';
import HardbaconToggle from './HardbaconToggle';
import FormItemContainer, { FormInputEmun } from './FormItemContainer';
import { updateBulkCategoryBudget } from '../actions/Banking';
import { useDispatch } from 'react-redux';
import { formatCurrency } from '../i18n';
import { getTransactionCtgName } from '../utils/banking';

interface AddSelectedBudgetCategoryProps {
  item: TransactionCategory;
  onPress: Function;
}

const AddSelectedBudgetCategory = ({ item, onPress }: AddSelectedBudgetCategoryProps) => {
  const [notifyMe, setNotifyMe] = useState(true);
  const [budget, setBudget] = useState(item.budget);
  const [createBudget, setCreateBudget] = useState(true);
  const [createSubBudget, setCreateSubBudget] = useState(true);
  const [subs, setSubs] = useState<any>({});
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const subCategories = item.categoryDetails;

  useEffect(() => {
    if (subCategories.length) {
      const subCategoriesBudget: any = {};
      subCategories.map((sub: any) => {
        subCategoriesBudget[sub.category] = { id: sub.categoryId, budget: sub.budget };
        return sub;
      });
      setSubs(subCategoriesBudget);
    }
  }, [subCategories]);

  const addBudget = (categoriesDto: any, value: number, categoryId: number) => {
    categoriesDto.push(buildDTOForCategory(value, categoryId));
  };

  const buildDTOForCategory = (value: number, categoryId: number) => {
    return {
      isActive: true,
      amount: value,
      isBudgetBySubCategory: false,
      isDisplayHome: true,
      isDisplayTrend: true,
      isNotificationEnabled: true,
      categoryId,
      topLevelCategoryId: item.categoryId,
      subCategoryBudgets: []
    };
  };

  const submit = () => {
    const allCategoriesArrary = Object.values(subs);
    let categoriesDto: Array<any> = [];
    if (!createBudget) {
      categoriesDto = [{
        isActive: false,
        amount: null,
        isBudgetBySubCategory: false,
        isDisplayHome: false,
        isDisplayTrend: true,
        isNotificationEnabled: true,
        categoryId: item.categoryId,
        topLevelCategoryId: item.categoryId,
        subCategoryBudgets: []
      }];
    } else {
      allCategoriesArrary.map((cat: any) => cat.budget && addBudget(categoriesDto, createSubBudget ? cat.budget : 0, cat.id));

      if (budget) {
        categoriesDto = [{
          isActive: true,
          amount: budget,
          isBudgetBySubCategory: false,
          isDisplayHome: true,
          isDisplayTrend: true,
          isNotificationEnabled: true,
          categoryId: item.categoryId,
          topLevelCategoryId: item.categoryId,
          subCategoryBudgets: categoriesDto
        }];
      }
    }
    dispatch(updateBulkCategoryBudget(categoriesDto));
    onPress();
  };

  return (
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.emoji}>{item.categoryEmoji}</span>
          <span className={styles.name}>{getTransactionCtgName(item)}</span>
          <span className={styles.desc}><span className={styles.amount}>{formatCurrency(item.periodAmount, 0)}</span>{' ' + t('thisMonth')}</span>
          <span className={styles.desc}>{formatCurrency(item.totalAmount, 0)} {t('lastYear')}</span>
        </div>
        <div className={styles.content}>
          <FormItemContainer
            id='activateBudget'
            left={t('activateBudget') + ''}
            right={<HardbaconToggle value={createBudget} onCheck={setCreateBudget} />}
          />
          {createBudget && (
            <>
              <FormItemContainer
                id='monthlyAmount'
                left={t('monthlyAmount') + ''}
                right={FormInputEmun.moneyInput}
                value={budget}
                onChange={setBudget}
              />
              <FormItemContainer
                id='notifyMe'
                left={t('notifyMe') + ''}
                right={<HardbaconToggle value={notifyMe} onCheck={setNotifyMe}/>}
              />
              <FormItemContainer
                id='budgetBySubcategory'
                left={t('budgetBySubcategory') + ''}
                right={<HardbaconToggle value={createSubBudget} onCheck={setCreateSubBudget} />}
              />
              {
                createSubBudget && !_.isEmpty(subs) && subCategories.map((sub: TransactionCategory, index: number) => {
                  return (
                  <FormItemContainer
                    key={index}
                    id={getTransactionCtgName(item)}
                    left={item.categoryEmoji + ' ' + getTransactionCtgName(sub)}
                    right={FormInputEmun.moneyInput}
                    value={subs[sub.category].budget}
                    withDivider={(index + 1) !== subCategories.length}
                    onChange={(v: number) => {
                      const newSubs = JSON.parse(JSON.stringify(subs));
                      newSubs[sub.category].budget = v;
                      setSubs(newSubs);
                    }}
                  />
                  );
                })
              }
            </>

          )}

        </div>
        <HardbaconButton
          name={'accept'}
          onPress={() => submit()}
          type="button"
          disabled={budget <= 0 && createBudget}
          title={t('header:budget')} />
      </div>
  );
};

export default AddSelectedBudgetCategory;
