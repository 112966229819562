import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

type NoRouteProps = WithTranslation

class NoRoute extends Component<NoRouteProps> {
  render () {
    const { t } = this.props;
    return (
            <div className="flex h-screen">
                <div className={'m-auto flex flex-col'}>
                    <span className={'mx-auto text-9xl font-gilmerBold text-hb-darkgray mb-8'}>404</span>
                    <span className={'mx-auto extra-large-text text-gray-medium mb-72'}>{t('pageNotFound')}</span>
                </div>
            </div>
    );
  }
}

export default withTranslation()(NoRoute);
