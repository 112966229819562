import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../../../assets/styles/modules/TransactionFilter.module.css';
import { checkIcon, uncheckIcon } from '../../../assets/images';
import { HardbaconButton, RightPanel } from '../../../component';
import { useSelector, useDispatch } from 'react-redux';
import { getTransactionCategories, setTransactionFilter } from '../../../actions/Transactions';
import { TransactionTypeEmun } from '../../../model/transactions/TransactionTypeEmun';
import _ from 'lodash';
import { SearchInput } from '../../../component/SearchInput';
import ExpandButton from '../../../component/ExpandButton';
import { BankingTransactionCtg } from '../../../model/BankingTransaction';

interface TransactionFilterProps {
  isVisible: boolean;
  onClose: Function;
}

const TransactionFilter = ({ isVisible, onClose }: TransactionFilterProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [selectedCtg, setSelectedCtg] = useState<number[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<TransactionTypeEmun[]>([]);
  const [expandItemId, setExpandItemId] = useState<number>();
  const [search, setSearch] = useState<string>();

  const { transactionCategories, filteredTrxCategories, filteredTrxTypes } = useSelector((state: any) => state.transactions);

  const isEn = i18n.language !== 'fr';

  useEffect(() => {
    if (transactionCategories.length === 0) {
      dispatch(getTransactionCategories());
    }
  }, [transactionCategories]);

  useEffect(() => {
    setSelectedTypes(filteredTrxTypes);
    setSelectedCtg(filteredTrxCategories);
  }, [filteredTrxCategories]);

  const updateSelectedCtg = (cat: BankingTransactionCtg) => {
    const subs = cat.subCategories;
    const id = cat.id;
    let newSelectedCtg = _.cloneDeep(selectedCtg);
    let isExisted = !!_.find(newSelectedCtg, (i) => i === id);
    if (subs) {
      isExisted = isExisted && isAllSubsChecked(cat);
    }
    if (isExisted) {
      newSelectedCtg = newSelectedCtg.filter((i: number) => i !== id);
    } else {
      newSelectedCtg.push(id);
    }
    if (subs && !_.isEmpty(subs)) {
      const subIds = subs.map((i: BankingTransactionCtg) => i.id);
      if (isExisted && subIds) {
        subIds.map((subId: number) => {
          newSelectedCtg = newSelectedCtg.filter((i: number) => subId !== i);
          return subId;
        });
      } else {
        newSelectedCtg = _.uniq([...newSelectedCtg, ...subIds]);
      }
    }
    setSelectedCtg(_.uniq([...newSelectedCtg]));
  };

  const getCtgCount = (cat: BankingTransactionCtg) => {
    let count = 0;
    if (selectedCtg.length > 0) {
      const subs = cat.subCategories;
      selectedCtg.map((i: number) => {
        if (subs.find((sub: BankingTransactionCtg) => sub.id === i)) {
          count = count + 1;
        }
        return i;
      });
      if (selectedCtg.find((i: number) => i === cat.id)) {
        count = count + 1;
      }
    }
    return count;
  };

  const isAllSubsChecked = (cat: BankingTransactionCtg) => {
    let check = false;
    const count = getCtgCount(cat);
    if (selectedCtg.length > 0) {
      const subs = cat.subCategories;
      if (count === subs.length + 1) {
        check = true;
      }
    }
    return check;
  };

  const renderCategories = () => {
    return (
        <div style={{ width: '100%' }}>
            {transactionCategories && transactionCategories.map((cat: BankingTransactionCtg) => {
              const isExisted = isAllSubsChecked(cat);
              const count = getCtgCount(cat);
              const subs = _.cloneDeep(cat.subCategories);
              subs.unshift(cat);
              const subsHeight = subs.length * 47 + 'px';
              let shouldDisplay = true;
              const name = isEn ? cat.english : cat.french;
              if (search) {
                const re = search && new RegExp(search.replace(/([.?*+^$[\]\\(){}|-])/g, ''), 'gi');
                shouldDisplay = !!name.match(re);
                if (subs.length) {
                  subs.map((i: BankingTransactionCtg) => {
                    if (!shouldDisplay) {
                      shouldDisplay = !!name.match(re);
                    }
                    return i;
                  });
                }
              }
              return shouldDisplay && (
                  <div key={(cat.id)}>
                    <div className={styles['ctg-container']}>
                        <div className={styles['ctg-container-left']} onClick={() => updateSelectedCtg(cat)}>
                            <img className={styles.uncheck} src={isExisted ? checkIcon : uncheckIcon}/>
                            <p className={styles.emoji}>{cat.emoji}</p>
                            <p>{name}</p>
                            {count && !search ? <span className={styles.count}>{count}</span> : null}
                        </div>
                        {!search && <ExpandButton
                          isOpen={expandItemId === cat.id}
                          onClick={() => expandItemId === cat.id ? setExpandItemId(undefined) : setExpandItemId(cat.id)}
                        />}
                    </div>
                    {!_.isEmpty(subs) && <div className={styles['subs-container']} style={{ height: search ? 'unset' : (expandItemId === cat.id ? subsHeight : '0px') }}>
                      {subs.map((sub: BankingTransactionCtg, index: number) => {
                        const subName = isEn ? sub.english : sub.french;
                        const isExisted = _.find(selectedCtg, (i) => i === sub.id);
                        let shouldDisplaySub = true;
                        if (search) {
                          const re = search && new RegExp(search.replace(/([.?*+^$[\]\\(){}|-])/g, ''), 'gi');
                          shouldDisplaySub = !!name.match(re);
                        }
                        return shouldDisplaySub && (
                              <div key={(sub.id)} className={styles['ctg-container'] + ' ' + styles['sub-ctg-container']}>
                              <div className={styles['ctg-container-left']} onClick={() => updateSelectedCtg(sub)} >
                                  <img className={styles.uncheck} src={isExisted ? checkIcon : uncheckIcon}/>
                                  <p className={styles.emoji}>{sub.emoji}</p>
                                  <p>{index === 0 ? t('manageAccounts:AccountType_UNKNOWN') : subName}</p>
                              </div>
                          </div>
                        );
                      })}
                    </div>}
                </div>
              );
            })}
        </div>
    );
  };

  const renderContent = () => {
    return (
        <div style={{ position: 'relative', height: '100vh' }}>
            <div className={styles.container}>
                <div className={styles['title-container']}>
                    <p>{t('type')}</p>
                </div>
                <div className={styles['ctg-container']}>
                    <div
                      className={styles['ctg-container-left']}
                      onClick={() => {
                        let newSelectedTypes = _.cloneDeep(selectedTypes);
                        if (_.find(newSelectedTypes, (i) => i === TransactionTypeEmun.income)) {
                          newSelectedTypes = newSelectedTypes.filter((i: TransactionTypeEmun) => i !== TransactionTypeEmun.income);
                        } else {
                          newSelectedTypes.push(TransactionTypeEmun.income);
                        }
                        setSelectedTypes(newSelectedTypes);
                      }}>
                        <img
                        className={styles.uncheck}
                        src={_.find(selectedTypes, (i) => i === TransactionTypeEmun.income) ? checkIcon : uncheckIcon}
                        />
                        <p>{t('income')}</p>
                    </div>
                </div>
                <div className={styles['ctg-container']}>
                    <div
                      className={styles['ctg-container-left']}
                      onClick={() => {
                        let newSelectedTypes = _.cloneDeep(selectedTypes);
                        if (_.find(newSelectedTypes, (i) => i === TransactionTypeEmun.expense)) {
                          newSelectedTypes = newSelectedTypes.filter((i: TransactionTypeEmun) => i !== TransactionTypeEmun.expense);
                        } else {
                          newSelectedTypes.push(TransactionTypeEmun.expense);
                        }
                        setSelectedTypes(newSelectedTypes);
                      }} >
                        <img
                          className={styles.uncheck}
                          src={_.find(selectedTypes, (i) => i === TransactionTypeEmun.expense) ? checkIcon : uncheckIcon}
                        />
                        <p>{t('outcome')}</p>
                    </div>
                </div>
                <div className={styles['title-container']}>
                    <p>{t('categories')}</p>
                </div>
                <div className={styles.search}>
                  <SearchInput value={search as string} placeholder={t('common:search')} onChange={setSearch} />
                </div>
                {renderCategories()}
            </div>
            <div className={styles['button-container']}>
            <HardbaconButton
                className={styles.button}
                name={'accept'}
                onPress={() => {
                  dispatch(setTransactionFilter(_.cloneDeep(selectedCtg), _.cloneDeep(selectedTypes)));
                  onClose();
                }}
                type="button"
                title={t('update')}/>
                </div>
        </div>

    );
  };
  return (
        <RightPanel
            title={t('filter')}
            visible={isVisible}
            onClose={onClose}
            closeOnClickOutside={true}
            renderContent={renderContent}
        />

  );
};

export default TransactionFilter;
