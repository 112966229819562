import React from 'react';
import { useSelector } from 'react-redux';
import Container from '../../component/Container';
import { decode } from 'html-entities';
import { NewsFeedItem } from '../../model/feed/NewsFeedItem';
import styles from '../../assets/styles/modules/NewsItem.module.css';
import { NewsCategoryItem } from '../../utils/feed';
import _ from 'lodash';

const NewsItem = ({ item, categories }: {item: NewsFeedItem, categories: NewsCategoryItem[]}) => {
  const { newsMediaFiles } =
  useSelector((state: any) => state.feed);

  let mediaUrl = '';
  if (newsMediaFiles['media' + item.featured_media]) {
    mediaUrl = newsMediaFiles['media' + item.featured_media];
  }

  const renderCategories = () => {
    return (
      <div className={styles['categories-container']}>
        {_.uniq(categories).map(i => {
          return (
            <div key={i.key} className={styles.category}>
              {i.icon && (<img src={i.icon} className={styles.icon}/>)}
              <span className={styles['category-text']}>{i.name}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Container key={item.id} onClick={() => window.open(item.link, '_blank', 'noopener,noreferrer')}>
        <div className={styles.container}>
          <div className={styles['text-container']}>
            <div>
              <p className={styles?.title}>
                  {decode(item?.title?.rendered)}
              </p>
              <p className={styles?.excerpt}>
                  {decode(item.excerpt.rendered.replace(/(<([^>]+)>)/ig, ''))}
              </p>
            </div>
            {renderCategories()}
          </div>
          {item?.featured_image_data?.src
            ? <img src={item?.featured_image_data?.src} className={styles.image}/>
            : <div className={styles.image}/>}
        </div>
    </Container>
  );
};

export default NewsItem;
