import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Point, ResponsiveLine } from '@nivo/line';
import { Defs } from '@nivo/core';
import { area, curveMonotoneX } from 'd3-shape';
import { BankingOverviewStats } from '../../../model/BankingOverviewStats';
import { BankingTransaction } from '../../../model/BankingTransaction';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';
import styles from '../../../assets/styles/modules/insightReport.module.css';
import up from '../../../assets/images/svg/up.svg';
import down from '../../../assets/images/svg/down.svg';
import Lines from '../../../assets/images/svg/lines';
import Bars from '../../../assets/images/svg/bars';
import HardbaconAmount from '../../../component/HardbaconAmount';
import { TimePeriod } from '../../../model/TimePeriod';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import { formatCurrency } from '../../../i18n';
import { ResponsiveBar } from '@nivo/bar';

const InsightReport = () => {
  const [data, setData] = useState([] as any[]);
  const [barData, setBarData] = useState([] as any[]);
  const [totals, setTotals] = useState({ credit: 0, debit: 0 });
  const [chartType, setchartType] = useState(1);
  const [avgExpenses, setAvgExpenses] = useState(0);
  const [selectedTick, setSelectedTick] = useState(undefined);
  const {
    bankStats,
    loadingBankStats
  }: { bankStats: BankingOverviewStats, loadingBankStats: boolean } = useSelector((state: any) => state.banking);
  const [period, setPeriod] = useState<TimePeriod>(TimePeriod.M12);
  const { t } = useTranslation();

  useEffect(() => {
    if (!bankStats || loadingBankStats) {
      return;
    }

    const debitByMonth = [] as any[];
    const creditByMonth = [] as any[];
    let totalDebit = 0;
    let totalCredit = 0;

    const earliestDate = dayjs().subtract(toNumber(period), 'month').subtract(1, 'day');
    bankStats.lastYearTransactions
      .filter(tr => !tr.excluded && dayjs(tr.date).startOf(period === TimePeriod.M1 ? 'week' : 'month').isAfter(earliestDate))
      .forEach((tr: BankingTransaction) => {
        const dateGroup = dayjs(tr.date).startOf(period === TimePeriod.M1 ? 'week' : 'month').format('YYYY-MM-DD');
        let recordDebit = debitByMonth.find(r => r.x === dateGroup);
        let recordCredit = creditByMonth.find(r => r.x === dateGroup);
        if (!recordDebit) {
          recordDebit = { x: dateGroup, y: 0 };
          debitByMonth.push(recordDebit);
        }
        if (!recordCredit) {
          recordCredit = { x: dateGroup, y: 0 };
          creditByMonth.push(recordCredit);
        }
        recordDebit.y += tr.debit;
        recordCredit.y += tr.credit;
        totalDebit += tr.debit;
        totalCredit += tr.credit;
      });

    const _data = [
      {
        id: 'debit',
        color: '#788EA4',
        data: debitByMonth
      },
      {
        id: 'credit',
        color: '#00CCCC',
        data: creditByMonth
      }
    ];
    const _barData = debitByMonth.map(d => {
      return { x: d.x, debit: d.y, credit: creditByMonth.find(c => c.x === d.x).y };
    }).sort((a, b) => dayjs(b.x).isBefore(dayjs(a.x)) ? 1 : -1);

    setData(_data);
    setBarData(_barData);
    setTotals({ credit: totalCredit, debit: totalDebit });
    const avgExp = debitByMonth.map(s => s.y).reduce((a, b) => a + b, 0) / debitByMonth.length;
    setAvgExpenses(avgExp || 0);
  }, [bankStats, loadingBankStats, period]);

  if (data.length === 0) {
    return (
      <div className={styles.progress}>
        <CircularProgress color={CircularProgressColorEmun.primary}/>
      </div>);
  }

  const CustomPoint = (props: any) => {
    const { currentSlice } = props;
    // it will show the current slice's points
    if (currentSlice) {
      setSelectedTick(currentSlice.x);
      return (
        <g>
          {currentSlice.points.map((p: Point) => {
            return (
              <circle
                style={{ pointerEvents: 'none' }}
                key={p.id}
                fill={p.serieColor}
                r={6}
                strokeWidth={2}
                stroke={p.serieColor}
                fillOpacity={1}
                cx={p.x}
                cy={p.y}
              />
            );
          })}
        </g>
      );
    } else {
      setSelectedTick(undefined);
    }
  };

  const AreaLayer = (props: any) => {
    const { series, xScale, yScale, height } = props;
    const areaGenerator = area()
      .x((d: any) => xScale(d.data.x))
      .y0((d: any) => height)
      .y1((d: any) => yScale(d.data.y + 10))
      .curve(curveMonotoneX);

    return (
          <>
              <Defs
                  defs={[
                    {
                      id: 'pattern',
                      type: 'patternLines',
                      background: 'transparent',
                      color: '#00cccc',
                      lineWidth: 1,
                      spacing: 6,
                      rotation: -45
                    }
                  ]}
              />
              <path
                  d={areaGenerator(series[1].data) as string}
                  fill="#B7E0E0"
                  fillOpacity={0.6}
                  stroke="#00cccc"
                  strokeWidth={0}
              />
          </>
    );
  };

  const AverageDashedLine = (props: any) => {
    const { series, lineGenerator, xScale, yScale } = props;
    const { id, data } = series[0]; // based on debit series
    return (
      <>
        <defs>
          <filter x="-0.1" y="-0.05" width="1.2" height="1.2" id="solid">
            <feFlood floodColor="#F5FAFE" result="bg"/>
            <feMerge>
              <feMergeNode in="bg"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        <g transform={`translate(20,${yScale(avgExpenses) + 20})`}>
          <text
            filter="url('#solid')"
            alignmentBaseline={'middle'}
            fill={'#A8B8C9'}
            fontSize={14}
            fontFamily={'Gilmer-Regular'}
          >
            {t('avg')} {formatCurrency(avgExpenses, 0)} {period === TimePeriod.M1 ? t('perWeek') : ''}
          </text>
        </g>
        <path
            key={id}
            d={lineGenerator(
              data.map((d: any) => ({
                x: xScale(d.data.x),
                y: yScale(avgExpenses)
              }))
            )}
            fill="none"
            stroke={'#A8B8C9'}
            style={{
              strokeDasharray: '4, 8',
              strokeWidth: 4,
              strokeLinejoin: 'round',
              strokeLinecap: 'round'
            }}
        />
      </>
    );
  };

  const AverageDashedLineForBar = (props: any) => {
    const { innerWidth, yScale } = props;
    return (
      <svg>
        <defs>
          <filter x="-0.1" y="-0.05" width="1.2" height="1.2" id="solid">
            <feFlood floodColor="#F5FAFE" result="bg"/>
            <feMerge>
              <feMergeNode in="bg"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        <g transform={`translate(20,${yScale(avgExpenses) - 20})`}>
          <text
            filter="url('#solid')"
            alignmentBaseline={'middle'}
            fill={'#A8B8C9'}
            fontSize={14}
            fontFamily={'Gilmer-Regular'}
          >
            {t('avg')} {formatCurrency(avgExpenses, 0)} {period === TimePeriod.M1 ? t('perWeek') : ''}
          </text>
        </g>
        <line
          x1={0}
          y1={yScale(avgExpenses)}
          x2={innerWidth}
          y2={yScale(avgExpenses)}
          fill="none"
          stroke={'#A8B8C9'}
          style={{
            strokeDasharray: '4, 8',
            strokeWidth: 4,
            strokeLinejoin: 'round',
            strokeLinecap: 'round'
          }}
        />
      </svg>
    );
  };

  const PeriodSelector = () => {
    return (
      <div className={styles.periodSelector}>
        <div onClick={() => setchartType(1)}
             className={`${styles.period} ${chartType === 1 ? styles.periodSelected : ''}`}>
          <Lines fill={chartType === 1 ? '#fff' : '#00CCCC'} />
        </div>
        <div onClick={() => setchartType(2)}
             className={`${styles.period} ${chartType === 2 ? styles.periodSelected : ''}`}>
          <Bars fill={chartType === 2 ? '#fff' : '#00CCCC'} />
        </div>
        <div/>
        <div/>
        {Object.keys(TimePeriod).map(key => {
          // @ts-ignore
          return TimePeriod[key];
        }).map((timePeriod: TimePeriod) =>
          <div key={timePeriod}
               onClick={() => setPeriod(timePeriod)}
               className={`${styles.period} ${timePeriod === period ? styles.periodSelected : ''}`}>
            {t(`timePeriod_${timePeriod}`)}
          </div>
        )}
      </div>
    );
  };

  const Totals = () => {
    return (
      <div className={styles.totalsContainer}>
        <div className={styles.total}>
          <div className={styles.income}>
            <div className={styles.arrowContainer}>
              <img src={up} alt={t('overview:reportIncome')} style={{ height: '28px' }}/>
            </div>
            <HardbaconAmount amount={totals.debit} decimals={0} size={24} bold={true} />
          </div>
          <div>{t('overview:reportIncome')}</div>
        </div>
        <div className={styles.total}>
          <div className={styles.expenses}>
            <div className={styles.arrowContainer}>
              <img src={down} alt={t('overview:reportExpenses')} style={{ height: '28px' }}/>
            </div>
            <HardbaconAmount amount={totals.credit} decimals={0} size={24} bold={true} />
          </div>
          <div>{t('overview:reportExpenses')}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <PeriodSelector />

      <Totals />

      {chartType === 1 && (<div className={styles.reportContainer}>
        <ResponsiveLine
          data={data}
          lineWidth={4}
          margin={{ top: 50, right: 0, bottom: 80, left: 0 }}
          xScale={{ format: '%Y-%m-%d', type: 'time', useUTC: false }}
          xFormat="time:%Y-%m-%d"
          enablePointLabel={false}
          enablePoints={false}
          enableSlices="x"
          useMesh={true}
          colors={d => d.color}
          sliceTooltip={({ slice }) => {
            return (
              <div className={styles.tooltip}>
                {slice.points.map(point => (
                  <div
                    key={point.id}
                    className={styles.tooltipItem}
                    style={{ color: point.serieColor }}
                  >
                    <img alt={point.serieId as string} src={point.serieId === 'credit' ? up : down}/>
                    <HardbaconAmount amount={point.data.y as number} decimals={0} size={18}/>
                  </div>
                ))}
              </div>
            );
          }}
          axisBottom={{
            tickValues: `every ${period === TimePeriod.M1 ? 'week' : '1 month'}`,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: '%b',
            legend: '',
            legendOffset: 36,
            legendPosition: 'middle',
            renderTick: ({
              tickIndex,
              opacity,
              textAnchor,
              textBaseline,
              textX,
              textY,
              value,
              x,
              y
            }) => {
              // Move the first and last labels so that they appear inside the chart
              const offset = period === TimePeriod.M1 ? 28 : 22;
              const newX = tickIndex === 0 ? offset : tickIndex === data[0].data.length - 1 ? (x - offset) : x;
              const periodLabel = dayjs(value).format(period === TimePeriod.M1 ? 'MM/DD' : 'MMM');
              return (
                <g transform={`translate(${newX},${y + 10})`}>
                  <text
                    alignmentBaseline={'middle'}
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                    fill={selectedTick === x ? '#00CCCC' : '#A8B8C9'}
                    fontSize={selectedTick === x ? 20 : 14}
                    fontFamily={selectedTick === x ? 'Gilmer-Medium' : 'Gilmer-Regular'}
                  >
                    {periodLabel}
                  </text>
                </g>
              );
            }
          }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false
          }}
          curve='monotoneX'
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisLeft={null}
          enableGridX={false}
          enableGridY={false}
          layers={[
            'grid',
            AreaLayer,
            'markers',
            'axes',
            'areas',
            'crosshair',
            'lines',
            AverageDashedLine,
            'slices',
            'points',
            'mesh',
            'legends',
            CustomPoint]}
          theme={{
            axis: {
              ticks: {
                line: {
                  strokeWidth: 0
                },
                text: {
                  fontSize: 14,
                  fill: '#A8B8C9'
                }
              },
              domain: {
                line: {
                  strokeWidth: 0
                }
              }
            }
          }}
        />
      </div>)}

      {chartType === 2 && (<div className={styles.reportContainer}>
        <ResponsiveBar
          data={barData}
          groupMode="grouped"
          keys={[
            'credit',
            'debit'
          ]}
          indexBy="x"
          margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          axisLeft={null}
          axisTop={null}
          axisRight={null}
          enableLabel={false}
          borderRadius={4}
          colors={(data) => data.id === 'debit' ? '#788EA4' : '#00CCCC'}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: null,
            legendPosition: 'middle',
            legendOffset: 32,
            renderTick: ({
              tickIndex,
              opacity,
              textAnchor,
              textBaseline,
              textX,
              textY,
              value,
              x,
              y
            }) => {
              const periodLabel = dayjs(value).format(period === TimePeriod.M1 ? 'MM/DD' : 'MMM');
              return (
                <g transform={`translate(${x},${y})`}>
                  <text
                    alignmentBaseline={'middle'}
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                    fill={selectedTick === x ? '#00CCCC' : '#A8B8C9'}
                    fontSize={selectedTick === x ? 20 : 14}
                    fontFamily={selectedTick === x ? 'Gilmer-Medium' : 'Gilmer-Regular'}
                  >
                    {periodLabel}
                  </text>
                </g>);
            }
          }}
          tooltip={({ data }) => {
            return (
              <div className={styles.tooltip}>
                  <div
                    className={styles.tooltipItem}
                    style={{ color: '#00CCCC' }}
                  >
                    <img alt={'credit'} src={up}/>
                    <HardbaconAmount amount={data.credit} decimals={0} size={18}/>
                  </div>
                <div
                  className={styles.tooltipItem}
                  style={{ color: '#788EA4' }}
                >
                  <img alt={'debit'} src={down}/>
                  <HardbaconAmount amount={data.debit} decimals={0} size={18}/>
                </div>
              </div>
            );
          }}
          enableGridX={false}
          enableGridY={false}
          layers={[
            'grid',
            'axes',
            'bars',
            'markers',
            'legends',
            'annotations',
            AverageDashedLineForBar
          ]}
        />
      </div>)}

    </div>
  );
};

export default InsightReport;
