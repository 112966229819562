export const transactionsEn = {
  transaction: 'Transaction',
  morning: 'Good morning {{name}}!',
  afternoon: 'Good afternoon {{name}}!',
  evening: 'Good evening {{name}}!',
  splitTransaction: 'Split transaction',
  originalDescription: 'Bank description',
  date: 'Date',
  description: 'Description',
  removeFromAnalytics: 'Remove from analytics',
  myPart: 'My part',
  totalYouPaid: 'Total you paid',
  splitBetweenPeople: 'Between people',
  splitBetweenCategories: 'Between categories',
  chooseAtLeastTwoCategories: 'Please choose at least 2 categories',
  timePeriod: 'Time period',
  custom: 'Custom',
  currentMonth: 'Current month',
  prevMonth: 'Last month',
  addManualTrx: 'Add a manual transaction to this account',
  deleteManualTrxPopUp: 'Are you sure you want to delete this transaction?',
  deleteManualTrx: 'Delete this transaction'
};

export const transactionsFr = {
  transaction: 'Transaction',
  morning: 'Bonjour {{name}} !',
  afternoon: 'Bon après-midi {{name}} !',
  evening: 'Bonsoir {{name}} !',
  splitTransaction: 'Séparer la transaction',
  originalDescription: 'Description banque',
  date: 'Date',
  description: 'Description',
  removeFromAnalytics: 'Enlever des statistiques',
  myPart: 'Ma part',
  totalYouPaid: 'Total payé',
  splitBetweenPeople: 'Entre plusieurs personnes',
  splitBetweenCategories: 'En plusieurs catégories',
  chooseAtLeastTwoCategories: 'Veuillez choisir au moins deux catégories',
  timePeriod: 'Filtrer par période',
  custom: 'Dates',
  currentMonth: 'Mois en cours',
  prevMonth: 'Mois dernier',
  addManualTrx: 'Ajouter une transaction manuelle au compte',
  deleteManualTrxPopUp: 'Êtes-vous sûr de vouloir supprimer cette transaction ?',
  deleteManualTrx: 'Supprimer cette transaction'
};
