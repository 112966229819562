import AxiosInstance from './index';
import { fromAxiosError } from '../model/APIError';
import InstitutionSource from '../model/InstitutionSource';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

export function getAvailableInstitution (): Observable<InstitutionSource[]> {
  return AxiosInstance.get<InstitutionSource[]>('api/institution-sources?size=300').pipe(
    map(resp => resp.data),
    catchError(error => throwError(fromAxiosError(error))));
}
