import React, { Component, SyntheticEvent } from 'react';
import UserInstitution from '../model/UserInstitution';
import { formatCurrency, formatDate } from '../i18n';
import dayjs from 'dayjs';
import { getIconForInstitution } from '../utils';
import { WithTranslation, withTranslation } from 'react-i18next';

type InstitutionRowState = {
    mouseOver: boolean;
}

type InstitutionRowProps = WithTranslation & {
    institution: UserInstitution,
    disconnect: Function,
    onClick: Function,
}

class InstitutionRow extends Component<InstitutionRowProps, InstitutionRowState> {
  constructor (props: InstitutionRowProps) {
    super(props);
    this.state = {
      mouseOver: false
    };
  }

  disconnect (event: SyntheticEvent, institution: UserInstitution) {
    event.stopPropagation();
    this.props.disconnect(institution);
  }

  render () {
    const { t, institution, i18n } = this.props;
    const { mouseOver } = this.state;

    const syncStatusLabel = institution.syncStatus === 'syncing'
      ? t('synchronizing')
      : dayjs(institution.syncDate).format('DD.MM') !== dayjs().format('DD.MM')
        ? t('unsynchronized')
        : t('synchronized');
    const syncStatusClass = institution.syncStatus === 'syncing'
      ? 'text-black'
      : dayjs(institution.syncDate).format('DD.MM') !== dayjs().format('DD.MM')
        ? 'text-red-600'
        : 'text-green-400';

    const isManual = institution?.institutionSource.description === 'BANKING' &&
    institution?.institutionSource.dataSource === 'DATABASE';

    const name = i18n.language === 'fr' ? institution.institutionSource.displayNameFrench : institution.institutionSource.displayNameEnglish;

    return (
            <li key={institution.id} onMouseOver={() => this.setState({ mouseOver: true })} onMouseLeave={() => this.setState({ mouseOver: false })}>
                <button className="block hover:bg-gray-50 w-full text-left"
                        onClick={() => this.props.onClick()}>
                    <div className="flex flex-1 flex-col sm:flex-row sm:items-center px-1 py-4 sm:px-6">
                        <div className="min-w-0 flex-1 flex items-center">
                            <div className="flex-shrink-0 w-12 object-contain">
                                <img className="h-12 w-auto mx-auto"
                                     src={getIconForInstitution(institution.institutionSource.iconName)}
                                     alt="institution"/>
                            </div>
                            <div className="min-w-0 flex-1 px-1 sm:px-4 md:grid md:grid-cols-2 md:gap-4">
                                <div>
                                    <p className="text-sm font-gilmerBold text-black">
                                        <span>{isManual ? name : institution.name}</span>
                                        {mouseOver && (<span className={'text-hardbacon-error ml-1 text-xs cursor-pointer'}
                                              onClick={(event) => this.disconnect(event, institution)}>{t('disconnectAccount')}</span>)}
                                    </p>
                                    <p className={'flex items-center text-sm ' + syncStatusClass}>
                                        <span >{syncStatusLabel}</span>
                                    </p>
                                </div>
                                <div className="hidden md:block">
                                    <div>
                                        <p className="text-sm text-black font-gilmerBold">
                                            <time dateTime={dayjs(institution.syncDate).format('YYYY-MM-DD')}>{formatDate(institution.syncDate, 'd MMM yyyy')}</time>
                                        </p>
                                        <p className="flex items-center text-sm text-gray-400">
                                            {t('lastSync')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ minWidth: 150 }} className={'flex flex-row justify-end text-right sm:text-left mt-2 sm:mt-0'}>
                            <span className={'font-gilmerBold ' + (institution.value > 0 ? 'text-green-400' : 'text-red-600')}>{formatCurrency(institution.value, 2, institution.currency)}</span>
                            <svg className="h-5 w-5 text-gray-400 mt-0.5" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                        </div>
                    </div>
                </button>
            </li>
    );
  }
}

export default withTranslation('manageAccounts')(InstitutionRow);
