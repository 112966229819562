import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../assets/styles/modules/UserInterests.module.css';
import { getCategoriesIds, getNewsCategories, NewsCategoryItem } from '../../utils/feed';
import { useSelector, useDispatch } from 'react-redux';
import { getNewsFeed, updateUserNewsTopics, updateUserNewsTopicsStarts } from '../../actions/Feed';
import _ from 'lodash';
import { isTablet } from '../../utils/hooks';

const UserInterests = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isUserNewsTopicsUpdating } = useSelector((state: any) => state.feed);
  const [newSelectedNewsTopics, setNewSelectedNewsTopics] = useState<string[]>();
  const isTabletScreen = isTablet();
  const userInterests = getNewsCategories();

  useEffect(() => {
    update(newSelectedNewsTopics);
  }, [newSelectedNewsTopics]);

  const update = useCallback(
    // Apply all changes 1 sec later after the frist change
    _.debounce((topics) => {
      if (topics !== newSelectedNewsTopics) {
        dispatch(updateUserNewsTopics(topics));
        dispatch(getNewsFeed(1, getCategoriesIds(userInterests, topics)));
      }
      if (topics === undefined && newSelectedNewsTopics === undefined) {
        dispatch(getNewsFeed(1, getCategoriesIds(getNewsCategories(), topics)));
      }
    }, 1000),
    []
  );

  const updateTopics = (i: NewsCategoryItem, value: boolean) => {
    let newTopics: string[] | undefined = [];
    if (!value) {
      newTopics.push(i.key);
    } else {
      newTopics = undefined;
    }
    !isUserNewsTopicsUpdating && dispatch(updateUserNewsTopicsStarts());
    // Save changes
    setNewSelectedNewsTopics(newTopics);
  };

  const renderItem = (i: NewsCategoryItem) => {
    const isSelected = !!newSelectedNewsTopics?.find((v: string) => i.key === v);
    return (
        <button onClick={() => updateTopics(i, isSelected)} key={i.name} className={`${styles.container} ${isSelected ? styles.selected : ''}`}>
            <p>
            {i.name}
            </p>
        </button>
    );
  };

  return (
    <section>
        {!isTabletScreen && <p className={styles.header}>{t('feed:articles')}</p>}
        <div className={isTabletScreen ? styles.scrollbar : ''}>
          {userInterests.map(i => {
            return renderItem(i);
          })}
        </div>
    </section>
  );
};

export default UserInterests;
