import React, { useCallback, useEffect, useState } from 'react';
import { goBackIcon, onboard1, onboard2 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import { HardbaconButton, HardbaconModal } from '../../../component';
import IncomeTypeSelector from './IncomeTypeSelector';
import BudgetInput from './BudgetInput';
import BudgetCategoryInput from './BudgetCategoryInput';
import BudgetReview from './BudgetReview';
import BudgetInfoPopUp from './BudgetInfoPopUp';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBudgetCategories,
  setFinancialInfo,
  updateBulkCategoryBudget,
  updateFinancialInfo
} from '../../../actions/Banking';
import { setAddInstitutionPanelOpen } from '../../../actions/UserInstitution';
import { HardbaconModalStyle } from '../../../component/HardbaconModal';
import styles from './../../../assets/styles/modules/budgetInfoPopUpProp.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import SimplePage from '../../../component/Layouts/SimplePage';
import { budgetRoute } from '../../../navigation/Banking';

/* eslint-disable no-unused-vars */
export enum IncomeTypeEmun {
  weekly = 'weekly',
  biWeekly = 'biWeekly',
  monthly = 'monthly',
  biMonthly = 'biMonthly'
}

export enum BudgetStatusTypeEmun {
  perfect,
  exceed,
  subceed
}

/* eslint-disable no-unused-vars */

interface Step {
  title?: string,
  desc?: string,
  mainButton?: {
    title: string,
    onPress?: Function
  },
  subButton?: {
    title: string
  },
  image?: string
}

const OnBoarding = () => {
  const [step, setStep] = useState(0);
  const [openAccountConnectedPopUp, setOpenAccountConnectedPopUp] = useState(false);
  const [openAddCategoryPanel, setOpenAddCategoryPanel] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [incomeType, setIncomeType] = useState(IncomeTypeEmun.weekly);
  const [income, setIncome] = useState<number>(0);
  const [categories, setCategories] = useState({
    Home: {
      id: 60,
      value: 0
    },
    'Food & Dining': {
      id: 43,
      value: 0
    },
    'Auto & Transport': {
      id: 1,
      value: 0
    }
  });

  const location = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { financialInfo, budget } = useSelector((state: any) => state.banking);
  const { hasBankingAccounts, addInstitutionPanelOpen } = useSelector((state: any) => state.userInstitution);

  const getMonthlyIncome = (): number => {
    switch (incomeType) {
      case IncomeTypeEmun.weekly:
        return Number(((income * 52) / 12).toFixed(0));
      case IncomeTypeEmun.biWeekly:
        return Number(((income * 26) / 12).toFixed(0));
      case IncomeTypeEmun.monthly:
        return income;
      case IncomeTypeEmun.biMonthly:
        return Number(((income * 24) / 12).toFixed(0));
      default:
        return 0;
    }
  };

  const getCurrency = (v: number) => {
    const isEnglish = i18n.language !== 'fr';
    return isEnglish ? '$' + Math.round(v * 100) / 100 : Math.round(v * 100) / 100 + '$';
  };

  const getTotal = () => {
    let total = 0;
    Object.values(categories).map(item => total = total + item.value);
    return total;
  };

  const monthlyIncome = getMonthlyIncome();
  const totalBudget = getTotal();
  const isOverBudget = totalBudget > monthlyIncome;
  const percentage = monthlyIncome ? ((totalBudget / monthlyIncome) * 100).toFixed(0) : '0';
  const diff = monthlyIncome - totalBudget;
  const diffCurrency = getCurrency(Math.abs(diff));

  const budgetStatus = monthlyIncome === totalBudget
    ? BudgetStatusTypeEmun.perfect
    : monthlyIncome < totalBudget ? BudgetStatusTypeEmun.exceed : BudgetStatusTypeEmun.subceed;

  const isButtonDisabled = () => {
    return (step === 2 && !income) || (step === 6 && !totalBudget);
  };

  useEffect(() => {
    dispatch(getBudgetCategories());
  }, []);

  // Navigate directly to the wizard step provided in the navigation state
  useEffect(() => {
    if (location && location.state && location.state) {
      const { step } = location.state as any;
      if (step) {
        setStep(step);
      }
    }
  }, [location]);

  useEffect(() => {
    if (step === 1 && !addInstitutionPanelOpen && hasBankingAccounts) {
      setOpenAccountConnectedPopUp(true);
    }
  }, [hasBankingAccounts, addInstitutionPanelOpen]);

  useEffect(() => {
    if (!financialInfo) {
      return;
    }
    if (financialInfo.payrollFrequencyPerYear && financialInfo.monthlyEarnings) {
      setIncomeType(getNetSalaryTimeframe(financialInfo.payrollFrequencyPerYear));
      let incomeTotal = 0;
      incomeTotal = financialInfo.monthlyEarnings
        ? parseInt(((financialInfo.monthlyEarnings * 12) / financialInfo.payrollFrequencyPerYear).toFixed(0), 10)
        : 0;
      setIncome(incomeTotal);
    }
  }, [financialInfo]);

  useEffect(() => {
    if (budget && budget.length !== 0) {
      navigate(budgetRoute);
    }
  }, [budget]);

  useEffect(() => {
    const onKeyup = (e: any) => {
      if (e.key === 'Enter' && !isButtonDisabled()) {
        onButtonPress();
      }
    };

    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [step, isButtonDisabled()]);

  const steps: Array<Step> = [
    {
      title: t('budgetOnboarding:step1:title'),
      desc: t('budgetOnboarding:step1:desc'),
      mainButton: {
        title: t('budgetOnboarding:step1:button')
      },
      subButton: {
        title: t('budgetOnboarding:step1:subButton')
      },
      image: onboard1
    },
    {
      title: t('budgetOnboarding:step2:title'),
      desc: t('budgetOnboarding:step2:desc'),
      mainButton: {
        title: t('manageAccounts:addAccount')
      },
      subButton: {
        title: t('budgetOnboarding:step2:subButton')
      },
      image: onboard2
    },
    {
      title: t('budgetOnboarding:step3:title'),
      desc: t('budgetOnboarding:step3:desc'),
      mainButton: {
        title: t('continue')
      }
    },
    {
      title: t('budgetOnboarding:step4:title'),
      mainButton: {
        title: t('continue')
      }
    },
    {
      title: t('budgetOnboarding:step5:title'),
      mainButton: {
        title: t('continue')
      }
    },
    {
      title: t('budgetOnboarding:step6:title'),
      mainButton: {
        title: t('continue')
      }
    },
    {
      title: budgetStatus === BudgetStatusTypeEmun.perfect
        ? t('budgetOnboarding:budgetState:perfect:title')
        : budgetStatus === BudgetStatusTypeEmun.exceed
          ? t('budgetOnboarding:budgetState:exceed:title')
          : t('budgetOnboarding:budgetState:subceed:title1') + diffCurrency + t('budgetOnboarding:budgetState:subceed:title2'),
      desc: budgetStatus === BudgetStatusTypeEmun.perfect
        ? t('budgetOnboarding:budgetState:perfect:subTitle')
        : budgetStatus === BudgetStatusTypeEmun.exceed
          ? t('budgetOnboarding:budgetState:exceed:subTitle1') + diffCurrency + t('budgetOnboarding:budgetState:exceed:subTitle2')
          : t('budgetOnboarding:budgetState:subceed:subTitle'),
      mainButton: {
        title: t('finish'),
        onPress: () => setOpenAlert(true)
      }
    }
  ];

  const renderProgressIndicator = () => {
    const items = new Array(5).fill('empty');
    return (
      <div className='progress-container'>
        {items.map((i, index) =>
          (
            <div className={'progress-item-container'} key={index}>
              <div className={'progress-item' + (step - 2 === index ? ' selected' : '')}/>
            </div>
          )
        )}
      </div>
    );
  };

  const getNetSalaryTimeframe = (f: number) => {
    switch (f) {
      case 12:
        return IncomeTypeEmun.monthly;
      case 24:
        return IncomeTypeEmun.biMonthly;
      case 52:
        return IncomeTypeEmun.weekly;
      case 26:
        return IncomeTypeEmun.biWeekly;
      default:
        return IncomeTypeEmun.monthly;
    }
  };

  const getPayrollFrequencyPerYear = () => {
    switch (incomeType) {
      case IncomeTypeEmun.weekly:
        return 52;
      case IncomeTypeEmun.biWeekly:
        return 26;
      case IncomeTypeEmun.monthly:
        return 12;
      case IncomeTypeEmun.biMonthly:
        return 24;
      default:
        return 0;
    }
  };

  const renderBudgetCategoryInput = (value: number, emoji: string, setValue: (v: number) => any) => {
    return (
      <BudgetCategoryInput
        setValue={setValue}
        value={value}
        emoji={emoji}
        isOverBudget={isOverBudget}
        diffCurrency={diffCurrency}
        valueCurrency={getCurrency(value)}
        incomeCurrency={getCurrency(monthlyIncome)}
        budgetPercentage={(value / monthlyIncome * 100).toFixed(0)}
        diff={diff}
        percentage={percentage}
      />
    );
  };

  const setCategoryValue = (key: string, id: number, value: number) => {
    const newCategories = JSON.parse(JSON.stringify(categories));
    newCategories[key] = { id: id, value: value };
    setCategories(newCategories);
  };

  const goBack = () => {
    if (step === 6) {
      const newCategories = JSON.parse(JSON.stringify(categories));
      if (!categories.Home) {
        newCategories.Home = { id: 60, value: 0 };
      }
      if (!categories['Food & Dining']) {
        newCategories['Food & Dining'] = { id: 43, value: 0 };
      }
      if (!categories['Auto & Transport']) {
        newCategories['Auto & Transport'] = { id: 1, value: 0 };
      }
      setCategories(newCategories);
    }
    if (step === 2 && hasBankingAccounts) {
      setStep(0);
    } else {
      setStep(step - 1);
    }
  };

  const onButtonPress = () => {
    const onPress = steps[step]?.mainButton?.onPress;
    if (step === 1 && !hasBankingAccounts) {
      dispatch(setAddInstitutionPanelOpen(true));
    } else if (onPress) {
      onPress();
    } else {
      if (step === 0 && hasBankingAccounts) {
        setStep(2);
      } else {
        setStep(step + 1);
      }
    }
  };

  const addBudget = (categoriesDto: any, value: number, categoryId: number) => {
    categoriesDto.push(buildDTOForCategory(value, categoryId));
  };

  const buildDTOForCategory = (value: number, categoryId: number) => {
    return {
      isActive: true,
      amount: value,
      isBudgetBySubCategory: false,
      isDisplayHome: true,
      isDisplayTrend: true,
      isNotificationEnabled: true,
      categoryId,
      topLevelCategoryId: categoryId,
      subCategoryBudgets: []
    };
  };

  const completeBudget = useCallback(() => {
    const newFinancialInfo: {
      monthlyEarnings: number;
      payrollFrequencyPerYear: number;
      id?: number
    } = {
      monthlyEarnings: getMonthlyIncome(),
      payrollFrequencyPerYear: getPayrollFrequencyPerYear()
    };
    if (financialInfo?.id) {
      newFinancialInfo.id = financialInfo.id;
      dispatch(updateFinancialInfo(newFinancialInfo));
    } else {
      dispatch(setFinancialInfo(newFinancialInfo));
    }

    const allCategoriesArrary = Object.values(categories);
    const categoriesDto: Array<any> = [];
    allCategoriesArrary.map(cat => cat.value && addBudget(categoriesDto, cat.value, cat.id));
    categoriesDto.push(buildDTOForCategory(getMonthlyIncome(), 68));
    dispatch(updateBulkCategoryBudget(categoriesDto));
  }, [financialInfo, categories]);

  return (
    <SimplePage>
      {budget && budget.length === 0 && (<div className='onboard'>
        <BudgetInfoPopUp setOpenAddCategoryPanel={setOpenAddCategoryPanel} budgetStatus={budgetStatus}
                         diffCurrency={diffCurrency} openAlert={openAlert} setOpenAlert={setOpenAlert}
                         completeBudget={completeBudget}/>
        <HardbaconModal
          style={HardbaconModalStyle.none}
          open={openAccountConnectedPopUp}
          negativeText={t('cancel')}
          positiveText={t('save')}
        >
          <div className={styles.container}>
            <span className={styles.emoji}>{'🎉'}</span>
            <p className={styles.title}>{t('accountConnectedTitle')}</p>
            <p className={styles['sub-title']}>{t('accountConnectedSubTitle')}</p>
            <div className={styles.buttonGroup}>
              <HardbaconButton
                className={styles.button}
                onPress={() => {
                  setOpenAccountConnectedPopUp(false);
                  setStep(2);
                }}
                name={'makeMyBudget'}
                title={t('makeMyBudget')}/>
            </div>
          </div>
        </HardbaconModal>
        <header className='onboard-header'>
          {step > 0 && <img className={'back-icon'} alt={'Go back'} src={goBackIcon} onClick={() => goBack()}/>}
          <h1 className='title'>{t('header:budget')}</h1>
        </header>
        <main className='main'>
          {steps[step].image && <img className={'main-image'} src={steps[step].image}/>}
          <section className={'content' + (steps[step].image ? '' : ' large')}>
            {step > 1 && renderProgressIndicator()}
            <p className='title'>{steps[step].title}</p>
            {steps[step].desc && <p className='desc'>{steps[step].desc}</p>}
            {step === 2 && <IncomeTypeSelector
              setType={(type: IncomeTypeEmun) => {
                setIncomeType(type);
              }}
              type={incomeType}
            />}
            {step === 2 &&
              <div className='budget-input-container'>
                <BudgetInput
                  setValue={(v: number) => {
                    setIncome(v);
                  }}
                  value={income}
                />
              </div>
            }
            {step === 3 && renderBudgetCategoryInput(categories.Home.value, '🏠', (v: number) => setCategoryValue('Home', 60, v))}
            {step === 4 && renderBudgetCategoryInput(categories['Food & Dining'].value, '🌮', (v: number) => setCategoryValue('Food & Dining', 43, v))}
            {step === 5 && renderBudgetCategoryInput(categories['Auto & Transport'].value, '🚗', (v: number) => setCategoryValue('Auto & Transport', 1, v))}
            {step === 6 && <BudgetReview
              setOpenAddCategoryPanel={setOpenAddCategoryPanel}
              openAddCategoryPanel={openAddCategoryPanel}
              totalBudget={getCurrency(totalBudget)}
              monthlyIncome={getCurrency(monthlyIncome)}
              diffCurrency={diffCurrency}
              budgetStatus={budgetStatus}
              categories={categories}
              setCategoryValue={setCategoryValue}
              setCategories={setCategories}
            />}
            {
              steps[step]?.mainButton?.title && <HardbaconButton
                disabled={isButtonDisabled()}
                className="button"
                onPress={onButtonPress}
                name={'connect'}
                title={steps[step]?.mainButton?.title}>
              </HardbaconButton>
            }
          </section>
        </main>
      </div>)}
    </SimplePage>
  );
};

export default OnBoarding;
