import React, { useState, useEffect } from 'react';
import styles from './../assets/styles/modules/addSelectedBudgetCategory.module.css';
import { useTranslation } from 'react-i18next';
import { getTransactionCtgNameById, getTransactionCtgEmojiById } from '../utils/banking';
import { TransactionCategory } from '../model/TransactionCategory';
import HardbaconButton from './HardbaconButton';
import FormItemContainer, { FormInputEmun } from './FormItemContainer';
import { useDispatch, useSelector } from 'react-redux';
import IncomeTypeSelector from '../container/Banking/Budget/IncomeTypeSelector';
import { IncomeTypeEmun } from '../container/Banking/Budget/Onboarding';
import { setFinancialInfo, updateBulkCategoryBudget, updateFinancialInfo } from '../actions/Banking';
import { formatCurrency } from '../i18n';

interface UpdateIncomeProps {
  item: TransactionCategory;
  onPress: Function;
}

const UpdateIncome = ({ item, onPress }: UpdateIncomeProps) => {
  const [incomeType, setIncomeType] = useState(IncomeTypeEmun.weekly);
  const [income, setIncome] = useState<number>(0);
  const dispatch = useDispatch();
  const financialInfo = useSelector((state: any) => state.banking.financialInfo);
  const payrollFrequencyPerYear = financialInfo.payrollFrequencyPerYear;
  const monthlyEarnings = financialInfo.monthlyEarnings;
  const financialInfoId = financialInfo.id;

  const { t } = useTranslation();

  useEffect(() => {
    if (payrollFrequencyPerYear && monthlyEarnings) {
      setIncomeType(getNetSalaryTimeframe(payrollFrequencyPerYear));
      let incomeTotal = 0;
      incomeTotal = monthlyEarnings
        ? parseInt(((monthlyEarnings * 12) / payrollFrequencyPerYear).toFixed(0), 10)
        : 0;
      setIncome(incomeTotal);
    }
  }, [monthlyEarnings, payrollFrequencyPerYear]);

  const getNetSalaryTimeframe = (f: number) => {
    switch (f) {
      case 12:
        return IncomeTypeEmun.monthly;
      case 24:
        return IncomeTypeEmun.biMonthly;
      case 52:
        return IncomeTypeEmun.weekly;
      case 26:
        return IncomeTypeEmun.biWeekly;
      default:
        return IncomeTypeEmun.monthly;
    }
  };

  const getPayrollFrequencyPerYear = () => {
    switch (incomeType) {
      case IncomeTypeEmun.weekly:
        return 52;
      case IncomeTypeEmun.biWeekly:
        return 26;
      case IncomeTypeEmun.monthly:
        return 12;
      case IncomeTypeEmun.biMonthly:
        return 24;
      default:
        return 0;
    }
  };

  const getMonthlyIncome = (): number => {
    switch (incomeType) {
      case IncomeTypeEmun.weekly:
        return Number(((income * 52) / 12).toFixed(0));
      case IncomeTypeEmun.biWeekly:
        return Number(((income * 26) / 12).toFixed(0));
      case IncomeTypeEmun.monthly:
        return income;
      case IncomeTypeEmun.biMonthly:
        return Number(((income * 24) / 12).toFixed(0));
      default:
        return 0;
    }
  };

  const addBudget = (categoriesDto: any, value: number, categoryId: number) => {
    categoriesDto.push(buildDTOForCategory(value, categoryId));
  };

  const buildDTOForCategory = (value: number, categoryId: number) => {
    return {
      isActive: true,
      amount: value,
      isBudgetBySubCategory: false,
      isDisplayHome: true,
      isDisplayTrend: true,
      isNotificationEnabled: true,
      categoryId,
      topLevelCategoryId: categoryId,
      subCategoryBudgets: []
    };
  };

  const submit = () => {
    const financialInfo: {
      monthlyEarnings: number;
      payrollFrequencyPerYear: number;
      id?: number
    } = {
      monthlyEarnings: getMonthlyIncome(),
      payrollFrequencyPerYear: getPayrollFrequencyPerYear()
    };
    if (financialInfoId) {
      financialInfo.id = financialInfoId;
      dispatch(updateFinancialInfo(financialInfo));
    } else {
      dispatch(setFinancialInfo(financialInfo));
    }

    const categoriesDto: Array<any> = [];
    if (income) {
      addBudget(categoriesDto, getMonthlyIncome(), item.categoryId);
    }
    dispatch(updateBulkCategoryBudget(categoriesDto));
  };

  return (
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.emoji}>{getTransactionCtgEmojiById(item.categoryId)}</span>
          <span className={styles.name}>{getTransactionCtgNameById(item.categoryId)}</span>
          <span className={styles.desc}><span className={styles.amount}>{formatCurrency(item.periodAmount, 0)}</span>{' ' + t('thisMonth')}</span>
          <span className={styles.desc}>{formatCurrency(item.totalAmount, 0)} {t('lastYear')}</span>
        </div>
        <div className={styles.content}>
          <IncomeTypeSelector
            setType={(type: IncomeTypeEmun) => {
              setIncomeType(type);
            }}
            type={incomeType}
          />
          <FormItemContainer
            id="income"
            left={t('income') + ''}
            right={FormInputEmun.moneyInput}
            value={income}
            onChange={setIncome}
          />
        </div>
        <HardbaconButton
          name={'accept'}
          onPress={() => submit()}
          type="button"
          disabled={income <= 0}
          title={t('update')} />
      </div>
  );
};

export default UpdateIncome;
