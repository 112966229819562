import React, { useEffect, useRef } from 'react';
import HardbaconButton from './HardbaconButton';
import styles from '../assets/styles/modules/HardbaconPopUp.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { closePopUp } from '../actions/PopUp';

const HardbaconPopUp = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { popUp } = useSelector((state: any) => state);

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      dispatch(closePopUp());
    }
  };

  useEffect(() => {
    if (popUp && popUp.closeOnClickOutside) {
      document.addEventListener('mousedown', handleClickOutside, true);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside, true);
      };
    }
  });

  if (!popUp || !popUp.isOpen) {
    return null;
  }

  return (
      <div className={styles.welcomePopup}>
        <div className={styles.content} ref={wrapperRef}>
          { popUp.showCloseButton && <div className={styles.header}>
            <button
                onClick={() => dispatch(closePopUp())}
                className={styles.closeButton}>
              <span className="sr-only">Close panel</span>
              <svg style={{ width: '1.5rem', height: '1.5rem' }} xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>}
          <div className={styles.body}>
            { popUp.emoji && <p className={styles.emoji}>{ popUp.emoji}</p>}
            <div className={styles.title}>
              { popUp.title}
            </div>
            { popUp.desc && <div className={styles.description}>
              { popUp.desc}
            </div>}
            <div className={styles.buttons}>
              <HardbaconButton className={styles.button} name={'connect-institution'} title={ popUp.mainButtonTitle}
              danger={ popUp.danger}
                               onPress={() => popUp.onMainButtonPress()}/>
              <HardbaconButton className={styles.button} name={'connect-later'} ghost={true} title={ popUp.subButtonTitle}
              danger={ popUp.danger}
                               onPress={() => popUp.onSubButtonPress()}/>
            </div>
          </div>
        </div>
      </div>
  );
};

export default HardbaconPopUp;
