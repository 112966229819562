import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadPlayStoreEn, downloadPlayStoreFr, downloadAppStoreEn, downloadAppStoreFr, phone as phoneImg } from '../../assets/images';
import { HardbaconInput, HardbaconButton } from '../../component';
import { useUserId } from '../../hooks';
import HardbaconLeftPanel from '../../component/HardbaconLeftPanel';
import { downloadApp } from '../../api/NotificationService';

const phoneRegex = /[(]+[0-9]{3}[)]+ [0-9]{3}[-]+[0-9]{4}/;

const DownloadApp = () => {
  const { t, i18n } = useTranslation(['downloadApp']);
  const [phone, setPhone] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const userIds = useUserId();

  const downloadPlayStore = i18n.language === 'fr' ? downloadPlayStoreFr : downloadPlayStoreEn;
  const downloadAppStore = i18n.language === 'fr' ? downloadAppStoreFr : downloadAppStoreEn;

  useEffect(() => {
    setDisabled(phone === undefined || !phoneRegex.test(phone));
  }, [phone]);

  const sendText = () => {
    setLoading(true);
    downloadApp(`1${phone.replace(/\D/g, '')}`, i18n.language)
      .subscribe(() => {
        setSent(true);
        setLoading(false);
      });
    setDisabled(true);
  };

  return (
      <div className={'download'}>
        {userIds[0] === undefined && <HardbaconLeftPanel />}
        <div className={'right-panel'}>
          <div className={'content'}>
            <div className={'phone'}><img src={phoneImg} alt={'smartphone'}/></div>
            <div className={'title'}>{t('title')}</div>
            <div className={'subTitle'}>
              <span>{t('subTitle')}</span>
              <a className={'hardbacon-link'} href={t('appStoreLink')} target={'_blank'} rel="noreferrer">App Store</a>
              <span>{t('or')}</span>
              <a className={'hardbacon-link'} href={t('playStoreLink')} target={'_blank'} rel="noreferrer">Google Play</a>
            </div>
            <div className={'form'}>
              <HardbaconInput name={'phoneNumber'}
                              placeholder={t('phonePlaceholder')}
                              mask={'(111) 111-1111'}
                              regex={'[(]+[0-9]{3}[)]+ [0-9]{3}[-]+[0-9]{4}'}
                              onChange={value => setPhone(value)}/>
              <HardbaconButton name={'sendLinkButton'} type={'submit'} title={sent ? t('sent') : t('send')} loading={loading} disabled={disabled} onPress={() => sendText()}/>
            </div>
            <div className={'images'}>
              <a className={'hardbacon-link'} href={t('appStoreLink')} target={'_blank'} rel="noreferrer"><img src={downloadAppStore}/></a>
              <a className={'hardbacon-link'} href={t('playStoreLink')} target={'_blank'} rel="noreferrer"><img src={downloadPlayStore}/></a>
            </div>
            <div className={'subTitle'}>
              <span>{t('whenClicking')}</span>
              <a className={'hardbacon-link'} href={t('translation:hardbaconLinkTerms')} target={'_blank'} rel="noreferrer">{t('translation:termsOfUse')}</a>
            </div>
          </div>
        </div>
      </div>
  );
};

export default DownloadApp;
