import ReducerPayload from '../model/ReducerPayload';
import {
  CREATE_MANUAL_TRANSACTION_ITEM,
  GET_BANKING_TRANSACTIONS,
  GET_BANKING_TRANSACTIONS_SUCCESS,
  GET_TRANSACTION_CATEGORIES,
  REMOVE_BANKING_TRANSACTION_ITEM,
  SET_TRANSACTION_FILTER,
  UPDATE_BANKING_TRANSACTION_ITEM,
  UPDATE_TRANSACTION_DATE_PICKER
} from '../types/Transactions';
import dayjs, { Dayjs } from 'dayjs';
import { BankingTransaction } from '../model/BankingTransaction';
import { TransactionTypeEmun } from '../model/transactions/TransactionTypeEmun';
import { DELETE_MANUAL_ACCOUNT } from '../types/UserInstitution';
import { GET_TOP_LEVEL_BANK_CATEGORIES_SUCCESS } from '../types/Banking';

export const initialState: any = {
  bankingTransactions: undefined as unknown as BankingTransaction[],
  isLoadingBankingTransactions: undefined as unknown as boolean,
  bakingTransactionsStartDate: undefined as unknown as Dayjs,
  bakingTransactionsEndDate: undefined as unknown as Dayjs,
  transactionCategories: [] as any,
  filteredTrxCategories: [] as number[],
  filteredTrxTypes: [] as TransactionTypeEmun[],
  transactionsStartDate: dayjs().startOf('month'),
  transactionsEndDate: dayjs().endOf('month').startOf('day'),
  shouldUpdateBudget: false
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case GET_BANKING_TRANSACTIONS:
      return {
        ...state,
        isLoadingBankingTransactions: true
      };
    case UPDATE_TRANSACTION_DATE_PICKER:
      return {
        ...state,
        transactionsStartDate: action.payload.start,
        transactionsEndDate: action.payload.end
      };
    case GET_BANKING_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingBankingTransactions: false,
        bankingTransactions: action.payload.transactions,
        bakingTransactionsStartDate: action.payload.from,
        bakingTransactionsEndDate: action.payload.to
      };
    case UPDATE_BANKING_TRANSACTION_ITEM: {
      const newTransactions = state.bankingTransactions.slice();
      const {
        bankAccountBackendId,
        backendId
      } = action.payload;
      const trToUpdate = newTransactions
        .find((tr: BankingTransaction) => tr.backendId === backendId && tr.bankAccountBackendId === bankAccountBackendId);
      const index = newTransactions.indexOf(trToUpdate);
      if (index !== -1) {
        newTransactions[index] = { ...trToUpdate, ...action.payload };
      }
      return {
        ...state,
        bankingTransactions: newTransactions,
        shouldUpdateBudget: true
      };
    };
    case REMOVE_BANKING_TRANSACTION_ITEM: {
      let newTransactions = state.bankingTransactions.slice();
      const {
        accountId,
        transactionId
      } = action.payload;
      newTransactions = newTransactions
        .filter((tr: BankingTransaction) => !(tr.id === transactionId && tr.bankAccountId === accountId));
      return {
        ...state,
        bankingTransactions: newTransactions,
        shouldUpdateBudget: true
      };
    }
    case CREATE_MANUAL_TRANSACTION_ITEM: {
      const newTransactions = state.bankingTransactions.slice();
      const item = action.payload;
      newTransactions.unshift(item);
      return {
        ...state,
        bankingTransactions: newTransactions,
        shouldUpdateBudget: true
      };
    }
    case GET_TRANSACTION_CATEGORIES: {
      return {
        ...state,
        transactionCategories: action.payload
      };
    }
    case SET_TRANSACTION_FILTER: {
      return {
        ...state,
        filteredTrxCategories: action.payload.ctgs,
        filteredTrxTypes: action.payload.types
      };
    }
    case DELETE_MANUAL_ACCOUNT: {
      let newTransactions = state.bankingTransactions?.slice();
      const {
        id
      } = action.payload;
      newTransactions = newTransactions
        ?.filter((tr: BankingTransaction) => tr.bankAccountId !== id);
      return {
        ...state,
        bankingTransactions: newTransactions,
        shouldUpdateBudget: true
      };
    }
    case GET_TOP_LEVEL_BANK_CATEGORIES_SUCCESS: {
      return {
        ...state,
        shouldUpdateBudget: false
      };
    }
    default:
      return state;
  }
};
