import { budgetEn } from './budget';
import { categoriesEn } from './categories';
import { errMsgsEn } from './errMsgs';
import { feedEn } from './feed';
import { tooltipsEn } from './tooltips';
import { transactionsEn } from './transactions';

const en = {
  rewardCategories: {
    estimatedReward: 'Estimated rewards for the first year',
    estimatedConsecutiveReward: 'Estimated rewards for subsequent years',
    totalreward: 'Total Reward',
    'air travel': 'Air Travel',
    'rental car & taxi': 'Rental Car & Taxi',
    'additional bonus': 'Additional Bonus',
    gym: 'Gym',
    restaurants: 'Restaurants',
    sports: 'Sports',
    annualfee: 'Annual Fee',
    'auto & transport': 'Auto & Transport',
    'auto insurance': 'Auto Insurance',
    'auto payment': 'Auto Payment',
    parking: 'Parking',
    'public transportation': 'Public Transportation',
    'service & parts': 'Service & Parts',
    'bills & utilities': 'Bills & Utilities',
    'domain names': 'Domain Names',
    'fraud protection': 'Fraud Protection',
    'home phone': 'Home Phone',
    hosting: 'Hosting',
    internet: 'Internet',
    'mobile phone': 'Mobile Phone',
    television: 'Television',
    utilities: 'Utilities',
    'business services': 'Business Services',
    advertising: 'Advertising',
    legal: 'Legal',
    'office supplies': 'Office Supplies',
    printing: 'Printing',
    shipping: 'Shipping',
    education: 'Education',
    'books & supplies': 'Books & Supplies',
    'student loan': 'Student Loan',
    tuition: 'Tuition',
    entertainment: 'Entertainment',
    amusement: 'Amusement',
    arts: 'Arts',
    'movies & dvds': 'Movies & DVDs',
    music: 'Music',
    'newspapers & magazines': 'Newspapers & Magazines',
    'fees & charges': 'Fees & Charges',
    'atm fee': 'ATM Fee',
    'banking fee': 'Banking Fee',
    'finance charge': 'Finance Charge',
    'late fee': 'Late Fee',
    'service fee': 'Service Fee',
    'trade commissions': 'Trade Commissions',
    financial: 'Financial',
    'financial advisor': 'Financial Advisor',
    'life insurance': 'Life Insurance',
    'food & dining': 'Food & Dining',
    'alcohol & bars': 'Alcohol & Bars',
    'coffee shops': 'Coffee Shops',
    'fast food': 'Fast Food',
    groceries: 'Groceries',
    'gifts & donations': 'Gifts & Donations',
    charity: 'Charity',
    gift: 'Gift',
    'health & fitness': 'Health & Fitness',
    dentist: 'Dentist',
    doctor: 'Doctor',
    eyecare: 'Eyecare',
    'health insurance': 'Health Insurance',
    pharmacy: 'Pharmacy',
    home: 'Home',
    furnishings: 'Furnishings',
    'home improvement': 'Home Improvement',
    'home insurance': 'Home Insurance',
    'home services': 'Home Services',
    'home supplies': 'Home Supplies',
    'lawn & garden': 'Lawn & Garden',
    'mortgage & rent': 'Mortgage & Rent',
    income: 'Income',
    bonus: 'Bonus',
    'interest income': 'Interest Income',
    paycheck: 'Paycheck',
    reimbursement: 'Reimbursement',
    'rental income': 'Rental Income',
    investments: 'Investments',
    buy: 'Buy',
    deposit: 'Deposit',
    'dividend & cap gains': 'Dividend & Cap Gains',
    sell: 'Sell',
    withdrawal: 'Withdrawal',
    kids: 'Kids',
    allowance: 'Allowance',
    'baby supplies': 'Baby Supplies',
    'babysitter & daycare': 'Babysitter & Daycare',
    'child support': 'Child Support',
    'kids activities': 'Kids Activities',
    toys: 'Toys',
    'personal care': 'Personal Care',
    hair: 'Hair',
    laundry: 'Laundry',
    'spa & massage': 'Spa & Massage',
    pets: 'Pets',
    'pet food & supplies': 'Pet Food & Supplies',
    'pet grooming': 'Pet Grooming',
    veterinary: 'Veterinary',
    shopping: 'Shopping',
    books: 'Books',
    clothing: 'Clothing',
    hobbies: 'Hobbies',
    'sporting goods': 'Sporting Goods',
    taxes: 'Taxes',
    'federal tax': 'Federal Tax',
    'local tax': 'Local Tax',
    'property tax': 'Property Tax',
    'sales tax': 'Sales Tax',
    'state tax': 'State Tax',
    transfer: 'Transfer',
    'credit card payment': 'Credit Card Payment',
    'transfer for cash spending': 'Transfer for Cash Spending',
    'mortgage payment': 'Mortgage Payment',
    travel: 'Travel',
    hotel: 'Hotel',
    vacation: 'Vacation',
    cash: 'Cash',
    check: 'Check',
    'electronics & software': 'Electronics & Software',
    'returned purchase': 'Returned Purchase'
  },
  translation: {
    delete: 'Delete',
    create: 'Create',
    from: 'from',
    to: 'to',
    type: 'Type',
    categories: 'Categories',
    filter: 'Filter',
    viewMore: 'View more',
    edit: 'Edit',
    update: 'Update',
    start: 'Get Started',
    finish: 'Done',
    continue: 'Continue',
    comingSoon: 'Coming soon...',
    simpleFinance: 'Make better financial decisions',
    keepControlOfYourFinance: 'All the tools you need to plan, budget and invest !',
    peopleWhoWonTheLoto: 'The most exhaustive personal finance app in Canada.',
    alreadyHaveAccount: 'Already have an account?',
    signIn: 'Sign in',
    signInWithGoogle: 'Sign in with Google',
    signInWithApple: 'Sign in with Apple',
    signInWithFacebook: 'Sign in with Facebook',
    signUp: 'Sign up',
    signUpWithGoogle: 'Sign up with Google',
    signUpWithApple: 'Sign up with Apple',
    signUpWithFacebook: 'Sign up with Facebook',
    loggingOut: 'Logging out...',
    createAccount: 'Create a new account to start',
    firstName: 'First name',
    lastName: 'Last name',
    phonePlaceholder: '(514) 123-4567',
    email: 'Email',
    password: 'Password',
    passwordRegex: 'Your password must contain at least 10 characters',
    passwordMatch: 'Your password must match the other one',
    noEmptyField: 'This field is required',
    readAndAgreed: 'I have read, understood and agreed to the ',
    termsOfUse: 'Terms of Use',
    invalidRegex: 'Please verify that this information is valid',
    emailAlreadyTaken: 'This email is already in use. Please use a different one.',
    dontHaveAccount: 'Don\'t have an account?',
    wrongEmailOrPassword: 'invalid email or password.',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password ?',
    sendEmail: 'Send email',
    emailSent: 'Email sent',
    afterResetText: 'Should an account exist with the email "{{email}}", instructions to reset the password were sent there.',
    afterResetText2: 'Note that this may take some time to get there. Please check your spams before trying again.',
    resetDone: 'Password reset successful',
    resetDoneText: 'Your password has successfully been reset.',
    resetPassword: 'Change my password',
    hardbaconLinkTerms: 'https://hardbacon.ca/en/legal/',
    newPassword: 'Input your new password',
    confirmPassword: 'Confirm your new password',
    sendNewPassword: 'Validate new password',
    confirmPasswordError: 'The password and its confirmation do not match!',
    resetPasswordInitError: 'Check your email and try again.',
    resetPasswordFinishError: 'The password reset link may have expired. Please try again.',
    signInDescription: '',
    forgotPasswordDescription: '',
    signUpDescription: '',
    manageInformation: 'Manage all information related to your profile',
    profilePicture: 'Profile picture',
    phoneNumber: 'Phone number',
    change: 'Change',
    birthday: 'Birthday',
    cancel: 'Cancel',
    save: 'Save',
    premium: 'Premium',
    managePremium: 'Manage my Hardbacon Premium subscription',
    subscribedFrom: 'Subscribed through',
    nextRenewal: 'Next renewal',
    expirationDate: 'Expiration date',
    appstore: 'App Store',
    playstore: 'Play Store',
    webSubscription: 'Web',
    couponSubscription: 'Promotional coupon',
    cancelSubscription: 'Cancel my subscription',
    cancelLinkAndroid: 'https://hardbacon.ca/en/assistance/unsubscribe/#1591903058665-c912928e-fe43',
    cancelLinkIOS: 'https://hardbacon.ca/en/assistance/unsubscribe/#1591903323107-08c81da8-7b9d',
    cancelWebSubscriptionTitle: 'Want to cancel your subscription?',
    cancelWebSubscriptionDescription: 'To cancel your subscription, please send an email to: support@hardbacon.com',
    iUnderstand: 'I understand',
    chooseFile: 'Choose a file...',
    chooseFile2: '',
    chooseFile3: 'PNG, JPG, GIF up to 500KB',
    uploadImageDialogTitle: 'Please select an image file',
    uploadImageDialogUnableToLoad: 'Unable to load image',
    uploadImageDialogUnableToLoadErrorMessage: 'Maybe your file is too big, the maximum allowed size is 500 KB.',
    subscriptionModalTitle: 'Subscribe to Hardbacon Premium',
    subscription_freeTrial: 'Try Hardbacon Premium free for 30 days',
    subscription_freeTrialSub: 'You will not be charged during the free trial, and you can cancel anytime.',
    subscription_price: '$ {{ price }}',
    subscription_month: 'month',
    subscription_year: 'year',
    subscription_MonthlyPlan: 'Monthly plan',
    subscription_AnnualPlan: 'Annual plan',
    subscription_ChangePlan: 'Change plan',
    subscription_name: 'Name on card',
    subscription_cardInfo: 'Card details',
    subscription_iHaveACoupon: 'I have a coupon',
    subscription_proceed: 'Start my 30-Day free trial',
    subscription_wait: 'Please wait...',
    subscription_thanks_title: 'Thank You',
    subscription_thanks_subtitle1: 'You are now a family member',
    subscription_thanks_subtitle2: 'We just sent you the link to download the app,\nYou can also directly download the app in stores :',
    subscription_thanks_help: 'If you have the app, make sure you logout and login again before using premium features.',
    subscription_thanks_TPS: 'GST: $ {{ TPS }}',
    subscription_thanks_TVQ: 'QST: $ {{ TVQ }}',
    subscription_thanks_total: 'Total: $ {{ total }}',
    subscription_t1: 'Try Hardbacon Premium',
    subscription_t1b: 'free',
    subscription_t1c: '\nfor 30 days',
    subscription_t2: 'Only $12.99 per month afterwards or $99.99 per year',
    subscription_t3: 'Try Premium',
    subscription_t4: 'Monitor your investments like never before',
    subscription_t5: 'Hardbacon gives you all the data you need to make better investment decisions, whether you\'re an independent investor or working with a financial advisor. And by making better decisions, you should be able to reach your goals faster.',
    subscription_i1: 'Everything under one roof',
    subscription_i2: 'Reports & Portfolio score',
    subscription_i3: 'Diagnostics',
    subscription_i4: 'Asset allocation',
    subscription_i5: 'Explore',
    subscription_i6: 'Practice accounts',
    subscription_i7: 'Premium financial data',
    subscription_i8: 'History of your banking and stock exchange transactions',
    saveSuccess: 'Successful saved',
    updatedAccountDescription: 'Your profile was successfully updated',
    updatedAccountDetailsDescription: 'Your account information were successfully updated',
    or: 'Or',
    iAlreadyHaveAnAccount: 'I already have an account (Sign-In)',
    iDontHaveAnAccount: 'I don\'t have an account (Sign-Up)',
    pageNotFound: 'Page not found',
    seeMore: ' See more',
    budgetExceedOverlayTitle: 'Do you really want to spend more than you make?',
    budgetExceedOverlaySubTitle: 'We recommend that you avoid making budgets where your expenses exceed your income',
    budgetExceedOverlayConfirm: "I don't want to spend more",
    budgetExceedOverlayCancel: 'I want to spend more',
    budgetSubceedOverlayTitle1: 'Do you want to add the remaining ',
    budgetSubceedOverlayTitle2: ' to your savings?',
    budgetSubceedOverlaySubTitle1: 'You still have ',
    budgetSubceedOverlaySubTitle2: ' left to categorize to get 100% of your money budgeted',
    budgetSubceedOverlayConfirm1: 'Save ',
    budgetSubceedOverlayConfirm2: ' each month',
    budgetSubceedOverlayCancel: 'Do not add it',
    budgetSubceedOverlay2Title1: 'Do you want to add the remaining ',
    budgetSubceedOverlay2Title2: ' to a new category?',
    budgetSubceedOverlay2SubTitle: 'Make your money work for you!',
    budgetSubceedOverlay2Confirm: 'Add another category ',
    budgetSubceedOverlay2Cancel: 'Do not budget the',
    timePeriod_12: '1y',
    timePeriod_6: '6m',
    timePeriod_3: '3m',
    timePeriod_1: '1m',
    successTitle: 'Process successful',
    syncInstitutionSuccess: 'Your account is successfully synced',
    ...budgetEn,
    ...categoriesEn,
    ...transactionsEn,
    ...errMsgsEn
  },
  feed: {
    ...feedEn
  },
  grantAccess: {
    grantAccessRequestTitle: 'Data access request',
    grantAccessRequest: 'You can see this page because you have requested help to our support team and they need to access your financial data in order to help you.',
    grantAccessRequestDesc: 'By granting this request, our support team will be able to temporarily access (read-only) your financial information.',
    grantAccess: 'Grant access',
    grantAccessWaiting: 'Please wait while we verify your data access approval...',
    grantAccessVerified: 'Thank you for trusting us, Your data is safe with us.',
    grantAccessVerified2: 'Our support team will now be able to investigate with your data until {{ validUntil }}.'
  },
  tooltips: {
    ...tooltipsEn
  },
  manageAccounts: {
    exceedDailySyncLimit: 'You reached your daily limit of 2 forced syncs',
    manualAccountAmount: 'Amount',
    addManualAccount: 'Create a manual account',
    deleteAccount1Title: 'Do you really want to delete this account?',
    deleteAccount1MainButton: 'No, keep it',
    deleteAccountSubButton: 'Delete this account',
    deleteAccount2Title: 'Are you sure you want to delete this account?',
    deleteAccount2Desc: 'By deleting this account, ALL of its transactions will be deleted. This action cannot be undone.',
    manualAccount: 'Manual account',
    title: 'My accounts',
    All: 'All',
    updateMyAccounts: 'Update',
    save: 'Save',
    synchronized: 'Synchronized',
    unsynchronized: 'Unsynchronized',
    synchronizing: 'Syncing...',
    notConnected: 'Last Sync Failed',
    close: 'Close',
    lastSync: 'Last Sync',
    synchronize: 'Synchronize',
    disconnectAccount: 'Remove',
    disconnectAccountModalTitle: 'Important',
    disconnectAccountModalRemoveIt: 'Yes, remove it',
    disconnectAccountModalKeepIt: 'No, keep it',
    disconnectAccountModalDescription: 'Are you sure you want to remove this account ?',
    disconnectAccountModalDescriptionSubText: 'This action cannot be undone',
    addAccount: 'Connect my account',
    addAccountDescription: 'Connect your accounts and get an overview of your finances, all in one place.',
    manageMyAccounts: 'Manage my accounts',
    manageMyAccountsDescription: 'Connect your banking accounts',
    bankLevelSecurity: 'Bank Level Security',
    bankLevelSecurityLink: 'https://hardbacon.ca/en/security/',
    addAccountTitle: 'What type of account do you want to connect ?',
    addingMyAccount: 'Connect my account',
    addBankAccount: 'Banking',
    addInvestmentAccount: 'Investment',
    AccountType_CHECKING: 'Checking',
    AccountType_SAVINGS: 'Savings',
    AccountType_CREDIT_CARD: 'Credit card',
    AccountType_LINE_OF_CREDIT: 'Line of credit',
    AccountType_INVESTMENT: 'Investment',
    AccountType_MARGIN: 'Margin',
    AccountType_LOAN: 'Loan',
    AccountType_MORTGAGE: 'Mortgage',
    AccountType_PROPERTY: 'Property',
    AccountType_INSURANCE: 'Insurance',
    AccountType_PREPAID: 'Prepaid',
    AccountType_ANY: 'Any',
    AccountType_CASH: 'Cash',
    AccountType_UNKNOWN: 'Other',
    AccountSubType_TFSA: 'TFSA',
    AccountSubType_RRSP: 'RRSP',
    AccountSubType_LIRA: 'LIRA',
    AccountSubType_RESP: 'RESP',
    AccountSubType_MARGIN: 'Marge',
    AccountSubType_UNKNOWN: 'Other',
    accountType: 'Account type',
    InstitutionType_ONLINE_BROKERAGE: 'Their clients invest in the stock market through the Internet.',
    InstitutionType_FULL_SERVICE_BROKER: 'Traditional brokerage firm where clients trade through an investment advisor.',
    InstitutionType_ROBO_ADVISOR: 'Clients invest in ready-made portfolios without having to deal with an advisor.',
    InstitutionType_MUTUAL_FUND: 'Clients invest in mutual funds through an advisor.',
    InstitutionType_BANK_ACCOUNT: 'This is where you deposit your checks and pay your bills.',
    InstitutionType_EMPLOYER: 'Investment account setup by employer.',
    InstitutionType_DEMO: 'Demo Investment account, for demonstration purpose only.',
    InstitutionType_OTHER: 'Other investment accounts.',
    InstitutionType_CUSTODY: 'Provide custody and brokerage services to independant investment firms.',
    InstitutionType_DISCRETIONARY: 'Discretionary asset management service integrated with financial and estate-planning services.',
    InstitutionType_HIGH_WORTH: 'Banking and wealth management service targeting high net worth individuals.',
    InstitutionType_ACCESS: 'Their clients invest in the stock market through the Internet, but have access to an investment advisor.',
    InstitutionType_WEALTH_MANAGEMENT: 'Wealth management service targeting high net worth individuals.',
    InstitutionType_BANKING: 'Chequing account',
    Group_nbc: 'National Bank',
    Group_desjardins: 'Desjardins',
    Group_td: 'TD',
    Group_tangerine: 'Tangerine',
    Group_scotia: 'Scotia',
    Group_rbc: 'RBC',
    Group_manulife: 'Manulife',
    Group_laurentian: 'Laurentian bank',
    Group_hsbc: 'HSBC',
    Group_fidelity: 'Fidelity',
    Group_eqbank: 'EQ Bank',
    Group_cibc: 'CIBC',
    Group_bmo: 'BMO',
    Group_demo: 'Demo',
    LoginIdentifier_mxbmo: 'Card Number',
    LoginIdentifier_mxcibc: 'Card Number',
    LoginIdentifier_mxdesjardins: 'Card Number',
    LoginIdentifier_mxnational: 'Email',
    LoginIdentifier_mxeqbank: 'Email',
    LoginIdentifier_mxrbc: 'Client Card',
    LoginIdentifier_mxtd: 'Username or Access Card',
    LoginIdentifier_mxamexca: 'USER ID',
    LoginIdentifier_mxatb: 'Username',
    LoginIdentifier_mxcanadiantire: 'Username',
    LoginIdentifier_mxcapitalone: 'Username',
    LoginIdentifier_mxcoastcapitalsavings: 'Username',
    LoginIdentifier_mxfirstnationalfi: 'ENTER YOUR USERNAME',
    LoginIdentifier_mxhsbc: 'Enter your username',
    LoginIdentifier_mxlaurentian: 'Access code',
    LoginIdentifier_mxmainstreet: 'OnlineAccess ID',
    LoginIdentifier_mxmbna: 'Login name',
    LoginIdentifier_mxmeridian: 'Email, Member Number or User ID',
    LoginIdentifier_mxnorthern: 'Username',
    LoginIdentifier_mxprospera: 'Username',
    LoginIdentifier_mxscotia: 'Username or Card number',
    LoginIdentifier_mxsimplii: 'Card number',
    LoginIdentifier_mxtangerine: 'Client Number, Card Number or Username',
    LoginIdentifier_mxvancity: 'MEMBER CARD #',
    // credentials fields
    computershareemp_username: 'Username',
    username: 'Client ID',
    employer: 'Employer Name or ID',
    code: 'Code',
    token: 'Token',
    _key: 'Key',
    secret: 'Secret',
    LOGIN: 'Login',
    PASSWORD: 'Password',
    next: 'Next',
    securityFAQ: 'FAQ on security',
    Partner_WEALTHICA: 'Powered by our trusted partner Wealthica',
    Partner_MX: 'Powered by our trusted partner MX',
    Partner_PLAID: 'Powered by our trusted partner PLAID',
    securityLink: 'https://hardbacon.ca/en/security/',
    answerQuestion: 'Please answer this security question to sync your account.',
    send: 'Envoyer',
    addAccountGenericError: 'An error occurred',
    addAccountGenericErrorDescription: 'Please try again or contact support if this is still not working.',
    addAccountLoginFailedError: 'Your credentials are incorrect. Please try again.',
    addAccountSecurityQuestionError: 'Please answer the security question used by your bank.',
    addAccountSecurityAnswerFailedError: 'Your answer to the security question is incorrect. Please try again.',
    fixThis: 'Fix this',
    retry: 'Retry',
    polling: 'Being processed...',
    pollingDone: 'Process completed',
    connexionInsideOtherWebsite: 'Please complete connecting to your account in the page that will open automatically.',
    connexionInsideOtherWebsiteDescription: 'Once the connection complete, you\'ll automatically be redirected to Hardbacon.',
    searchInstitution: 'Search an institution...',
    institutionNotFound: 'Didn\'t find your finncial institution?',
    institutionNotFoundLink: 'Ask for it!',
    accountName: 'Account name',
    initialBalance: 'Balance',
    initialDate: 'Date',
    currency: 'Currency'
  },
  header: {
    feed: 'Feed',
    plan: 'Plan',
    budget: 'Budget',
    transactions: 'Transactions',
    overview: 'Overview',
    invest: 'Invest',
    compare: 'Compare',
    premium: 'Premium',
    manageMyAccounts: 'Manage my accounts',
    viewProfile: 'View profile',
    profile: 'Profile',
    signOut: 'Sign out',
    otherLang: 'Français',
    otherLangCode: 'fr',
    viewSupport: 'Get help',
    hideSupport: 'Hide help'
  },
  downloadApp: {
    title: 'Get the Hardbacon app and take control of your finances',
    subTitle: 'Send a link to your phone, or download the Hardbacon app directly from the ',
    or: ' or ',
    phonePlaceholder: 'Phone number',
    send: 'Send link',
    sent: 'Link sent !',
    appStoreLink: 'https://apps.apple.com/ca/app/hardbacon-my-financial-coach/id1313964435?l=en',
    playStoreLink: 'https://play.google.com/store/apps/details?id=ca.hardbacon.hardbacon&hl=en_CA',
    whenClicking: 'By clicking "Send link", you agree to the '
  },
  comingSoon: {
    title: 'This feature is coming soon!',
    subTitle: 'It\'s already available on our app - you can download it now!',
    downloadApp: 'Download the app'
  },
  compare: {
    rewardCategories: {
      'personal care': 'Personal Care',
      withdrawal: 'Withdrawal',
      'business services': 'Business Services',
      'health & fitness': 'Health & Fitness',
      'food & dining': 'Food & Dining',
      'home improvement': 'Home Improvement',
      'bills & utilities': 'Bills & Utilities',
      'public transportation': 'Public Transportation',
      'fees & charges': 'Fees & Charges',
      charity: 'Charity',
      'interest income': 'Interest Income',
      estimatedReward: 'Estimated rewards for the first year',
      estimatedConsecutiveReward: 'Estimated rewards for subsequent years',
      'air travel': 'Air Travel',
      'rental car & taxi': 'Rental Car & Taxi',
      gym: 'Gym',
      sports: 'Sports',
      groceries: 'Groceries',
      pharmacy: 'Pharmacy',
      vacation: 'Vacation',
      moviesDvds: 'Movies & DVDs',
      gas: 'Gas',
      mobilePhone: 'Mobile phone',
      shipping: 'Shipping',
      restaurant: 'Food & Dining',
      parking: 'Parking',
      internet: 'Internet',
      autoTransport: 'Auto & Transport',
      shopping: 'Shopping',
      totalreward: 'Total Reward',
      entertainment: 'Entertainment',
      restaurants: 'Restaurants',
      'additional bonus': 'Additional Bonus',
      other: 'Other',
      annualfee: 'Annual Fee'
    },
    estimatedRewards: 'Estimated Rewards',
    fieldKey: {
      annualFee: 'Annual Fee',
      purchaseInterestRate: 'Interest rate on purchases',
      cardCategories: 'Card Categories',
      cardUserTypes: 'Card User Types',
      cashAdvance: 'Cash Advance',
      balanceTransfer: 'Balance Transfer',
      rewards: 'Rewards',
      estimatedRewards: 'Estimated Rewards',
      requiredDeposit: 'Required Deposit',
      requiredIncome: 'Required Income',
      requiredFamilialIncome: 'Familial Income',
      requiredCreditScore: 'Recommended Credit Score',
      minimumExpenses: 'Minimum Expenses',
      creditLimit: 'Credit Limit',
      insurance: 'Insurance',
      insurances: 'Insurances',
      legalNote: 'Legal Note',
      additionalCard: 'Additional Card',
      estimatedBenefit: 'Estimated Benefit',
      specialFeatures: 'Special Feature',
      rewardRate: 'Rewards',
      pricing: 'Pricing',
      estimatedManagementFee: 'Estimated annual management fees',
      accountTypes: 'Account types',
      averageManagementExpenseRatio: 'Average Management Expense Ratio',
      sociallyResponsibleInvestment: 'Socially responsible investment',
      minimumDeposit: 'Minimum deposit',
      portfolioTypes: 'Portfolio types',
      platforms: 'Platforms'
    },
    fieldValues: {
      BASIC: 'Basic',
      US_DOLLARS: 'US Dollars',
      BEST_OFFER: 'Best offer',
      REWARD: 'Reward',
      REWARDS: 'Rewards',
      CASHBACK: 'Cashback',
      BALANCE_TRANSFER: 'Balance transfer',
      LOW_INTEREST_RATE: 'Low interest',
      SECURED: 'Secured',
      PREPAID: 'Prepaid',
      CREDIT_LINE: 'Credit line',
      VIRTUAL: 'Virtual',
      TOP_RATED: 'Top Rated',
      REGULAR: 'Personal',
      BUSINESS: 'Business',
      STUDENT: 'Student',
      NEWCOMER: 'Newcomer',
      CREDIT_BUILDER: 'Credit builder'
    },
    titleDescriptionValues: {
      CAR_RENTAL_COVERAGE: 'Auto rental coverage',
      CAR_RENTAL_COVERAGE_emoji: '\uD83D\uDE97',
      PURCHASE_SECURITY: 'Purchase protection',
      PURCHASE_SECURITY_emoji: '\uD83D\uDECD',
      EXTENDED_WARRANTY: 'Extended warranty',
      EXTENDED_WARRANTY_emoji: '\uD83D\uDCC6',
      PHONE_INSURANCE: 'Mobile device insurance',
      PHONE_INSURANCE_emoji: '\uD83D\uDCF5',
      TRAVEL_INTERRUPTION: 'Trip interruption',
      TRAVEL_INTERRUPTION_emoji: '⚡️',
      TRAVEL_ACCIDENT: 'Travel accident',
      TRAVEL_ACCIDENT_emoji: '\uD83E\uDDB7',
      TRAVEL_DELAY: 'Flight delay',
      TRAVEL_DELAY_emoji: '⏱',
      TRAVEL_CANCELLATION: 'Trip cancellation',
      TRAVEL_CANCELLATION_emoji: '❌',
      EMERGENCY_PURCHASES: 'Emergency purchases',
      EMERGENCY_PURCHASES_emoji: '\uD83E\uDE74',
      HOTEL_BURGLARY: 'Hotel or motel burglary',
      HOTEL_BURGLARY_emoji: '\uD83D\uDCA8',
      MEDICAL_EMERGENCY: 'Medical emergency',
      MEDICAL_EMERGENCY_emoji: '\uD83C\uDFE5',
      BAGGAGE_INSURANCE: 'Lost or stolen baggage insurance',
      BAGGAGE_INSURANCE_emoji: '\uD83E\uDDF3'
    },
    estimatedReward: {
      description: 'based on your spending taking into account annual fees. After the first year, rewards will be ',
      descriptionContinue: 'taking into account annual fees.'
    },
    applyNow: 'Apply Now',
    learnMore: 'Learn more',
    suggested: 'Suggested',
    suggestedProducts: 'Suggested products',
    all: 'All',
    compare: 'Compare',
    weAnalysisYourAccount: 'We will analyze your accounts and transactions to find',
    findBestProduct: 'the best financial products',
    forYourNeed: 'that match your profile.',
    startAnalysis: 'Start analysis',
    products: 'Products',
    comparatorOption_CREDITCARD: 'Credit cards',
    comparatorOption_SAVING: 'Savings accounts',
    comparatorOption_BROKER: 'Brokers',
    comparatorOption_CHECKING: 'Chequing accounts',
    comparatorOption_ROBOADVISOR: 'Robo advisors',
    comparatorOption_MORTGAGE: 'Mortgages',
    comparatorOption_CRYPTO: 'Cryptocurrency exchanges',
    'connectMycredit-cards': 'Connect my credit card',
    connectMysavings: 'Connect my savings account',
    connectMycheckings: 'Connect my chequing account',
    connectMybrokers: 'Connect my broker',
    'connectMyrobo-advisors': 'Connect my robo advisor',
    analysing: 'Analysis in progress...',
    analysingDescription: 'We are analyzing your data to find the best financial products that match your profile. This may take up to 2 minutes.',
    bestProductTitle: 'Congratulations',
    bestProductDescription: 'We analyzed your data and could not find any better alternatives for you to optimize your budget',
    estimatedBenefit: 'Estimated Benefit',
    cantRecommendTitle: 'No recommendation',
    cantRecommendDescription: 'We could not find recommendations for you, you might have the best product already!',
    noRecommendationButton: 'See all products',
    saveUpTo: 'Save up to',
    refresh: 'refresh',
    basedOnYourExpense: 'Based on your purchasing habits and your financial situation, you could save up to {{amount}} per year by changing your financial products in the following verticals',
    listOfRecommendations: 'Here is the list of the most advantageous products allowing you to save in this category :',
    monthlyFee: 'Monthly fee',
    interestRate: 'Interest rate',
    perYear: ' /an'
  },
  welcome: {
    title: 'Congratulations! You\'ve created your Hardbacon account 😎',
    description: 'Connect to your financial institution to get personalized recommendations',
    connectLater: 'Connect later'
  },
  deleteAccount: {
    deleteMyAccount: 'Delete my account',
    skip: 'Skip',
    contactSupport: 'Contact support',
    cancel: 'Cancel',
    unsubscribe: 'Unsubscribe',
    step1: {
      title: 'We are sad to see you go...',
      text1: 'By deleting your account, all your information will be deleted.',
      text2: 'Click here',
      text2Link: 'https://intercom.help/hardbacon/en/articles/5820550-discover-what-happens-when-you-delete-your-hardbacon-account',
      text3: 'to learn more.'
    },
    step2: {
      title: 'Why do you wish to delete your account?',
      reason1: 'I have connection problems with my accounts',
      reason2: 'My institution is not available',
      reason3: 'I don\'t want to pay anymore',
      reason4: 'Another reason'
    },
    step3: {
      titleReason1: 'Contact us to resolve your connection problems',
      titleReason2: 'We may be able to add your financial institution',
      titleReason3: 'You can use the free version of the app',
      titleReason4: 'Could you tell us more?',
      titleReason5: 'Could you tell us more?',
      yourComment: 'Comment',
      send: 'Send',
      error: 'Write your comments 😊',
      addMyInstitution: 'Add my institution'
    },
    step4: {
      title: 'Are you sure you wish to delete your Hardbacon account?',
      typeDelete: 'Type "delete" to confirm account deletion',
      delete: 'delete',
      confirm: 'Confirm deletion'
    }
  },
  budgetOnboarding: {
    step1: {
      title: "It's time to make a budget!",
      desc:
        "Did you know that Canadians who make a budget are two-times less likely make late payments, repay their debts faster, and save more than those that don't?",
      button: 'Create my budget',
      subButton: 'Later'
    },
    step2: {
      title: 'Connect your bank account',
      desc:
          'By connecting your account and your credit cards, your transactions will be entered and categorized for you.'
    },
    step3: {
      title: 'What is your net income?',
      desc: 'This is income you have after paying taxes and deductions.'
    },
    step4: {
      title: 'How much money per month do you spend on housing?'
    },
    step5: {
      title: 'How much money do you spend on food?'
    },
    step6: {
      title: 'How much money do you spend each month on transportation?'
    },
    budgetState: {
      perfect: {
        title: 'Wow! Your budget is perfect! 🤑',
        subTitle: 'Every dollar is accounted for! Congratulations!'
      },
      exceed: {
        title: 'Your expenses are greater than your revenue 😨',
        subTitle1: 'Reduce your expenses by ',
        subTitle2: ' to balance your budget'
      },
      subceed: {
        title1: 'You have ',
        title2: ' left in your budget 😇',
        subTitle: 'Make every dollar work!'
      }
    },
    addCategory: 'Add another category'
  },
  incomeType: {
    weekly: 'Weekly',
    biWeekly: '2 weeks',
    monthly: 'Monthly',
    biMonthly: 'Bi-monthly'
  },
  connect: {
    connectYourInstitution: 'Login into your account'
  },
  patience: {
    bePatient: 'Be patient',
    bePatientSubtext: 'Connecting your account will start in just few seconds...',
    quote0Text: 'Be patient 🙂. Connecting your account can take up to 5 minutes.',
    quote1Text: '"Patience, persistence and perspiration make an unbeatable combination for success."',
    quote1Author: '🤓 - Napoleon Hill',
    quote2Text: '"Patience is a virtue, and I\'m learning patience. It\'s a tough lesson."',
    quote2Author: '🤓 - Elon Musk',
    quote3Text: '"A man who is a master of patience is master of everything else."',
    quote3Author: '🤓 - George Savile',
    quote4Text: '"Patience is the art of concealing your impatience."',
    quote4Author: '🤓 - Guy Kawasaki',
    quote5Text: '"I am extraordinarily patient, provided I get my own way in the end."',
    quote5Author: '🤓 - Margaret Thatcher',
    quote6Text: '"Intuition is a suspension of logic due to impatience."',
    quote6Author: '🤓 - Rita Mae Brown',
    quote7Text: '"I am patient with stupidity but not with those who are proud of it."',
    quote7Author: '🤓 - Edith Sitwell',
    quote8Text: '"Wise to resolve, and patient to perform."',
    quote8Author: '🤓 -  Homer',
    quote9Text: '"No, I will be the pattern of all patience; I will say nothing."',
    quote9Author: '🤓 - William Shakespeare'
  },
  plaid: {
    waitForPlaid: 'Ready !',
    waitForPlaidSubText: 'Our partner\'s widget should be visible now, please follow instructions there to successfully connect your account',
    waitForSync: 'Finalizing...',
    waitForSyncSubText: 'We are finalizing the connection, please bear with us a couple more seconds...'
  },
  notifications: {
    title: 'Notifications',
    DIVIDEND: '',
    TRANSFER: '',
    PRICE_ABOVE: '',
    PRICE_UNDER: '',
    CASH: '',
    AGGREGATION_BANKING: '',
    AGGREGATION_INVESTMENT: '',
    AGGREGATION_ERROR: '',
    BUDGET_OVER_90_PERCENT: 'Budget Alert!',
    BUDGET_TREND_YELLOW: 'Budget Alert!',
    BUDGET_TREND_RED: 'Budget Alert!',
    createABudgetTitle: 'Create your budget!',
    createABudgetDescription: '',
    createABudgetButton: 'Create'
  },
  overview: {
    currentBalance: 'Current balance',
    tips: 'Tips',
    potentialSavings: 'Potential savings',
    report: 'Report',
    recurringPayments: 'Recurring payments',
    mostAmountMerchant: 'Where you spend the most',
    mostFrequentMerchant: 'Most frequent transactions',
    averageSavingsCapacity: 'Average Saving Capacity',
    saveAmount: 'Save',
    reportIncome: 'Income',
    reportExpenses: 'Expenses',
    total: 'Total'
  }
};

export default en;
