import ReducerPayload from '../model/ReducerPayload';
import ReducerInstitutionState from '../model/ReducerInstitutionState';
import { GET_AVAILABLE_INSTITUTION_SUCCESS } from '../types/Institution';

export const initialState: ReducerInstitutionState = {
  availableInstitution: []
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case GET_AVAILABLE_INSTITUTION_SUCCESS:
      return {
        ...state,
        availableInstitution: action.payload
      };
    default:
      return state;
  }
};
