import AxiosInstance from './index';
import { fromAxiosError } from '../model/APIError';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import Notification from '../model/Notification';

export function downloadApp (phoneNumber: string, lang: string): Observable<boolean> {
  return AxiosInstance.post('api/notifications/download-app', undefined, { params: { phoneNumber, lang } }).pipe(
    map(() => true),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getNotifications (pageNum: number = 0): Observable<Notification[]> {
  return AxiosInstance.get<Notification[]>(`api/user/notifications?all=true&size=20&page=${pageNum}`).pipe(
    map((res) => res.data),
    catchError(error => throwError(fromAxiosError(error))));
}

export function markAsRead (ids: number[]): Observable<void> {
  return AxiosInstance.post('api/user/notifications/mark-as-read', JSON.stringify(ids)).pipe(
    map((res) => {}),
    catchError(error => throwError(fromAxiosError(error))));
}
