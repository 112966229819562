import React, { Component } from 'react';
import i18n from 'i18next';
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeElementLocale
} from '@stripe/stripe-js';
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import HardbaconModal, { HardbaconModalStyle } from './HardbaconModal';
import { getCoupon, getPlans, proceedWithCardToken } from '../api/PurchaseService';
import Plan from '../model/Plan';
import APIError from '../model/APIError';
import HardbaconInput from './HardbaconInput';
import Coupon from '../model/Coupon';
import {
  downloadAppStoreEn,
  downloadAppStoreFr,
  downloadPlayStoreEn,
  downloadPlayStoreFr,
  premiumBelly
} from '../assets/images';
import { WithTranslation, withTranslation } from 'react-i18next';
import HardbaconButton from './HardbaconButton';

type SubscriptionComponentState = {
  stripe?: Stripe,
  plans: Plan[],
  selectedPlan?: Plan,
  couponCode?: string,
  selectedCoupon?: Coupon,
  errorCoupon: boolean,
  name?: string,
  cardComplete: boolean,
  errorMessage?: string,
  succeeded: boolean,
  loading: boolean,
  subscriptionModalOpened: boolean,
}
type SubscriptionComponentProps = WithTranslation & {
  onSuccess?: () => void;
  onDismiss?: () => void;
  open: boolean;
}

export class SubscriptionComponent extends Component<SubscriptionComponentProps, SubscriptionComponentState> {
  private cardElement?: StripeCardElement;

  // Buffer the coupon input using RxJs
  private subscription?: Subscription;
  private onSearchCoupon$: Subject<string>;

  constructor (props: SubscriptionComponentProps) {
    super(props);
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string, { locale: i18n.language as StripeElementLocale })
      .then((stripe) => {
        this.setState({
          stripe: stripe!
        });
      });

    this.state = {
      plans: [],
      errorCoupon: false,
      cardComplete: false,
      succeeded: false,
      loading: false,
      subscriptionModalOpened: false
    };
    this.onSearchCoupon$ = new Subject<string>();
  }

  componentDidMount () {
    this.setState({
      errorCoupon: false,
      cardComplete: false,
      errorMessage: undefined,
      succeeded: false,
      loading: false
    });

    getPlans().subscribe(
      (plans: Plan[]) => {
        this.setState({
          plans: plans,
          selectedPlan: plans[0]
        });
      },
      (error: APIError) => console.warn(error));

    // Whenever a coupon code is input, wait 300ms to use it and search
    // This is to avoid too many API calls for each stroke
    this.subscription = this.onSearchCoupon$
      .pipe(
        debounceTime(300)
      )
      .subscribe(debounced => this.setCoupon(debounced));
  }

  componentDidUpdate (prevProps: Readonly<SubscriptionComponentProps>, prevState: Readonly<SubscriptionComponentState>, snapshot?: any) {
    if (this.props.open !== prevProps.open) {
      this.setState({ subscriptionModalOpened: this.props.open });
    }
  }

  componentWillUnmount () {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  proceed () {
    // Call Stripe to create a card token with given information.
    // Then call our backend with the token, to make the purchase.
    this.setState({ loading: true });

    const { stripe, name, selectedCoupon, selectedPlan } = this.state;
    stripe!.createToken(this.cardElement!, { name }).then(({ error, token }) => {
      if (error) {
        this.setState({ errorMessage: error.message, loading: false });
      }

      if (token) {
        proceedWithCardToken(token, selectedPlan!, selectedCoupon!)
          .subscribe(
            () => this.success(),
            (error: APIError) => this.setState({ errorMessage: error.title, loading: false }));
      }
    }).catch((error) => this.setState({ errorMessage: error, loading: false }));
  }

  success () {
    this.setState({
      errorCoupon: false,
      errorMessage: '',
      succeeded: true,
      loading: false
    });
  }

  close () {
    if (this.props.onSuccess) {
      this.props.onSuccess();
    }
  }

  changePlan = () => {
    const { plans, selectedPlan } = this.state;
    this.setState({
      selectedPlan: selectedPlan === plans[0] ? plans[1] : plans[0]
    });
  }

  renderPlanPrice = (plan: Plan, withCoupon = false) => {
    const { t } = this.props;
    const { selectedCoupon } = this.state;

    if (withCoupon && selectedCoupon) {
      let price = plan.price;
      if (selectedCoupon.amountOff) {
        price = price - (selectedCoupon.amountOff / 100);
      } else if (selectedCoupon.percentageOff) {
        price = price - (price * selectedCoupon.percentageOff / 100);
      }

      return `${t('subscription_price', { price: (price).toFixed(2) })} / ${t('subscription_' + plan.interval)}`;
    } else {
      return `${t('subscription_price', { price: (plan.price).toFixed(2) })} / ${t('subscription_' + plan.interval)}`;
    }
  }

  computeTaxes = (plan?: Plan, coupon?: Coupon) => {
    if (!plan) {
      return {
        tps: 0,
        tvq: 0,
        total: 0
      };
    }

    let price = plan.price;
    if (coupon && coupon.amountOff) {
      price = price - (coupon.amountOff / 100);
    } else if (coupon && coupon.percentageOff) {
      price = price - (price * coupon.percentageOff / 100);
    }

    return {
      tps: Math.round(9.975 * price) / 100,
      tvq: Math.round(5 * price) / 100,
      total: Math.round((price + price * 0.05 + price * 0.09975) * 100) / 100
    };
  }

  setCoupon = (couponCode: string) => {
    if (!couponCode) {
      this.setState({ couponCode, selectedCoupon: undefined, errorCoupon: false });
      return;
    }

    getCoupon(couponCode)
      .subscribe(
        (coupon: Coupon) => {
          this.setState({ couponCode, selectedCoupon: coupon, errorCoupon: false });
        },
        (error: APIError) => {
          this.setState({ couponCode, errorCoupon: true });
          console.warn(error);
        });
  }

  onStripeChange = (card: StripeCardElementChangeEvent) => {
    if (card.complete) {
      this.setState({ cardComplete: true, errorMessage: undefined });
    } else if (card.error) {
      this.setState({ cardComplete: false, errorMessage: card.error?.message });
    } else {
      this.setState({ cardComplete: false, errorMessage: undefined });
    }
  }

  render () {
    const { t } = this.props;

    const {
      succeeded,
      stripe,
      selectedPlan,
      cardComplete,
      errorMessage,
      couponCode,
      selectedCoupon,
      errorCoupon,
      name,
      loading,
      subscriptionModalOpened
    } = this.state;

    const formValid = (!errorCoupon && name && cardComplete);

    const taxes = this.computeTaxes(selectedPlan, selectedCoupon);
    const okText = loading ? t('subscription_wait') : succeeded ? t('close') : t('subscription_proceed');

    return (
        <HardbaconModal
            open={subscriptionModalOpened}
            title={t('subscriptionModalTitle')}
            description={t('cancelWebSubscriptionDescription')}
            negativeText={t('cancel')}
            singleLineButton={true}
            customHeader={<div className={'bg-hardbacon w-full flex h-40'}>
              <div className={'h-full flex items-end ml-10'}>
                <img alt={'premium'} className={'h-3/4 w-auto object-fit'} src={premiumBelly}/>
              </div>
              <div className={'flex flex-1 flex-col justify-between ml-4 mb-1'}>
                <div/>
                <div className={'flex w-full flex-col mt-4'}>
                    <span
                        className={'text-xl font-gilmerBold text-white leading-5'}>{t('subscriptionModalTitle')}</span>
                  <span
                      className={'text-sm font-gilmerRegular text-white leading-4'}>{t('subscription_freeTrial')}</span>
                </div>
                <div className={'flex flex-col justify-end'}>
                    <span
                        className={'text-2xs font-gilmerRegular text-gray-100'}>{t('subscription_freeTrialSub')}</span>
                </div>
              </div>

            </div>
            }
            positiveText={okText}
            disabled={!formValid || loading}
            positiveAction={() => this.proceed()}
            dismissAction={() => this.props.onDismiss!()}
            style={succeeded ? HardbaconModalStyle.info : HardbaconModalStyle.action}>
          <div>
            {!succeeded && (
                <>
                  <div className="flex justify-between mt-4">
                    {selectedPlan && (
                        <div>
                          <div className="font-medium">{t('subscription_' + selectedPlan.name)}</div>
                          <div>
                            {selectedCoupon && (
                                <>
                                            <span
                                                className="medium-regular-text text-hardbacon mr-2">{this.renderPlanPrice(selectedPlan, true)}</span>
                                  <span
                                      className="small-text text-gray-dark line-through">{this.renderPlanPrice(selectedPlan)}</span>
                                </>
                            )}
                            {!selectedCoupon && (
                                <>
                                            <span
                                                className="medium-regular-text text-gray-dark">{this.renderPlanPrice(selectedPlan)}</span>
                                </>
                            )}
                          </div>
                        </div>
                    )}
                    <div>
                      <HardbaconButton title={t('subscription_ChangePlan')} name={'changePlan'} inverse={true} size={'small'} onPress={this.changePlan} />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="font-medium">{t('subscription_name')}</div>
                    <HardbaconInput type="text"
                                    name="name" className="form-input max-w-none"
                                    errorClassName={'form-input-error'}
                                    onChange={(value) => this.setState({ name: value })}/>
                  </div>
                  <div className="mt-4">
                    <div className="font-medium">
                      {t('subscription_cardInfo')}
                    </div>
                    <div className="form-input border">
                      {stripe && <Elements stripe={stripe!}>
                        <CardElement
                            onReady={ref => this.cardElement = ref}
                            onChange={this.onStripeChange}
                            options={{
                              hidePostalCode: true,
                              iconStyle: 'solid',
                              style: {
                                base: {
                                  fontSize: '16px',
                                  color: '#000000',
                                  '::placeholder': {
                                    color: '#bac9d9'
                                  }
                                },
                                invalid: {
                                  color: '#f87171'
                                },
                                complete: {
                                  iconColor: '#00CCCC'
                                }
                              }
                            }}
                        />
                      </Elements>}
                    </div>
                  </div>
                  <div className="mt-4">
                    <div>
                      <div className="font-medium">{t('subscription_iHaveACoupon')}</div>
                      <div className="medium-regular-text text-gray-dark">
                      </div>
                    </div>
                    <div className="flex content-center">
                      <HardbaconInput type="text"
                                      name="coupon" className="form-input max-w-none"
                                      errorClassName={'form-input-error'}
                                      onChange={(value) => this.onSearchCoupon$.next(value)}/>
                      <div className="self-center ml-1">
                        {selectedCoupon && !errorCoupon && (
                            <svg className="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        )}
                        {couponCode && errorCoupon && (
                            <svg className="h-6 w-6 text-red-400" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 text-center">
                    <span className="ml-2 small-text text-hardbacon-error">{errorMessage}</span>
                  </div>
                  <div className="text-right">
                    <div>
                                    <span className="small-text text-gray-medium">
                                        {t('subscription_thanks_TPS', { TPS: taxes.tps.toFixed(2) })}
                                    </span>
                    </div>
                    <div>
                                    <span className="small-text text-gray-medium">
                                        {t('subscription_thanks_TVQ', { TVQ: taxes.tvq.toFixed(2) })}
                                    </span>
                    </div>
                    <div>
                                    <span className="medium-regular-text text-gray-dark">
                                        {t('subscription_thanks_total', { total: taxes.total.toFixed(2) })}
                                    </span>
                    </div>
                  </div>
                </>
            )}
            {succeeded && (
                <div className="mt-4 text-center">
                  <div className="extra-large-text text-hardbacon mt-4">
                    {t('subscription_thanks_title')}
                  </div>
                  <div className="large-text text-gray-dark mt-4">
                    {t('subscription_thanks_subtitle1')}
                  </div>
                  <div className="small-text text-gray-medium mt-4 mb-4 italic whitespace-pre-line">
                    {t('subscription_thanks_help')}
                  </div>
                  <div className="small-text text-gray-medium mt-4 mb-4 whitespace-pre-line">
                    {t('subscription_thanks_subtitle2')}
                  </div>
                  {i18n.language !== 'fr' && (<div className="flex flex-row justify-evenly">
                        <a href="https://apps.apple.com/ca/app/hardbacon-invest-like-a-pro/id1313964435"
                           target="_blank" rel="noreferrer">
                          <img src={downloadAppStoreEn} className="h-10"
                               alt="Download Hardbacon on Apple App Store"/>
                        </a>
                        <a href="https://apps.apple.com/ca/app/hardbacon-invest-like-a-pro/id1313964435"
                           target="_blank" rel="noreferrer">
                          <img src={downloadPlayStoreEn} className="h-10"
                               alt="Download Hardbacon on Google Play Store"/>
                        </a>
                      </div>
                  )}
                  {i18n.language === 'fr' && (<div className="flex flex-row justify-evenly">
                        <a href="https://apps.apple.com/ca/app/hardbacon-invest-like-a-pro/id1313964435"
                           target="_blank" rel="noreferrer">
                          <img src={downloadAppStoreFr} className="h-10"
                               alt="Télécharger Hardbacon sur le Store Apple"/>
                        </a>
                        <a href="https://apps.apple.com/ca/app/hardbacon-invest-like-a-pro/id1313964435"
                           target="_blank" rel="noreferrer">
                          <img src={downloadPlayStoreFr} className="h-10"
                               alt="Télécharger Hardbacon sur le Google Play Store"/>
                        </a>
                      </div>
                  )}
                </div>
            )}
          </div>
        </HardbaconModal>
    );
  }
};

export default withTranslation()(SubscriptionComponent);
