import React, { useState } from 'react';
import styles from './../assets/styles/modules/addBudgetCategory.module.css';
import { useTranslation } from 'react-i18next';
import { getTransactionCtgEmojiById, getAmountByMonth, getTransactionCtgName } from '../utils/banking';
import HardbaconButton from './HardbaconButton';
import AddSelectedBudgetCategory from './AddSelectedBudgetCategory';
import { TransactionCategory } from '../model/TransactionCategory';
import { formatCurrency } from '../i18n';
import dayjs from 'dayjs';

interface AddBudgetCategoryProps {
  onPress: Function;
  categories: TransactionCategory[];
}

const AddBudgetCategory = ({ onPress, categories }: AddBudgetCategoryProps) => {
  const [categorySelected, setCategorySelected] = useState<TransactionCategory>(
    {
      amountPerMonth: {},
      budget: -1,
      budgetDisplayHome: false,
      budgetDisplayTrend: false,
      budgetRatio: -1,
      budgetTrend: 'GREEN',
      category: '',
      categoryDetails: [{}],
      categoryId: -1,
      isBudgetDisplayHome: false,
      isBudgetDisplayTrend: false,
      periodAmount: -1,
      topLevelCategory: '',
      topLevelCategoryId: -1,
      totalAmount: -1,
      topLevelCategoryEnglish: '',
      topLevelCategoryFrench: '',
      topLevelCategoryEmoji: '',
      categoryEnglish: '',
      categoryFrench: '',
      categoryEmoji: ''
    }
  );
  const { t } = useTranslation();

  const renderItem = (i: TransactionCategory) => {
    const prevMonth = dayjs().month(dayjs().month() - 1);
    const amount = getAmountByMonth(i.amountPerMonth, prevMonth);
    return (
      <div className={styles.item} key={i.category}>
        <div className={styles.header}>
          <span className={styles.emoji}>{getTransactionCtgEmojiById(i.categoryId)}</span>
          <div className={styles['title-container']}>
            <span className={styles.title}>{getTransactionCtgName(i)}</span>
            {amount > 0 && <span className={styles['sub-title']}>{formatCurrency(amount, 0)} {t('lastMonth')}</span>}
          </div>
        </div>
        <HardbaconButton
          name={'accept'}
          onPress={() => setCategorySelected(i)}
          type="button"
          title={t('header:budget')} />
      </div>
    );
  };

  return (
    categorySelected.categoryId < 0
      ? <div className={styles.container}>
          {categories && categories.map((i: TransactionCategory) => {
            return renderItem(i);
          })}
          </div>
      : <AddSelectedBudgetCategory item={categorySelected} onPress={onPress}/>
  );
};

export default AddBudgetCategory;
