import { AxiosInstanceWP } from './index';
import { fromAxiosError } from '../model/APIError';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { NewsFeedItem } from '../model/feed/NewsFeedItem';

export function getNewsFeedService (url: string): Observable<NewsFeedItem[]> {
  return AxiosInstanceWP.get<NewsFeedItem[]>(url).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}
