import React from 'react';
import dayjs from 'dayjs';

type HardbaconFooterProps = {
    dark: boolean,
}

export const HardbaconFooter = (props: HardbaconFooterProps) => {
  return (
        <footer className={'flex flex-col text-center mb-2'}>
            <span className={props.dark ? 'copyright-dark' : 'copyright'}>v{process.env.REACT_APP_VERSION} - © {dayjs().format('YYYY')} Bacon Financial Technologies Inc.</span>

            {/* <a href="https://hardbacon.freshstatus.io" id="freshstatus-badge-root" className={'self-center mt-8 p-3 rounded bg-hardbacon hover:bg-white'} */}
            {/*       data-banner-style="compact"> */}
            {/*    <img src="https://public-api.freshstatus.io/v1/public/badge.svg/?badge=eac776b2-f8c0-4a99-9f8a-d3e33afe8731" /> */}
            {/* </a> */}
            {/* <script type="module" src="https://cdn.freshstatus.io/widget/index.js"></script> */}
        </footer>
  );
};

export default HardbaconFooter;
