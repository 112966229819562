import { Dispatch } from 'redux';
import { getOffersService, updateUserNewsTopicsService } from '../api/FeedService';
import { GET_HARDBACON_NEWS, GET_HARDBACON_NEWS_FAILED, GET_HARDBACON_NEWS_SUCCESS, GET_OFFERS, GET_OFFERS_SUCCESS, UPDATE_USER_NEWS_TOPICS_STARTS, UPDATE_USER_NEWS_TOPICS_SUCCESS } from '../types/Feed';
import { getNewsFeedService } from '../api/Feed';
import { getLang } from '../utils';

export const updateUserNewsTopicsStarts = () => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_USER_NEWS_TOPICS_STARTS });
};

export const updateUserNewsTopics = (topics: string[]) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_USER_NEWS_TOPICS_SUCCESS, payload: topics });
  updateUserNewsTopicsService(topics).subscribe(
    () => {
      dispatch({ type: UPDATE_USER_NEWS_TOPICS_SUCCESS, payload: topics });
    }
  );
};

export const getNewsFeed = (pageNum: number, topics: string) => (dispatch: Dispatch) => {
  dispatch({ type: GET_HARDBACON_NEWS });
  const url =
    `${
      getLang() === 'fr' ? '/fr' : ''
    }/wp-json/wp/v2/posts?_fields=id,date,modified,link,categories,tags,title,featured_media,featured_image_data,excerpt,wpml_translations` +
    '&orderby=date' +
    '&per_page=20&page=' +
    pageNum +
    '&tags_exclude=795' +
    '&categories=' +
    topics;
  getNewsFeedService(url).subscribe(
    (newsFeed) => {
      let canLoadMore = true;
      if (newsFeed.length < 20) {
        canLoadMore = false;
      }
      dispatch({
        type: GET_HARDBACON_NEWS_SUCCESS,
        payload: { data: newsFeed, pageNum: pageNum, canLoadMore: canLoadMore }
      });
    },
    (error) => {
      console.log(error);
      dispatch({ type: GET_HARDBACON_NEWS_FAILED });
    }
  );
};

export const getOffers = (province: string) => (dispatch: Dispatch) => {
  dispatch({
    type: GET_OFFERS
  });

  getOffersService(province).subscribe(
    (data) => {
      dispatch({
        type: GET_OFFERS_SUCCESS,
        payload: data
      });
    }
  );
};
