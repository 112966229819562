import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { HardbaconButton, SocialLoginApple, SocialLoginGoogle } from '../../component';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation, useNavigate } from 'react-router-dom';
import { CredentialResponse } from '@react-oauth/google';

import i18n from '../../i18n';
import { appleAuth, auth, googleAuth } from '../../actions/User';
import { bankSecurityLock } from '../../assets/images';
import { route as signUpRoute } from '../../navigation/SignUp';
import { route as resetPasswordRoute } from '../../navigation/ResetPassword';
import { HardbaconLeftPanel } from '../../component/HardbaconLeftPanel';
import { route as homeRoute } from '../../navigation/Home';
import { HardbaconInput } from '../../component/HardbaconInput';

const SignIn = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const emailInput = useRef<HardbaconInput>(null);
  const passwordInput = useRef<HardbaconInput>(null);
  const [rememberMe, setRememberMe] = useState(false);

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { authenticated } = useSelector((state: any) => state.session);
  const { wrongEmailOrPassword, signingIn } = useSelector((state: any) => state.signIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state && (location.state as any).appleRes) {
      setTimeout(() => {
        // @ts-ignore
        responseApple((location.state as any).appleRes);
      }, 500);
    }
  }, [location]);

  useEffect(() => {
    if (wrongEmailOrPassword) {
      passwordInput.current?.setErrorLabel(t('wrongEmailOrPassword'));
    }
  }, [wrongEmailOrPassword]);

  useEffect(() => {
    if (authenticated) {
      if (location.state) {
        const {
          from,
          redirectAfterLogin
        } = location.state as { from?: string, redirectAfterLogin?: boolean };
        if (redirectAfterLogin && from) {
          navigate(from, { state: { redirectAfterLogin: false } });
          return;
        }
      }
      navigate(homeRoute);
    }
  }, [authenticated, location]);

  const login = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const emailValidation = emailInput.current?.validate();
    const passwordValidation = passwordInput.current?.validate();

    if (emailValidation && passwordValidation) {
      // Call recaptcha and join the token to the request
      recaptchaRef.current?.executeAsync().then((token) => {
        if (token && emailInput.current && passwordInput.current) {
          dispatch(auth(emailInput.current.state.value, passwordInput.current.state.value, rememberMe, token));
        }
      }).finally(() => recaptchaRef.current?.reset());
    }
  };

  const responseGoogle = (googleRes: CredentialResponse) => {
    if (googleRes && googleRes.credential) {
      // Call recaptcha and join the token to the request
      // email: googleRes.getBasicProfile().getEmail()
      recaptchaRef.current?.executeAsync().then((token) => {
        if (token) {
          dispatch(googleAuth(googleRes.credential!, rememberMe, token));
        }
      }).finally(() => recaptchaRef.current?.reset());
    } else {
      // nothing to do
    }
  };

  const responseGoogleFailure = (error: any) => {
    // nothing to do
    console.warn(error);
  };

  const responseApple = (appleRes: any) => {
    // Call recaptcha and join the token to the request
    //  email: appleRes.user ? appleRes.user.email
    if (appleRes.user !== undefined) {
      // This is a signUp
      // Redirect the user to the signup page that will automatically sign him up
      navigate(signUpRoute, { state: appleRes });
    } else {
      // This is a signIn
      recaptchaRef.current?.executeAsync().then((token) => {
        if (token) {
          dispatch(appleAuth(appleRes.authorization.id_token, rememberMe, token));
        }
      }).finally(() => recaptchaRef.current?.reset());
    }
  };

  return (
    <div className={'flex flex-col md:flex-row flex-1 w-full'}>
      <HardbaconLeftPanel/>
      <div className={'flex-1 p-4 md:p-8 lg:p-20 flex-col flex bg-white'}>
        <div onClick={(e) => {
          i18n.changeLanguage(t('header:otherLangCode'));
        }} className={'flex w-full justify-end button-hover cursor-pointer'}>
          <span className={'small-bold-text text-gray-medium text-right'}>{t('header:otherLang')}</span>
        </div>
        <form id={'form-sign-in'} className={'flex flex-1 justify-center flex-col lg:w-96 self-center'} onSubmit={(e) => login(e)}>
          <h1 className={'large-text text-black'}>{t('signIn')}</h1>

          <SocialLoginGoogle
            type={'signIn'}
            onSuccess={(res) => responseGoogle(res)}
            onFailure={responseGoogleFailure}
          />

          <SocialLoginApple
            type={'signIn'}
            onSuccess={(res) => responseApple(res)}
            onFailure={() => undefined}
          />

          <div className={'flex justify-center items-center mt-4'}>
            <hr className={'flex-1'}/>
            <span className={'text-sm text-gray-medium mx-2'}>{t('or')}</span>
            <hr className={'flex-1'}/>
          </div>

          <div className="mt-4">
            <HardbaconInput
              regex={'\\S+@\\S+\\.\\S+'}
              optional={false}
              ref={emailInput}
              name={'email'} type={'email'}
              placeholder={t('email')}
              />
          </div>
          <div className="mt-4">
            <HardbaconInput
              regex={'^.{10,50}$'}
              optional={false}
              ref={passwordInput}
              name={'password'}
              isSecureTextEntry={true}
              placeholder={t('password')}
            />
          </div>
          <div className="flex items-center justify-between mt-4 mb-8">
            <div className="flex items-center">
              <input id="termsAndCondition" name="termsAndCondition" type="checkbox"
                     className="h-4 w-4 text-hardbacon focus:ring-hb-default border-gray-300 rounded"
                     checked={rememberMe}
                     onChange={(e) => setRememberMe(e.target.checked) }/>
              <label htmlFor="termsAndCondition" className="ml-2 block small-text text-gray-dark">
                {t('rememberMe')}
              </label>
            </div>

            <div className="small-bold-text text-gray-dark cursor-pointer button-hover"
                 onClick={() => navigate(resetPasswordRoute)}>
              {t('forgotPassword')}
            </div>
          </div>

          <ReCAPTCHA
            ref={recaptchaRef}
            hl={i18n.language}
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
            size="invisible"
          />

          <HardbaconButton
            name={'login'}
            type={'submit'}
            title={t('signIn')}
            disabled={signingIn}
            loading={signingIn}
          />

          <hr className={'my-6'}/>

          <HardbaconButton
            name={'signUp'}
            type={'button'}
            title={t('iDontHaveAnAccount')}
            inverse={true}
            onPress={() => {
              navigate(signUpRoute);
            }}
          />

          <div className={'flex mt-4 w-full justify-center'}>
            <img src={bankSecurityLock} className={'h-4 w-4 object-contain mr-1'} alt="bank-level-security"/>
            <a href={t('manageAccounts:bankLevelSecurityLink')} target={'_blank'} rel="noreferrer"
               className={'text-sm font-gilmerMedium text-gray-dark'}>{t('manageAccounts:bankLevelSecurity')}</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
