import { budgetRoute, overviewRoute, transactionsRoute } from '../../navigation/Banking';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../assets/styles/modules/BudgetHeader.module.css';
import { setAccountSelectorPanelOpen } from '../../actions/UserInstitution';
import { useDispatch } from 'react-redux';
import BudgetNotificationsRightPanel from '../BudgetNotificationsRightPanel';
import HeaderButtons from './HeaderButtons';

/* eslint-disable no-unused-vars */
enum BudgetTab {
  transactions = 'transactions',
  budget = 'budget',
  overview = 'overview',
}

const BudgetHeader = () => {
  const [selectedTab, setSelectedTab] = useState(BudgetTab.transactions);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === budgetRoute) {
      setSelectedTab(BudgetTab.budget);
    } else if (location.pathname === transactionsRoute) {
      setSelectedTab(BudgetTab.transactions);
    } else if (location.pathname === overviewRoute) {
      setSelectedTab(BudgetTab.overview);
    }
  }, [location]);

  const openAddAccount = () => {
    dispatch(setAccountSelectorPanelOpen(true));
  };

  const openNotifications = () => {
    setNotificationOpen(true);
  };

  return (
    <div>
      <BudgetNotificationsRightPanel open={notificationOpen} onClose={() => setNotificationOpen(false)}/>
      <h1 className={'large-text text-white my-3'}>{t('budgetTab')}</h1>
      <div className={styles.menuBarMobile}>
        <label htmlFor="selected-tab" className="sr-only">Select a tab</label>
        <select id="selected-tab" name="selected-tab" value={selectedTab}
                className="form-select mb-2"
                onChange={(e) => {
                  const tab = e.target.value as BudgetTab;
                  if (tab === BudgetTab.transactions) {
                    navigate(transactionsRoute);
                  } else if (tab === BudgetTab.budget) {
                    navigate(budgetRoute);
                  } else if (tab === BudgetTab.overview) {
                    navigate(overviewRoute);
                  }
                }}>
          <option value={BudgetTab.transactions}>{t('transactionsTab')}</option>
          <option value={BudgetTab.budget}>{t('budgetTab')}</option>
          <option value={BudgetTab.overview}>{t('header:overview')}</option>
        </select>
        <HeaderButtons />
      </div>
      <div className={styles.menuBar}>
        <nav className="flex space-x-4">
          <Link
            to={transactionsRoute}
            className={selectedTab === BudgetTab.transactions ? 'form-tab-selected' : 'form-tab'}
            aria-current={selectedTab === BudgetTab.transactions ? 'page' : 'false'}>
            {t('transactionsTab')}
          </Link>
          <Link
            to={budgetRoute}
            className={selectedTab === BudgetTab.budget ? 'form-tab-selected' : 'form-tab'}
            aria-current={selectedTab === BudgetTab.budget ? 'page' : 'false'}>
            {t('budgetTab')}
          </Link>
          <Link
            to={overviewRoute}
            className={selectedTab === BudgetTab.overview ? 'form-tab-selected' : 'form-tab'}
            aria-current={selectedTab === BudgetTab.overview ? 'page' : 'false'}>
            {t('header:overview')}
          </Link>
        </nav>
        <HeaderButtons />
      </div>
    </div>
  );
};

export default BudgetHeader;
