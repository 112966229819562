import ReducerPayload from '../model/ReducerPayload';
import ReducerUserInstitutionState from '../model/ReducerUserInstitutionState';
import { LOGOUT } from '../types/Session';
import {
  ADD_USER_INSTITUTION,
  ADD_USER_INSTITUTION_FAILED,
  ADD_USER_INSTITUTION_SUCCESS,
  CLEAR_ADD_USER_INSTITUTION,
  CLEAR_ERROR_USER_INSTITUTION,
  CLEAR_POLL_USER_INSTITUTION,
  GET_USER_INSTITUTION_SUCCESS,
  POLL_USER_INSTITUTION,
  POLL_USER_INSTITUTION_FAILED,
  POLL_USER_INSTITUTION_SUCCESS,
  DELETE_USER_INSTITUTION,
  DELETE_USER_INSTITUTION_SUCCESS,
  DELETE_USER_INSTITUTION_FAILED,
  SET_ADD_INSTITUTION_PANEL_OPEN,
  SET_ACCOUNT_SELECTOR_PANEL_OPEN,
  GET_USER_INSTITUTION,
  SET_SELECTED_ACCOUNTS,
  SET_PRELOADED,
  DELETE_MANUAL_ACCOUNT,
  SET_NOTIFICATIONS_PANEL_OPEN
} from '../types/UserInstitution';
import InstitutionFilterModel from '../model/InstitutionFilterModel';
import { minifyAccountIds } from '../utils/Accounts';
import UserInstitution from '../model/UserInstitution';

export const initialState: ReducerUserInstitutionState = {
  loadingInstitutions: false,
  institutions: [],
  hasBankingAccounts: undefined,
  addingInstitution: false,
  lastInstitutionAdded: undefined,
  lastInstitutionAddFailed: false,
  lastInstitutionAddError: undefined,
  isPollingInstitution: false,
  pollingInstitution: undefined,
  pollingInstitutionFailed: false,
  addInstitutionPanelOpen: false,
  openCreateManualAccount: false,
  addInstitutionPanelParameters: undefined,
  accountSelectorPanelOpen: false,
  notificationsPanelOpen: false,
  selectedAccounts: [],
  minifiedSelectedAccountIds: undefined, // make it undefined so that components know it hasn't been loaded yet
  filter: InstitutionFilterModel.banking,
  preloaded: false
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case GET_USER_INSTITUTION:
      return {
        ...state,
        loadingInstitutions: true
      };
    case GET_USER_INSTITUTION_SUCCESS:
      return {
        ...state,
        institutions: action.payload,
        hasBankingAccounts: action.payload && action.payload.some((i: UserInstitution) => i.institutionSource.description === 'BANKING'),
        loadingInstitutions: false
      };
    case DELETE_MANUAL_ACCOUNT: {
      const newInstitutions = state.institutions.filter(account => {
        const newAccount = account;
        if (account.institutionSource.id === action.payload.sourceId) {
          newAccount.institutionAccountDetailDTOs = newAccount.institutionAccountDetailDTOs.filter(i => i.manualId !== action.payload.id);
        }
        return newAccount.institutionAccountDetailDTOs.length > 0;
      });
      return {
        ...state,
        institutions: newInstitutions
      };
    }
    case ADD_USER_INSTITUTION:
      return {
        ...state,
        addingInstitution: true,
        isPollingInstitution: true,
        lastInstitutionAdded: undefined,
        lastInstitutionAddError: undefined,
        lastInstitutionAddFailed: false
      };
    case ADD_USER_INSTITUTION_SUCCESS:
      return {
        ...state,
        lastInstitutionAdded: action.payload,
        addingInstitution: false,
        lastInstitutionAddFailed: false
      };
    case ADD_USER_INSTITUTION_FAILED:
      return {
        ...state,
        addingInstitution: false,
        isPollingInstitution: false,
        lastInstitutionAddError: action.payload,
        lastInstitutionAddFailed: true
      };
    case POLL_USER_INSTITUTION:
      return {
        ...state,
        isPollingInstitution: true,
        pollingInstitutionFailed: false,
        pollingInstitution: undefined
      };
    case POLL_USER_INSTITUTION_SUCCESS:
      return {
        ...state,
        isPollingInstitution: false,
        pollingInstitution: action.payload,
        pollingInstitutionFailed: false
      };
    case POLL_USER_INSTITUTION_FAILED:
      return {
        ...state,
        isPollingInstitution: false,
        pollingInstitution: undefined,
        pollingInstitutionFailed: true
      };
    case CLEAR_ADD_USER_INSTITUTION:
      return {
        ...state,
        lastInstitutionAddFailed: false,
        lastInstitutionAdded: undefined,
        addingInstitution: false,
        isUpdatingInstitution: false,
        lastInstitutionAddError: undefined
      };
    case CLEAR_POLL_USER_INSTITUTION:
      return {
        ...state,
        isPollingInstitution: false,
        pollingInstitutionFailed: false,
        pollingInstitution: undefined
      };
    case CLEAR_ERROR_USER_INSTITUTION:
      return {
        ...state,
        pollingInstitutionFailed: false,
        lastInstitutionAddFailed: false,
        lastInstitutionAddError: undefined,
        pollingInstitution: { ...state.pollingInstitution, syncStatus: '' }
      };
    case DELETE_USER_INSTITUTION:
      return {
        ...state,
        isPollingInstitution: true
      };
    case DELETE_USER_INSTITUTION_SUCCESS: {
      const newInstitutionList = state.institutions.filter(i => i.id !== action.payload);
      return {
        ...state,
        isPollingInstitution: false,
        institutions: newInstitutionList,
        hasBankingAccounts: newInstitutionList.some((i: UserInstitution) => i.institutionSource.description === 'BANKING')
      };
    }
    case DELETE_USER_INSTITUTION_FAILED:
      return {
        ...state,
        isPollingInstitution: false
      };
    case SET_ADD_INSTITUTION_PANEL_OPEN:
      return {
        ...state,
        addInstitutionPanelOpen: action.payload.isOpen,
        addInstitutionPanelParameters: action.payload.parameters
      };
    case SET_ACCOUNT_SELECTOR_PANEL_OPEN:
      return {
        ...state,
        accountSelectorPanelOpen: action.payload
      };
    case SET_NOTIFICATIONS_PANEL_OPEN:
      return {
        ...state,
        notificationsPanelOpen: action.payload
      };
    case SET_SELECTED_ACCOUNTS: {
      const newSelectedAccounts = action.payload;
      const newMinifiedSelectedAccountIds = minifyAccountIds(newSelectedAccounts, state.institutions);
      return {
        ...state,
        selectedAccounts: newSelectedAccounts,
        minifiedSelectedAccountIds: newMinifiedSelectedAccountIds
      };
    }
    case SET_PRELOADED: {
      return {
        ...state,
        preloaded: action.payload
      };
    }
    case LOGOUT:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
