import AxiosInstance from './index';
import { fromAxiosError } from '../model/APIError';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import FinancialInfo from '../model/FinancialInfo';
import { BankingCategoryDetail } from '../model/BankingCategoryDetail';
import { BankingOverviewStats } from '../model/BankingOverviewStats';
import { CategoryBudget } from '../model/CategoryBudget';
import { Category } from '../model/Category';
import { BankingTips } from '../model/BankingTips';
import { BankingSubscription } from '../model/BankingSubscription';
import { clearCachedData } from './caching';

export function getBankingCategories (): Observable<Category[]> {
  return AxiosInstance.get<Category[]>('api/banking/categories').pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getFinancialInfo (): Observable<FinancialInfo[]> {
  return AxiosInstance.get<FinancialInfo[]>('api/financial-infos').pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function updateFinancialInfoService (param: any): Observable<FinancialInfo> {
  return AxiosInstance.put<FinancialInfo>('api/financial-infos', param).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function setFinancialInfoService (param: any): Observable<FinancialInfo> {
  return AxiosInstance.post<FinancialInfo>('api/financial-infos', param).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function updateBulkCategoryBudgetService (param: any): Observable<boolean> {
  clearCachedData();
  const data = JSON.stringify(param);
  return AxiosInstance.post<boolean>('/api/banking/budget/categories/bulk', data).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getBudgetCategoriesService (): Observable<CategoryBudget[]> {
  return AxiosInstance.get<CategoryBudget[]>('/api/banking/budget/categories/').pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getBankOverviewService (account: string[]): Observable<BankingOverviewStats> {
  return AxiosInstance.get<BankingOverviewStats>(`api/banking/overview?account=${account}`).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getTopLevelCategoriesTransactionService (account: string[]): Observable<BankingCategoryDetail[]> {
  return AxiosInstance.get<BankingCategoryDetail[]>(`api/banking/budget/top-level-categories?account=${account}`).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function fetchBankingTips (account: string[]): Observable<BankingTips[]> {
  return AxiosInstance.get<BankingTips[]>(`/api/banking/tips?account=${account}`).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function fetchBankingSubscriptions (account: string[]): Observable<BankingSubscription[]> {
  return AxiosInstance.get<BankingSubscription[]>(`/api/banking/subscriptions?account=${account}`).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}
