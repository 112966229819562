export const GET_BANKING_CATEGORIES = 'GET_BANKING_CATEGORIES';
export const SET_BANKING_CATEGORIES = 'SET_BANKING_CATEGORIES';
export const SET_FINANCIAL_INFO = 'SET_FINANCIAL_INFO';
export const GET_FINANCIAL_INFO = 'GET_FINANCIAL_INFO';
export const GET_FINANCIAL_INFO_SUCCESS = 'GET_FINANCIAL_INFO_SUCCESS';
export const SET_FINANCIAL_INFO_SUCCESS = 'SET_FINANCIAL_INFO_SUCCESS';
export const UPDATE_FINANCIAL_INFO = 'UPDATE_FINANCIAL_INFO';
export const UPDATE_FINANCIAL_INFO_SUCCESS = 'UPDATE_FINANCIAL_INFO_SUCCESS';
export const UPDATE_CATEGORY_BUDGET_SUCCESS = 'UPDATE_CATEGORY_BUDGET_SUCCESS';
export const GET_CATEGORY_BUDGET = 'GET_CATEGORY_BUDGET';
export const GET_CATEGORY_BUDGET_SUCCESS = 'GET_CATEGORY_BUDGET_SUCCESS';
export const GET_BANK_STATS = 'GET_BANK_STATS';
export const GET_BANK_STATS_SUCCESS = 'GET_BANK_STATS_SUCCESS';
export const GET_TOP_LEVEL_BANK_CATEGORIES_SUCCESS = 'GET_TOP_LEVEL_BANK_CATEGORIES_SUCCESS';
export const GET_TOP_LEVEL_BANK_CATEGORIES_FAILED = 'GET_TOP_LEVEL_BANK_CATEGORIES_FAILED';
export const GET_TOP_LEVEL_BANK_CATEGORIES = 'GET_TOP_LEVEL_BANK_CATEGORIES';
export const GET_BANKING_TIPS_SUCCESS = 'GET_BANKING_TIPS_SUCCESS';
export const GET_BANKING_TIPS = 'GET_BANKING_TIPS';
export const GET_BANKING_SUBSCRIPTIONS_SUCCESS = 'GET_BANKING_SUBSCRIPTIONS_SUCCESS';
export const GET_BANKING_SUBSCRIPTIONS = 'GET_BANKING_SUBSCRIPTIONS';
export const UPDATE_BUDGET_DATE_PICKER = 'UPDATE_BUDGET_DATE_PICKER';
