import React from 'react';
import { useTranslation } from 'react-i18next';

const AccountsHeader = () => {
  const { t } = useTranslation('header');

  return (
    <div>
      <h1 className={'large-text text-white my-3'}>{t('manageMyAccounts')}</h1>
    </div>
  );
};

export default AccountsHeader;
