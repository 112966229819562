import React from 'react';

const LockClosed = (props) => (
        // eslint-disable-next-line react/prop-types
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill={props.fill} viewBox="0 0 24 24" stroke={props.stroke} strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
);

export default LockClosed;
