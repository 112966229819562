import { AxiosError } from 'axios';

interface APIError {
    status: number,
    title: string,
    detail: string,
    errorKey?: string,
}

export default APIError;

export function fromAxiosError (error: AxiosError): APIError {
  return {
    status: error.response?.status || 999,
    // @ts-ignore
    title: error.response?.data?.title || 'Unknown error',
    // @ts-ignore
    detail: error.response?.data?.detail || '',
    // @ts-ignore
    errorKey: error.response?.data?.errorKey || ''
  };
}
