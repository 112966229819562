import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../i18n';
import { hardbaconLogo, hardbaconIcon } from '../assets/images';
import { fetchPicture } from '../actions/User';
import { showSupport } from '../actions/Support';
import { route as profileRoute } from '../navigation/Profile';
import { route as accountsRoute } from '../navigation/Accounts';
import { route as compareRoute } from '../navigation/Comparator';
import { route as feedRoute } from '../navigation/Feed';
import { route as bankingRoute } from '../navigation/Banking';
import { route as logoutRoute } from '../navigation/Logout';
import useClickOutside from '../hooks/UseClickOutside';
import BudgetHeader from './Headers/BudgetHeader';
import CompareHeader from './Headers/CompareHeader';
import ProfileHeader from './Headers/ProfileHeader';
import AccountsHeader from './Headers/AccountsHeader';
import FeedHeader from './Headers/FeedHeader';

const navigation = [
  {
    key: 'feed',
    location: feedRoute,
    display: true,
    subHeader: <FeedHeader />
  },
  {
    key: 'budget',
    location: bankingRoute,
    display: true,
    subHeader: <BudgetHeader />
  },
  {
    key: 'compare',
    location: compareRoute,
    display: true,
    subHeader: <CompareHeader />
  },
  {
    key: 'profile',
    location: profileRoute,
    display: false,
    subHeader: <ProfileHeader />
  },
  {
    key: 'accounts',
    location: accountsRoute,
    display: false,
    subHeader: <AccountsHeader />
  }
];

const HardbaconHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [currentNav, setCurrentNav] = useState<any>(undefined);
  const userMenuRef = useRef(null);
  const userMenuButtonRef = useRef(null);
  useClickOutside([userMenuRef, userMenuButtonRef], () => setProfileOpen(false));

  const { t, i18n } = useTranslation('header');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { picture, user } = useSelector((state: any) => state.user);
  const supportVisible = useSelector((state: any) => state.support.visible);

  useEffect(() => {
    dispatch(fetchPicture());
  }, []);

  useEffect(() => {
    const currentPage = navigation.find(n => location.pathname.startsWith(n.location));
    if (currentPage) {
      setCurrentNav(currentPage);
    }
  }, [location]);

  const logout = () => {
    setProfileOpen(false);
    navigate(logoutRoute);
  };

  return (
    <>
      <nav className="bg-hardbacon">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 border-b border-white border-opacity-25">
            <div className="flex flex-1">
              <Link to={feedRoute} className="flex-shrink-0 flex items-center cursor-pointer">
                <img className="block lg:hidden h-8 w-auto"
                     src={hardbaconIcon} alt="hardbacon"/>
                <img className="hidden lg:block h-8 w-auto"
                     src={hardbaconLogo}
                     alt="hardbacon"/>
              </Link>
              <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8 flex-1 justify-end">
                {navigation.filter(n => n.display).map(n => (
                  <Link key={n.key}
                        to={n.location}
                        className={location.pathname.startsWith(n.location)
                          ? 'nav-link-selected'
                          : 'nav-link'}
                        aria-current={location.pathname.startsWith(n.location) ? 'page' : undefined}
                  >
                    {t(n.key)}
                  </Link>
                ))}
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <div className="ml-3 relative">
                <div>
                  <button
                      ref={userMenuButtonRef}
                      onClick={() => setProfileOpen(!profileOpen)}
                      className="nav-profile-picture"
                      id="user-menu" aria-haspopup="true">
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full object-cover"
                         src={picture}
                         alt="profile"/>
                  </button>
                </div>
                <div ref={userMenuRef}
                    className={(profileOpen ? 'nav-profile-menu-open' : 'nav-profile-menu')}
                    role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                  <Link
                    to={profileRoute}
                    className="nav-profile-link"
                    role="menuitem">{t('viewProfile')}</Link>
                  <Link
                    to={accountsRoute}
                    className="nav-profile-link"
                    role="menuitem">{t('manageMyAccounts')}</Link>
                  <button
                      onClick={() => {
                        changeLanguage(t('otherLangCode'));
                      }}
                      className="nav-profile-link"
                      role="menuitem">{t('otherLang')}</button>
                  <button onClick={() => logout()}
                          className="nav-profile-link"
                          role="menuitem">{t('signOut')}</button>
                </div>
              </div>
            </div>
            <div className="flex items-center sm:hidden">
              <button
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="nav-hamburger">
                <span className="sr-only">Open main menu</span>
                <svg className={(!menuOpen ? 'block' : 'hidden') + ' h-6 w-6'}
                     xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"/>
                </svg>
                <svg className={(menuOpen ? 'block' : 'hidden') + ' h-6 w-6'}
                     xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className={(menuOpen ? 'block' : 'hidden') + ' sm:hidden'}>
          <div className="pt-2 pb-3 space-y-1">
            {navigation.filter(n => n.display).map(n => (
              <Link key={n.key}
                    to={n.location}
                    className={(location.pathname === n.location ? 'nav-link-hamburger-selected' : 'nav-link-hamburger') + ' cursor-default'}>
                <span>{t(n.key)}</span>
              </Link>
            ))}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            <Link
                to={profileRoute}
                className="flex items-center px-4 button-hover cursor-pointer">
              <div className="flex-shrink-0">
                <img className="h-10 w-10 rounded-full object-cover"
                     src={picture}
                     alt={'profile'}/>
              </div>
              <div className="ml-3">
                <div
                    className="text-base font-medium text-white">{user?.firstName} {user?.lastName}</div>
                <div className="text-sm font-medium text-white opacity-70">{user?.login}</div>
              </div>
            </Link>
            <div className="mt-3 space-y-1">
              <Link
                to={profileRoute}
                className="nav-mobile-profile-link">{t('viewProfile')}</Link>
              <Link
                  to={accountsRoute}
                  className="nav-mobile-profile-link">{t('manageMyAccounts')}</Link>
              <button
                  onClick={() => {
                    i18n.changeLanguage(t('otherLangCode'));
                  }}
                  className="nav-mobile-profile-link">{t('otherLang')}</button>
              <button
                  onClick={() => {
                    showSupport!(!supportVisible);
                  }}
                  className="nav-mobile-profile-link">{supportVisible ? t('hideSupport') : t('viewSupport')}</button>
              <button
                  onClick={() => logout()}
                  className="nav-mobile-profile-link">{t('signOut')}</button>
            </div>
          </div>
        </div>
      </nav>

      <header className="max-w-7xl lg:mb-8 mx-auto px-4 sm:px-6 lg:px-8 pt-4 lg:pt-8" style={{ width: '100%' }}>
        {currentNav && currentNav.subHeader}
      </header>
    </>
  );
};

export default HardbaconHeader;
