import React from 'react';
import { useTranslation } from 'react-i18next';
import SimplePage from '../../component/Layouts/SimplePage';
import { isMobile, isTablet } from '../../utils/hooks';
import styles from '../../assets/styles/modules/Feed.module.css';
import UserInterests from './UserInterests';
import NewsFeed from './NewsFeed';
import Greetings from '../../component/Greetings';

type FeedProps = {
}

const Feed = (props: FeedProps) => {
  const { t } = useTranslation();

  const isTabletScreen = isTablet();
  const isMobileScreen = isMobile();

  const renderHeader = () => {
    return (
      <header className={styles.header}>
        <p>{ isMobileScreen ? '' : t('feed:feed')}</p>
      </header>
    );
  };

  const renderRightSection = () => {
    return (
        <section className={styles.section + ' ' + styles.right}>
          <UserInterests/>
          <NewsFeed />
        </section>
    );
  };

  return (
    <SimplePage>
        <main>
            {!isTabletScreen && !isMobileScreen && renderHeader()}
            <div className={styles.main}>
                <section className={styles.section + ' ' + styles.left}>
                {!isTabletScreen && !isMobileScreen && <>
                  <Greetings withAlert={true}/>
                </>}
                {isTabletScreen ? renderRightSection() : null}
                </section>
                {!isTabletScreen ? renderRightSection() : null}
            </div>
        </main>
    </SimplePage>
  );
};

export default Feed;
