module.exports = {
  primary60: '#BFF2F2',
  primary80: '#7EE5E5',
  primary: '#00CCCC',
  primary100: '#00CCCC',
  primary120: '#00B8B8',
  textPrimary100: '#1C1C1C',
  textSecondary100: '#A8B8C9',
  textSecondary120: '#788EA4',
  white: '#FFFFFF',
  surface100: '#FFFFFF',
  surface140: '#F1F1F1',
  border120: '#ECF0F5',
  border140: '#C9D5E4',
  accentWarning100: '#D70014',
  greyBlue: '#8EA3B9',
  background: '#F5FAFE'
};
