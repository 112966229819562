import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ComparatorType from '../model/ComparatorType';

type ComparatorWidgetProps = {
  recommendation?: { recommendations?: any[]; status: string },
  selectedType: ComparatorType,
  singleType?: boolean,
  cartTransfer?: boolean,
  runSavedSearch?: boolean
}

const ComparatorWidget = (props: ComparatorWidgetProps) => {
  const [display, setDisplay] = useState(false);
  const { i18n } = useTranslation();

  const cleanupDOM = () => {
    // If there is already an existing script, remove it
    for (let i = 0; i < document.scripts.length; i++) {
      const script = document.scripts[i];
      if (script.id === 'hardbacon-comparator-script') {
        script.remove();
      }
    }
  };

  /**
   * Using a trick to hide and then re show the comparator div in html
   * otherwise the event binding of js object inside the widget doesn't work
   */
  useEffect(() => {
    setDisplay(false);
    cleanupDOM();
    let _scriptInDom: HTMLScriptElement;
    setTimeout(() => {
      const script = document.createElement('script');
      script.src = `${process.env.REACT_APP_COMPARATOR_WIDGET_BASE}/index-${props.selectedType}.js.gz`;
      script.id = 'hardbacon-comparator-script';
      _scriptInDom = document.body.appendChild(script);
      setDisplay(true);
    }, 500);

    return () => _scriptInDom?.remove();
  }, [props.selectedType]);

  const getRecommendationIdList = () => {
    if (props.recommendation && props.recommendation.recommendations && props.recommendation.recommendations.length > 0) {
      const productsId = props.recommendation.recommendations!.map((product: any) => product.product.id);
      return productsId.join(',');
    }
    return undefined;
  };

  const getEstimatedBenefitList = () => {
    if (props.recommendation && props.recommendation.recommendations && props.recommendation.recommendations.length > 0) {
      const productsBenefit = props.recommendation.recommendations!.map((product: any) => product.estimatedBenefit);
      return productsBenefit.join(',');
    }
    return undefined;
  };

  return (
    <div className={'w-full flex flex-1 h-full'}>
      {display && (<div id={'hb-comparator-widget'}
            className="hb-widget hardbacon-comparator-widget w-full"
            data-comparator={props.selectedType}
            data-language={i18n.language}
            data-hideanchor={'true'}
            data-hidecopyright={'true'}
            data-carttransfer={props.cartTransfer ? 'true' : 'false'}
            data-runsavedsearch={props.runSavedSearch ? 'true' : 'false'}
            data-estimatedbenefit={getEstimatedBenefitList()}
            data-products={getRecommendationIdList()}
            data-key="64f359b2-4b69-422f-a67f-e56ffd94cbe8">
      </div>)}
    </div>

  );
};

export default ComparatorWidget;
