export const feedEn = {
  feed: 'Feed',
  fire: 'Financial Independence',
  planning: 'Financial Planning',
  travel: 'Travel',
  richest: 'Richest',
  debts: 'Debts',
  loan: 'Loans',
  bank: 'Banking',
  insurance: 'Insurances',
  mortgage: 'Mortgages',
  investing: 'Investing',
  creditScore: 'Credit score',
  creditCard: 'Credit cards',
  crypto: 'Cryptocurrency',
  budget: 'Budget',
  car: 'Car',
  articles: 'Articles',
  exclusiveOffer: 'Exclusive offer',
  exclusiveOffers: 'Exclusive offers',
  learnMoreOffer: 'Learn more'
};

export const feedFr = {
  feed: 'Actualité',
  fire: 'Indépendance financière ',
  planning: 'Planification financière',
  travel: 'Voyages',
  richest: 'Riches',
  debts: 'Dettes',
  loan: 'Prêts',
  bank: 'Banque',
  insurance: 'Assurances',
  mortgage: 'Hypothèques',
  investing: 'Investissement',
  creditScore: 'Cote de crédit',
  creditCard: 'Cartes de crédit',
  crypto: 'Cryptomonnaie',
  budget: 'Budget',
  car: 'Voiture',
  articles: 'Articles',
  exclusiveOffer: 'Offre exclusive',
  exclusiveOffers: 'Offres exclusives',
  learnMoreOffer: 'En savoir plus'
};
