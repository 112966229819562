import React, { useEffect, useState } from 'react';
import Container from '../../../component/Container';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { TransactionCategory } from '../../../model/TransactionCategory';
import { getTransactionCtgNameById, getTransactionCtgEmojiById, getTransactionCtgName } from '../../../utils/banking';
import styles from './../../../assets/styles/modules/budgetCategory.module.css';
import _ from 'lodash';
import { HardbaconButton } from '../../../component';
import { Link } from 'react-router-dom';
import { budgetRoute } from '../../../navigation/Banking';
import { getTopLevelCategoriesTransaction } from '../../../actions/Banking';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';
import { BankingTransaction } from '../../../model/BankingTransaction';
import { formatCurrency } from '../../../i18n';

interface BudgetOverviewProps {
  dates: Date | Date[],
  isCurrentMonth: boolean,
  bankingTransactions: Map<string, BankingTransaction[]>
}

interface BudgetCategories {
  [key: string | number]: number
}

interface FilteredTopCtg {
  categoryId: number,
  amount: number,
  budget?: number,
}

const BudgetOverview = ({ dates, isCurrentMonth, bankingTransactions }: BudgetOverviewProps) => {
  const [topCategories, setTopCategories] = useState<TransactionCategory[]>();
  const [filteredTopCtgs, setFilteredTopCtgs] = useState<FilteredTopCtg[]>();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    categoriesTransaction,
    loadingCategoriesTransaction
  } = useSelector((state: any) => state.banking);
  const { minifiedSelectedAccountIds } = useSelector((state: any) => state.userInstitution);

  useEffect(() => {
    if (!categoriesTransaction && !loadingCategoriesTransaction) {
      dispatch(getTopLevelCategoriesTransaction(minifiedSelectedAccountIds));
    }
  }, [categoriesTransaction, loadingCategoriesTransaction, minifiedSelectedAccountIds]);

  useEffect(() => {
    let categories: TransactionCategory[] = [];
    // current month
    if (isCurrentMonth) {
      setFilteredTopCtgs(undefined);
      if (categoriesTransaction) {
        categories = categoriesTransaction.filter((i:TransactionCategory) => {
          return (
            i.budget > 0 && i.categoryId !== 68
          );
        }).sort((a: any, b: any) => b.periodAmount > a.periodAmount).slice(0, 3);
        setTopCategories(categories);
      }
    // not current month
    } else {
      setTopCategories(undefined);
      const values = bankingTransactions && Array.from(bankingTransactions.values()).flat();
      const budgetCategories: BudgetCategories = {};
      // get all top level categories amount from the transaction list
      values && values.map((i: BankingTransaction) => {
        budgetCategories[i.topLevelCategoryId] =
        Math.round(((budgetCategories[i.topLevelCategoryId] ? budgetCategories[i.topLevelCategoryId] : 0) + i.debit) * 100) / 100;
        return i;
      });
      // sort categories accroding to it's amount from high to low && only keep expense transactions
      const ctgsSortted = [];
      for (const key in budgetCategories) {
        budgetCategories[key] && ctgsSortted.push({ categoryId: parseInt(key), amount: budgetCategories[key], budget: 0 });
      }
      ctgsSortted.sort((a: FilteredTopCtg, b: FilteredTopCtg) => {
        const result = b.amount - a.amount;
        return result;
      });
      // get categories that have a budget
      let budgettedCtgs: TransactionCategory[] = [];
      if (categoriesTransaction) {
        budgettedCtgs = categoriesTransaction.filter((i:TransactionCategory) => {
          return (
            i.budget > 0
          );
        });
      }
      // only get catgeories that 1. have a budget 2. from the transaction list 3. have expense
      let budgettedCtgsSortted: FilteredTopCtg[] = [];
      ctgsSortted.map((i: FilteredTopCtg) => {
        return budgettedCtgs.map((j: TransactionCategory) => {
          if (i.categoryId === j.categoryId &&
            i.categoryId !== 0
          ) {
            const newCtg = i;
            newCtg.budget = j.budget;
            budgettedCtgsSortted.push(i);
          }
          return j;
        });
      });
      // if the above list is < 3, get more from the transaction list
      if (budgettedCtgsSortted.length < 3) {
        ctgsSortted.map((i: FilteredTopCtg) => {
          if (!budgettedCtgsSortted.find((e: FilteredTopCtg) => i === e) &&
          i.categoryId !== 0 &&
          budgettedCtgsSortted.length < 3) {
            budgettedCtgsSortted.push(i);
          }
          return i;
        });
      }
      budgettedCtgsSortted = budgettedCtgsSortted.slice(0, 3);
      setFilteredTopCtgs(budgettedCtgsSortted);
    }
  }, [categoriesTransaction, isCurrentMonth, bankingTransactions, dates]);

  const renderItem = (i: TransactionCategory) => {
    const budgetRatio = i.budgetRatio;
    const categoryId = i.categoryId;
    let initBase = '#74D783';
    if (i.budgetTrend === 'RED') {
      initBase = '#DD5C68';
    } else if (i.budgetTrend === 'YELLOW') {
      initBase = '#EBCB97';
    }

    return (
      <div key={categoryId} className={styles.header} style={{ marginTop: '10px' }}>
        <div>
          <p className={styles.image}>{getTransactionCtgEmojiById(categoryId)}</p>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div>
            <p className={styles.title}>{getTransactionCtgName(i)}</p>
          </div>
          <div className={styles.base}>
            <div className={styles.bar} style={{ backgroundColor: initBase, width: `${budgetRatio * 100 <= 100 ? budgetRatio * 100 : 100}%` }}/>
          </div>
        </div>
      </div>
    );
  };

  const renderFilteredItem = (i: FilteredTopCtg) => {
    const amount = i.amount;
    const budget = i.budget || 0;
    const budgetRatio = amount / budget;
    const categoryId = i.categoryId;
    let initBase = '#74D783';
    if (budgetRatio > 1) {
      initBase = '#DD5C68';
    } else {
      initBase = '#EBCB97';
    }

    return (
      <div key={categoryId} className={styles.header} style={{ marginTop: '10px' }}>
        <div>
          <p className={styles.image}>{getTransactionCtgEmojiById(categoryId)}</p>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div>
            <p className={styles.title}>{getTransactionCtgNameById(categoryId)}</p>
          </div>
          {budget
            ? <div className={styles.base}>
            <div className={styles.bar} style={{ backgroundColor: initBase, width: `${budgetRatio * 100 <= 100 ? budgetRatio * 100 : 100}%` }}/>
          </div>
            : <p className={styles['footer-value']}>{formatCurrency(amount, 0)}</p>}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (isCurrentMonth) {
      return topCategories && topCategories.map((i: TransactionCategory) => {
        return renderItem(i);
      });
    } else {
      return filteredTopCtgs && filteredTopCtgs.map((i: FilteredTopCtg) => {
        return renderFilteredItem(i);
      });
    }
  };

  return !_.isEmpty(topCategories) || !_.isEmpty(filteredTopCtgs) || loadingCategoriesTransaction
    ? (
    <Container header={t('budget')}>
      <div style={{ height: '215px' }}>
        {loadingCategoriesTransaction
          ? (
            <CircularProgress classNames={styles.progress} color={CircularProgressColorEmun.primary}/>
            )
          : (
          <div>
        {renderContent()}
        <div className={styles['edit-center']}>
          <HardbaconButton ghost={true} onPress={() => null} title={t('viewMore')} name={t('viewMore')}>
          <Link to={budgetRoute}>{t('viewMore')}</Link>
          </HardbaconButton>
        </div>
          </div>
            )}
      </div>
    </Container>
      )
    : null;
};

export default BudgetOverview;
