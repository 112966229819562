import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import _ from 'lodash';
import { formatDate, formatDateDMY } from '../../../i18n';
import SimplePage from '../../../component/Layouts/SimplePage';
import styles from './../../../assets/styles/modules/transactions.module.css';
import { filterIcon, calenderIcon, addButtonIcon } from '../../../assets/images';
import TransactionItem from './TransactionItem';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';
import IncomeExpenseView from '../Budget/IncomeExpenseView';
import BudgetOverview from './BudgetOverview';
import { BankingTransaction, BankingTransactionStatus } from '../../../model/BankingTransaction';
import { getBankingTransactions, getTransactionCategories } from '../../../actions/Transactions';
import { pollInstitution, setAccountSelectorPanelOpen, setAddInstitutionPanelOpen } from '../../../actions/UserInstitution';
import NoBankingAccountConnected from '../../../component/NoBankingAccountConnected';
import TransactionItemDetailPanel from '../../../component/TransactionItemDetailPanel';
import TransactionFilter from './TransactionFilter';
import { TransactionTypeEmun } from '../../../model/transactions/TransactionTypeEmun';
import { usePrevious } from '../../../hooks';
import ReducerUserInstitutionState from '../../../model/ReducerUserInstitutionState';
import TransactionDatePicker from './TransactionDatePicker';
import ComparatorSuggestedProduct from '../../../component/ComparatorSuggestedProduct';
import ComparatorType from '../../../model/ComparatorType';
import ConnectYourBankAccount from '../../../component/ConnectYourBankAccount';
import { DateSelectionEmun } from '../../../model/DateSelectionEmun';
import { isMobile, isTablet } from '../../../utils/hooks';
import CreateManualTransaction from './CreateManualTransaction';
import { LoadMore } from '../../../component/LoadMore';
import Greetings from '../../../component/Greetings';
import UserInstitution from '../../../model/UserInstitution';
import { PlaidConnectWidget } from '../../../component/PlaidConnectWidget';
import InstitutionSource from '../../../model/InstitutionSource';

export const defaultTransactionItem = {
  id: -1,
  backendId: '',
  date: '',
  description: '',
  originalDescription: '',
  debit: 0,
  credit: 0,
  balance: 0,
  originalAmount: 0,
  originalCategory: '',
  originalCategoryId: -1,
  splitByCategories: {},
  bankAccountBackendId: '',
  bankAccountId: -1,
  iconName: '',
  excluded: false,
  status: BankingTransactionStatus.POSTED,
  category: '',
  categoryId: -1,
  categoryName: '',
  topLevelCategory: '',
  topLevelCategoryId: -1,
  merchantCategoryCode: -1,
  merchantId: '',
  income: false,
  currency: 'CAD',
  userExchangeRate: 1
};

const Transaction = ({ item, openEditItem, setOpenEditItem, setSelectedTransaction }: {
  setSelectedTransaction: React.Dispatch<React.SetStateAction<BankingTransaction>>
  setOpenEditItem: React.Dispatch<React.SetStateAction<boolean>>; openEditItem: boolean; item: BankingTransaction}) => {
  const { bankAccountBackendId, backendId } = item;
  const { institutions } = useSelector((state: any) => state.userInstitution);

  const findAccountName = (bankAccountBackendId: string) => {
    for (const inst of institutions) {
      for (const acc of inst.institutionAccountDetailDTOs) {
        if (acc.id === bankAccountBackendId) {
          return `${acc.name}`;
        }
      }
    }
    return '';
  };

  return (
    <div key={backendId}>
      <TransactionItem
        item={item}
        accountName={findAccountName(bankAccountBackendId)}
        openEditItem={openEditItem}
        setOpenEditItem={setOpenEditItem}
        setOpenItem={setSelectedTransaction}
      />
    </div>
  );
};

const TransactionList = ({ data, allFilteredData, onLoadMore, setSelectedTransaction, setOpenEditItem, openEditItem }: {
  setSelectedTransaction: React.Dispatch<React.SetStateAction<BankingTransaction>>
  setOpenEditItem: React.Dispatch<React.SetStateAction<boolean>>;
  openEditItem: boolean;
  onLoadMore: () => void;
  data?: Map<string, BankingTransaction[]>;
  allFilteredData?: Map<string, BankingTransaction[]>
}) => {
  const { isLoadingBankingTransactions } = useSelector((state: any) => state.transactions);
  const isEndReached = data && allFilteredData && (data?.size >= allFilteredData?.size);

  if (!data || _.isEmpty(data)) {
    return null;
  }

  return (
    <>
      {Array.from(data.entries()).map(([key, value]) => (
        <div key={key}>
          <p className={styles.date}>{formatDate(key, 'd MMM yyyy')}</p>
          {value.map(v => (
            <Transaction
              key={v.bankAccountId}
              item={v}
              openEditItem={openEditItem}
              setSelectedTransaction={setSelectedTransaction}
              setOpenEditItem={setOpenEditItem}/>
          ))}
        </div>
      ))}
      <LoadMore
        isLoading={isLoadingBankingTransactions}
        loadMore={onLoadMore}
        moreToLoad={!isEndReached}
      />
    </>
  );
};

const RightSection = ({ allFilteredData, data, onLoadMore, setSelectedTransaction, setOpenEditItem, openEditItem }: {
  setSelectedTransaction: React.Dispatch<React.SetStateAction<BankingTransaction>>
  setOpenEditItem: React.Dispatch<React.SetStateAction<boolean>>;
  openEditItem: boolean;
  onLoadMore: () => void;
  data?: Map<string, BankingTransaction[]>;
  allFilteredData?: Map<string,
  BankingTransaction[]>
}) => {
  const [showPlaid, setShowPlaid] = useState(false);
  const dispatch = useDispatch();
  const { institutions } = useSelector((state: any) => state.userInstitution);
  const { isLoadingBankingTransactions } = useSelector((state: any) => state.transactions);
  const { t } = useTranslation();
  const hasInstitution = institutions.length > 0;
  const hasNotSyncInstitution = institutions.some((institution: UserInstitution) => institution.syncStatus === 'error');
  const notSyncedInstitution = institutions.find((institution: UserInstitution) => institution.syncStatus === 'error');
  const lastCreatedConnectedTime = localStorage.getItem('connectedTime');

  const isAccountConnectedInHour = useMemo(() => {
    const difference = dayjs().diff(dayjs(lastCreatedConnectedTime), 'hours');
    return difference < 1;
  }, [lastCreatedConnectedTime]);

  const fixInstitutionError = () => {
    dispatch(pollInstitution(notSyncedInstitution.id, notSyncedInstitution.institutionSource.id));
    setShowPlaid(true);
    // this.openAddAccountModal();
  };

  return (
    <section className={styles.section + ' ' + styles.right}>
      {showPlaid && (
        <PlaidConnectWidget
          hideLoading
          institutionId={notSyncedInstitution && notSyncedInstitution.id}
          onInstitutionAdded={(institutionProviderCode: string, publicToken: string) => {
            const is = { providerCode: institutionProviderCode, credentials: ['token'], dataSource: 'PLAID' } as InstitutionSource;
            const tokenCredential = new Map();
            tokenCredential.set('token', publicToken);
            setShowPlaid(false);
            // this.addAnInstitution(is, undefined, tokenCredential);
          }}
          onConnectSuccess={(institutionProviderCode: string, institutionId: string) => {
            const is = notSyncedInstitution.institutionSource;
            const now = new Date();
            dispatch(pollInstitution(institutionId, is!.id));
            setShowPlaid(false);
            localStorage.setItem('connectedTime', `${now}`);
          }}
          onExit={success => {
            setShowPlaid(false);
          }}
        />
      )}
      {isLoadingBankingTransactions
        ? (
          <div className={styles.progress}>
            <CircularProgress color={CircularProgressColorEmun.primary}/>
          </div>
          )
        : (
            institutions.length === 1 && hasNotSyncInstitution
              ? <NoBankingAccountConnected
                title={t('reconnectAccountTitle')}
                description={t('reconnectAccountDescription')}
                primaryButtonTitle={t('reconnectAccountButton')}
                onPressPrimaryButton={() => fixInstitutionError()} />
              : !_.isEmpty(data)
                  ? (<div>
            <TransactionList
              data={data}
              allFilteredData={allFilteredData}
              onLoadMore={onLoadMore}
              setSelectedTransaction={setSelectedTransaction}
              setOpenEditItem={setOpenEditItem}
              openEditItem={openEditItem} />
          </div>)
                  : isAccountConnectedInHour
                    ? <NoBankingAccountConnected
          title={t('noTransactionInfo')}
          description={t('reconnectInAnHour')} />
                    : !hasInstitution
                        ? (
            <NoBankingAccountConnected
              title={t('addYourTransactions')}
              description={t('addYourTransactionsDescription')}
              primaryButtonTitle={t('manageAccounts:addAccount')}
              onPressPrimaryButton={() => dispatch(setAddInstitutionPanelOpen(true))} />
                          )
                        : (
                <NoBankingAccountConnected
                  title={t('noTransactionInfo')}
                  description={t('noTransactionInfoDesc')}
                  primaryButtonTitle={t('noTransactionInfoAction')}
                  onPressPrimaryButton={() => dispatch(setAccountSelectorPanelOpen(true))}
                  secondaryButtonTitle={t('noTransactionInfoAction2')}
                  onPressSecondaryButton={() => dispatch(setAddInstitutionPanelOpen(true))} />
                          )
          )}
    </section>
  );
};

const Transactions = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const [openAddManualAct, setOpenAddManualAct] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openEditItem, setOpenEditItem] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<BankingTransaction>(defaultTransactionItem);
  const [data, setData] = useState<Map<string, BankingTransaction[]>>();
  const [allFilteredData, setAllFilteredData] = useState<Map<string, BankingTransaction[]>>();
  const [dateSelection, setDateSelection] = useState<DateSelectionEmun>(DateSelectionEmun.none);
  const [page, setPage] = useState(1);
  const date = new Date();
  const currentMonthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const currentMonthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const prevMonthFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const prevMonthLastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobileScreen = isMobile();
  const isTabletScreen = isTablet();
  const { minifiedSelectedAccountIds, hasBankingAccounts } = useSelector((state: any) => state.userInstitution);
  const previousMinifiedSelectedAccountIds = usePrevious<ReducerUserInstitutionState>(minifiedSelectedAccountIds);
  const { transactionsStartDate, transactionsEndDate, bankingTransactions, isLoadingBankingTransactions, transactionCategories, filteredTrxCategories, filteredTrxTypes } = useSelector((state: any) => state.transactions);

  const incomeFilter = _.find(filteredTrxTypes, (i) => i === TransactionTypeEmun.income);
  const expenseFilter = _.find(filteredTrxTypes, (i) => i === TransactionTypeEmun.expense);

  useEffect(() => {
    if (transactionCategories.length === 0) {
      dispatch(getTransactionCategories());
    }
  }, [transactionCategories]);

  useEffect(() => {
    if (!bankingTransactions) {
      return;
    }

    const _data = new Map<string, BankingTransaction[]>();
    let transactions = bankingTransactions;
    transactions.sort((a: BankingTransaction, b: BankingTransaction) => {
      if (dayjs(a.date).isBefore(b.date)) return 1;
      if (dayjs(a.date).isAfter(b.date)) return -1;
      return 0;
    });
    if (transactionsStartDate && transactionsEndDate) {
      transactions = transactions.filter((tr: BankingTransaction) => {
        let shouldDisplay = false;
        if ((dayjs(tr.date).isAfter(transactionsStartDate) || dayjs(tr.date).isSame(transactionsStartDate)) &&
       (dayjs(tr.date).isBefore(transactionsEndDate) || dayjs(tr.date).isSame(transactionsEndDate))) {
          shouldDisplay = true;
        }
        return shouldDisplay;
      });
    }
    if (!_.isEmpty(filteredTrxCategories) || !_.isEmpty(filteredTrxTypes)) {
      transactions = transactions.filter((tr: BankingTransaction) => {
        const result = filteredTrxCategories?.length
          ? !!filteredTrxCategories.find((i: number) => {
              if (tr.categoryId === 0 && tr.splitByCategories) {
                const isFound = Object.keys(tr.splitByCategories).find((key) => {
                  return key === i.toString();
                });
                return isFound;
              }
              return i === tr.categoryId;
            })
          : true;
        let shouldDisplay = result;
        if (incomeFilter && !expenseFilter) {
          shouldDisplay = shouldDisplay && (tr.credit > 0);
        } else if (!incomeFilter && expenseFilter) {
          shouldDisplay = shouldDisplay && (tr.debit > 0);
        }
        return shouldDisplay;
      });
    }

    const allData = new Map<string, BankingTransaction[]>();

    transactions.forEach((tr: BankingTransaction) => {
      const list = allData.get(tr.date);
      if (!list) {
        allData.set(tr.date, [tr]);
      } else {
        list.push(tr);
      }
    });

    setAllFilteredData(allData);

    transactions.slice(0, page * 20).forEach((tr: BankingTransaction) => {
      const list = _data.get(tr.date);
      if (!list) {
        _data.set(tr.date, [tr]);
      } else {
        list.push(tr);
      }
    });
    setData(_data);
  }, [bankingTransactions, page, filteredTrxCategories, filteredTrxTypes, transactionsStartDate, transactionsEndDate]);

  const dispatchGetBankingTransactions = () => {
    dispatch(
      getBankingTransactions(
        minifiedSelectedAccountIds,
        dayjs(dayjs(new Date()).subtract(6, 'months')),
        dayjs()
      )
    );
  };

  useEffect(() => {
    if (!minifiedSelectedAccountIds || JSON.stringify(previousMinifiedSelectedAccountIds || []) === JSON.stringify(minifiedSelectedAccountIds)) {
      return;
    }
    !isLoadingBankingTransactions && dispatchGetBankingTransactions();
  }, [minifiedSelectedAccountIds]);

  useEffect(() => {
    if (hasBankingAccounts && minifiedSelectedAccountIds?.length === 0 && _.isUndefined(bankingTransactions) && !isLoadingBankingTransactions) {
      dispatchGetBankingTransactions();
    }
  }, [minifiedSelectedAccountIds, bankingTransactions, hasBankingAccounts]);

  let filterCount = filteredTrxCategories.length;
  incomeFilter && (filterCount = filterCount + 1);
  expenseFilter && (filterCount = filterCount + 1);

  useEffect(() => {
    if (transactionsEndDate && transactionsStartDate) {
      if (formatDateDMY(transactionsStartDate) === formatDateDMY(currentMonthFirstDay) && formatDateDMY(transactionsEndDate) === formatDateDMY(currentMonthLastDay)) {
        setDateSelection(DateSelectionEmun.current);
      } else if (formatDateDMY(transactionsStartDate) === formatDateDMY(prevMonthFirstDay) && formatDateDMY(transactionsEndDate) === formatDateDMY(prevMonthLastDay)) {
        setDateSelection(DateSelectionEmun.prev);
      } else {
        setDateSelection(DateSelectionEmun.custom);
      }
    } else {
      setDateSelection(DateSelectionEmun.current);
    }
  }, [transactionsStartDate, transactionsEndDate]);

  const renderHeader = () => {
    return (
      <header className={styles.header}>
        <p>{ isMobileScreen ? '' : t('transactions')}</p>
        <div className={styles['button-container']}>
          <div className={styles['date-button']} onClick={() => setOpenDatePicker(!openDatePicker)}>
            <img src={calenderIcon}
                className={styles['button-icon'] + ' ' + styles['small-icon']}
                alt={t('addCategory')}/>
              {dateSelection === DateSelectionEmun.custom
                ? (
                <p className={styles['date-text']}>
                  <span>
                      {' ' + t('from') + ' '}
                      <span>
                          {formatDate(transactionsStartDate, 'd MMM yyyy')}
                      </span>
                  </span>
                  <span>
                      {' ' + t('to') + ' '}
                      <span>
                          {formatDate(transactionsEndDate, 'd MMM yyyy')}
                      </span>
                  </span>
              </p>
                  )
                : (
                <p className={styles['date-text']}>
                  <span>
                      {dateSelection === DateSelectionEmun.current && t('currentMonth')}
                      {dateSelection === DateSelectionEmun.prev && t('prevMonth')}
                  </span>
            </p>
                  )}
          </div>
          <div className={styles['header-button']} onClick={() => setOpenFilter(!openFilter)}>
            <img src={filterIcon}
                className={styles['button-icon']}
                alt={t('addCategory')}/>
            {filterCount
              ? <span className={styles['filter-count']}>
                {filterCount}
              </span>
              : null
            }
          </div>
          <div className={styles['header-button']} onClick={() => setOpenAddManualAct(!openAddManualAct)}>
            <img src={addButtonIcon}
                className={styles['button-icon']}
                alt={t('addCategory')}/>
          </div>
        </div>
      </header>
    );
  };

  return <SimplePage>
    {hasBankingAccounts === false && (
      <ConnectYourBankAccount />
    )}
    {hasBankingAccounts && (
      <div>
        <CreateManualTransaction
          isVisible={openAddManualAct}
          onClose={() => setOpenAddManualAct(false)}
        />
        <TransactionFilter
          isVisible={openFilter}
          onClose={() => setOpenFilter(false)}
        />
        <TransactionDatePicker
          isVisible={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
        />
        <main>
          {renderHeader()}
          {<TransactionItemDetailPanel
            visible={openEditItem}
            onClose={() => {
              setOpenEditItem(false);
              setSelectedTransaction(defaultTransactionItem);
            }}
            selectedTransaction={selectedTransaction as BankingTransaction}
            onItemPress={() => {
              setOpenEditItem(false);
              setSelectedTransaction(defaultTransactionItem);
            }}
          />}
          <div className={styles.main}>
            <section className={styles.section + ' ' + styles.left}>
              <Greetings />
              <div className={styles['top-two']}>
                <IncomeExpenseView dates={[transactionsStartDate, transactionsEndDate]}/>
                {!isMobileScreen
                  ? (
                  <BudgetOverview
                    bankingTransactions={allFilteredData as Map<string, BankingTransaction[]>}
                    isCurrentMonth={dateSelection === DateSelectionEmun.current} dates={[transactionsStartDate, transactionsEndDate]}
                  />
                    )
                  : null}
              </div>
              {isTabletScreen
                ? (<RightSection
                    onLoadMore={() => setPage(page + 1)}
                    data={data}
                    setSelectedTransaction={setSelectedTransaction}
                    setOpenEditItem={setOpenEditItem}
                    openEditItem={openEditItem} />)
                : null}
              {isMobileScreen
                ? (
                  <BudgetOverview
                    bankingTransactions={allFilteredData as Map<string, BankingTransaction[]>}
                    isCurrentMonth={dateSelection === DateSelectionEmun.current} dates={[transactionsStartDate, transactionsEndDate]}
                  />
                  )
                : null}
              <ComparatorSuggestedProduct selectedTypes={[ComparatorType.creditCard, ComparatorType.checking, ComparatorType.saving]} />
            </section>
            {!isTabletScreen
              ? (<RightSection
                onLoadMore={() => setPage(page + 1)}
                data={data}
                setSelectedTransaction={setSelectedTransaction}
                setOpenEditItem={setOpenEditItem}
                openEditItem={openEditItem} />)
              : null}
          </div>
        </main>
      </div>)}
  </SimplePage>;
};

export default Transactions;
