import { useEffect, useRef } from 'react';

/**
 * usePrevious allows to get the previous version of an object in a component
 * (for example to compare changes in a component)
 * @param value the value to keep track of
 */
export const usePrevious = <T extends unknown> (value: T) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};
