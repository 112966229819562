import React from 'react';
import HardbaconButton from './HardbaconButton';
import styles from '../assets/styles/modules/NoBankingAccountConnected.module.css';
import noBankingAccountConnected from '../assets/images/files/banking/no-bank-account-connected.png';

interface NoBankingAccountConnectedProps {
  title: string;
  description: string;
  primaryButtonTitle?: string;
  onPressPrimaryButton?: Function;
  secondaryButtonTitle?: string;
  onPressSecondaryButton?: Function;
}

const NoBankingAccountConnected = (
  { title, description, primaryButtonTitle, onPressPrimaryButton, secondaryButtonTitle, onPressSecondaryButton }
    : NoBankingAccountConnectedProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={noBankingAccountConnected} alt={'no bank account'}/>
      </div>
      <div className={styles.text1}>{title}</div>
      <div className={styles.text2}>{description}</div>
      {primaryButtonTitle && <HardbaconButton name={'connect'} title={primaryButtonTitle}
                       onPress={() => onPressPrimaryButton && onPressPrimaryButton()}/>}
      {secondaryButtonTitle && <HardbaconButton name={'action2'} title={secondaryButtonTitle}
                        inverse={true}
                        onPress={() => onPressSecondaryButton!()}/>}
    </div>
  );
};

export default NoBankingAccountConnected;
