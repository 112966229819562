import ReducerPayload from '../model/ReducerPayload';
import ReducerResetPasswordState from '../model/ReducerResetPasswordState';
import {
  SET_FORGOT_PASSWORD_SENT, SET_FORGOT_PASSWORD_ERROR,
  SET_RESET_PASSWORD_DONE, SET_RESET_PASSWORD_ERROR
} from '../types/ResetPassword';

export const initialState: ReducerResetPasswordState = {
  forgotPasswordError: false,
  resetPasswordError: false,
  resetPasswordDone: false,
  lastPasswordResetTime: 0,
  lastPasswordResetEmail: undefined
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case SET_FORGOT_PASSWORD_SENT:
      return {
        ...state,
        forgotPasswordError: false,
        lastPasswordResetEmail: action.payload,
        lastPasswordResetTime: Date.now()
      };
    case SET_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: false,
        forgotPasswordError: action.payload
      };
    case SET_RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: action.payload,
        forgotPasswordError: false
      };
    case SET_RESET_PASSWORD_DONE:
      return {
        ...state,
        resetPasswordDone: action.payload,
        resetPasswordError: false
      };
    default:
      return state;
  }
};
