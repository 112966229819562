import React, { useEffect } from 'react';
import { Navigate, Route } from 'react-router-dom';
import Budget from '../container/Banking/Budget';
import Transactions from '../container/Banking/Transactions';
import Overview from '../container/Banking/Overview';
import OnBoarding from '../container/Banking/Budget/Onboarding';
import { fetchBankingCategories } from '../actions/Banking';
import { useSelector, useDispatch } from 'react-redux';

export const route = '/budget';
export const budgetRoute = route + '/budget';
export const budgetOnBoardingRoute = route + '/onboarding';
export const transactionsRoute = route + '/transactions';
export const overviewRoute = route + '/overview';

export default function bankingRoute () {
  const dispatch = useDispatch();
  const {
    bankingCategories,
    loadingBankCategories
  } = useSelector((state: any) => state.banking);

  useEffect(() => {
    if (!bankingCategories.length && !loadingBankCategories) {
      dispatch(fetchBankingCategories());
    }
  }, [bankingCategories, loadingBankCategories]);
  return (
    <>
      <Route
        path={route}
        element={<Navigate to={transactionsRoute} replace />}
      />
      <Route path={transactionsRoute} element={<Transactions/>}/>
      <Route path={budgetRoute} element={<Budget/>}/>
      <Route path={budgetOnBoardingRoute} element={<OnBoarding/>}/>
      <Route path={overviewRoute} element={<Overview/>}/>
    </>
  );
}
