import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { informationRoute, premiumRoute } from '../../navigation/Profile';
import { useSelector } from 'react-redux';

/* eslint-disable no-unused-vars */
enum ProfileTab {
  profile,
  premium,
}

const ProfileHeader = () => {
  const [selectedTab, setSelectedTab] = useState(ProfileTab.profile);
  const { t } = useTranslation('header');
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (location.pathname === informationRoute) {
      setSelectedTab(ProfileTab.profile);
    } else if (location.pathname === premiumRoute) {
      setSelectedTab(ProfileTab.premium);
    }
  }, [location]);

  return (
    <div>
      <h1 className={'large-text text-white my-3'}>{user?.firstName} {user?.lastName}</h1>
    </div>
  );
};

export default ProfileHeader;
