import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(
  sagaMiddleware,
  thunk
);

export default createStore(rootReducer, middleware);
