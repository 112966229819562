import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import HardbaconModal, { HardbaconModalStyle } from './HardbaconModal';
import { useTranslation } from 'react-i18next';

type HardbaconImageUploadProps = {
  visible: boolean,
  onSave?: (imageAsBas64: string, imageContentType: string) => void;
  onClose?: () => void;
}

const HardbaconImageUpload = (props: HardbaconImageUploadProps) => {
  const [errorFileTooLarge, setErrorFileTooLarge] = useState<boolean>(false);
  const [errorLoadingFile, setErrorLoadingFile] = useState<boolean>(false);
  const [image, setImage] = useState<string>();
  const [imageContentType, setImageContentType] = useState<string>();
  const [crop, setCrop] = useState<any>({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    aspect: 1
  });
  const [imageElement, setImageElement] = useState<HTMLImageElement>();
  const [imageModalOpened, setImageModalOpened] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.visible) {
      setImage(undefined);
      setCrop({
        unit: '%',
        x: 25,
        y: 25,
        width: 50,
        aspect: 1
      });
      setImageElement(undefined);
      setErrorFileTooLarge(false);
      setErrorLoadingFile(false);
    }

    setImageModalOpened(props.visible);
  }, [props.visible]);

  const loadFile = (event: any) => {
    const files = event.target.files;
    const filesArr = Array.prototype.slice.call(files);
    const file = filesArr && filesArr.length > 0 ? filesArr[0] : undefined;
    if (file) {
      if (file.size <= 500000) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64 = reader.result as string;
          const mimeType = base64.substring(5, base64.indexOf(';'));
          setImage(base64);
          setImageContentType(mimeType);
          setErrorFileTooLarge(false);
          setErrorLoadingFile(false);
        };
        reader.onerror = (error) => {
          console.warn(error);
          setErrorLoadingFile(true);
        };
        reader.readAsDataURL(file);
      } else {
        setErrorFileTooLarge(true);
      }
    }
  };

  const save = () => {
    const image = getCroppedImg();

    if (image && imageContentType && props.onSave) {
      // Call the onSave props function
      props.onSave(image, imageContentType as string);
    }
    // Close everything
    if (props.onClose) {
      props.onClose();
    }
  };

  const onChange = (crop: any) => {
    setCrop(crop);
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    setImageElement(image);
  };

  const getCroppedImg = (): string => {
    if (!imageElement) {
      return '';
    }
    const canvas = document.createElement('canvas');
    const scaleX = imageElement.naturalWidth / imageElement.width;
    const scaleY = imageElement.naturalHeight / imageElement.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(
      imageElement,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    // As Base64 string
    const base64Url = canvas.toDataURL(imageContentType);
    return base64Url.substr(base64Url.indexOf(',') + 1);
  };

  return (
    <HardbaconModal
      style={HardbaconModalStyle.action}
      open={imageModalOpened}
      negativeText={t('cancel')}
      positiveText={t('save')}
      title={t('uploadImageDialogTitle')}
      positiveAction={() => {
        save();
      }}
      dismissAction={() => {
        setImageModalOpened(false);
      }}
    >
      <>
        {(errorFileTooLarge || errorLoadingFile) && (
          <div className="mt-4">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h4 className="text-lg leading-6 text-gray-900" id="modal-headline">
                {t('uploadImageDialogUnableToLoad')}
              </h4>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('uploadImageDialogUnableToLoadErrorMessage')}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="mt-3 text-center sm:mt-5">
          <div className="mt-2">
            {!image && (
              <div
                className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                       aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label htmlFor={'photo'}
                           className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                      <span>{t('chooseFile')}</span>
                      <input type="file" accept="image/*"
                             name={'photo'} id={'photo'}
                             className="sr-only"
                             onChange={(e) => loadFile(e)}/>
                    </label>
                    <p className="pl-1">{t('chooseFile2')}</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    {t('chooseFile3')}
                  </p>
                </div>
              </div>
            )}
            {image && (
              <ReactCrop
                maxWidth={400}
                onChange={onChange}
                crop={crop}
                src={image as string}
                locked={false}
                ruleOfThirds={true}
                onImageLoaded={onImageLoaded}
              />)}
          </div>
        </div>
      </>
    </HardbaconModal>

  );
};

export default HardbaconImageUpload;
