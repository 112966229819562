import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HardbaconAmount from '../../../component/HardbaconAmount';
import styles from '../../../assets/styles/modules/insightRecurring.module.css';
import { getIconForMerchant } from '../../../utils/banking';
import { BankingFrequentTransaction } from '../../../model/BankingFrequentTransaction';
import { BankingOverviewStats } from '../../../model/BankingOverviewStats';
import { formatCurrency } from '../../../i18n';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';

interface InsightMostAmountProps {
  seeMore: () => void;
}

const InsightMostAmount = (props: InsightMostAmountProps) => {
  const { t } = useTranslation();
  const { bankStats, loadingBankStats }: { bankStats: BankingOverviewStats, loadingBankStats: boolean } = useSelector((state: any) => state.banking);

  const renderFrequent = (frequentTransaction: BankingFrequentTransaction) => (
    <div key={frequentTransaction.description} className={styles.recurringItem}>
      <div className={styles.recurringMerchant}>
        <img src={getIconForMerchant(frequentTransaction.merchantId)} width={40}/>
      </div>
      <div className={styles.middle}>
        <div className={styles.title}>{frequentTransaction.description}</div>
        <div className={styles.desc}>{formatCurrency(frequentTransaction.periodAmount)} {t('thisMonth')}</div>
      </div>
      <div className={styles.end}>
        <HardbaconAmount amount={frequentTransaction.totalAmount} color={'#788EA4'} size={14} decimals={2} />
        <div>{t('twelveMonths')}</div>
      </div>
    </div>);

  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        {loadingBankStats && (
          <div className={styles.progress}>
            <CircularProgress color={CircularProgressColorEmun.primary}/>
          </div>
        )}
        {!loadingBankStats && bankStats && bankStats.topMerchantsTransactions.slice(0, 3).map(tr => renderFrequent(tr))}
      </div>
      {!loadingBankStats && <div className={styles.seeMore} onClick={() => props.seeMore()}>{t('seeMore')}</div>}
    </div>
  );
};

export default InsightMostAmount;
