import React, { useState } from 'react';
import { BankingTransaction } from '../../../model/BankingTransaction';
import TransactionItemDetailPanel from '../../../component/TransactionItemDetailPanel';
import InstitutionAccountDetail from '../../../model/InstitutionAccountDetail';
import { defaultTransactionItem } from '.';

interface CreateManualTransactionProps {
  isVisible: boolean;
  onClose: Function;
}

const CreateManualTransaction = ({ isVisible, onClose }: CreateManualTransactionProps) => {
  const [openManualTrx, setOpenManualTrx] = useState<InstitutionAccountDetail>();

  const onAccountPress = (act: InstitutionAccountDetail) => {
    setOpenManualTrx(act);
  };

  let originalDescription = '';
  if (openManualTrx) {
    originalDescription = openManualTrx.name;
  }

  const item: BankingTransaction = {
    ...defaultTransactionItem,
    originalDescription: originalDescription,
    id: 1,
    categoryId: 115,
    topLevelCategoryId: 115
  };

  return (
    <TransactionItemDetailPanel
      visible={isVisible}
      onClose={onClose}
      selectedTransaction={item}
      manualAccount={openManualTrx}
      onItemPress={() => {
        onClose();
      }}
      onManualAccountPress={onAccountPress}
    />
  );
};

export default CreateManualTransaction;
