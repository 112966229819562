import React from 'react';
import { Route } from 'react-router-dom';
import ManageAccount from '../container/ManageAccount';
import { route as profileRoute } from './Profile';

export const route = '/accounts';
export const alternativePreviousAccountRoute = `${profileRoute}/accounts`;

export default function accountsRoute () {
  return (
        <Route path={route} element={<ManageAccount/>}/>
  );
}
