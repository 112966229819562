import AxiosInstance from './index';
import User from '../model/User';
import { fromAxiosError } from '../model/APIError';
import UserToken from '../model/UserToken';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { clearCachedData } from './caching';
import dayjs, { Dayjs } from 'dayjs';

export function register (user: User, recaptcha: string): Observable<User> {
  user.platform = 'WEBAPP';
  const data = JSON.stringify({
    ...user,
    recaptcha
  });
  return AxiosInstance.post<User>('api/register', data, { params: { source: 'WEBSITE' } }).pipe(
    map(resp => resp.data),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getAccount (withSubscription: boolean = false): Observable<User> {
  return AxiosInstance.get<User>(`api/account?withSubscription=${withSubscription}&platform=WEBAPP`).pipe(
    map(resp => resp.data),
    catchError(error => throwError(fromAxiosError(error))));
}

export function logOut (): Observable<boolean> {
  clearCachedData();
  return AxiosInstance.post('api/logout').pipe(
    map(res => true),
    catchError(error => throwError(fromAxiosError(error))));
}

export function authenticate (login: string | undefined, password: string | undefined, googleIdToken: string | undefined, appleIdToken: string | undefined, fbIdToken: string | undefined, rememberMe: boolean, recaptcha: string): Observable<UserToken> {
  const data = JSON.stringify({ login, password, googleIdToken, appleIdToken, fbIdToken, rememberMe, recaptcha, platform: 'WEBAPP', reactVersion: process.env.REACT_APP_VERSION });
  return AxiosInstance.post<User>('api/authenticate', data).pipe(
    map(resp => ({ token: resp.headers.authorization, user: resp.data })),
    catchError(error => throwError(fromAxiosError(error))));
}

export function initForgotPassword (mail: string): Observable<boolean> {
  return AxiosInstance.post('api/account/reset-password/init', mail, { headers: { 'Content-Type': 'text/plain' } }).pipe(
    map(resp => true),
    catchError(error => throwError(fromAxiosError(error))));
}

export function finishForgotPassword (key: string, password: string, recaptcha: string): Observable<boolean> {
  const data = JSON.stringify({ key, newPassword: password, recaptcha });
  return AxiosInstance.post('api/account/reset-password/finish', data).pipe(
    map(resp => true),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getPicture (): Observable<string> {
  return AxiosInstance.get<string>('api/account/picture').pipe(
    map(resp => resp.data),
    catchError(error => throwError(fromAxiosError(error))));
}

export function updateUser (user: User): Observable<boolean> {
  const data = JSON.stringify({ ...user });
  return AxiosInstance.post('api/account', data).pipe(
    map(resp => true),
    catchError(error => throwError(fromAxiosError(error))));
}

export function updateUserPicture (image: string, mimeType: string): Observable<boolean> {
  const data = JSON.stringify({ image, mimeType });
  return AxiosInstance.post('api/account/picture', data).pipe(
    map(resp => true),
    catchError(error => throwError(fromAxiosError(error))));
}

export function deleteAccount (reason: string, comment: string | undefined): Observable<boolean> {
  const data = JSON.stringify({ reason, feedback: comment });
  return AxiosInstance.delete<boolean>('api/account', { data }).pipe(
    map(() => true),
    catchError(error => throwError(fromAxiosError(error))));
}

export function grantAccess (token: string): Observable<Dayjs | undefined> {
  return AxiosInstance.get(`api/support/grant/${token}`).pipe(
    map((resp) => dayjs(resp.data)),
    catchError(() => of(undefined)));
}
