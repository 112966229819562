import React, { useEffect, useRef, useState } from 'react';
import { HardbaconButton, HardbaconInput } from '../../component';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword as resetUserPassword, sendForgetPassword } from '../../actions/User';
import i18n from '../../i18n';
import { route as signUpRoute } from '../../navigation/SignUp';
import { route as signInRoute } from '../../navigation/SignIn';
import { HardbaconLeftPanel } from '../../component/HardbaconLeftPanel';
import AppNotificationType from '../../model/AppNotificationType';
import { setNotification } from '../../actions/Notification';
import { greenCheck } from '../../assets/images';

const resetCodeQueryName = 'key';

const ResetPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [resetCode, setResetcode] = useState<string>();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    forgotPasswordError,
    resetPasswordError,
    resetPasswordDone,
    lastPasswordResetTime,
    lastPasswordResetEmail
  } = useSelector((state: any) => state.resetPassword);
  const dispatch = useDispatch();

  const emailInput = useRef<HardbaconInput>(null);
  const passwordInput = useRef<HardbaconInput>(null);
  const confirmPasswordInput = useRef<HardbaconInput>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const _resetCode = params.get(resetCodeQueryName);
    if (_resetCode && _resetCode.length > 0) {
      setResetcode(_resetCode);
    }
  }, [location]);

  useEffect(() => {
    if (resetPasswordDone) {
      navigate(signInRoute);
      dispatch(setNotification({
        title: t('resetDone'),
        description: t('resetDoneText'),
        type: AppNotificationType.success
      }));
    }
    if (forgotPasswordError) {
      dispatch(setNotification({
        title: t('addAccountGenericError'),
        description: t('resetPasswordInitError'),
        type: AppNotificationType.error
      }));
    }
    if (resetPasswordError) {
      dispatch(setNotification({
        title: t('addAccountGenericError'),
        description: t('resetPasswordFinishError'),
        type: AppNotificationType.error
      }));
    }
  }, [resetPasswordDone, forgotPasswordError, resetPasswordError]);

  const resetPassword = () => {
    const passwordValidation = passwordInput.current?.validate();
    const confirmPasswordValidation = confirmPasswordInput.current?.validate();
    if (confirmPasswordInput.current?.state.value !== passwordInput.current?.state.value) {
      confirmPasswordInput.current?.setErrorLabel(t('confirmPasswordError'));
      return;
    }
    if (passwordValidation && confirmPasswordValidation) {
      recaptchaRef.current?.executeAsync().then((token) => {
        if (token) {
          dispatch(resetUserPassword(resetCode!, passwordInput.current?.state.value!, token));
        }
      }).finally(() => recaptchaRef.current?.reset());
    }
  };

  const sendForgotPassword = () => {
    const emailValidation = emailInput.current?.validate();
    if (emailValidation) {
      setEmailSent(true);
      const email = emailInput.current?.state.value!;
      dispatch(sendForgetPassword(email));
    }
  };

  const renderBeforeResetSent = () => {
    return (<div className={'flex flex-1 justify-center flex-col lg:w-96 self-center'}>
      <h1 className={'large-text text-black'}>{t('forgotPassword')}</h1>
      <div className="my-4">
        <HardbaconInput regex={'\\S+@\\S+\\.\\S+'} optional={false}
                        ref={emailInput}
                        name={'email'} type={'email'}
                        placeholder={t('email')}/>
      </div>

      <HardbaconButton name={'login'} title={t('sendEmail')} onPress={() => {
        sendForgotPassword();
      }}/>

      <div onClick={(e) => {
        navigate(signUpRoute);
      }} className={'flex w-full justify-start button-hover cursor-pointer mt-4'}>
                            <span
                              className={'small-bold-text text-gray-medium text-right'}>{t('dontHaveAccount')}
                              <span className={'small-bold-text text-hardbacon'}> {t('signUp')}</span></span>
      </div>
    </div>);
  };

  const renderAfterResetSent = () => {
    return (
      <div className={'flex flex-1 justify-center flex-col items-center lg:w-96 self-center'}>
        <img alt={'email sent'} src={greenCheck} className={'w-16'}/>
        <h1 className={'large-text text-black mt-3'}>{t('emailSent')}</h1>
        <span style={{ lineHeight: 1.2 }}
              className={'medium-regular-text text-hardbacon-success mt-3 text-center'}>{t('afterResetText', { email: lastPasswordResetEmail })}</span>

        <span style={{ lineHeight: 1.2 }}
              className={'medium-regular-text text-gray-dark mt-3 mb-10 text-center'}>{t('afterResetText2')}</span>

        <HardbaconButton
          name={'signIn'}
          type={'button'}
          title={t('signIn')}
          onPress={() => {
            navigate(signInRoute);
          }}
        />
      </div>);
  };

  const renderTooSoonToRetry = () => {
    return renderAfterResetSent();
  };

  const renderResetPassword = () => {
    return (<div className={'flex flex-1 justify-center flex-col lg:w-96 self-center'}>
      <h1 className={'large-text text-black'}>{t('resetPassword')}</h1>
      <div className="mt-4">
        <HardbaconInput regex={'^.{10,50}$'} optional={false}
                        ref={passwordInput} name={'password'}
                        isSecureTextEntry={true} placeholder={t('newPassword')}
                        helpLabel={t('passwordRegex')}/>
      </div>
      <div className="my-4">
        <HardbaconInput regex={'^.{10,50}$'} optional={false}
                        ref={confirmPasswordInput} name={'confirmPassword'}
                        isSecureTextEntry={true} placeholder={t('confirmPassword')}
                        helpLabel={t('passwordMatch')}/>
      </div>

      <div className={'mb-3'}>
        <ReCAPTCHA
          ref={recaptchaRef}
          hl={i18n.language}
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
          size="invisible"
        />
      </div>

      <HardbaconButton name={'login'} title={t('sendNewPassword')} onPress={() => {
        resetPassword();
      }}/>

      <div onClick={(e) => {
        navigate(signUpRoute);
      }} className={'flex w-full justify-start button-hover cursor-pointer mt-4'}>
                            <span
                              className={'small-bold-text text-gray-medium text-right'}>{t('dontHaveAccount')}
                              <span className={'small-bold-text text-hardbacon'}> {t('signUp')}</span></span>
      </div>
    </div>);
  };

  return (
    <div className={'flex flex-col md:flex-row flex-1 w-full'}>
      <HardbaconLeftPanel/>
      <div className={'flex-1 p-4 md:p-8 lg:p-20 flex-col flex bg-white'}>
        <div onClick={(e) => {
          i18n.changeLanguage(t('header:otherLangCode'));
        }} className={'flex w-full justify-end button-hover cursor-pointer'}>
          <span className={'small-bold-text text-gray-medium text-right'}>{t('header:otherLang')}</span>
        </div>
        {resetCode
          ? renderResetPassword()
          : (emailSent
              ? renderAfterResetSent()
              : ((Date.now() - lastPasswordResetTime < 300000) ? renderTooSoonToRetry() : renderBeforeResetSent()))}
        <div/>
      </div>
    </div>
  );
};

export default ResetPassword;
