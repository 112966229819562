import React, { CSSProperties } from 'react';
import { tooltipIcon } from '../assets/images';
import styles from './../assets/styles/modules/container.module.css';

interface ContainerProps {
  isExpandable?: boolean;
  header?: string;
  tooltip?: string;
  renderContent?: Function;
  children: string | React.ReactChild;
  onClick?: () => any;
  className?: string;
  headerClassName?: string;
  isExcluded?: boolean;
  style?: CSSProperties;
  headerStyle?: CSSProperties | string
}

const Container = ({ style, headerStyle, header, tooltip, onClick, className, headerClassName, children, isExcluded = false }: ContainerProps) => {
  return (
    <div
      className={`${styles.box} ${className ?? ''} ${isExcluded ? styles.excluded : ''} ${onClick ? styles['with-click'] : ''}`}
      onClick={onClick || (() => null)}
      style={style}
    >
      {header && <header className={`${styles.header} ${headerClassName ?? ''}`}>
        <p className={styles['header-title']}>{header}</p>
        { tooltip && <img className={styles.tooltip} alt={tooltip} src={tooltipIcon} onClick={() => null}/>}
      </header>}
      {children}
    </div>
  );
};

export default Container;
