import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimplePage from '../../../component/Layouts/SimplePage';
import styles from '../../../assets/styles/modules/budgetOverview.module.css';
import Container from '../../../component/Container';
import InsightCurrentBalance from './InsightCurrentBalance';
import InsightTips from './InsightTips';
import InsightRecurring from './InsightRecurring';
import InsightMostOften from './InsightMostOften';
import InsightMostAmount from './InsightMostAmount';
import { useDispatch, useSelector } from 'react-redux';
import { getBankOverview } from '../../../actions/Banking';
import InsightReport from './InsightReport';
import { RightPanel } from '../../../component';
import InsightTipsPanel from './InsightTipsPanel';
import InsightRecurringPanel from './InsightRecurringPanel';
import InsightMostAmountPanel from './InsightMostAmountPanel';
import InsightMostOftenPanel from './InsightMostOftenPanel';
import ConnectYourBankAccount from '../../../component/ConnectYourBankAccount';

const Overview = () => {
  const [panelTitle, setPanelTitle] = useState('');
  const [openRightPanel, setOpenRightPanel] = useState(false);
  const { t } = useTranslation('overview');
  const { bankStats } = useSelector((state: any) => state.banking);
  const { minifiedSelectedAccountIds, hasBankingAccounts } = useSelector((state: any) => state.userInstitution);
  const dispatch = useDispatch();

  useEffect(() => {
    // Refetch the data when the account selection changes
    if (hasBankingAccounts && !bankStats && minifiedSelectedAccountIds) {
      dispatch(getBankOverview(minifiedSelectedAccountIds));
    }
  }, [bankStats, hasBankingAccounts, minifiedSelectedAccountIds]);

  const triggerPanel = (key: string) => {
    setPanelTitle(key);
    setOpenRightPanel(true);
  };

  const hide = () => {
    setOpenRightPanel(false);
  };

  const renderPanelContent = () => {
    switch (panelTitle) {
      case 'tips':
        return <InsightTipsPanel />;
      case 'recurringPayments':
        return <InsightRecurringPanel />;
      case 'mostAmountMerchant':
        return <InsightMostAmountPanel />;
      case 'mostFrequentMerchant':
        return <InsightMostOftenPanel />;
    }
    return <></>;
  };

  return <SimplePage>
    {hasBankingAccounts === false && (
      <ConnectYourBankAccount />
    )}
    {hasBankingAccounts && (
      <div className={styles.grid}>
        <RightPanel
          closeOnClickOutside={true}
          title={t(panelTitle)}
          visible={openRightPanel}
          onClose={() => hide()}
          renderContent={renderPanelContent}
        />
        <div className={styles.leftContainers}>
          <Container header={t('currentBalance')} className={styles.averagesContainer}>
            <InsightCurrentBalance/>
          </Container>
          <Container header={t('tips')} className={styles.tipsContainer}>
            <InsightTips seeMore={() => triggerPanel('tips')}/>
          </Container>
        </div>
        <Container header={t('report')} className={styles.chartContainer} headerClassName={styles.chartContainerHeader}>
          <InsightReport/>
        </Container>
        <Container header={t('recurringPayments')} className={styles.frequentContainer}>
          <InsightRecurring seeMore={() => triggerPanel('recurringPayments')}/>
        </Container>
        <Container header={t('mostAmountMerchant')} className={styles.frequentContainer}>
          <InsightMostAmount seeMore={() => triggerPanel('mostAmountMerchant')}/>
        </Container>
        <Container header={t('mostFrequentMerchant')} className={styles.frequentContainer}>
          <InsightMostOften seeMore={() => triggerPanel('mostFrequentMerchant')}/>
        </Container>
      </div>
    )}
  </SimplePage>;
};

export default Overview;
