import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../assets/styles/modules/NewsFeed.module.css';
import { getCategoriesIds, getNewsCategories, NewsCategoryItem } from '../../utils/feed';
import { useSelector, useDispatch } from 'react-redux';
import { getNewsFeed } from '../../actions/Feed';
import { NewsFeedItem } from '../../model/feed/NewsFeedItem';
import NewsItem from './NewsItem';
import { formatDate } from '../../i18n';
import { LoadMore } from '../../component/LoadMore';
import { usePrevious } from '../../hooks';
import CircularProgress, { CircularProgressColorEmun } from '../../component/CircularProgress';

const NewsFeed = () => {
  const { i18n } = useTranslation();

  const [allFilteredData, setAllFilteredData] = useState<Map<string, NewsFeedItem[]>>();
  const [lang, setLang] = useState<string>(i18n.language);

  const dispatch = useDispatch();

  const userInterests = getNewsCategories();

  const { newsFeed, isNewsFeedLoading, newsFeedPageNum, selectedNewsTopics, canLoadMoreNews, isUserNewsTopicsUpdating } =
  useSelector((state: any) => state.feed);

  const prevLang = usePrevious<string>(lang);

  useEffect(() => {
    if (!newsFeed.length) {
      dispatch(getNewsFeed(1, getCategoriesIds(userInterests, selectedNewsTopics)));
    }
  }, []);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (prevLang && lang && (prevLang !== lang)) {
      dispatch(getNewsFeed(1, getCategoriesIds(userInterests, selectedNewsTopics)));
    }
  }, [prevLang, lang]);

  useEffect(() => {
    if (newsFeed.length) {
      const allData = new Map<string, NewsFeedItem[]>();
      newsFeed.forEach((i: NewsFeedItem) => {
        const date = i.date.substring(0, 10);
        const list = allData.get(date);
        if (!list) {
          allData.set(date, [i]);
        } else {
          list.push(i);
        }
      });
      setAllFilteredData(allData);
    }
  }, [newsFeed]);

  const loadMore = () => {
    dispatch(getNewsFeed(newsFeedPageNum + 1, getCategoriesIds(userInterests, selectedNewsTopics)));
  };

  if (isUserNewsTopicsUpdating) {
    return <CircularProgress color={CircularProgressColorEmun.primary}/>;
  }

  return (
    <section className={styles.container}>
        {(!isNewsFeedLoading || allFilteredData)
          ? allFilteredData && Array.from(allFilteredData.entries()).map(([key, value]) => (
                <div key={key}>
                  <p className={styles.date}>{formatDate(key, 'd MMM yyyy')}</p>
                  {value.map(i => {
                    const categories: NewsCategoryItem[] = [];
                    i.categories.map((cat: number) => {
                      userInterests.map((obj: NewsCategoryItem) => {
                        if (cat === obj.englishId || cat === obj.frenchId) {
                          categories.push(obj);
                        }
                        return obj;
                      });
                      return cat;
                    });
                    return <NewsItem item={i} categories={categories} key={i.id}/>;
                  })}
                </div>
          ))
          : null}
        <LoadMore
            isLoading={isNewsFeedLoading}
            loadMore={() => loadMore()}
            moreToLoad={canLoadMoreNews}
        />
    </section>
  );
};

export default NewsFeed;
