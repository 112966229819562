import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getBankingSubscriptions } from '../../../actions/Banking';
import HardbaconAmount from '../../../component/HardbaconAmount';
import { BankingSubscription } from '../../../model/BankingSubscription';
import styles from '../../../assets/styles/modules/insightRecurringPanel.module.css';
import { getIconForMerchant } from '../../../utils/banking';
import { CircularProgress, CircularProgressColorEmun } from '../../../component/CircularProgress';

const InsightRecurringPanel = () => {
  const { t } = useTranslation();
  const { subscriptions, loadingSubscriptions }: { subscriptions: BankingSubscription[], loadingSubscriptions: boolean } = useSelector((state: any) => state.banking);
  const { minifiedSelectedAccountIds } = useSelector((state: any) => state.userInstitution);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!subscriptions) {
      dispatch(getBankingSubscriptions(minifiedSelectedAccountIds));
    }
  }, [minifiedSelectedAccountIds, subscriptions]);

  const renderRecurring = (recurring: BankingSubscription) => (
    <div key={`${recurring.merchantId}_${recurring.description}`} className={`${styles.item} ${styles.recurringItem}`}>
      <div className={styles.recurringMerchant}>
        <img src={getIconForMerchant(recurring.merchantId)} width={40}/>
      </div>
      <div className={styles.middle}>
        <div className={styles.title}>{recurring.description}</div>
        <div className={styles.desc}>{t('monthly')}</div>
      </div>
      <div className={styles.end}>
        <HardbaconAmount amount={recurring.lastAmount} color={'#788EA4'} size={14} decimals={2} />
      </div>
    </div>);

  const Total = () => {
    const total = subscriptions.map(s => s.lastAmount).reduce((a, b) => a + b, 0);
    return (
      <div className={styles.item} style={{ borderRadius: '24px' }}>
        <div className={styles.totalTitle}>{t('overview:total')}</div>
        <div className={styles.totalAmount}>
          <HardbaconAmount amount={total} decimals={0} size={40} bold={true}/>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        {loadingSubscriptions && (
          <div className={styles.progress}>
            <CircularProgress color={CircularProgressColorEmun.primary}/>
          </div>
        )}
        {!loadingSubscriptions && subscriptions && <Total />}
        {!loadingSubscriptions && subscriptions && subscriptions.map(rec => renderRecurring(rec))}
      </div>
    </div>
  );
};

export default InsightRecurringPanel;
