import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getBankingSubscriptions } from '../../../actions/Banking';
import HardbaconAmount from '../../../component/HardbaconAmount';
import { BankingSubscription } from '../../../model/BankingSubscription';
import styles from '../../../assets/styles/modules/insightRecurring.module.css';
import { getIconForMerchant } from '../../../utils/banking';
import { CircularProgress, CircularProgressColorEmun } from '../../../component/CircularProgress';

interface InsightRecurringProps {
  seeMore: () => void;
}

const InsightRecurring = (props: InsightRecurringProps) => {
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const { subscriptions, loadingSubscriptions }: { subscriptions: BankingSubscription[], loadingSubscriptions: boolean } = useSelector((state: any) => state.banking);
  const { minifiedSelectedAccountIds } = useSelector((state: any) => state.userInstitution);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!subscriptions) {
      dispatch(getBankingSubscriptions(minifiedSelectedAccountIds));
    }
  }, [minifiedSelectedAccountIds, subscriptions]);

  useEffect(() => {
    if (subscriptions) {
      setTotal(subscriptions.map(s => s.lastAmount).reduce((a, b) => a + b, 0));
    }
  }, [subscriptions]);

  const renderRecurring = (recurring: BankingSubscription) => (
    <div key={`${recurring.merchantId}_${recurring.description}`} className={styles.recurringItem}>
      <div className={styles.recurringMerchant}>
        <img src={getIconForMerchant(recurring.merchantId)} width={40}/>
      </div>
      <div className={styles.middle}>
        <div className={styles.title}>{recurring.description}</div>
        <div className={styles.desc}>{t('monthly')}</div>
      </div>
      <div className={styles.end}>
        <HardbaconAmount amount={recurring.lastAmount} color={'#788EA4'} size={14} decimals={2} />
      </div>
    </div>);

  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        <div className={styles.total}>
          <HardbaconAmount amount={total} color={'#788EA4'} size={24} decimals={2} />
        </div>
        {loadingSubscriptions && (
          <div className={styles.progress}>
            <CircularProgress color={CircularProgressColorEmun.primary}/>
          </div>
        )}
        {!loadingSubscriptions && subscriptions && subscriptions.slice(0, 3).map(rec => renderRecurring(rec))}
      </div>
      {!loadingSubscriptions && <div className={styles.seeMore} onClick={() => props.seeMore()}>{t('seeMore')}</div>}
    </div>
  );
};

export default InsightRecurring;
