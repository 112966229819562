import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../assets/styles/modules/FeedHeader.module.css';
import { Link, useLocation } from 'react-router-dom';
import BudgetNotificationsRightPanel from '../BudgetNotificationsRightPanel';
import HeaderButtons from './HeaderButtons';
import { articlesRoute, offersRoute } from '../../navigation/Feed';
import { isTablet } from '../../utils/hooks';

/* eslint-disable no-unused-vars */
enum FeedTab {
  articles = 'articles',
  offers = 'offers',
}

const FeedHeader = () => {
  const [selectedTab, setSelectedTab] = useState(FeedTab.articles);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const isTabletScreen = isTablet();

  useEffect(() => {
    if (location.pathname === articlesRoute) {
      setSelectedTab(FeedTab.articles);
    } else if (location.pathname === offersRoute) {
      setSelectedTab(FeedTab.offers);
    }
  }, [location]);

  return (
    <div>
      <BudgetNotificationsRightPanel open={notificationOpen} onClose={() => setNotificationOpen(false)}/>
      <div className={styles['mobile-header']}>
        <h1 className={'large-text text-white my-3'}>{t('feed:feed')}</h1>
        <HeaderButtons />
      </div>

      <div className={styles.menu}>
        {isTabletScreen && <nav className="flex space-x-4">
          <Link
            to={articlesRoute}
            className={selectedTab === FeedTab.articles ? 'form-tab-selected' : 'form-tab'}
            aria-current={selectedTab === FeedTab.offers ? 'page' : 'false'}>
            {t('feed:articles')}
          </Link>
          <Link
            to={offersRoute}
            className={selectedTab === FeedTab.offers ? 'form-tab-selected' : 'form-tab'}
            aria-current={selectedTab === FeedTab.articles ? 'page' : 'false'}>
            {t('feed:exclusiveOffers')}
          </Link>
        </nav>}
      </div>
    </div>
  );
};

export default FeedHeader;
