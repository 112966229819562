import { useSelector } from 'react-redux';
import { useCookie } from './useCookie';
import { v4 as uuidv4 } from 'uuid';

export function useUserId () {
  const state = useSelector((state: any) => state.user);
  const [anonymousId] = useCookie('anomymousId', uuidv4());

  if (state.user && state.user.id) {
    return [state.user.id, undefined];
  }

  return [undefined, anonymousId];
}
