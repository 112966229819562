import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SplitedCategoryType } from '../model/BankingTransaction';
import { getTransactionCtgNameById, getTransactionCtgEmojiById } from '../utils/banking';
import FormItemContainer, { FormInputEmun } from './FormItemContainer';
import styles from './../assets/styles/modules/transactionItemDetail.module.css';
import { addButtonIcon, editCategoryIcon, removeCategoryIcon } from '../assets/images';
import _ from 'lodash';
import HardbaconButton from './HardbaconButton';
import { formatCurrency } from '../i18n';

interface SplitedCatgoriesDetailProps {
  splitedCategories?: SplitedCategoryType[];
  setSplitedCategories: Function;
  originalAmount: number;
  onClose: Function;
  setItemCategoryToChange: Function;
  setAddCategory: Function;
}

/* eslint-disable no-unused-vars */
export enum BudgetStateEnum {
  perfect,
  exceed,
  subceed
}
/* eslint-disable no-unused-vars */

const SplitedCatgoriesDetail = ({ setAddCategory, splitedCategories, originalAmount, setSplitedCategories, onClose, setItemCategoryToChange }: SplitedCatgoriesDetailProps) => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState(splitedCategories);

  const removeItem = (id: number) => {
    let newSelected = _.cloneDeep(categories);
    if (newSelected) {
      newSelected = newSelected.filter(i => {
        return i.id !== id;
      });
      setCategories(newSelected);
      setSplitedCategories(newSelected);
    }
  };

  const renderCategoryButton = (cat: SplitedCategoryType) => {
    return (
      <div className={styles['category-container']}>
        {showRemoveIcon && <img alt={'open'} onClick={() => removeItem(cat.id)} src={removeCategoryIcon} className={styles['remove-icon']}/>}
        <div className={styles['category-button']} onClick={() => setItemCategoryToChange(cat)}>
          <span className={styles.category}>{getTransactionCtgEmojiById(cat.id) + ' ' + getTransactionCtgNameById(cat.id)}</span>
          <img alt={'open'} src={editCategoryIcon} className={styles['open-icon']}/>
        </div>
      </div>
    );
  };

  const showRemoveIcon = categories && categories.length > 2;

  const onAmountChange = (v: string | number, id: number) => {
    const newSelected = _.cloneDeep(categories);
    if (newSelected) {
      newSelected.map((i: SplitedCategoryType) => {
        if (i.id === id) {
          const value = v + '';
          i.amount = value;
        }
        return i;
      });
      setCategories(newSelected);
    }
  };

  let totalAmount = 0;
  categories && categories.map((cat: SplitedCategoryType) => {
    if (cat.amount) {
      totalAmount = Math.round(totalAmount * 100) / 100 + Math.round(parseFloat(`${cat.amount}`) * 100) / 100;
    }
    return cat;
  });

  const diff = Math.round((originalAmount - totalAmount) * 100) / 100 || 0;

  const budgetState = diff > 0 ? BudgetStateEnum.subceed : diff === 0 ? BudgetStateEnum.perfect : BudgetStateEnum.exceed;

  const shouldDisableButton = () => {
    let shouldDisable = true;
    let selectedItemCount = 0;
    categories && categories.map((item: SplitedCategoryType) => {
      if (selectedItemCount <= 2) {
        if (item.amount !== 0) {
          selectedItemCount = selectedItemCount + 1;
        }
      }
      return item;
    });
    if (selectedItemCount >= 2 && budgetState === BudgetStateEnum.perfect) {
      shouldDisable = false;
    }
    return shouldDisable;
  };

  const onSubmit = () => {
    const result = categories?.filter((i: SplitedCategoryType) => i.amount !== 0);
    setSplitedCategories(result);
    onClose();
  };

  return (
    <div className={styles['summary-container']}>
      {categories && categories.map((cat: SplitedCategoryType) => {
        return (
          <div key={cat.id} style={{ width: '100%' }}>
            <FormItemContainer
              id={`${cat.id}`}
              leftWidth={'65%'}
              rightWidth={'20%'}
              maxAmount={999999}
              withInputUnderline={true}
              left={renderCategoryButton(cat)}
              right={FormInputEmun.moneyInput}
              withDivider={false}
              value={cat.amount || ''}
              onChange={(v: string | number) => onAmountChange(v, cat.id)}
            />
        </div>
        );
      })}
      <button className={styles['add-button-container']} onClick={() => setAddCategory(true)}>
        <img className={styles['add-button']} src={addButtonIcon} alt={t('addCategory')}/>
        <span>{t('budgetOnboarding:addCategory')}</span>
      </button>
      <div className={styles.summary + ` ${budgetState === BudgetStateEnum.perfect ? styles.perfect : ''}` + ` ${budgetState === BudgetStateEnum.exceed ? styles.exceed : ''}`}>
        <div><span className={styles['budget-total']}>{formatCurrency(totalAmount)}</span>{' / ' + formatCurrency(originalAmount)}</div>
        <div>
          {formatCurrency(diff) +
          ' ' +
          (budgetState === BudgetStateEnum.perfect ? t('available') : '') +
          (budgetState === BudgetStateEnum.subceed ? t('available') : '') +
          (budgetState === BudgetStateEnum.exceed ? t('budgetExceed') : '')}
        </div>
      </div>
      <HardbaconButton
          disabled={shouldDisableButton()}
          name={'update'}
          onPress={() => onSubmit()}
          type="button"
          title={t('update')} />
    </div>
  );
};

export default SplitedCatgoriesDetail;
