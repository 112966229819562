import { Dispatch } from 'redux';
import {
  GET_BROKER_RECOMMENDATION,
  GET_BROKER_RECOMMENDATION_FAILED,
  GET_BROKER_RECOMMENDATION_SUCCESS,
  GET_CHECKING_RECOMMENDATION,
  GET_CHECKING_RECOMMENDATION_FAILED,
  GET_CHECKING_RECOMMENDATION_SUCCESS,
  GET_CREDIT_CARD_RECOMMENDATION,
  GET_ROBO_ADVISOR_RECOMMENDATION,
  GET_ROBO_ADVISOR_RECOMMENDATION_FAILED,
  GET_ROBO_ADVISOR_RECOMMENDATION_SUCCESS,
  GET_SAVING_RECOMMENDATION,
  GET_SAVING_RECOMMENDATION_FAILED,
  GET_SAVING_RECOMMENDATION_SUCCESS,
  GET_CREDIT_CARD_RECOMMENDATION_FAILED,
  GET_CREDIT_CARD_RECOMMENDATION_SUCCESS
} from '../types/Comparator';
import {
  getBrokerRecommendation,
  getCheckingRecommendation,
  getRoboAdvisorRecommendation,
  getSavingRecommendation,
  getCreditCardRecommendation
} from '../api/ComparatorService';
import ComparatorType from '../model/ComparatorType';

export const getRecommendations = (type: ComparatorType) => (dispatch: Dispatch) => {
  switch (type) {
    case ComparatorType.creditCard:
      getRecommendationCreditCard()(dispatch);
      break;
    case ComparatorType.checking:
      getRecommendationChecking()(dispatch);
      break;
    case ComparatorType.saving:
      getRecommendationSaving()(dispatch);
      break;
    case ComparatorType.roboAdvisor:
      getRecommendationRoboAdvisor()(dispatch);
      break;
    case ComparatorType.broker:
      getRecommendationBroker()(dispatch);
      break;
  }
};

export const getRecommendationCreditCard = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_CREDIT_CARD_RECOMMENDATION });
  getCreditCardRecommendation().subscribe(
    (creditCards) => {
      setTimeout(() => dispatch({ type: GET_CREDIT_CARD_RECOMMENDATION_SUCCESS, payload: creditCards }), 5000);
    },
    () => {
      setTimeout(() => dispatch({ type: GET_CREDIT_CARD_RECOMMENDATION_FAILED }), 5000);
    }
  );
};

export const getRecommendationBroker = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_BROKER_RECOMMENDATION });
  getBrokerRecommendation().subscribe(
    (brokers) => {
      setTimeout(() => dispatch({ type: GET_BROKER_RECOMMENDATION_SUCCESS, payload: brokers }), 5000);
    },
    () => {
      setTimeout(() => dispatch({ type: GET_BROKER_RECOMMENDATION_FAILED }), 5000);
    }
  );
};

export const getRecommendationSaving = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_SAVING_RECOMMENDATION });
  getSavingRecommendation().subscribe(
    (savings) => {
      setTimeout(() => dispatch({ type: GET_SAVING_RECOMMENDATION_SUCCESS, payload: savings }), 5000);
    },
    () => {
      setTimeout(() => dispatch({ type: GET_SAVING_RECOMMENDATION_FAILED }), 5000);
    }
  );
};

export const getRecommendationChecking = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_CHECKING_RECOMMENDATION });
  getCheckingRecommendation().subscribe(
    (checkings) => {
      setTimeout(() => dispatch({ type: GET_CHECKING_RECOMMENDATION_SUCCESS, payload: checkings }), 5000);
    },
    () => {
      setTimeout(() => dispatch({ type: GET_CHECKING_RECOMMENDATION_FAILED }), 5000);
    }
  );
};

export const getRecommendationRoboAdvisor = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_ROBO_ADVISOR_RECOMMENDATION });

  getRoboAdvisorRecommendation().subscribe(
    (roboAdvisors) => {
      setTimeout(() => dispatch({ type: GET_ROBO_ADVISOR_RECOMMENDATION_SUCCESS, payload: roboAdvisors }), 5000);
    },
    () => {
      setTimeout(() => dispatch({ type: GET_ROBO_ADVISOR_RECOMMENDATION_FAILED }), 5000);
    }
  );
};
