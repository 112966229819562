import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../../../assets/styles/modules/TransactionDatePicker.module.css';
import { calenderIcon } from '../../../assets/images';
import { HardbaconButton, RightPanel } from '../../../component';
import { useSelector, useDispatch } from 'react-redux';
import Calendar from 'react-calendar';
import { formatDateDMY, formatDateMY } from '../../../i18n';
import RadioButton from '../../../component/RadioButton';
import { updateBudgetDatePicker } from '../../../actions/Banking';
import { DateSelectionEmun } from '../../../model/DateSelectionEmun';

interface BudgetDatePickerProps {
  isVisible: boolean;
  onClose: Function;
}

const BudgetDatePicker = ({ isVisible, onClose }: BudgetDatePickerProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [value, setValue] = useState<Date>(new Date());
  const [dateSelection, setDateSelection] = useState<DateSelectionEmun>(DateSelectionEmun.none);

  const { budgetMonth } = useSelector((state: any) => state.banking);

  const date = new Date();
  const currentMonthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const currentMonthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const prevMonthFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);

  const selectedMonthFirstDay = value && new Date(value.getFullYear(), value.getMonth(), 1);
  const selectedMonthLastDay = value && new Date(value.getFullYear(), value.getMonth() + 1, 0);

  useEffect(() => {
    if (isVisible) {
      if (budgetMonth) {
        if (formatDateMY(budgetMonth) === formatDateMY(currentMonthFirstDay)) {
          setDateSelection(DateSelectionEmun.current);
        } else if (formatDateMY(budgetMonth) === formatDateMY(prevMonthFirstDay)) {
          setDateSelection(DateSelectionEmun.prev);
        } else {
          setValue(budgetMonth);
          setDateSelection(DateSelectionEmun.custom);
        }
      } else {
        setValue(date);
        setDateSelection(DateSelectionEmun.custom);
      }
    }
  }, [budgetMonth, isVisible]);

  useEffect(() => {
    if (value && formatDateMY(value) === formatDateMY(currentMonthFirstDay)) {
      setDateSelection(DateSelectionEmun.current);
    } else if (value && formatDateMY(value) === formatDateMY(prevMonthFirstDay)) {
      setDateSelection(DateSelectionEmun.prev);
    } else if (value) {
      setDateSelection(DateSelectionEmun.custom);
    }
  }, [value]);

  useEffect(() => {
    if (dateSelection === DateSelectionEmun.none && value) {
      setValue(null as unknown as Date);
    } else if (dateSelection === DateSelectionEmun.current) {
      setValue(currentMonthFirstDay);
    } else if (dateSelection === DateSelectionEmun.prev) {
      setValue(prevMonthFirstDay);
    }
  }, [dateSelection]);

  const lang = i18n.language;

  const onChange = useCallback(
    (val) => {
      setValue(val);
    },
    [setValue]
  );

  const onSubmit = () => {
    if (value) {
      dispatch(updateBudgetDatePicker(value));
    } else {
      dispatch(updateBudgetDatePicker(date));
    }
    onClose();
  };

  const onRadioClick = (type: DateSelectionEmun) => {
    if (dateSelection === type) {
      setDateSelection(DateSelectionEmun.none);
    } else {
      setDateSelection(type);
    }
  };

  const renderContent = () => {
    return (
          <div className={styles.container + ' ' + styles.small}>
            <div className={styles.header} onClick={() => onRadioClick(DateSelectionEmun.current)}>
                <RadioButton check={dateSelection === DateSelectionEmun.current}/>
                <p className={styles.title}>{t('currentMonth')}</p>
            </div>
            <div className={styles.header} onClick={() => onRadioClick(DateSelectionEmun.prev)}>
                <RadioButton check={dateSelection === DateSelectionEmun.prev} />
                <p className={styles.title}>{t('prevMonth')}</p>
            </div>
            <div className={styles.header + ' ' + styles.last}>
                <RadioButton check={dateSelection === DateSelectionEmun.custom} onClick={() => onRadioClick(DateSelectionEmun.custom)}/>
                <p className={styles.title}>{t('custom')}</p>
                {value && <div className={styles['date-button']}>
                    <img src={calenderIcon}
                        className={styles['button-icon'] + ' ' + styles['small-icon']}
                        alt={t('addCategory')}/>
                    <p className={styles['date-text']}>
                        <span>
                            {' ' + t('from') + ' '}
                            <span className={styles.text}>
                                {formatDateDMY(selectedMonthFirstDay)}
                            </span>
                        </span>
                        <span>
                            {' ' + t('to') + ' '}
                            <span className={styles.text}>
                                {formatDateDMY(selectedMonthLastDay)}
                            </span>
                        </span>
                    </p>
                </div>}
            </div>
            <Calendar
                maxDate={currentMonthLastDay}
                locale={lang}
                onChange={onChange}
                value={value}
                selectRange={false}
                maxDetail={'year'}
                minDetail={'decade'}
             />
            <div className={styles['button-container']}>
                <HardbaconButton
                    className={styles.button}
                    name={'accept'}
                    onPress={onSubmit}
                    type="button"
                    title={t('update')}/>
            </div>
            </div>
    );
  };

  return (
        <RightPanel
            title={t('timePeriod')}
            visible={isVisible}
            onClose={onClose}
            closeOnClickOutside={true}
            renderContent={renderContent}
        />

  );
};

export default BudgetDatePicker;
