import React from 'react';
import { bell, institution } from '../../assets/images';
import styles from '../../assets/styles/modules/BudgetHeader.module.css';
import { setAccountSelectorPanelOpen, setNotificationsPanelOpen } from '../../actions/UserInstitution';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { route as accountsRoute } from '../../navigation/Accounts';

const HeaderButtons = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasBankingAccount = useSelector((state: any) => state?.userInstitution?.hasBankingAccounts);
  const { hasUnreadBudgetNotifications } = useSelector((state: any) => state.notification);

  const openAddAccount = () => {
    if (hasBankingAccount) {
      dispatch(setAccountSelectorPanelOpen(true));
    } else {
      navigate(accountsRoute);
    }
  };

  const openNotifications = () => {
    dispatch(setNotificationsPanelOpen(true));
  };

  return (
    <div>
      <div className={styles.icons}>
        <div className={styles.roundIcon} onClick={() => openAddAccount()}>
          <div className={styles.roundIconImgDiv} style={{ marginBottom: '2px' }}>
            <img src={institution} width="22" alt="add an account" />
          </div>
        </div>
        <div className={styles.roundIcon} onClick={() => openNotifications()}>
          <div className={styles.roundIconImgDiv}>
            <img src={bell} width="22" alt="see notifications" />
            {hasUnreadBudgetNotifications && <div className={styles.newNotificationRedDot}/> }
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderButtons;
