import { AxiosRequestConfig, AxiosResponse } from 'axios';
// import { murmurHash } from '../utils/murmurhash';

// interface CachedValue {
//   timestamp: number;
//   data: string;
// }

// const ttl = Number(process.env.REACT_APP_GET_CACHE_TTL);
// const regex = new RegExp(process.env.REACT_APP_GET_CACHE_REGEX!);
const CACHE_PREFIX = '_zPHB';
const getLocalStorageEntry = (hash: string) => `${CACHE_PREFIX}${hash}`;

/**
 * This implementation uses session storage but can easily be modified
 * to use local storage or some other API.
 * @param key the key for the cached data to retrieve
 */
// const getCachedData = (key: string) => {
//   return localStorage.getItem(getLocalStorageEntry(key));
// };

/**
 * This implementation uses session storage but can easily be modified
 * to use local storage or some other API.
 * @param key the key for the cached data to store
 * @param data the data object to store in the cache
 */
const setCachedData = (key: string, data: any) => {
  localStorage.setItem(getLocalStorageEntry(key), JSON.stringify(data));
};

export const clearCachedData = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)!;
    if (key.startsWith(CACHE_PREFIX)) {
      localStorage.removeItem(key);
    }
  }
};

/**
 * Interceptor that decides whether a request must be cached
 * and return the cached value if it exists
 * @param request the request to potentially cache
 */
export function onHttpCallRequestCacheInterceptor (request: AxiosRequestConfig) {
  return request;
  // if (!process.env.REACT_APP_GET_CACHE_ENABLED) {

  // }

  // if (request.method?.toUpperCase() !== 'GET' || !regex.test(request.url!)) {
  //   return request;
  // }

  // const hash = murmurHash(request.url!, 17).toString();
  // const cachedValue = getCachedData(hash);
  // if (cachedValue) {
  //   const cachedData = JSON.parse(cachedValue) as CachedValue;
  //   if (cachedData.timestamp + ttl >= new Date().getTime()) {
  //     request.adapter = () => {
  //       return Promise.resolve({
  //         data: cachedData.data,
  //         status: 200,
  //         statusText: 'OK',
  //         headers: request.headers,
  //         config: request,
  //         request: request
  //       } as AxiosResponse);
  //     };
  //   } else {
  //     localStorage.removeItem(hash);
  //   }
  // }

  // // @ts-ignore
  // request.toBeCached = true;
  // // @ts-ignore
  // request.cacheHash = hash;
  // return request;
}

/**
 * Interceptor that decides whether a response must be cached
 * and cache it if necessary
 * @param response the response to potentially cache
 */
export function onHttpCallResponseCacheInterceptor (response: AxiosResponse) {
  // @ts-ignore
  if (process.env.REACT_APP_GET_CACHE_ENABLED && response.config.toBeCached && response.status === 200 && response.data) {
    // @ts-ignore
    const hash = response.config.cacheHash;
    const cachedData = { timestamp: new Date().getTime(), data: JSON.stringify(response.data) };
    setCachedData(hash, cachedData);
  }
  return response;
}
