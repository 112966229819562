import React from 'react';
import { Route } from 'react-router-dom';
import Information from '../container/Profile/Information';
import GrantDataAccess from '../container/GrantDataAccess';

export const route = '/profile';
export const informationRoute = route + '/information';
export const premiumRoute = route + '/subscription';
export const grantRoute = route + '/grant';

export default function profileRoute () {
  return (
    <>
      <Route path={route} element={<Information/>} />
      <Route path={informationRoute} element={<Information/>} />
      <Route path={grantRoute} element={<GrantDataAccess/>} />
    </>
  );
}
