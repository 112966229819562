import React, { ReactChild, ReactElement, useEffect, useState } from 'react';

type HardbaconCarouselItemProps = {
  width?: number,
  children: ReactChild | ReactChild[],
}

export const HardbaconCarouselItem = (props: HardbaconCarouselItemProps) => {
  return (
    <div style={{ width: props.width ?? '100%', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
      {props.children}
    </div>
  );
};

const HardbaconCarousel = ({ children, autoplay, interval }: { children: ReactElement | ReactElement[], autoplay: boolean, interval: number }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!autoplay || !interval) {
      return;
    }

    const intervalTimer = setInterval(() => {
      setActiveIndex(oldCount => {
        let newIndex = oldCount + 1;
        if (newIndex >= React.Children.count(children)) {
          newIndex = 0;
        }
        return newIndex;
      });
    }, interval);
    return () => clearInterval(intervalTimer);
  }, [autoplay, interval]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={{ transform: `translateX(-${activeIndex * 100}%)`, whiteSpace: 'nowrap', transition: 'transform 0.5s' }}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child);
        })}
      </div>
    </div>
  );
};

export default HardbaconCarousel;
