import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import NoRoute from '../container/NoRoute';
import i18n, { changeLanguage } from '../i18n';
import SignUpRoute, { route as signUpRoute } from './SignUp';
import SignInRoute, { route as signInRoute } from './SignIn';
import forgotPasswordRoute, { route as forgotPasswordPathname } from './ResetPassword';
import logoutRoute from './Logout';
import DownloadAppRoute, { route as downloadAppRoute } from './DownloadApp';
import profileRoute from './Profile';
import bankingRoute from './Banking';
import planRoute from './Plan';
import accountsRoute from './Accounts';
import comparatorRoute from './Comparator';
import homeRoute from './Home';
import AccountSelectorRightPanel from '../component/AccountSelectorRightPanel';
import { AddAccountRightPanel, HardbaconFooter } from '../component';
import AppNotification from '../component/AppNotification';
import { setAccountSelectorPanelOpen, setAddInstitutionPanelOpen, setNotificationsPanelOpen } from '../actions/UserInstitution';
import UnsecuredLayout from '../component/Layouts/UnsecuredLayout';
import SecuredLayout from '../component/Layouts/SecuredLayout';
import { fetchNotifications } from '../actions/Notification';
import HardbaconPopUp from '../component/HardbaconPopUp';
import { closePopUp, openPopUp } from '../actions/PopUp';
import feedRoute from './Feed';
import BudgetNotificationsRightPanel from '../component/BudgetNotificationsRightPanel';

const locationsWithoutHeader = [
  signInRoute,
  signUpRoute,
  forgotPasswordPathname,
  downloadAppRoute
];

const HardbaconNavigation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { addInstitutionPanelOpen, addInstitutionPanelParameters, accountSelectorPanelOpen, notificationsPanelOpen } = useSelector((state: any) => state.userInstitution);
  const { authenticated } = useSelector((store: any) => store.session);
  const { notificationsPageNum } = useSelector((state: any) => state.notification);

  const shouldShowFooter = (pathname: string) => {
    return !locationsWithoutHeader.includes(pathname);
  };

  const onMainButtonPress = () => {
    dispatch(closePopUp());
    dispatch(setAddInstitutionPanelOpen(true));
  };
  const onSubButtonPress = () => {
    dispatch(closePopUp());
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const langQuery = params.get('lang');
    if (langQuery && langQuery.length > 0 && i18n.language !== langQuery) {
      changeLanguage(langQuery);
    }
    const welcomeOpenParam = params.get('welcome');
    if (welcomeOpenParam && authenticated) {
      dispatch(openPopUp({
        closeOnClickOutside: false,
        onMainButtonPress,
        onSubButtonPress,
        mainButtonTitle: t('manageAccounts:addAccount'),
        subButtonTitle: t('welcome:connectLater'),
        isOpen: true,
        title: t('welcome:title'),
        desc: t('welcome:description'),
        danger: false,
        showCloseButton: true
      }));
    }
  }, [location, authenticated]);

  useEffect(() => {
    if (notificationsPageNum < 0 && authenticated) {
      dispatch(fetchNotifications(0));
    }
  }, [notificationsPageNum, authenticated]);

  const pageKey = 'route_' +
    location.pathname.substring(location.pathname.indexOf('/web/') + 5)
      .replace(/[\W]+/g, '_');

  return (
    <div className={'flex flex-col flex-1 min-h-screen'}>
      {/* Automatically update page title based on path */}
      {/* Make sure there is a 'route_{pageKey} entry in translation */}
      {/* pageKey is the route path after /app, with any special char replace with '_' */}
      <Helmet>
        {i18n.exists(pageKey) && (
          <title>{t(pageKey)}</title>
        )}
        {!i18n.exists(pageKey) && (
          <title>Hardbacon - Plan, budget and invest</title>
        )}
        {i18n.exists(`${pageKey}_description`) && (
          <meta name="description" content={t(`${pageKey}_description`)}/>
        )}
      </Helmet>
      {/* Setup Notification center */}
      <AppNotification />

      {/* Setup Welcome popup */}
      <HardbaconPopUp />

      <Routes>
        <Route path="/" element={<UnsecuredLayout/>}>
          {SignInRoute()}
          {SignUpRoute()}
          {forgotPasswordRoute()}
          {logoutRoute()}
          {DownloadAppRoute()}

          <Route path="*" element={<NoRoute/>}/>
        </Route>

        <Route path="/" element={<SecuredLayout displayHeader={true}/>}>
          {homeRoute()}
          {accountsRoute()}
          {profileRoute()}
          {bankingRoute()}
          {planRoute()}
          {feedRoute()}
          {comparatorRoute()}
        </Route>

        <Route path="*" element={<NoRoute/>}/>
      </Routes>

      {/* Show footer only for authenticated users and paths without header */}
      {shouldShowFooter(location.pathname) && <HardbaconFooter dark={true}/>}

      {/* Declare Add Account Selector globally to be accessible from anywhere in the app */}
      <AccountSelectorRightPanel visible={accountSelectorPanelOpen}
                                 onClose={() => dispatch(setAccountSelectorPanelOpen(false))}/>

      <BudgetNotificationsRightPanel open={notificationsPanelOpen} onClose={() => dispatch(setNotificationsPanelOpen(false))}/>

      {/* Declare Add Account component globally to be accessible from anywhere in the app */}
      <AddAccountRightPanel visible={addInstitutionPanelOpen} parameters={addInstitutionPanelParameters}
                            onClose={() => dispatch(setAddInstitutionPanelOpen(false))}/>
    </div>
  );
};

export default HardbaconNavigation;
