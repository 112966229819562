import AxiosInstance from './index';
import Plan from '../model/Plan';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { fromAxiosError } from '../model/APIError';
import Coupon from '../model/Coupon';
import { Token } from '@stripe/stripe-js';

export function getPlans (): Observable<Plan[]> {
  return AxiosInstance.get<Plan[]>('api/purchase/plans')
    .pipe(
      map(response => response.data),
      tap((plans: Plan[]) => plans.forEach(p => p.price = p.amountDecimal / 100)),
      catchError(response => throwError(fromAxiosError(response)))
    );
}

export function getCoupon (code: string): Observable<Coupon> {
  return AxiosInstance.get<Coupon>('api/coupons/check', { params: { code } })
    .pipe(
      map(response => response.data),
      catchError(response => throwError(fromAxiosError(response)))
    );
}

export function proceedWithCardToken (token: Token, plan: Plan, coupon: Coupon): Observable<boolean> {
  const payment = {
    productId: plan.id,
    coupon: coupon ? coupon.code : null,
    token
  };

  return AxiosInstance.post<boolean>('api/purchase/pay', payment)
    .pipe(
      map(response => response.data),
      catchError(response => throwError(fromAxiosError(response)))
    );
}
