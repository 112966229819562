import AxiosInstance from './index';
import { fromAxiosError } from '../model/APIError';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import CreditCardProduct from '../model/comparators/CreditCardProduct';
import BrokerProduct from '../model/comparators/BrokerProduct';
import SavingProduct from '../model/comparators/SavingProduct';
import CheckingProduct from '../model/comparators/CheckingProduct';
import RoboAdvisorProduct from '../model/comparators/RoboAdvisorProduct';

export function getCreditCardRecommendation (): Observable<CreditCardProduct[]> {
  return AxiosInstance.get<CreditCardProduct[]>('api/comparators/recommendations/credit-cards').pipe(
    map(resp => resp.data.slice(0, 20)),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getBrokerRecommendation (): Observable<BrokerProduct[]> {
  return AxiosInstance.get<BrokerProduct[]>('api/comparators/recommendations/brokerage-accounts').pipe(
    map(resp => resp.data.slice(0, 20)),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getSavingRecommendation (): Observable<SavingProduct[]> {
  return AxiosInstance.get<SavingProduct[]>('api/comparators/recommendations/savings-accounts').pipe(
    map(resp => resp.data.slice(0, 20)),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getCheckingRecommendation (): Observable<CheckingProduct[]> {
  return AxiosInstance.get<CheckingProduct[]>('api/comparators/recommendations/checking-accounts').pipe(
    map(resp => resp.data.slice(0, 20)),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getRoboAdvisorRecommendation (): Observable<RoboAdvisorProduct[]> {
  return AxiosInstance.get<RoboAdvisorProduct[]>('api/comparators/recommendations/robo-advisor-accounts').pipe(
    map(resp => resp.data.slice(0, 20)),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getOffer (productType: string, accountId: string, lang: string) {
  const sourceUrl = encodeURIComponent(btoa(window.location.href));
  return new Promise((resolve, reject) => {
    AxiosInstance
      .get(`/comparators/${productType}/${accountId}/offer/${lang}`, {
        params: { redirect: false, sourceUrl }
      }).pipe(
        map(resp => resp.data),
        catchError(error => throwError(fromAxiosError(error))));
  });
}
