import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getInstitutions, setPreloaded, setSelectedAccounts } from '../actions/UserInstitution';
import { formatCurrency } from '../i18n';
import RightPanel from './RightPanel';
import HardbaconToggle from './HardbaconToggle';
import HardbaconButton from './HardbaconButton';
import { route as accountsRoute } from '../navigation/Accounts';
import AccountType from '../model/AccountType';
import UserInstitution from '../model/UserInstitution';
import InstitutionAccountDetail from '../model/InstitutionAccountDetail';
import { getAccountBackendId, shouldIncludeInstitutionDetail } from '../utils/Accounts';
import { getIconForInstitution } from '../utils';
import styles from '../assets/styles/modules/AccountSelectorRightPanel.module.css';
import { loadingPiggyBank } from '../assets/images';
import dayjs from 'dayjs';

type AccountSelectorRightPanelProps = {
  visible?: boolean;
  onClose?: () => void;
}

const AccountSelectorRightPanel = (props: AccountSelectorRightPanelProps) => {
  const [accountsToDisplay, setAccountsToDisplay] = useState<UserInstitution[]>([]);
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);
  const [openRightPanel, setOpenRightPanel] = useState(false);
  const { t, i18n } = useTranslation('manageAccounts');

  const { institutions, loadingInstitutions, selectedAccounts, filter, preloaded } = useSelector((state: any) => state.userInstitution);
  const { user } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getInstitutions());
    }
  }, [user]);

  useEffect(() => {
    if (props.visible !== undefined) {
      setOpenRightPanel(props.visible);
    }
  }, [props.visible]);

  useEffect(() => {
    const filteredInstitutions = institutions
      .map((item: UserInstitution) => {
        return {
          ...item,
          institutionAccountDetailDTOs: item.institutionAccountDetailDTOs.filter((item: InstitutionAccountDetail) => shouldIncludeInstitutionDetail(filter, item))
        };
      })
      .filter((item: UserInstitution) => item.syncStatus === 'error' ? true : item.institutionAccountDetailDTOs.length > 0);

    setAccountsToDisplay(filteredInstitutions);
  }, [institutions, filter]);

  useEffect(() => {
    if (!user || institutions.length === 0) {
      return;
    }

    if (!preloaded) {
      const ids = localStorage.getItem(`${user.id}_account_selector`);
      if (ids) {
        // Before reading the account ids like that, make sure they match something, otherwise, dont bother using data
        const accountsfromStorage = ids.split(',');
        const allExistingAccountIds = institutions.flatMap((i: UserInstitution) =>
          i.institutionAccountDetailDTOs.flatMap(a => getAccountBackendId(i, a))
        );
        const newSelectedAccounts = allExistingAccountIds.filter((id: string) => accountsfromStorage.indexOf(id) !== -1);
        setSelectedAccountIds(newSelectedAccounts);
        dispatch(setSelectedAccounts(newSelectedAccounts));
      } else {
        // If nothing is selected, that means everything is !
        const allExistingAccountIds = institutions.flatMap((i: UserInstitution) =>
          i.institutionAccountDetailDTOs.flatMap(a => getAccountBackendId(i, a))
        );
        setSelectedAccountIds(allExistingAccountIds);
        dispatch(setSelectedAccounts(allExistingAccountIds));
      }
      dispatch(setPreloaded(true));
    } else {
      setSelectedAccountIds(selectedAccounts);
    }
  }, [preloaded, user, institutions, selectedAccounts]);

  const hide = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      setOpenRightPanel(false);
    }
  };

  const Loading = () => {
    return (
      <img src={loadingPiggyBank} alt={'loading'} className={'h-36 w-36 object-contain'}/>
    );
  };

  const isAllSelected = useCallback(() => {
    return accountsToDisplay
      .flatMap(i => i.institutionAccountDetailDTOs.flatMap(a => getAccountBackendId(i, a)))
      .every(id => selectedAccountIds.some(iid => iid === id));
  }, [accountsToDisplay, selectedAccountIds]);

  const isNothingSelected = useCallback(
    newSelection => {
      return accountsToDisplay
        .flatMap(i => i.institutionAccountDetailDTOs.flatMap(a => getAccountBackendId(i, a)))
        .every(id => !newSelection.some((iid: string) => id === iid));
    },
    [accountsToDisplay]
  );

  const selectAll = () => {
    const newArray = accountsToDisplay.flatMap(i => i.institutionAccountDetailDTOs.flatMap(a => getAccountBackendId(i, a)));
    setSelectedAccountIds(newArray);
  };

  const clickAccount = useCallback(
    (institution: UserInstitution, account: InstitutionAccountDetail) => {
      const newArray = selectedAccountIds.slice();
      const accountBackendId = getAccountBackendId(institution, account);
      if (selectedAccountIds.some(id => id === accountBackendId)) {
        newArray.splice(newArray.indexOf(accountBackendId), 1);
      } else {
        newArray.push(accountBackendId);
      }
      if (!isNothingSelected(newArray)) {
        setSelectedAccountIds(newArray);
      }
    },
    [isNothingSelected, selectedAccountIds]
  );

  const clickInstitution = useCallback(
    (institutionId: string) => {
      const institutionAccountsToToggle = accountsToDisplay
        .filter(i => i.id === institutionId)
        .flatMap(i => i.institutionAccountDetailDTOs.flatMap(a => getAccountBackendId(i, a)));

      const newArray = selectedAccountIds.slice();
      if (institutionAccountsToToggle.every(newId => selectedAccountIds.some(id => id === newId))) {
        // the user is unselecting the institution
        institutionAccountsToToggle.forEach(id => {
          newArray.splice(newArray.indexOf(id), 1);
        });
      } else {
        // the user is selecting the whole institution
        institutionAccountsToToggle.forEach(id => {
          if (!newArray.some(idInArray => idInArray === id)) {
            newArray.push(id);
          }
        });
      }
      if (!isNothingSelected(newArray)) {
        setSelectedAccountIds(newArray);
      }
    },
    [accountsToDisplay, isNothingSelected, selectedAccountIds]
  );

  const isWholeInstitutionSelected = useCallback(
    (institutionId: string) => {
      const institutionAccountBackendIds = accountsToDisplay
        .filter(i => i.id === institutionId)
        .flatMap(i => {
          return i.institutionAccountDetailDTOs.map(a => getAccountBackendId(i, a));
        });
      return institutionAccountBackendIds.every(newId => selectedAccountIds.some(id => id === newId));
    },
    [accountsToDisplay, selectedAccountIds]
  );

  function getAccountType (iad: InstitutionAccountDetail) {
    if (iad.accountType === AccountType.investment) {
      return t(`AccountSubType_${iad.accountSubType}`);
    } else {
      return t(`AccountType_${iad.accountType}`);
    }
  }

  function getBalance (iad: InstitutionAccountDetail) {
    if (iad.value < 0) {
      return <span className={styles.negativeBalance}>{formatCurrency(iad.value, 0)}</span>;
    } else {
      return <span className={styles.positiveBalance}>{formatCurrency(iad.value, 0)}</span>;
    }
  }

  const InstitutionSubList = ({ institution }: { institution: UserInstitution }) => {
    const syncStatusLabel = institution.syncStatus === 'syncing'
      ? t('synchronizing')
      : dayjs(institution.syncDate).format('DD.MM') !== dayjs().format('DD.MM')
        ? t('unsynchronized')
        : t('synchronized');
    const syncStatusClass = institution.syncStatus === 'syncing' ? '' : dayjs(institution.syncDate).format('DD.MM') !== dayjs().format('DD.MM') ? styles.negativeBalance : '';
    const isInstitutionSelected = isWholeInstitutionSelected(institution.id);

    const isManual = institution?.institutionSource.description === 'BANKING' &&
    institution?.institutionSource.dataSource === 'DATABASE';
    const manualAccountName = i18n.language === 'fr' ? institution.institutionSource.displayNameFrench : institution.institutionSource.displayNameEnglish;

    return (
      <div className={styles.institutionContainer}>
        <div className={styles.institutionHeader}>
          <div>
            <img className={styles.institutionImg}
                 src={getIconForInstitution(institution.institutionSource.iconName)}
                 alt="institution"/>
          </div>
          <div className={styles.institutionName}>
            <div className={styles.heading7}>{isManual ? manualAccountName : institution.name}</div>
            <div className={`${styles.institutionStatus} ${syncStatusClass}`}>{syncStatusLabel}</div>
          </div>
          <HardbaconToggle value={isInstitutionSelected} onCheck={() => clickInstitution(institution.id)} />
        </div>
        {institution.institutionAccountDetailDTOs.map((iad: InstitutionAccountDetail) => (
          <div key={iad.id} className={styles.account}>
            <div className={styles.accountNameContainer}>
              <div className={styles.accountName}>{`${iad.name} - ${iad.currency}`}</div>
              <div className={styles.accountBalance}>{getAccountType(iad)} • {getBalance(iad)}</div>
            </div>
            <HardbaconToggle value={selectedAccountIds.some(id => id.endsWith(iad.id)) } onCheck={() => clickAccount(institution, iad)}/>
          </div>
        ))}
      </div>
    );
  };

  const updateSelectedAccounts = () => {
    const filteredOutAccountBackendId = institutions.flatMap((item: UserInstitution) =>
      item.institutionAccountDetailDTOs
        .filter(acc => !shouldIncludeInstitutionDetail(filter, acc))
        .flatMap(acc => getAccountBackendId(item, acc))
    );

    const newSelectedAccounts = selectedAccounts
      .slice()
      .filter((id: string) => filteredOutAccountBackendId.some((fOutId: string) => fOutId === id));

    newSelectedAccounts.push(...selectedAccountIds);

    // Make sure we use a list of unique ids
    const finalSelection = Array.from(new Set(newSelectedAccounts)) as string[];

    localStorage.setItem(`${user.id}_account_selector`, finalSelection.join(','));
    dispatch(setSelectedAccounts(finalSelection));
    hide();
  };

  const renderContent = () => {
    if (loadingInstitutions) {
      return <div className={styles.loadingContainer}><Loading /></div>;
    }

    return (
      <div className={styles.container}>
        <Link to={accountsRoute} onClick={() => hide()} className={styles.manageAccounts}>{t('manageMyAccounts')}</Link>
        <div className={styles.all}>
          <div className={styles.heading7}>{t('All')}</div>
          <HardbaconToggle value={isAllSelected()} onCheck={(s) => selectAll()}/>
        </div>
        {accountsToDisplay.map((i: UserInstitution) => <InstitutionSubList key={i.id} institution={i} />)}
        <HardbaconButton title={t('save')} name={'Update'} className={styles.button} onPress={() => updateSelectedAccounts()}/>
      </div>
    );
  };

  return (
    <RightPanel
      title={t('title')}
      visible={openRightPanel} onClose={() => hide()}
      renderContent={renderContent}
    />
  );
};

export default AccountSelectorRightPanel;
