import React from 'react';
import { Route } from 'react-router-dom';
import { comingSoonPlanEn, comingSoonPlanFr } from '../assets/images';
import ComingSoon from '../container/ComingSoon';

export const route = '/plan';

export default function planRoute () {
  return (
    <Route path={route} element={<ComingSoon featureImgEn={comingSoonPlanEn} featureImgFr={comingSoonPlanFr}/>}/>
  );
}
