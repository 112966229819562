/* eslint-disable no-unused-vars */
import Analytics from 'analytics-node';
import User from '../model/User';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent as fbLogEvent } from 'firebase/analytics';

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
});

// Initialize Analytics and get a reference to the service
const fbAnalytics = getAnalytics(firebaseApp);

/* DECLARE ANALYTICS INSTANCE ONCE */
const analytics = new Analytics(process.env.REACT_APP_SEGMENT_KEY!);

let currentUserId: string;

export enum AnalyticsEvent {
    ACCOUNT_CREATED = 'account_created',
    USER_SESSION_STARTED = 'user_session_started',
    INSTITUTION_INVESTMENT_CONNECTED = 'institution_investment_connected',
    INSTITUTION_BANKING_CONNECTED = 'institution_banking_connected',
    DOWNLOAD_APP_SMS = 'download_app_sms',
    USER_ACCOUNT_DELETED = 'user_account_deleted'
}

export const identify = (user: User) => {
  const userInfo = {
    email: user.login,
    firstName: user.firstName,
    lastName: user.lastName,
    plan: user.status,
    lang: user.langKey,
    status: user.status,
    statusReason: user.statusReason,
    phone: user.phoneNumber,
    birthday: user.birthday,
    createdAt: user.createdDate,
    subscriptionPurchaseDate: user.purchaseDate,
    subscriptionExpirationDate: user.expirationDate,
    contactSource: user.contactSource
  };
  analytics.identify({
    userId: `${user.id}`,
    traits: userInfo
  });
  currentUserId = `${user.id}`; // globally setting the current userId after an 'identify' so it can be reused for all subsequent logEvent calls
  logEvent(AnalyticsEvent.USER_SESSION_STARTED);
};

export const logEvent = (event: string, properties?: any, flush = false) => {
  analytics.track({
    userId: currentUserId,
    event,
    properties
  });
  if (flush) {
    analytics.flush();
  }
  fbLogEvent(fbAnalytics, event, properties);
};
