import React from 'react';
import CircularProgress, { CircularProgressColorEmun } from './CircularProgress';
import HardbaconButton from './HardbaconButton';
import { useTranslation } from 'react-i18next';
import styles from '../assets/styles/modules/LoadMore.module.css';

interface LoadMoreProps {
    isLoading: boolean;
    moreToLoad: boolean;
    loadMore: Function
  }

export const LoadMore = ({ isLoading, moreToLoad, loadMore }: LoadMoreProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <CircularProgress color={CircularProgressColorEmun.primary}/>;
  }
  if (!moreToLoad) return null;
  return (
    <div className={styles.container}>
        <HardbaconButton
          ghost={true}
          onPress={() => loadMore()}
          title={t('viewMore')}
          name={t('viewMore')}
        />
    </div>
  );
};
