import ReducerPayload from '../model/ReducerPayload';
import ReducerNotificationState from '../model/ReducerNotificationState';
import {
  FETCH_NEXT_NOTIFICATIONS,
  MARK_NOTIFICATIONS_AS_READ,
  NOTIFICATIONS_LOADING,
  SET_NOTIFICATION
} from '../types/Notification';
import Notification, { NotificationType } from '../model/Notification';

export const initialState: ReducerNotificationState = {
  isAppNotificationVisible: false,
  appNotificationModel: undefined,
  notifications: [],
  notificationsLoading: false,
  notificationsPageNum: -1,
  hasUnreadBudgetNotifications: false,
  budgetNotifications: []
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        isAppNotificationVisible: action.payload !== undefined,
        appNotificationModel: action.payload
      };
    case NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationsLoading: action.payload
      };
    case FETCH_NEXT_NOTIFICATIONS: {
      let newNotifications: Notification[];
      if (action.payload.pageNum > state.notificationsPageNum) {
        newNotifications = [...state.notifications, ...action.payload.notifications];
      } else {
        newNotifications = action.payload.notifications;
      }

      const budgetNotifications = newNotifications
        .filter(n => n.notificationType === NotificationType.BUDGET_TREND_YELLOW ||
          n.notificationType === NotificationType.BUDGET_TREND_RED ||
          n.notificationType === NotificationType.BUDGET_OVER_90_PERCENT);
      const hasUnreadBudgetNotifications = action.payload.pageNum === 0 && budgetNotifications.some(n => n.unread);

      return {
        ...state,
        notifications: newNotifications,
        notificationsPageNum: action.payload.pageNum,
        notificationsLoading: false,
        hasUnreadBudgetNotifications,
        budgetNotifications
      };
    }
    case MARK_NOTIFICATIONS_AS_READ: {
      const newNotifications = [...state.notifications];
      newNotifications.filter(n => action.payload.some((id: number) => id === n.id)).forEach(n => n.unread = false);
      const newBudgetNotifications = [...state.budgetNotifications];
      newBudgetNotifications.filter(n => action.payload.some((id: number) => id === n.id)).forEach(n => n.unread = false);

      return {
        ...state,
        notifications: newNotifications,
        budgetNotifications: newBudgetNotifications,
        hasUnreadBudgetNotifications: false
      };
    }
    default:
      return state;
  }
};
