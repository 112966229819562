export const INCLUDE_BANKING_TRANSACTION = 'INCLUDE_BANKING_TRANSACTION';
export const EXCLUDE_BANKING_TRANSACTION = 'EXCLUDE_BANKING_TRANSACTION';
export const UPDATE_BANKING_TRANSACTION_ITEM = 'UPDATE_BANKING_TRANSACTION_ITEM';
export const GET_BANKING_TRANSACTIONS = 'GET_BANKING_TRANSACTIONS';
export const GET_BANKING_TRANSACTIONS_SUCCESS = 'GET_BANKING_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTION_CATEGORIES = 'GET_TRANSACTION_CATEGORIES';
export const SPLIT_TRANSACTION_BEWTEEN_PEOPLE = 'SPLIT_TRANSACTION_BEWTEEN_PEOPLE';
export const DELETE_SPLIT_TRANSACTION_BEWTEEN_PEOPLE = 'DELETE_SPLIT_TRANSACTION_BEWTEEN_PEOPLE';
export const CATEGORIZE_TRANSACTION = 'CATEGORIZE_TRANSACTION';
export const SPLIT_TRANSACTION_BY_CATEGORY = 'SPLIT_TRANSACTION_BY_CATEGORY';
export const DELETE_SPLIT_TRANSACTION_BY_CATEGORY = 'DELETE_SPLIT_TRANSACTION_BY_CATEGORY';
export const SET_TRANSACTION_FILTER = 'SET_TRANSACTION_FILTER';
export const UPDATE_TRANSACTION_DATE_PICKER = 'UPDATE_TRANSACTION_DATE_PICKER';
export const REMOVE_BANKING_TRANSACTION_ITEM = 'REMOVE_BANKING_TRANSACTION_ITEM';
export const CREATE_MANUAL_TRANSACTION_ITEM = 'CREATE_MANUAL_TRANSACTION_ITEM';
