import React from 'react';
import {
  GoogleLogin, CredentialResponse
} from '@react-oauth/google';

type SocialLoginGoogleProps = {
    type: 'signIn' | 'signUp';
    onSuccess: (response: CredentialResponse) => void;
    onFailure: (error?: any) => void;
}

export const SocialLoginGoogle = (props: SocialLoginGoogleProps) => {
  const { onSuccess, onFailure } = props;

  return (
    <div style={{ marginTop: '12px' }}>
      <GoogleLogin
        width="384px"
        shape="pill"
        onSuccess={(credentialResponse) => {
          onSuccess(credentialResponse);
        }}
        onError={() => {
          onFailure();
        }}
      />
    </div>
  );
};

export default SocialLoginGoogle;
