import React from 'react';
import styles from './../assets/styles/modules/ExpandButton.module.css';

interface ExpandButtonProps {
  isOpen: boolean;
  onClick?: Function;
  className?: string;
  size?: number
}

const ExpandButton = ({ isOpen, onClick, className, size }: ExpandButtonProps) => {
  return (
    <button onClick={() => onClick && onClick()} className={className}>
        <svg className={`${styles.button} ${isOpen ? styles.open : ''}`} width={size || '25'} height={size || '25'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={styles.path} d="M1.00049 6L12.0005 17L23.0005 6" stroke={isOpen ? '#00CCCC' : '#C9D5E4'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </button>
  );
};

export default ExpandButton;
