/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPlaidToken } from '../api/UserInstitutionService';
import styles from './../assets/styles/modules/mXConnectWidget.module.css';
import dayjs from 'dayjs';
import {
  PlaidLinkError, PlaidLinkOnEvent, PlaidLinkOnEventMetadata,
  PlaidLinkOnExit, PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions, PlaidLinkStableEvent,
  usePlaidLink
} from 'react-plaid-link';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import colors from '../colors';
import { loadingPiggyBank } from '../assets/images';
import { useDispatch, useSelector } from 'react-redux';
import { setAddInstitutionPanelOpen } from '../actions/UserInstitution';
import { getBankingTransactions } from '../actions/Transactions';

type PlaidConnectWidget = {
  institutionId?: string;
  hideLoading?: boolean;
  onInstitutionAdded: (institutionProviderCode: string, publicToken: string) => void;
  onConnectSuccess: (institutionProviderCode: string, institutionId: string) => void;
  onExit: (success: boolean) => void;
};

export const PlaidConnectWidget = (props: PlaidConnectWidget) => {
  const [token, setToken] = useState<string>();
  const [progress, setProgress] = useState<number>(0);
  const [counter, setCounter] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { minifiedSelectedAccountIds } = useSelector((state: any) => state.userInstitution);

  const { t } = useTranslation(['patience', 'connect', 'plaid']);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {     
      setLoading(true);
      if (props.institutionId) {
        props.onConnectSuccess(metadata.institution!.institution_id, props.institutionId);
      } else {
        props.onInstitutionAdded(metadata.institution!.institution_id, publicToken);
      }
    },
    []
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
      // log and save error and metadata
      // handle invalid link token
      // console.log('====== onExit');
      // console.log(error, metadata);
      if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
        // generate new link token
        props.onExit(false);
      }
      // to handle other error codes, see https://plaid.com/docs/errors/
      dispatch(setAddInstitutionPanelOpen(false))
      props.onExit(true);
    },
    []
  );

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata
    ) => {
      // console.log('====== onEvent');
      // console.log(eventName, metadata);
      // log eventName and metadata
    },
    []
  );

  const config: PlaidLinkOptions = {
    onSuccess: onSuccess,
    onExit: onExit,
    onEvent: onEvent,
    token: token!
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (token && ready) {
      setProgress(100);
      open();
    }
  }, [token, ready, open]);


  useEffect(() => {
    const id = setInterval(() => setProgress((oldCount) => oldCount + 45 * 0.01), 1000);
    setCounter(id);
    getPlaidToken(props.institutionId)
      .subscribe(token => setToken(token));
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (progress >= 100 && counter) {
      clearInterval(counter);
    }
  }, [progress, counter]);


  const renderWaitingView = () => {
    return (
      <div className={styles.waiting}>
        <div className={styles.progressContainer}>
          <CircularProgressbarWithChildren
            styles={buildStyles({
              trailColor: colors.white,
              pathColor: colors.primary,
              pathTransitionDuration: 0.5
            })}
            minValue={0}
            maxValue={100}
            className={styles.progress}
            strokeWidth={6}
            value={progress}
          >
            <div className={styles.progress}>
              <div className={styles.piggyText}>{`${progress.toFixed()} %`}</div>
              <img className={styles.piggy} src={loadingPiggyBank}/>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className={styles.bePatient}>{t('bePatient')}</div>
        <div className={styles.bePatientSubText}>{t('bePatientSubtext')}</div>
      </div>
    );
  };

  const renderBackgroundView = () => {
    return (
      <div className={styles.waiting}>
        <div className={styles.bePatient}>{t('plaid:waitForPlaid')}</div>
        <div className={styles.bePatientSubText}>{t('plaid:waitForPlaidSubText')}</div>
      </div>
    );
  }

  const renderLoadingView = () => {
    return (
      <div className={styles.waiting}>
        <div className={styles.bePatient}>{t('plaid:waitForSync')}</div>
        <div className={styles.bePatientSubText}>{t('plaid:waitForSyncSubText')}</div>
      </div>
    );
  }

  return (
    <div className={styles.containerM}>
      <div id={'connect-widget'}
           className={styles.mxWidgetIframe}>
        {!props.hideLoading && (
          <>
            {progress < 100 && renderWaitingView()}
            {progress >= 100 && renderBackgroundView()}
            {loading && renderLoadingView()}
          </>  
        )}
      </div>
    </div>
  );
};
