import i18n from '../i18n';
import { Component } from 'react';
import { UserRole } from '../model/UserRole';

export const handleLangQueryParam = (component: Component<any, any>) => {
  const params = new URLSearchParams(component.props.location.search);
  const lang = params.get('lang');
  if (lang && lang.length > 0) {
    i18n.changeLanguage(lang);
    component.setState({ lang });
  }
};

export function groupBy<K, V> (list: Array<V>, keyGetter: (input: V) => K): any[] {
  const newArray = [];
  const alreadyGrouped: K[] = [];
  for (let i = 0; i < list.length; i++) {
    const grouped = [];
    const groupName = keyGetter(list[i]);
    if (!alreadyGrouped.includes(groupName)) {
      if (groupName) {
        for (let j = i + 1; j < list.length; j++) {
          const subGroupName = keyGetter(list[j]);
          if (groupName === subGroupName) {
            grouped.push(list[j]);
          }
        }
        if (grouped.length > 0) {
          grouped.push(list[i]);
          newArray.push(grouped);
          alreadyGrouped.push(groupName);
        } else {
          newArray.push(list[i]);
        }
      } else {
        newArray.push(list[i]);
      }
    }
  }

  return newArray;
}

export function removeZeroes (value: string | number) {
  if (typeof value === 'string' && (value.endsWith(',00') || value.endsWith('.00'))) {
    return value.substr(0, value.length - 3);
  }
  return value;
}

export function getLang () {
  if (i18n.language.startsWith('fr')) {
    return 'fr';
  } else {
    return 'en';
  }
}

export function formatMoney (value: string | number) {
  const floatValue = Number((parseFloat(value + '')).toFixed(2)).toLocaleString(getLang());
  if (i18n.language.startsWith('fr')) {
    return (removeZeroes(floatValue) + ' $').replace('.', ',');
  } else {
    return '$ ' + removeZeroes(floatValue);
  }
}

export const getIconForInstitution = (iconName: string) => {
  return `${process.env.REACT_APP_S3_INSTITUTIONS_URL}${iconName}.png`;
};

export function hasRole (userRoles?: UserRole[], role?: UserRole[] | UserRole) {
  let hasRole = true;

  if (Array.isArray(role)) {
    (role as UserRole[]).forEach(userRole => {
      if (!userRoles?.includes(userRole)) {
        hasRole = false;
      }
    });
  } else if (role) {
    if (!userRoles?.includes(role as UserRole)) {
      hasRole = false;
    }
  }

  return hasRole;
}
