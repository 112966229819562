import React, { useRef, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';
import {
  arrowRight,
  back,
  deleteCompareFr,
  deleteCompareEn,
  deleteBudgetFr,
  deleteBudgetEn,
  deleteAnalyzeFr,
  deleteAnalyzeEn,
  deletePlanFr,
  deletePlanEn,
  emojiCrying
} from '../assets/images';
import styles from '../assets/styles/modules/deleteAccountFlow.module.css';
import { deleteUserAccount } from '../actions/User';
import HardbaconButton from './HardbaconButton';
import HardbaconModal, { HardbaconModalStyle } from './HardbaconModal';
import { HardbaconInput } from './';
import { useDispatch } from 'react-redux';
import { showSupport } from '../actions/Support';
import { premiumRoute } from '../navigation/Profile';
import { route as signInRoute } from '../navigation/SignIn';

const reasons = [
  'INST_CONNECTION_PROBLEMS',
  'INST_NOT_SUPPORTED',
  'DONT_WANT_TO_PAY',
  'SOMETHING_ELSE',
  'NO_ANSWER'
];

type DeleteAccountFlowProps = {
  cancel: () => void;
}

const DeleteAccountFlow = (props: DeleteAccountFlowProps) => {
  const [reason, setReason] = useState(5);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [wizardRef, setWizardRef] = useState<StepWizardProps>();
  const [canDelete, setCanDelete] = useState(false);
  const [comment, setComment] = useState<string | undefined>();
  const [commentMissing, setCommentMissing] = useState(false);
  const reasonRef = useRef<HTMLTextAreaElement>(null);
  const { t, i18n } = useTranslation(['deleteAccount']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Step1 = (stepProps: any) => {
    return (
      <div className={'grid justify-items-center sm:grid-cols-2'}>
        <div className={'hidden sm:block'}><img alt={'delete account'} className={styles.image}
                  src={i18n.language === 'fr' ? deleteCompareFr : deleteCompareEn}/></div>
        <div className={'m-auto'}>
          <h4 className={styles.title}>{t('step1.title')}</h4>
          <div className={styles.text}>
            {t('step1.text1')}&nbsp;
            <a className={styles.link} href={t('step1.text2Link')} target={'_blank'}
               rel="noreferrer">{t('step1.text2')}</a>&nbsp;
            {t('step1.text3')}
          </div>
          <div className={'sm:hidden'}>
            <img alt={'delete account'} className={styles.image}
                 src={i18n.language === 'fr' ? deleteCompareFr : deleteCompareEn}/>
          </div>
          <HardbaconButton name={t('cancel')} title={t('cancel')} className={styles.button}
                           onPress={() => props.cancel()}/>
          <HardbaconButton name={t('deleteMyAccount')} title={t('deleteMyAccount')}
                           ghost={true}
                           gray={true}
                           onPress={stepProps.nextStep}
                           className={styles.button}/>
        </div>
      </div>
    );
  };

  const Step2 = (stepProps: any) => {
    return (
      <div className={'grid justify-items-center sm:grid-cols-2'}>
        <div className={'hidden sm:block'}><img alt={'delete account'} className={styles.image}
                  src={i18n.language === 'fr' ? deletePlanFr : deletePlanEn}/></div>
        <div className={'m-auto'}>
          <h4 className={styles.title}>{t('step2.title')}</h4>
          <div className={styles.problem} onClick={() => {
            setReason(1);
            stepProps.nextStep();
          }}>
            <div>{t('step2.reason1')}</div>
            <div className={'arrow'}><img className={styles['arrow-img']} alt={t('step2.reason1')} src={arrowRight} width={24}/></div>
          </div>
          <div className={styles.problem} onClick={() => {
            setReason(2);
            stepProps.nextStep();
          }}>
            <div>{t('step2.reason2')}</div>
            <div className={'arrow'}><img className={styles['arrow-img']} alt={t('step2.reason2')} src={arrowRight} width={24}/></div>
          </div>
          <div className={styles.problem} onClick={() => {
            setReason(3);
            stepProps.nextStep();
          }}>
            <div>{t('step2.reason3')}</div>
            <div className={'arrow'}><img className={styles['arrow-img']} alt={t('step2.reason3')} src={arrowRight} width={24}/></div>
          </div>
          <div className={styles.problem} onClick={() => {
            setReason(4);
            stepProps.nextStep();
          }}>
            <div>{t('step2.reason4')}</div>
            <div className={'arrow'}><img className={'my-auto'} alt={t('step2.reason4')} src={arrowRight} width={24}/></div>
          </div>
          <HardbaconButton name={t('skip')} title={t('skip')}
                           ghost={true}
                           gray={true}
                           onPress={() => {
                             setReason(5);
                             stepProps.goToStep(4);
                           }}
                           className={styles.button}/>
        </div>
      </div>
    );
  };

  const Step3 = (stepProps: any) => {
    const title = t(`step3.titleReason${reason}`);

    return (
      <div className={'grid justify-items-center sm:grid-cols-2'}>
        <div className={'hidden sm:block'}><img alt={'delete account'} className={styles.image}
                  src={i18n.language === 'fr' ? deleteBudgetFr : deleteBudgetEn}/></div>
        <div className={'m-auto md:px-10'}>
          <h4 className={styles.title}>{title}</h4>
          {reason === 1 && (
            <>
              <div className={'sm:hidden'}>
                <img alt={'delete account'} className={styles.image}
                     src={i18n.language === 'fr' ? deleteBudgetFr : deleteBudgetEn}/>
              </div>
              <HardbaconButton name={t('contactSupport')} title={t('contactSupport')} className={styles.button}
                               onPress={() => openSupport()}/>
            </>
          )}
          {reason === 2 && (
            <>
              <div className={'sm:hidden'}>
                <img alt={'delete account'} className={styles.image}
                     src={i18n.language === 'fr' ? deleteBudgetFr : deleteBudgetEn}/>
              </div>
              <HardbaconButton name={t('step3.addMyInstitution')} title={t('step3.addMyInstitution')}
                               className={styles.button}
                               onPress={() => openSupport()}/>
            </>
          )}
          {reason === 3 && (
            <>
              <div className={'sm:hidden'}>
                <img alt={'delete account'} className={styles.image}
                     src={i18n.language === 'fr' ? deleteBudgetFr : deleteBudgetEn}/>
              </div>
              <HardbaconButton name={t('unsubscribe')} title={t('unsubscribe')} className={styles.button}
                               onPress={() => navigateToSubscription()}/>
            </>
          )}
          {(reason === 4 || reason === 5) && (
            <>
              <textarea key={'reasonInput'}
                        ref={reasonRef}
                        className={styles.textarea}
                        maxLength={1000}
                        placeholder={t('step3.yourComment')}>
              </textarea>
              <div className={styles.error}>{commentMissing ? t('step3.error') : ''}&nbsp;</div>
              <HardbaconButton name={t('send')} title={t('step3.send')} className={styles.button}
                           onPress={() => {
                             const text = reasonRef.current?.value;
                             setComment(text);
                             setCommentMissing(!text);
                             if (text) {
                               stepProps.nextStep();
                             }
                           }}/>
            </>
          )}
          <HardbaconButton name={t('skip')} title={t('skip')}
                           ghost={true}
                           gray={true}
                           onPress={stepProps.nextStep}
                           className={styles.button}/>
        </div>
      </div>
    );
  };

  const Step4 = (stepProps: any) => {
    return (
      <div className={'grid justify-items-center sm:grid-cols-2'}>
        <div className={'hidden sm:block'}><img alt={'delete account'} className={styles.image}
                  src={i18n.language === 'fr' ? deleteAnalyzeFr : deleteAnalyzeEn}/></div>
        <div className={'m-auto md:px-10'}>
          <h4 className={styles.title}>{t('step4.title')}</h4>
          <div className={'sm:hidden'}>
            <img alt={'delete account'} className={styles.image}
                 src={i18n.language === 'fr' ? deleteAnalyzeFr : deleteAnalyzeEn}/>
          </div>
          <HardbaconButton name={t('cancel')} title={t('cancel')} className={styles.button}
                           onPress={() => props.cancel()}/>
          <HardbaconButton name={t('deleteMyAccount')} title={t('deleteMyAccount')}
                           ghost={true}
                           gray={true}
                           onPress={() => setDeletePopupOpen(true)}
                           className={styles.button}/>
        </div>
      </div>
    );
  };

  const previousStepOrClose = () => {
    // @ts-ignore
    const currentStep = wizardRef.state.activeStep;
    if (currentStep === 0) {
      props.cancel();
    } else {
      if (currentStep === 3 && reason === 5) {
        // @ts-ignore
        wizardRef?.goToStep(2);
      } else {
        // @ts-ignore
        wizardRef?.previousStep();
      }
    }
  };

  const openSupport = () => {
    dispatch(showSupport(true));
  };

  const navigateToSubscription = () => {
    props.cancel();
    setTimeout(() => {
      navigate(premiumRoute);
    }, 100);
  };

  const deleteAccount = () => {
    dispatch(deleteUserAccount(reasons[reason - 1], comment));
    setTimeout(() => {
      navigate(signInRoute);
    }, 100);
  };

  return (
    <div className={'page-children'}>
      <HardbaconModal
        style={HardbaconModalStyle.none}
        open={deletePopupOpen}
        negativeText={t('cancel')}
        positiveText={t('save')}
        title={''}
        customHeader={<div/>}
        positiveAction={() => {
          deleteAccount();
        }}
        dismissAction={() => {
          setDeletePopupOpen(false);
        }}
      >
        <div className={'flex flex-col mt-10 mx-4 text-center'}>
          <img alt={'very sad'} className={styles['sad-face']} src={emojiCrying} width={56}/>
          <div className={styles.title}><h6>{t('step4.typeDelete')}</h6></div>
          <HardbaconInput name={'delete'} placeholder={t('step4.delete')} optional={false}
                          onChange={(value) => {
                            if (value === t('step4.delete')) {
                              setCanDelete(true);
                            } else {
                              setCanDelete(false);
                            }
                          }}/>
          <HardbaconButton name={'cancelDeleteAccount'} title={t('cancel')} className={styles['danger-button']}
                           danger={true}
                           onPress={() => {
                             setDeletePopupOpen(false);
                             props.cancel();
                           }}/>
          <HardbaconButton name={'confirmDeleteAccount'} disabled={!canDelete} title={t('step4.confirm')}
                           className={styles['confirm-button']} ghost={true} danger={true}
                           onPress={() => deleteAccount()}/>
        </div>
      </HardbaconModal>

      <div className={'flex justify-between content-center overflow-visible'}>
        <div className={'overflow-visible cursor-pointer justify-self-start'} onClick={() => previousStepOrClose()}>
          <img alt={'back'} className={'shadowed'} src={back} width={36}/>
        </div>
        <div className={styles['main-title']}>{t('deleteMyAccount')}</div>
        <div className={''} style={{ width: 36 }}>
        </div>
      </div>
      <StepWizard instance={setWizardRef} nav={<Nav canClick={false}/>}>
        <Step1/>
        <Step2/>
        <Step3/>
        <Step4/>
      </StepWizard>
    </div>);
};

export default DeleteAccountFlow;
