import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  comparatorRecommendation,
  error,
  loadingClock
} from '../../assets/images';
import Modal from 'react-modal';
import ReducerComparatorState from '../../model/ReducerComparatorState';
import ComparatorType from '../../model/ComparatorType';
import ComparatorWidget from '../../component/ComparatorWidget';
import {
  getRecommendationBroker,
  getRecommendationChecking,
  getRecommendationCreditCard,
  getRecommendationRoboAdvisor,
  getRecommendationSaving
} from '../../actions/Comparator';
import { AddAccount, HardbaconButton } from '../../component';
import ComparatorLoadingState from '../../model/ComparatorLoadingState';
import ReducerUserInstitutionState from '../../model/ReducerUserInstitutionState';
import { getInstitutions, setAddInstitutionPanelOpen } from '../../actions/UserInstitution';
import { CreditCardDetailComparator, formatAmount } from '../../component/CreditCardDetailComparator';
import { ICreditCard } from '../../model/comparators/CreditCard';
import { getOffer } from '../../api/ComparatorService';

/* eslint-disable no-unused-vars */
enum CompareTab {
  suggested,
  all,
}

type ComparatorProps = {
  selectedType: ComparatorType,
  isCartTransfer: boolean,
  isRunSavedSearch: boolean
}

const Comparator = (props: ComparatorProps) => {
  const [selectedTab, setSelectedTab] = useState<CompareTab>();
  const [displayAlert, setDisplayAlert] = useState(true);
  const [selectedCard, setSelectedCard] = useState<ICreditCard>();

  const location = useLocation();
  const { t, i18n } = useTranslation(['compare']);
  const displayedLanguage = i18n.language === 'fr' ? 'french' : 'english';
  const dispatch = useDispatch();

  const comparatorState: ReducerComparatorState = useSelector((state: any) => state.comparator);
  const userInstitutionState: ReducerUserInstitutionState = useSelector((state: any) => state.userInstitution);
  const firstYearRewards: { label: string; value: string }[] | undefined =
  useMemo(() => {
    if (selectedCard) {
      const rewardPerCategories = Object.keys(
        selectedCard?.result?.firstYear?.rewardPerCategory
      ).map((key) => {
        return {
          label: `${key}`,
          value: `${formatAmount({
            value:
              selectedCard?.result?.firstYear?.rewardPerCategory[key] *
              selectedCard?.result?.rewardValue,
            options: { language: i18n.language }
          })}`
        };
      });
      const selectedFirstYearRewards = [
        {
          label: 'totalReward',
          value: `${formatAmount({
            value: selectedCard?.result?.firstYear?.totalReward,
            options: { language: i18n.language }
          })}`
        },
        ...rewardPerCategories,
        {
          label: 'annualFee',
          value: `${formatAmount({
            value: selectedCard?.result?.firstYear?.annualFee,
            options: { language: i18n.language }
          })}`
        }
      ];

      return selectedFirstYearRewards;
    }
  }, [i18n.language, selectedCard]);

  const consecutiveYearsRewards:
  | { label: string; value: string }[]
  | undefined = useMemo(() => {
    if (selectedCard) {
      const rewardPerCategories = Object.keys(
        selectedCard?.result?.consecutiveYears?.rewardPerCategory
      ).map((key) => {
        return {
          label: `${key}`,
          value: `${formatAmount({
          value:
            selectedCard?.result?.consecutiveYears?.rewardPerCategory[key] *
            selectedCard?.result?.rewardValue,
          options: { language: i18n.language }
        })}`
        };
      });
      const selectedFirstYearRewards = [
        {
          label: 'totalReward',
          value: `${formatAmount({
          value: selectedCard?.result?.consecutiveYears?.totalReward,
          options: { language: i18n.language }
        })}`
        },
        ...rewardPerCategories,
        {
          label: 'annualFee',
          value: `${formatAmount({
          value: selectedCard?.result?.consecutiveYears?.annualFee,
          options: { language: i18n.language }
        })}`
        }
      ];

      return selectedFirstYearRewards;
    }
  }, [i18n.language, selectedCard]);

  useEffect(() => {
    setSelectedTab(CompareTab.all);
    const recommendations = getRecommandationState(props.selectedType);
    setDisplayAlert(!recommendations.hasAccountOfThisType);
  }, [props.selectedType]);

  useEffect(() => {
    const { hash } = location;
    if (hash && hash.endsWith(t('suggested'))) {
      setSelectedTab(CompareTab.suggested);
    } else {
      setSelectedTab(CompareTab.all);
    }
  }, [location]);

  // Do that only once, if no institution detected, try and load them
  useEffect(() => {
    if (userInstitutionState.institutions.length === 0) {
      dispatch(getInstitutions());
    }
  }, []);

  const getRecommandationState = (type: ComparatorType) => {
    switch (type) {
      case ComparatorType.creditCard:
        return comparatorState.creditCard;
      case ComparatorType.broker:
        return comparatorState.broker;
      case ComparatorType.saving:
        return comparatorState.saving;
      case ComparatorType.checking:
        return comparatorState.checking;
      case ComparatorType.roboAdvisor:
        return comparatorState.roboAdvisor;
      default:
        return { recommendations: [], status: ComparatorLoadingState.loaded };
    }
  };

  const runRecommendationEngine = () => {
    switch (props.selectedType) {
      case ComparatorType.creditCard:
        dispatch(getRecommendationCreditCard());
        break;
      case ComparatorType.checking:
        dispatch(getRecommendationChecking());
        break;
      case ComparatorType.saving:
        dispatch(getRecommendationSaving());
        break;
      case ComparatorType.roboAdvisor:
        dispatch(getRecommendationRoboAdvisor());
        break;
      case ComparatorType.broker:
        dispatch(getRecommendationBroker());
        break;
    }
  };

  const renderRecommendations = () => {
    const recommendations = getRecommandationState(props.selectedType);
    const creditCards = recommendations && (recommendations?.recommendations?.map((reccommandation) => reccommandation.product));

    const handleApply = async (id: string) => {
      const url = (await getOffer(
        'credit-cards',
        id,
        i18n.language
      )) as unknown as string;
      window.location.href = url;
    };

    if (recommendations.status !== ComparatorLoadingState.loaded) {
      return renderAnalysisPlaceholder(recommendations);
    } else if (recommendations.recommendations?.length === 0) {
      return renderNoResults();
    } else {
      // Automatically scroll to top when displaying recommendations
      window.scrollTo(0, 0);
      return (
        <>
          {creditCards?.map((creditCard) => (
            <CreditCardDetailComparator key={creditCard?.id} creditCard={creditCard} onPress={(card) => setSelectedCard(card)}/>
          ))}
      <Modal
        isOpen={!!selectedCard}
        onRequestClose={() => setSelectedCard(undefined)}
        className="my-auto px-3 py-6 bg-white rounded-lg md:w-[400px] md:mx-auto"
        style={{
          content: {
            maxHeight: '90vh',
            overflow: 'scroll'
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0, 0.4)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }}
      >
        <div className="relative">
          <button
            onClick={() => setSelectedCard(undefined)}
            className="absolute -top-4 right-0"
          >
            X
          </button>
          {selectedCard
            ? (
            <>
              <div className="flex gap-x-2 mb-2">
                <div className="relative w-12 h-8">
                  <img
                    src={`https://hardbacon-prod.s3.us-east-1.amazonaws.com/comparators/${selectedCard?.id}_card`}
                    alt={selectedCard?.name?.english ?? ''}
                  />
                </div>
                <div>
                  <h3 className="font-medium">
                    {selectedCard?.name?.[displayedLanguage]}
                  </h3>
                  <h4 className="text-xs text-[#788EA4]">
                    {selectedCard?.institution?.name?.[displayedLanguage]}
                  </h4>
                </div>
              </div>
              <p className="pb-1 text-10px font-serif border-b">
                {t('rewardCategories.estimatedReward')}
              </p>
              {firstYearRewards?.length &&
                firstYearRewards?.map((item) => {
                  return (
                    <div className="flex justify-between my-1" key={item.label}>
                      <span className="text-10px font-serif text-hb-keygray">
                        {t(`rewardCategories.${item?.label.toLowerCase()}`)}
                      </span>
                      <span className="text-10px font-serif-bold">
                        {item?.value}
                      </span>
                    </div>
                  );
                })}
              <hr className="my-2" />
              <p className="text-right text-10px font-serif-bold">
                {formatAmount({
                  value:
                    selectedCard?.result?.firstYear?.totalReward -
                    selectedCard?.result?.firstYear?.annualFee,
                  options: { language: i18n.language }
                })}
              </p>
              <p className="text-right text-10px font-serif text-hb-keygray">
                {t('total')}
              </p>
              <hr className="-mx-3 my-2" />
              <p className="pb-1 text-10px font-serif border-b">
                {t('rewardCategories.estimatedConsecutiveReward')}
              </p>
              {consecutiveYearsRewards?.length &&
                consecutiveYearsRewards?.map((item) => {
                  return (
                    <div className="flex justify-between my-1" key={item.label}>
                      <span className="text-10px font-serif text-hb-keygray">
                        {t(`rewardCategories.${item?.label.toLowerCase()}`)}
                      </span>
                      <span className="text-10px font-serif-bold">
                        {item?.value}
                      </span>
                    </div>
                  );
                })}
              <hr className="my-2" />
              <p className="text-right text-10px font-serif-bold">
                {formatAmount({
                  value:
                    selectedCard?.result?.consecutiveYears?.totalReward -
                    selectedCard?.result?.consecutiveYears?.annualFee,
                  options: { language: i18n.language }
                })}
              </p>
              <p className="text-right text-10px font-serif text-hb-keygray">
                {t('total')}
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() => handleApply(selectedCard?.id)}
                  className="bg-hb-default rounded-xl px-3 py-2  text-white text-xs font-serif-medium border border-hb-default"
                >
                  {t('applyNow')}
                </button>
              </div>
            </>
              )
            : null}
        </div>
      </Modal>
        </>
      );
    }
  };

  const renderAnalysisPlaceholder = (recommendation: { recommendations?: any[]; status: string, hasAccountOfThisType?: boolean }) => {
    if (!recommendation.hasAccountOfThisType) {
      return <div className={'run-analysis'}>
        <AddAccount
            title={t(`connectMy${props.selectedType}`)}
            onAddAccount={() => dispatch(setAddInstitutionPanelOpen(true))}>
          <div className={'subtitle-div'}>
            <span className={'subtitle'}>{t('weAnalysisYourAccount')}<span className={'bold'}> {t('findBestProduct')}</span> {t('forYourNeed')}</span>
          </div>
        </AddAccount>
      </div>;
    }

    const isLoading = recommendation.status === ComparatorLoadingState.loading;

    return (
        <div className={'run-analysis'}>
          <img alt={'analyze'} src={isLoading ? loadingClock : comparatorRecommendation} className={'run-analysis-img'}/>
          <span style={{ lineHeight: 1 }} className={'title'}>{isLoading ? t('analysing') : t('compare')}</span>
          <div className={'subtitle-div'}>
            {isLoading
              ? (<span className={'subtitle'}>{t('analysingDescription')}</span>)
              : (<span className={'subtitle'}>{t('weAnalysisYourAccount')}<span className={'bold'}> {t('findBestProduct')}</span> {t('forYourNeed')}</span>)
            }
          </div>

          <div className={'button-div'}>
            <HardbaconButton onPress={() => runRecommendationEngine()}
                             className={'button'}
                             title={t('startAnalysis')}
                             loading={isLoading}
                             disabled={isLoading}
                             name={'startAnalysis'}/>
          </div>
        </div>
    );
  };

  const renderNoResults = () => {
    return (
        <div className={'no-result'}>
          <img alt={'analyze'} src={error} className={'no-result-img'}/>
          <span style={{ lineHeight: 1 }} className={'title'}>{t('cantRecommendTitle')}</span>
          <div className={'subtitle-div'}>
            <span className={'subtitle'}>{t('cantRecommendDescription')}</span>
          </div>

          <div className={'button-div'}>
            <HardbaconButton onPress={() => setSelectedTab(CompareTab.all)}
                             className={'button'}
                             title={t('noRecommendationButton')}
                             name={'seeAllResults'}/>
          </div>
        </div>
    );
  };

  return (
    <div className={'compare'}>
      <div className="compare-content">
        {selectedTab === CompareTab.all && (
          <ComparatorWidget singleType={true}
                            selectedType={props.selectedType}
                            cartTransfer={props.isCartTransfer}
                            runSavedSearch={props.isRunSavedSearch}/>
        )}
        {selectedTab === CompareTab.suggested && (
          <div className={'w-full p-4'}>
            {renderRecommendations()}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comparator;
