import React from 'react';

/* eslint-disable no-unused-vars */
export enum CircularProgressColorEmun {
  primary = 'primary',
}

interface CircularProgressProps {
  color?: CircularProgressColorEmun;
  classNames?: string,
}

export const CircularProgress = ({ color, classNames }: CircularProgressProps) => {
  return (
    <div className={classNames} style={{ height: '40px', width: '40px', marginLeft: 'auto', marginRight: 'auto' }}>
      <svg className={'spinner-hardbacon'} style={{ marginLeft: 'auto', marginRight: 'auto' }} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle className={'spinner-hardbacon-circle ' + (color || '')} cx="50" cy="50" r="45"/>
      </svg>
    </div>
  );
};

export default CircularProgress;
