import ReducerPayload from '../model/ReducerPayload';
import ReducerComparatorState from '../model/ReducerComparatorState';
import ComparatorLoadingState from '../model/ComparatorLoadingState';
import {
  GET_CREDIT_CARD_RECOMMENDATION_SUCCESS,
  GET_CREDIT_CARD_RECOMMENDATION,
  GET_CREDIT_CARD_RECOMMENDATION_FAILED,
  GET_BROKER_RECOMMENDATION,
  GET_BROKER_RECOMMENDATION_SUCCESS,
  GET_BROKER_RECOMMENDATION_FAILED,
  GET_CHECKING_RECOMMENDATION,
  GET_CHECKING_RECOMMENDATION_FAILED,
  GET_CHECKING_RECOMMENDATION_SUCCESS,
  GET_ROBO_ADVISOR_RECOMMENDATION,
  GET_ROBO_ADVISOR_RECOMMENDATION_FAILED,
  GET_ROBO_ADVISOR_RECOMMENDATION_SUCCESS,
  GET_SAVING_RECOMMENDATION,
  GET_SAVING_RECOMMENDATION_FAILED,
  GET_SAVING_RECOMMENDATION_SUCCESS
} from '../types/Comparator';
import { LOGOUT } from '../types/Session';
import { GET_USER_INSTITUTION_SUCCESS } from '../types/UserInstitution';
import InstitutionDescription from '../model/InstitutionDescription';
import UserInstitution from '../model/UserInstitution';

export const initialState: ReducerComparatorState = {
  creditCard: {
    recommendations: undefined,
    status: ComparatorLoadingState.notLoaded,
    hasAccountOfThisType: undefined
  },
  broker: {
    recommendations: undefined,
    status: ComparatorLoadingState.notLoaded,
    hasAccountOfThisType: undefined
  },
  saving: {
    recommendations: undefined,
    status: ComparatorLoadingState.notLoaded,
    hasAccountOfThisType: undefined
  },
  checking: {
    recommendations: undefined,
    status: ComparatorLoadingState.notLoaded,
    hasAccountOfThisType: undefined
  },
  roboAdvisor: {
    recommendations: undefined,
    status: ComparatorLoadingState.notLoaded,
    hasAccountOfThisType: undefined
  }
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case GET_CREDIT_CARD_RECOMMENDATION:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          recommendations: undefined,
          status: ComparatorLoadingState.loading
        }
      };
    case GET_CREDIT_CARD_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          recommendations: action.payload,
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_CREDIT_CARD_RECOMMENDATION_FAILED:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          recommendations: [],
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_SAVING_RECOMMENDATION:
      return {
        ...state,
        saving: {
          ...state.saving,
          recommendations: undefined,
          status: ComparatorLoadingState.loading
        }
      };
    case GET_SAVING_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        saving: {
          ...state.saving,
          recommendations: action.payload,
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_SAVING_RECOMMENDATION_FAILED:
      return {
        ...state,
        saving: {
          ...state.saving,
          recommendations: [],
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_CHECKING_RECOMMENDATION:
      return {
        ...state,
        checking: {
          ...state.checking,
          recommendations: undefined,
          status: ComparatorLoadingState.loading
        }
      };
    case GET_CHECKING_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        checking: {
          ...state.checking,
          recommendations: action.payload,
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_CHECKING_RECOMMENDATION_FAILED:
      return {
        ...state,
        checking: {
          ...state.checking,
          recommendations: [],
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_ROBO_ADVISOR_RECOMMENDATION:
      return {
        ...state,
        roboAdvisor: {
          ...state.roboAdvisor,
          recommendations: undefined,
          status: ComparatorLoadingState.loading
        }
      };
    case GET_ROBO_ADVISOR_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        roboAdvisor: {
          ...state.roboAdvisor,
          recommendations: action.payload,
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_ROBO_ADVISOR_RECOMMENDATION_FAILED:
      return {
        ...state,
        roboAdvisor: {
          ...state.roboAdvisor,
          recommendations: [],
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_BROKER_RECOMMENDATION:
      return {
        ...state,
        broker: {
          ...state.broker,
          recommendations: undefined,
          status: ComparatorLoadingState.loading
        }
      };
    case GET_BROKER_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        broker: {
          ...state.broker,
          recommendations: action.payload,
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_BROKER_RECOMMENDATION_FAILED:
      return {
        ...state,
        broker: {
          ...state.broker,
          recommendations: [],
          status: ComparatorLoadingState.loaded
        }
      };
    case GET_USER_INSTITUTION_SUCCESS: {
      const creditCard = (action.payload as UserInstitution[]).some(i => i.institutionAccountDetailDTOs.some(d => d.accountType === 'CREDIT_CARD'));
      const checking = (action.payload as UserInstitution[]).some(i => i.institutionAccountDetailDTOs.some(d => d.accountType === 'CHECKING'));
      const saving = (action.payload as UserInstitution[]).some(i => i.institutionAccountDetailDTOs.some(d => d.accountType === 'SAVINGS'));
      const broker = (action.payload as UserInstitution[]).some(i => i.institutionSource.description === InstitutionDescription.FULL_SERVICE_BROKER || i.institutionSource.description === InstitutionDescription.ONLINE_BROKERAGE);
      const roboAdvisor = (action.payload as UserInstitution[]).some(i => i.institutionSource.description === InstitutionDescription.ROBO_ADVISOR);

      return {
        creditCard: {
          ...state.creditCard,
          hasAccountOfThisType: creditCard
        },
        broker: {
          ...state.broker,
          hasAccountOfThisType: broker
        },
        saving: {
          ...state.saving,
          hasAccountOfThisType: saving
        },
        checking: {
          ...state.checking,
          hasAccountOfThisType: checking
        },
        roboAdvisor: {
          ...state.roboAdvisor,
          hasAccountOfThisType: roboAdvisor
        }
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
