import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import fr from './locales/fr';
import { enCA, frCA } from 'date-fns/esm/locale';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import _ from 'lodash';
require('dayjs/locale/fr');

dayjs.extend(localizedFormat);
dayjs.extend(dayOfYear);
dayjs.extend(isBetween);

const language = /^en\b/.test(navigator.language) ? 'en' : 'fr';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: language,
    resources: {
      en: en,
      fr: fr
    }
  });

export const changeLanguage = (langKey: string) => {
  i18n.changeLanguage(langKey);
  dayjs.locale(langKey);
};

export const stringToDate = (date: string) => {
  const str = date;
  const [year, month, day] = str.split('-');
  const minDate = new Date(+year, +month - 1, +day);
  return minDate;
};

export const formatDateMY = (date: Date | string | Dayjs) => {
  return formatDate(date, 'MMM yyyy');
};

export const formatDateDMY = (date: Date | string | Dayjs) => {
  return formatDate(date, 'd MMM yyyy');
};

export const formatDate = (date: Date | string | Dayjs, dateFormat: string) => {
  if (date) {
    return format(dayjs(date).toDate(), dateFormat, { locale: i18n.language === 'en' ? enCA : frCA }) || '';
  } else {
    return '';
  }
};

export const formatDay = (date: Date | string | Dayjs) => {
  return dayjs(date).format('ll');
};

export const isTextPresent = (value: string) => {
  return (en.translation as {
        [key: string]: number | string,
    })[value];
};

export const formatCurrency = (value: number, decimals = 2, currency = 'CAD') => {
  const result = new Intl.NumberFormat(i18n.language === 'en' ? 'en-CA' : 'fr-CA', {
    currency: currency,
    style: 'currency',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value) || 0;
  return _.isNaN(value) ? 0 : result;
};

export const formatAmount = (value: number, decimals = 2) => {
  return new Intl.NumberFormat(i18n.language === 'en' ? 'en-CA' : 'fr-CA', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value);
};

export default i18n;
