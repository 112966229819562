import React, { useEffect, useState } from 'react';
import ModalState from '../model/ModalState';
import { isTextPresent } from '../i18n';
import { HardbaconButton, HardbaconInput } from './index';
import AccountType from '../model/AccountType';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReducerInstitutionState from '../model/ReducerInstitutionState';
import InstitutionSource from '../model/InstitutionSource';
import { getIconForInstitution, groupBy } from '../utils';
import {
  addExistingInstitution,
  addInstitution,
  clearAddInstitution,
  clearErrorInstitution,
  clearPollInstitution,
  disconnectInstitution,
  getInstitutions,
  pollInstitution,
  sendSecurityAnswer,
  syncInstitution
} from '../actions/UserInstitution';
import { getAvailableInstitutionSource } from '../actions/Institution';
import { usePrevious } from '../hooks';
import { bankSecurityLock, greenCheck, institution, leftArrow, loadingPiggyBank, lookup } from '../assets/images';
import ReducerUserInstitutionState from '../model/ReducerUserInstitutionState';
import UserInstitution from '../model/UserInstitution';
import InstitutionDescription from '../model/InstitutionDescription';
import { MXConnectWidget } from './MXConnectWidget';
import { showSupport } from '../actions/Support';
import styles from './../assets/styles/modules/rightPanel.module.css';
import EditManualAccount from './EditManualAccount';
import RightPanel from './RightPanel';
import { PlaidConnectWidget } from './PlaidConnectWidget';

const CREDENTIALS_ORDER: { [ key: string ]: number } = {
  username: 0,
  employer: 1,
  code: 2,
  query_id: 3,
  token: 4,
  loginId: 5,
  LOGIN: 6,
  password: 7,
  PASSWORD: 8,
  _key: 9,
  secret: 10
};

export interface AddAccountParameter {
  addInstitutionOnOpening?: boolean,
  institutionSourceToAddOnOpening?: InstitutionSource,
  institutionCredentialToAddOnOpening?: object,
  createManualAccount?: boolean
}

type AddAccountRightPanelProps = {
  visible?: boolean;
  parameters?: AddAccountParameter;
  onClose?: Function;
};

export const AddAccountRightPanel = (props: AddAccountRightPanelProps) => {
  const [modalState, setModalState] = useState(ModalState.closed);
  const [showSecurityQuestionFromFail, setShowSecurityQuestionFromFail] = useState(false);
  const [accountType, setAccountType] = useState<AccountType>();
  const [selectedInstitutionSourceGroup, setSelectedInstitutionSourceGroup] = useState(undefined);
  const [selectedInstitutionSource, setSelectedInstitutionSource] = useState<InstitutionSource>();
  const [searchText, setSearchText] = useState('');
  const [credentials, setCredentials] = useState<Map<string, string>>(new Map<string, string>());
  const [securityAnswer, setSecurityAnswer] = useState<string>('');
  const [isAddManualAccount, setIsAddManualAccount] = useState<boolean>(false);

  const { authenticated } = useSelector((state: any) => state.session);
  const institutionState: ReducerInstitutionState = useSelector((state: any) => state.institution);
  const userInstitutionState: ReducerUserInstitutionState = useSelector((state: any) => state.userInstitution);
  const previousUserInstitution = usePrevious<ReducerUserInstitutionState>(userInstitutionState);

  const dispatch = useDispatch();
  const { t } = useTranslation('manageAccounts');

  // timeout for fetching institution
  let timeoutPoll: NodeJS.Timeout;

  useEffect(() => {
    if (authenticated && institutionState.availableInstitution.length === 0) {
      dispatch(getAvailableInstitutionSource());
    }
  }, [authenticated]);

  useEffect(() => {
    if (modalState === ModalState.closed && isAddManualAccount) {
      setIsAddManualAccount(false);
    }
  }, [modalState]);

  useEffect(() => {
    if (props.parameters?.createManualAccount) {
      setAccountType(AccountType.banking);
      setIsAddManualAccount(true);
    }
  }, [props.parameters?.createManualAccount]);

  useEffect(() => {
    if (props.visible) {
      show(props.parameters);
    } else {
      hide();
    }
  }, [props.visible, props.parameters]);

  useEffect(() => {
    setCredentials(new Map<string, string>());
    setSecurityAnswer('');
  }, [selectedInstitutionSource]);

  useEffect(() => {
    if (previousUserInstitution?.lastInstitutionAdded === undefined && userInstitutionState.lastInstitutionAdded) {
      dispatch(pollInstitution(userInstitutionState.lastInstitutionAdded.id, userInstitutionState.lastInstitutionAdded.institutionSource.id));
    }
  }, [userInstitutionState.lastInstitutionAdded]);

  useEffect(() => {
    if (!userInstitutionState.pollingInstitution) {
      return;
    }
    if (userInstitutionState.pollingInstitution.syncStatus === 'syncing') {
      if (timeoutPoll !== undefined) {
        clearInterval(timeoutPoll);
      }
      timeoutPoll = setTimeout(() => {
        if (userInstitutionState.pollingInstitution) {
          dispatch(pollInstitution(userInstitutionState.pollingInstitution.id, userInstitutionState.pollingInstitution.institutionSource.id));
        }
      }, 10000);
    } else if (userInstitutionState.pollingInstitution.syncStatus === 'ok' || userInstitutionState.pollingInstitution.syncStatus === 'error') {
      dispatch(getInstitutions());
      setShowSecurityQuestionFromFail(false);
    }
  }, [userInstitutionState.pollingInstitution]);

  const show = (parameter?: AddAccountParameter) => {
    if (parameter && parameter.addInstitutionOnOpening) {
      dispatch(addInstitution(parameter.institutionSourceToAddOnOpening!.name, parameter.institutionCredentialToAddOnOpening!, parameter.institutionSourceToAddOnOpening!, userInstitutionState.pollingInstitution));
    }

    setModalState(ModalState.opening);
    setTimeout(() => {
      setModalState(ModalState.opened);
    }, 500);
  };

  const hide = () => {
    setModalState(ModalState.closing);
    setTimeout(() => {
      setCredentials(new Map<string, string>());
      setSecurityAnswer('');
      clearInterval(timeoutPoll);
      dispatch(clearPollInstitution());
      dispatch(clearAddInstitution());
      setModalState(ModalState.closed);
      setShowSecurityQuestionFromFail(false);
      setAccountType(undefined);
      setSelectedInstitutionSourceGroup(undefined);
      setSelectedInstitutionSource(undefined);
      setSearchText('');
    }, 500);
  };

  const selectInstitutionSource = (institutionSource: InstitutionSource) => {
    setSelectedInstitutionSource(institutionSource);
  };

  const renderNotFoundInstitution = () => {
    return (
        <div
            onClick={(e) => {
              dispatch(showSupport(true));
            }}
            className={'institution-item not-found'}>
          <img alt={'insitution not found'} className={'institution-item-img'}
               src={institution}/>
          <div className={'not-found-text'}>{t('institutionNotFound')}</div>
          <div className={'not-found-link'}>{t('institutionNotFoundLink')}</div>
        </div>
    );
  };

  const renderSelectInstitution = () => {
    const { availableInstitution } = institutionState;
    let isInsideInstitutionSourceGroup = false;
    let filteredAvailableInstitution: any = selectedInstitutionSourceGroup !== undefined
      ? selectedInstitutionSourceGroup
      : availableInstitution.filter(item => accountType === AccountType.banking ? item.description === InstitutionDescription.BANKING : item.description !== InstitutionDescription.BANKING);
    if (accountType === AccountType.investment && selectedInstitutionSourceGroup === undefined) {
      filteredAvailableInstitution = groupBy(filteredAvailableInstitution!, (item: InstitutionSource) => (item as InstitutionSource).groupName).sort((a: any, b: any) => {
        let nameA: string;
        let nameB: string;

        if (a.length === undefined) {
          nameA = a.name;
        } else {
          nameA = t(`Group_${a[0].groupName}`);
        }

        if (b.length === undefined) {
          nameB = b.name;
        } else {
          nameB = t(`Group_${b[0].groupName}`);
        }

        if ((a.length === undefined && b.length === undefined) || (a.length !== undefined && b.length !== undefined)) {
          return nameA.localeCompare(nameB);
        } else {
          if (a.length !== undefined) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    } else {
      isInsideInstitutionSourceGroup = accountType === AccountType.investment && selectedInstitutionSourceGroup !== undefined;

      filteredAvailableInstitution.sort((a: any, b: any) => a.name.localeCompare(b.name));
    }

    // Filter with search text
    if (searchText) {
      filteredAvailableInstitution = filteredAvailableInstitution.filter((item: any) => {
        let name: string;

        if (item.length === undefined) {
          name = item.name;
        } else {
          name = t(`Group_${item[0].groupName}`);
        }

        return name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return (
      <div className={'flex items-center flex-col flex-1 h-full space-y-2'}>
        <div className={'flex w-full px-4 sm:px-6'}>
          <div className={'flex-1 flex shadow-lg border border-gray-200 rounded h-12 px-4 items-center'}>
            <img alt={'search'} src={lookup} className={'h-4 w-4 object-contain'}/>
            <input
                placeholder={t('searchInstitution')}
                name={'search'}
                value={searchText || ''}
                className={'ml-2 font-gilmerMedium text-gray-dark text-sm w-full placeholder-gray-400'}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
            />
            <button
                onClick={() => setSearchText('')}
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 outline-none">
              <span className="sr-only">Close panel</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
        <div
             className={'flex w-full items-center pb-24 ' + (isInsideInstitutionSourceGroup ? 'flex-col px-4 sm:px-6' : 'flex-row flex-wrap justify-center overflow-x-hidden')}>
       {accountType === AccountType.banking && <HardbaconButton onPress={() => setIsAddManualAccount(true)} className={styles['float-button']}
        title={t('manageAccounts:addManualAccount')} name={t('manageAccounts:addManualAccount')}/>}
          {filteredAvailableInstitution!.map((anyItem: any) => {
            let icon: string | undefined;
            let name: string | undefined;
            let institutionSource: any;
            // Check if anyItem is an array of InstitutionSource (for group) or a single InstitutionSource

            if (anyItem.length === undefined) {
              const item = anyItem as InstitutionSource;
              institutionSource = item;
              icon = getIconForInstitution(item.iconName);
              name = item.name;
            } else {
              const item = (anyItem as InstitutionSource[])[0];
              institutionSource = anyItem as InstitutionSource[];
              icon = getIconForInstitution(item.iconName);
              name = t(`Group_${item.groupName}`);
            }
            if (!isInsideInstitutionSourceGroup) {
              return (
                  <div
                      key={name}
                      onClick={(e) => {
                        if (institutionSource.length === undefined) {
                          selectInstitutionSource(institutionSource);
                        } else {
                          setSelectedInstitutionSourceGroup(institutionSource);
                        }
                      }}
                      className={'institution-item'}>
                    <img alt={'insitutionSource'} className={'institution-item-img'}
                         src={icon}/>
                    <span className={'institution-name'}>{name}</span>
                  </div>
              );
            } else {
              return (
                  <div
                      key={name}
                      onClick={(e) => {
                        selectInstitutionSource(institutionSource);
                      }}
                      className={'w-full m-4 border shadow-md rounded items-center flex flex flex-row px-4 py-4 cursor-pointer button-hover'}>
                    <img alt={`insitution ${name}`} className={'h-8 w-8 object-contain'} src={icon}/>
                    <span ref={ref => {
                      if (ref) {
                        ref.style.setProperty('line-height', '1.2', 'important');
                      }
                    }} className={'text-sm text-black font-gilmerBold ml-4'}>{name}</span>
                  </div>
              );
            }
          })}
          {!isInsideInstitutionSourceGroup && renderNotFoundInstitution()}
        </div>
      </div>
    );
  };

  const addAnInstitution = (selectedInstitutionSource?: InstitutionSource, institutionId?: string, _credentials?: Map<string, string>) => {
    if (selectedInstitutionSource === undefined) {
      return;
    }
    // If the institution was already added (ie MX Connect Widget), we already have an institutionId
    if (institutionId !== undefined) {
      dispatch(addExistingInstitution(selectedInstitutionSource, institutionId));
      return;
    }

    let allInputsAreValid: undefined | boolean;
    selectedInstitutionSource.credentials!.forEach(key => {
      const input = _credentials?.get(key);
      const isInputValid = !!input;
      if (allInputsAreValid === undefined) {
        allInputsAreValid = isInputValid;
      } else {
        allInputsAreValid = allInputsAreValid && isInputValid;
      }
    });

    if (allInputsAreValid) {
      // If pollingInstitution is not undefined, then an update will be called instead of a create
      const credentialsAsObject = {};
      _credentials?.forEach(function (val, key) {
        // @ts-ignore
        credentialsAsObject[key] = val;
      });
      dispatch(addInstitution(selectedInstitutionSource.name, credentialsAsObject, selectedInstitutionSource, userInstitutionState.pollingInstitution));
    }
  };

  const renderPlaidWidget = () => {
    return (
      <PlaidConnectWidget
        institutionId={userInstitutionState.pollingInstitution && userInstitutionState.pollingInstitution.id}
        onInstitutionAdded={(institutionProviderCode: string, publicToken: string) => {
          const is = { providerCode: institutionProviderCode, credentials: ['token'], dataSource: 'PLAID' } as InstitutionSource;
          const tokenCredential = new Map();
          tokenCredential.set('token', publicToken);
          setCredentials(tokenCredential);
          addAnInstitution(is, undefined, tokenCredential);
        }}
        onConnectSuccess={(institutionProviderCode: string, institutionId: string) => {
          const is = userInstitutionState.pollingInstitution?.institutionSource;
          dispatch(pollInstitution(institutionId, is!.id));
        }}
      onExit={success => {
        hide();
      }}/>
    );
  };

  const renderFormConnection = () => {
    const icon = getIconForInstitution(selectedInstitutionSource!.iconName);
    const name = selectedInstitutionSource!.name;

    if (selectedInstitutionSource!.code === 'wealthsimple' || selectedInstitutionSource!.code === 'wealthsimpletrade' || selectedInstitutionSource!.code === 'questrade') {
      const wealthicaStateParam = {
        sub: process.env.REACT_APP_WEALTHICA_SUB,
        path: '/accounts',
        institution: selectedInstitutionSource!.code
      };

      const jsonEncodeWealthicaState = JSON.stringify(wealthicaStateParam);
      const base64EncodeWealthicaState = btoa(jsonEncodeWealthicaState);
      const urlEncodeWealthicaState = encodeURIComponent(base64EncodeWealthicaState);

      if (selectedInstitutionSource!.code === 'wealthsimpletrade') {
        window.location.href = `https://my.wealthsimple.com/oauth/authorize?client_id=7f3b10301c7ed28baed03ccb7dc57d37c336fce47c5ec34dfb7efc21a005aa3e&response_type=code&state=${urlEncodeWealthicaState}&redirect_uri=https://api.wealthica.com/oauth/redirect&scope=trade.read`;
      } else if (selectedInstitutionSource!.code === 'wealthsimple') {
        window.location.href = `https://my.wealthsimple.com/oauth/authorize?client_id=7f3b10301c7ed28baed03ccb7dc57d37c336fce47c5ec34dfb7efc21a005aa3e&response_type=code&state=${urlEncodeWealthicaState}&redirect_uri=https://api.wealthica.com/oauth/redirect`;
      } else if (selectedInstitutionSource!.code === 'questrade') {
        window.location.href = `https://login.questrade.com/oauth2/authorize?client_id=SWRZmAVjbFp9SmOYhAlA2tqas6YYTF&response_type=code&state=${urlEncodeWealthicaState}&redirect_uri=https://api.wealthica.com/oauth/redirect`;
      }

      return <form className={'flex justify-center items-center flex-col flex-1 h-full space-y-2 px-4 sm:px-6'}>
        <div className={'w-full flex ml-8 mb-2'}>
          <img onClick={(e) => {
            setSelectedInstitutionSource(undefined);
            setSelectedInstitutionSourceGroup(undefined);
          }} src={leftArrow} style={{ marginTop: -40 }}
               className={'h-4 w-6 absolute object-contain button-hover cursor-pointer'} alt={'back'}/>
        </div>
        <div className={'flex flex-col flex-wrap w-full h-full justify-center items-center'}>
          <span
              className={'text-xl leading-5 font-gilmerBold text-black text-center mx-4'}>{t('connexionInsideOtherWebsite')}</span>
          <span
              className={'text-lg mt-2 leading-5 font-gilmerMedium text-gray-dark text-center mx-8'}>{t('connexionInsideOtherWebsiteDescription')}</span>
        </div>
      </form>;
    }

    if (selectedInstitutionSource?.dataSource === 'MX') {
      return <MXConnectWidget
          institutionSourceId={selectedInstitutionSource.id}
          institutionId={userInstitutionState.pollingInstitution && userInstitutionState.pollingInstitution.id}
          onInstitutionAdded={(memberGuid: string) => addAnInstitution(selectedInstitutionSource, memberGuid)}
          onConnectSuccess={(memberGuid: string) => dispatch(pollInstitution(memberGuid, selectedInstitutionSource.id))}
          onDelete={(memberGuid: string) => dispatch(disconnectInstitution(memberGuid, selectedInstitutionSource.id))} />;
    }

    return <form onSubmit={(e) => {
      addAnInstitution(selectedInstitutionSource, undefined, credentials);
      e.preventDefault();
    }} className={'flex justify-center items-center flex-col flex-1 h-min-full space-y-2 px-4 sm:px-6'}>
      <div className={'w-full flex ml-8 mb-2'}>
        <img onClick={(e) => {
          setSelectedInstitutionSource(undefined);
          setSelectedInstitutionSourceGroup(undefined);
        }} src={leftArrow} style={{ marginTop: -40 }}
             className={'h-4 w-6 absolute object-contain button-hover cursor-pointer'} alt={'back'}/>
      </div>
      <div className={'flex flex-col flex-wrap w-full h-full justify-center items-center'}>
        <img alt={'institutionSource'} src={icon} className={'w-24 h-24 object-contain'}/>
        <span className={'text-black text-md font-gilmerBold mt-8'}>{name}</span>
        {selectedInstitutionSource?.credentials!.sort((a, b) => CREDENTIALS_ORDER[a] - CREDENTIALS_ORDER[b]).map(item => {
          // Exception for the ComputerShare 'username' field
          const translationText = selectedInstitutionSource!.name.toLowerCase().includes('computershare') && item.toLowerCase() === 'username'
            ? `computershareemp_${item.toLowerCase()}`
            : item;

          const placeholder = translationText === 'LOGIN' && selectedInstitutionSource!.dataSource === 'MX' ? isTextPresent(`LoginIdentifier_${selectedInstitutionSource!.code}`) ? t(`LoginIdentifier_${selectedInstitutionSource!.code}`) : t(translationText) : t(translationText);

          return <div key={item} className={'mt-4 w-full'}>
            <HardbaconInput
                optional={false}
                onChange={(value) => {
                  if (value && value.trim() !== '') {
                    credentials.set(item as string, value);
                  } else {
                    credentials.delete(item as string);
                  }
                }}
                type={item.toLowerCase() === 'password' || item.toLowerCase() === 'secret' ? 'password' : 'text'}
                name={item.toLowerCase()}
                placeholder={placeholder}
            />
          </div>;
        })}
        <HardbaconButton
            type={'submit'}
            loading={userInstitutionState.addingInstitution}
            className={'mt-4 w-full'}
            title={t('next')}
            name={'addAccount'}/>
        <div className={'flex mt-4 w-full justify-center'}>
          <img src={bankSecurityLock} className={'h-4 w-4 object-contain mr-1'} alt="bank-level-security"/>
          <a href={t('manageAccounts:bankLevelSecurityLink')} target={'_blank'} rel="noreferrer"
             className={'text-sm font-gilmerMedium text-gray-dark'}>{t('manageAccounts:bankLevelSecurity')}</a>
        </div>
        <span onClick={(e) => window.open(t('securityLink'), '_blank', 'noreferrer noopener')}
              className={'text-sm font-gilmerMedium text-hardbacon mt-4 cursor-pointer button-hover'}>{t('securityFAQ')}</span>
        <span
            className={'text-sm font-gilmerRegular text-gray-medium mt-8 text-center'}>{t(`Partner_${selectedInstitutionSource!.dataSource}`)}</span>
      </div>
    </form>;
  };

  const renderPolling = () => {
    const { pollingInstitution, isPollingInstitution } = userInstitutionState;

    return <div className={'flex justify-center items-center flex-col flex-1 h-full space-y-2'}>
      {isPollingInstitution || pollingInstitution?.syncStatus === 'syncing'
        ? <img src={loadingPiggyBank} alt={'loading'} className={'h-36 w-36 object-contain'}/>
        : <img src={greenCheck} alt={'check'} className={'h-16 w-16 object-contain'}/>}
      <span
          className={'text-lg font-gilmerBold text-black mt-2'}>{isPollingInstitution || pollingInstitution?.syncStatus === 'syncing' ? t('polling') : t('pollingDone')}</span>
      <svg className={'spinner-hardbacon mx-auto'} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle className={'spinner-hardbacon-circle'} cx="50" cy="50" r="45"/>
      </svg>
      {pollingInstitution?.syncStatus === 'ok' &&
      <HardbaconButton title={t('close')} name={'close'} onPress={() => {
        if (props.onClose) {
          props.onClose();
        } else {
          hide();
        }
      }}/>}
    </div>;
  };

  const sendASecurityAnswer = (pollingInstitution: UserInstitution, response: string) => {
    dispatch(sendSecurityAnswer(pollingInstitution.name, response, pollingInstitution.id, pollingInstitution.institutionSource.id));
  };

  const renderError = () => {
    const {
      pollingInstitutionFailed,
      pollingInstitution,
      lastInstitutionAddFailed,
      lastInstitutionAddError
    } = userInstitutionState;

    if (pollingInstitution?.institutionSource.dataSource === 'MX') {
      return <MXConnectWidget
          institutionSourceId={pollingInstitution.institutionSource.id}
          institutionId={pollingInstitution.id}
          onConnectSuccess={(memberGuid: string) => dispatch(pollInstitution(memberGuid, pollingInstitution.institutionSource.id))}
          onDelete={(memberGuid: string) => dispatch(disconnectInstitution(memberGuid, pollingInstitution.institutionSource.id))} />;
    } else if (pollingInstitution?.institutionSource.dataSource === 'PLAID') {
      return renderPlaidWidget();
    }

    let errorTitle = t('addAccountGenericError');
    let errorSubTitle = t('addAccountGenericErrorDescription');
    let buttonTitle = t('close');

    let action = () => {};

    if (lastInstitutionAddError !== undefined && lastInstitutionAddFailed) {
      errorTitle = lastInstitutionAddError.title;
      buttonTitle = t('retry');
      action = () => {
        dispatch(clearErrorInstitution());
      };
    } else if (pollingInstitutionFailed || pollingInstitution?.syncError) {
      const syncErrorName = pollingInstitution?.syncError.name;
      if (syncErrorName === 'LoginFailedError' || (pollingInstitutionFailed && !lastInstitutionAddFailed)) {
        errorSubTitle = t('addAccountLoginFailedError');
        buttonTitle = t('retry');
        action = () => {
          setAccountType(pollingInstitution?.institutionSource.description === InstitutionDescription.BANKING ? AccountType.banking : AccountType.investment);
          setSelectedInstitutionSource(pollingInstitution?.institutionSource);
          dispatch(clearErrorInstitution());
        };
      } else if (syncErrorName === 'SecurityQuestionError' || (pollingInstitution && pollingInstitution.syncError?.message?.length > 0 && syncErrorName === 'SecurityAnswerFailedError' && showSecurityQuestionFromFail)) {
        return <form onSubmit={(e) => {
          sendASecurityAnswer(pollingInstitution!, securityAnswer);
          e.preventDefault();
        }} className={'flex justify-center items-center flex-col flex-1 h-full px-4 sm:px-6'}>
          <img alt={'institutionSource'} src={getIconForInstitution(pollingInstitution!.institutionSource.iconName)}
               className={'w-24 h-24 object-contain mb-2'}/>
          <span
              className={'text-sm text-gray-700 font-gilmerBold mx-8 text-center my-4'}>{t('answerQuestion')}</span>
          <span
              className={'text-xl text-black font-gilmerBold mx-8 text-center'}>{pollingInstitution?.syncError.message}</span>
          <div style={{ maxWidth: 280 }} className={'mt-4 flex flex-col w-full'}>
            {(pollingInstitution?.syncError?.options?.length || 0) <= 1
              ? <div className={'mt-4'}>
                  {pollingInstitution?.syncError?.options?.length === 1
                    ? <div className={'w-full flex items-center justify-center mb-4'}>
                        <img alt={'questionImage'} src={pollingInstitution!.syncError.options[0].data}/>
                      </div>
                    : <div/>}
                  <HardbaconInput
                      optional={false}
                      onChange={(value) => {
                        setSecurityAnswer(value);
                      }}
                      value={securityAnswer}
                      name={'response'}
                  />
                </div>
              : renderOptions(pollingInstitution!, pollingInstitution!.syncError.options)}
            {(pollingInstitution?.syncError?.options?.length || 0) <= 1 &&
            <div className={'flex self-center w-full justify-center items-center mt-4'}>
              <HardbaconButton title={t('send')} name={'addInstitution'} type={'submit'}/>
            </div>}
          </div>
        </form>;
      } else if (syncErrorName === 'SecurityAnswerFailedError') {
        errorSubTitle = t('addAccountSecurityAnswerFailedError');
        buttonTitle = t('retry');
        if (pollingInstitution && pollingInstitution.syncError?.message?.length > 0 && syncErrorName === 'SecurityAnswerFailedError') {
          action = () => setShowSecurityQuestionFromFail(true);
        } else {
          action = () => dispatch(syncInstitution(pollingInstitution!.id, pollingInstitution!.institutionSource.id));
        }
      } else {
        action = () => {
          if (props.onClose) {
            props.onClose();
          } else {
            hide();
          }
        };
        // Handle for error TemporaryFailureError, TimeoutError, ValidationError, ContactInstitutionError, UnableToConnectError
      }
    }

    return <div className={'flex justify-center items-center flex-col flex-1 h-full px-4 sm:px-6'}>
      {pollingInstitution && pollingInstitution.institutionSource &&
      <img alt={'institutionSource'} src={getIconForInstitution(pollingInstitution!.institutionSource.iconName)}
           className={'w-24 h-24 object-contain mb-2'}/>}
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
        <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
        </svg>
      </div>
      <span className={'text-md text-black font-gilmerBold text-center mx-4 mt-2'}>{errorTitle}</span>
      <span className={'text-sm text-gray-dark font-gilmerMedium text-center mx-4 mt-2'}>{errorSubTitle}</span>
      <div className={'mt-8'}>
        <HardbaconButton title={buttonTitle} name={'back'} onPress={action}/>
      </div>
    </div>;
  };

  const renderOptions = (pollingInstitution: UserInstitution, options?: {
    data: string,
    label: string,
    value: string
  }[]) => {
    return <div className={'flex flex-col items-center justify-center'}>
      {options?.map(option => {
        return <div key={option.label} className={'m-2 w-full max-w-xs'}>
          <HardbaconButton className={'w-full'} name={option.label} title={option.label} onPress={() => {
            sendASecurityAnswer(pollingInstitution!, option.value);
          }}/>
        </div>;
      })}
    </div>;
  };

  const getGoBackBehavior = () => {
    if (isAddManualAccount) return () => setIsAddManualAccount(false);
    if (accountType && selectedInstitutionSource === undefined) {
      if (selectedInstitutionSourceGroup === undefined) {
        return () => setAccountType(undefined);
      } else {
        return () => setSelectedInstitutionSourceGroup(undefined);
      }
    }
    return null;
  };

  const getTitle = () => {
    if (isAddManualAccount) return t('manageAccounts:manualAccount');
    if (accountType && selectedInstitutionSource === undefined) return t('manageAccounts:addAccount');
    return '';
  };

  if (modalState === ModalState.closed) {
    return <></>;
  }

  let contentToShow: any;

  if (userInstitutionState.lastInstitutionAddFailed || userInstitutionState.pollingInstitution?.syncStatus === 'error' || userInstitutionState.pollingInstitutionFailed) {
    contentToShow = renderError();
  } else {
    if (userInstitutionState.isPollingInstitution || userInstitutionState.pollingInstitution?.syncStatus === 'ok' || userInstitutionState.pollingInstitution?.syncStatus === 'syncing') {
      contentToShow = renderPolling();
    } else {
      if (!accountType) {
        contentToShow = props.visible ? renderPlaidWidget() : null;
      } else {
        if (accountType === AccountType.banking) {
          // Override everything with plaid if the user selects "banking"
          contentToShow = renderPlaidWidget();
        } else {
          if (selectedInstitutionSource === undefined) {
            contentToShow = renderSelectInstitution();
          } else {
            contentToShow = renderFormConnection();
          }
        }
      }
    }
  }

  return (
    <RightPanel
      title={getTitle()}
      visible={!!props.visible}
      onClose={() => {
        if (props.onClose) {
          props.onClose();
        } else {
          hide();
        }
      }}
      goBack={getGoBackBehavior()}
      closeOnClickOutside={!accountType || selectedInstitutionSource === undefined || isAddManualAccount}
      renderContent={() => {
        return isAddManualAccount
          ? (
            <EditManualAccount
            onClose={() => {
              if (props.onClose) {
                props.onClose();
              } else {
                hide();
              }
            }}
            />
            )
          : contentToShow;
      }}
    />
  );
};
