import React, { useState, useEffect, useReducer } from 'react';
import styles from './../assets/styles/modules/EditManualAccount.module.css';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import HardbaconButton from './HardbaconButton';
import FormItemContainer, { FormInputEmun } from './FormItemContainer';
import { formatCurrency, formatDateDMY, stringToDate } from '../i18n';
import Calendar from 'react-calendar';
import {
  currencyIcon,
  initalBalanceIcon,
  initalDateIcon,
  accountTypeIcon,
  accountNameIcon,
  removeIcon
} from '../assets/images';
import AccountTypeSelector from './AccountTypeSelector';
import { createManualAccount } from '../actions/UserInstitution';
import colors from '../colors/colors';
import InstitutionAccountDetail from '../model/InstitutionAccountDetail';

interface EditManualAccountProps {
  visible?: boolean;
  onClose: Function;
  account?: InstitutionAccountDetail;
  setDeleteBankAccountOpen?: Function;
  onAccountUpdate?: Function;
}

interface FormState {
    name: string;
    type: { main: string, sub: string };
    balance: number;
    date: Date;
    currency: string
}

type FormAction =
 | { type: 'name'; payload: string }
 | { type: 'type'; payload: {
     main: string,
     sub: string
 } }
 | { type: 'balance'; payload: number }
 | { type: 'date'; payload: Date }
 | { type: 'currency'; payload: string };

const EditManualAccount = ({ onClose, account, setDeleteBankAccountOpen, onAccountUpdate }: EditManualAccountProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const lang = i18n.language;

  const isEdit = !_.isEmpty(account);

  const initialState: FormState = {
    name: '',
    type: {
      main: 'CHECKING',
      sub: 'UNKNOWN'
    },
    balance: 0,
    date: new Date(),
    currency: 'CAD'
  };

  const reducer = (state: FormState, action: FormAction): FormState => {
    switch (action.type) {
      case 'name': {
        return { ...state, name: action.payload };
      }
      case 'type': {
        return { ...state, type: action.payload };
      }
      case 'balance': {
        return { ...state, balance: action.payload };
      }
      case 'date': {
        return { ...state, date: action.payload };
      }
      case 'currency': {
        return { ...state, currency: action.payload };
      }
      default:
        return initialState;
    }
  };

  const [formState, dispatchForm] = useReducer(reducer, initialState);
  const [isCurrencySelectorOpen, setIsCurrencySelectorOpen] = useState(false);
  const [isAccountTypeSelectorOpen, setIsAccountTypeSelectorOpen] = useState(false);
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);

  useEffect(() => {
    if (isEdit && account) {
      dispatchForm({ type: 'name', payload: account.name });
      dispatchForm({ type: 'type', payload: { main: account.accountType, sub: account.accountSubType } });
      dispatchForm({ type: 'currency', payload: account.currency });
      dispatchForm({ type: 'balance', payload: account.value });
      dispatchForm({ type: 'date', payload: stringToDate(account.openingDate) });
    }
  }, [account]);

  const getAccountType = () => {
    const main = t(`manageAccounts:AccountType_${formState.type.main}`);
    return main;
  };

  const onSubmit = () => {
    if (isEdit && onAccountUpdate) {
      const newAccount = {
        ...account,
        name: formState.name,
        accountSubType: formState.type.sub,
        accountType: formState.type.main
      };
      onAccountUpdate(newAccount);
    } else {
      dispatch(createManualAccount(
        formState.name,
        formState.type.main,
        formState.type.sub,
        formState.currency,
        formState.date,
        formState.balance > 0 ? formState.balance : 0
      ));
    }
    onClose();
  };

  const onDeleteButtonPress = () => {
    setDeleteBankAccountOpen && setDeleteBankAccountOpen();
  };

  const renderContent = () => {
    if (isCurrencySelectorOpen) {
      return (
            <div className={styles['button-group']}>
            <HardbaconButton
                className={styles.button}
                name={'accept'}
                onPress={() => {
                  dispatchForm({ type: 'currency', payload: 'CAD' });
                  setIsCurrencySelectorOpen(false);
                }}
                type="button"
                title={'CAD'}
            />
            <HardbaconButton
                className={styles.button}
                name={'accept'}
                onPress={() => {
                  dispatchForm({ type: 'currency', payload: 'USD' });
                  setIsCurrencySelectorOpen(false);
                }}
                type="button"
                title={'USD'}
            />
            </div>
      );
    }

    if (isAccountTypeSelectorOpen) {
      return (
            <div className={styles['button-group']}>
                <AccountTypeSelector showTypeSelector={false} onAccountTypeChange={(main: string, sub: string) => {
                  dispatchForm({ type: 'type', payload: { main: main, sub: sub } });
                  setIsAccountTypeSelectorOpen(false);
                }}/>
            </div>
      );
    }

    return (
        <div className={styles.form}>
            {formState.name
              ? <p className={styles.title}>{formState.name}</p>
              : (
                <p className={styles['title-empty']}>{t('manageAccounts:accountName')}</p>
                )}
            <p className={styles.amount + ' ' + (Math.abs(formState.balance) > 0 ? (formState.balance < 0 ? styles['negative-amount'] : styles['positive-amount']) : '')}>
                {(formState.balance < 0 ? '- ' : '+ ') + formatCurrency(Math.abs(formState.balance), 0, formState.currency)}
            </p>
            <FormItemContainer
                id='accountName'
                tooltip={t('tooltips:accountName')}
                icon={accountNameIcon}
                placeholder={t('manageAccounts:accountName')}
                left={t('manageAccounts:accountName')}
                right={FormInputEmun.stringInput}
                value={formState.name}
                onChange={(v: string) => dispatchForm({ type: 'name', payload: v })}
            />
            <FormItemContainer
                id='accountType'
                tooltip={t('tooltips:accountType')}
                icon={accountTypeIcon}
                onPress={() => setIsAccountTypeSelectorOpen(true)}
                left={t('manageAccounts:accountType')}
                right={FormInputEmun.stringInput}
                value={getAccountType()}
            />
            <FormItemContainer
                id='initialBalance'
                tooltip={t('tooltips:accountBalance')}
                icon={initalBalanceIcon}
                allowNegativeValue={true}
                left={t('manageAccounts:initialBalance')}
                right={FormInputEmun.moneyInput}
                value={formState.balance}
                onChange={isEdit ? undefined : (v: number) => dispatchForm({ type: 'balance', payload: v })}
            />
            <FormItemContainer
                id='initialDate'
                tooltip={t('tooltips:accountDate')}
                icon={initalDateIcon}
                left={t('manageAccounts:initialDate')}
                right={FormInputEmun.stringInput}
                value={formatDateDMY(formState.date)}
                withDivider={!isCalenderOpen}
                onPress={isEdit ? undefined : () => setIsCalenderOpen(!isCalenderOpen)}
            />
            <div className={styles.calendar} style={{ height: isCalenderOpen ? '370px' : '0px', opacity: isCalenderOpen ? '1' : '0' }}>
                <Calendar
                    maxDate={new Date()}
                    locale={lang}
                    onChange={(v: Date) => dispatchForm({ type: 'date', payload: v })}
                    value={formState.date}
                    selectRange={false}
                />
            </div>
            <FormItemContainer
                id='currency'
                icon={currencyIcon}
                tooltip={t('tooltips:accountCurrency')}
                left={t('manageAccounts:currency')}
                right={FormInputEmun.stringInput}
                value={formState.currency}
                onPress={isEdit ? undefined : () => setIsCurrencySelectorOpen(true)}
            />
            {isEdit && <FormItemContainer
                id='delete'
                icon={removeIcon}
                left={<span style={{ color: colors.accentWarning100 }}>{t('delete')}</span>}
                right={<div/>}
                value={formState.currency}
                onPress={onDeleteButtonPress}
            />}
            <HardbaconButton
                disabled={!formState.name}
                name={'accept'}
                className={styles['float-button']}
                type="button"
                title={isEdit ? t('update') : t('create')}
                onPress={onSubmit}
            />
        </div>
    );
  };

  return (
      <div className={styles.container}>
        <div className={styles.content}>
            {renderContent()}
        </div>
      </div>
  );
};

export default EditManualAccount;
