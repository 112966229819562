import React, { useEffect, useState } from 'react';
import {
  brokerRoute,
  checkingRoute,
  creditCardCartTransfer,
  creditCardRoute,
  cryptoRoute,
  mortgageCartTransfer,
  mortgageRoute,
  roboAdvisorRoute,
  savingRoute
} from '../../navigation/Comparator';
import ComparatorType from '../../model/ComparatorType';
import {
  alert,
  brokerComparatorIconActive,
  brokerComparatorIconInactive,
  checkingComparatorIconActive,
  checkingComparatorIconInactive,
  creditcardComparatorIconActive,
  creditcardComparatorIconInactive,
  mortgageComparatorIconActive,
  mortgageComparatorIconInactive,
  roboadvisorComparatorIconActive,
  roboadvisorComparatorIconInactive,
  savingComparatorIconActive,
  savingComparatorIconInactive
} from '../../assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTopLevelCategoriesTransaction } from '../../actions/Banking';

/* eslint-disable no-unused-vars */
enum CompareTab {
  suggested,
  all,
}

export const getSelectedTypeFromPath = (pathname: string) => {
  if ((pathname === creditCardRoute || pathname === creditCardCartTransfer)) {
    return ComparatorType.creditCard;
  } else if (pathname === savingRoute) {
    return ComparatorType.saving;
  } else if (pathname === brokerRoute) {
    return ComparatorType.broker;
  } else if (pathname === roboAdvisorRoute) {
    return ComparatorType.roboAdvisor;
  } else if (pathname === checkingRoute) {
    return ComparatorType.checking;
  } else if ((pathname === mortgageRoute || pathname === mortgageCartTransfer)) {
    return ComparatorType.mortgage;
  } else if ((pathname === cryptoRoute || pathname === cryptoRoute)) {
    return ComparatorType.crypto;
  } else {
    return ComparatorType.creditCard;
  }
};

export const getPathFromSelectedType = (type: ComparatorType) => {
  switch (type) {
    case ComparatorType.saving:
      return savingRoute;
    case ComparatorType.checking:
      return checkingRoute;
    case ComparatorType.broker:
      return brokerRoute;
    case ComparatorType.roboAdvisor:
      return roboAdvisorRoute;
    case ComparatorType.mortgage:
      return mortgageRoute;
    case ComparatorType.crypto:
      return checkingRoute;
    case ComparatorType.creditCard:
    default:
      return creditCardRoute;
  }
};

const CompareHeader = () => {
  const [selectedTab, setSelectedTab] = useState(CompareTab.all);
  const [selectedComparator, setSelectedComparator] = useState<ComparatorType>(ComparatorType.creditCard);
  const [displayAlert, setDisplayAlert] = useState(true);
  const { minifiedSelectedAccountIds } = useSelector((state: any) => state.userInstitution);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['compare']);

  useEffect(() => {
    dispatch(getTopLevelCategoriesTransaction(minifiedSelectedAccountIds));
  }, [dispatch]);

  useEffect(() => {
    const { pathname, hash } = location;
    setSelectedComparator(getSelectedTypeFromPath(pathname));
    if (hash && hash.endsWith(t('suggested'))) {
      setSelectedTab(CompareTab.suggested);
    } else {
      setSelectedTab(CompareTab.all);
    }
  }, [location]);

  return (
    <div className={'compare-header'}>
      <div className={'title-div'}>
        <h1 className={'large-text text-white my-3'}>{t('compare')}</h1>
      </div>
      <div className="md:hidden">
        <select id="selected-tab" name="selected-tab"
                className="form-select selected-tab-div"
                onChange={(e) => {
                  navigate(e.target.value);
                }}
                value={getPathFromSelectedType(selectedComparator)}>
          <option value={checkingRoute}>{t('comparatorOption_CHECKING')}</option>
          <option value={savingRoute}>{t('comparatorOption_SAVING')}</option>
          <option value={creditCardRoute}>{t('comparatorOption_CREDITCARD')}</option>
          <option value={brokerRoute}>{t('comparatorOption_BROKER')}</option>
          <option value={roboAdvisorRoute}>{t('comparatorOption_ROBOADVISOR')}</option>
          <option value={mortgageRoute}>{t('comparatorOption_MORTGAGE')}</option>
          <option value={cryptoRoute}>{t('comparatorOption_CRYPTO')}</option>
        </select>
      </div>
      <div className="hidden md:block mb-4">
        <nav className="flex flex-wrap">
          <button
            onClick={() => navigate(creditCardRoute)}
            className={(selectedComparator === ComparatorType.creditCard ? 'form-tab-selected' : 'form-tab') + ' comparator-type-btn'}
            aria-current={selectedComparator === ComparatorType.creditCard ? 'page' : 'false'}>
            <img className={'comparator-type-img'} src={selectedComparator === ComparatorType.creditCard ? creditcardComparatorIconActive : creditcardComparatorIconInactive} alt={t('comparatorOption_CREDITCARD')}/>
            {t('comparatorOption_CREDITCARD')}
          </button>
          <button
            onClick={() => navigate(checkingRoute)}
            className={(selectedComparator === ComparatorType.checking ? 'form-tab-selected' : 'form-tab') + ' comparator-type-btn'}
            aria-current={selectedComparator === ComparatorType.checking ? 'page' : 'false'}>
            <img className={'comparator-type-img'} src={selectedComparator === ComparatorType.checking ? checkingComparatorIconActive : checkingComparatorIconInactive} alt={t('comparatorOption_CHECKING')}/>
            {t('comparatorOption_CHECKING')}
          </button>
          <button
            onClick={() => navigate(savingRoute)}
            className={(selectedComparator === ComparatorType.saving ? 'form-tab-selected' : 'form-tab') + ' comparator-type-btn'}
            aria-current={selectedComparator === ComparatorType.saving ? 'page' : 'false'}>
            <img className={'comparator-type-img'} src={selectedComparator === ComparatorType.saving ? savingComparatorIconActive : savingComparatorIconInactive} alt={t('comparatorOption_SAVING')}/>
            {t('comparatorOption_SAVING')}
          </button>
          <button
            onClick={() => navigate(brokerRoute)}
            className={(selectedComparator === ComparatorType.broker ? 'form-tab-selected' : 'form-tab') + ' comparator-type-btn'}
            aria-current={selectedComparator === ComparatorType.broker ? 'page' : 'false'}>
            <img className={'comparator-type-img'} src={selectedComparator === ComparatorType.broker ? brokerComparatorIconActive : brokerComparatorIconInactive} alt={t('comparatorOption_BROKER')}/>
            {t('comparatorOption_BROKER')}
          </button>
          <button
            onClick={() => navigate(roboAdvisorRoute)}
            className={(selectedComparator === ComparatorType.roboAdvisor ? 'form-tab-selected' : 'form-tab') + ' comparator-type-btn'}
            aria-current={selectedComparator === ComparatorType.roboAdvisor ? 'page' : 'false'}>
            <img className={'comparator-type-img'} src={selectedComparator === ComparatorType.roboAdvisor ? roboadvisorComparatorIconActive : roboadvisorComparatorIconInactive} alt={t('comparatorOption_ROBOADVISOR')}/>
            {t('comparatorOption_ROBOADVISOR')}
          </button>
          <button
            onClick={() => navigate(mortgageRoute)}
            className={(selectedComparator === ComparatorType.mortgage ? 'form-tab-selected' : 'form-tab') + ' comparator-type-btn'}
            aria-current={selectedComparator === ComparatorType.mortgage ? 'page' : 'false'}>
            <img className={'comparator-type-img'} src={selectedComparator === ComparatorType.mortgage ? mortgageComparatorIconActive : mortgageComparatorIconInactive} alt={t('comparatorOption_MORTGAGE')}/>
            {t('comparatorOption_MORTGAGE')}
          </button>
        </nav>
      </div>
      {(selectedComparator === ComparatorType.creditCard) && (<div className={'selected-tab-div'}>
        <button
          onClick={() => navigate(`#${t('all')}`)}
          className={(selectedTab === CompareTab.all ? 'form-tab-selected' : 'form-tab') + ' comparator-tab-btn'}
          aria-current={selectedTab === CompareTab.all ? 'page' : 'false'}>
          {t('all')}
        </button>
        {selectedComparator === ComparatorType.creditCard && (
          <button
            onClick={() => navigate(`#${t('suggested')}`)}
            className={(selectedTab === CompareTab.suggested ? 'form-tab-selected' : 'form-tab') + ' comparator-tab-btn'}
            aria-current={selectedTab === CompareTab.suggested ? 'page' : 'false'}>
            <div className={'suggested-tab'}>
              <div>{t('suggested')}</div>
              {displayAlert && (<div><img src={alert} className='alert-icon' alt={'no account connected'}/></div>)}
            </div>
          </button>
        )}
      </div>)}
    </div>
  );
};

export default CompareHeader;
