import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatDay } from '../i18n';
import { RightPanel } from './index';
import { fetchNotifications, markNotificationsAsRead } from '../actions/Notification';
import Notification from '../model/Notification';
import NotificationItem from './NotificationItem';
import styles from '../assets/styles/modules/BudgetNotificationsRightPanel.module.css';
import HardbaconButton from './HardbaconButton';
import { budgetRoute } from '../navigation/Banking';

type BudgetNotificationsRightPanelProps = {
  open?: boolean,
  onClose?: () => void;
}

const BudgetNotificationsRightPanel = (props: BudgetNotificationsRightPanelProps) => {
  const [openRightPanel, setOpenRightPanel] = useState(false);
  const divRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation('notifications');
  const navigate = useNavigate();

  const { budgetNotifications, notificationsLoading, notificationsPageNum } = useSelector((state: any) => state.notification);
  const budget = useSelector((state: any) => state.banking.budget);

  useEffect(() => {
    if (!props.open && openRightPanel) {
      // mark all unread displayed elements as read when closing the drawer
      const idsToMarkAsRead = budgetNotifications.filter((n: Notification) => n.unread).map((n: Notification) => n.id);
      if (idsToMarkAsRead && idsToMarkAsRead.length > 0) {
        dispatch(markNotificationsAsRead(idsToMarkAsRead));
      }
    }
    setOpenRightPanel(!!props.open);
  }, [props.open, budgetNotifications]);

  const hide = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      setOpenRightPanel(false);
    }
  };

  const handleScroll = useCallback((e: any) => {
    if (!divRef.current) {
      return;
    }
    const atBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (atBottom && !notificationsLoading) {
      dispatch(fetchNotifications(notificationsPageNum + 1));
    }
  }, [notificationsPageNum, notificationsLoading]);

  // Create budget component to navigate directly to step 2 of onBoarding budget
  const CreateBudgetItem = () => {
    return (
      <div className={styles.createBudgetItem + ' hb-shadow'}>
        <div className={styles.createBudgetEmoji}>🤓</div>
        <div>
          <div className={styles.createBudgetTitle}>{t('createABudgetTitle')}</div>
          <div className={styles.createBudgetDesc}>{t('createABudgetDescription')}</div>
        </div>
        <div>
          <HardbaconButton name={t('createABudgetButton')} title={t('createABudgetButton')} onPress={() => {
            navigate(budgetRoute, { state: { step: 2 } });
            hide();
          }}/>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const notificationsByDate = budgetNotifications.reduce((mapAccumulator: Map<string, Notification[]>, notification: Notification) => {
      const key = dayjs(notification.notificationDateTime).format('YYYY-MM-DD');
      mapAccumulator.set(key, [...mapAccumulator.get(key) ?? [], notification]);
      return mapAccumulator;
    }, new Map<string, Notification[]>()) as Map<string, Notification[]>;
    return (
      <div ref={divRef}>
        {(!budget || budget.length <= 0) && <CreateBudgetItem />}
        {Array.from(notificationsByDate.keys()).map(date =>
          <div key={date}>
            <div className={styles.date}>{formatDay(date)}</div>
            {notificationsByDate.get(date)!.map((notification: Notification) => <NotificationItem key={notification.id} notification={notification} />)}
          </div>
        )}
      </div>
    );
  };

  return (
    <RightPanel
      onScroll={(e) => handleScroll(e)}
      title={t('title')}
      visible={openRightPanel} onClose={() => hide()}
      renderContent={renderContent}
    />
  );
};

export default BudgetNotificationsRightPanel;
