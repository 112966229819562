import React, { useState, useEffect } from 'react';
import styles from './../assets/styles/modules/addCategory.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getTransactionCtgEmojiById } from '../utils/banking';
import { fetchBankingCategories } from '../actions/Banking';
import _ from 'lodash';
import { BankingTransactionCtg, SplitedCategoryType } from '../model/BankingTransaction';
import HardbaconButton from './HardbaconButton';

interface AddCategoryProps {
  onItemPress: Function;
  hasSubCategories?: boolean;
  isSubCategoryOpen?: boolean;
  setIsSubCategoryOpen?: Function;
  minNumOfSelection?: number;
  itemToUpdate?: SplitedCategoryType;
  disabledItems?: SplitedCategoryType[];
  visible?: boolean;
}

const AddCategory = ({ visible, itemToUpdate, disabledItems, onItemPress, hasSubCategories = false, isSubCategoryOpen, setIsSubCategoryOpen, minNumOfSelection = 1 }: AddCategoryProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [subCategories, setSubCategories] = useState<BankingTransactionCtg[]>();
  const [selected, setSelected] = useState<SplitedCategoryType[]>([]);
  const [toUpdate, setToUpdate] = useState<SplitedCategoryType>();
  const { bankingCategories, loadingBankCategories } = useSelector((state: any) => state.banking);
  const isEn = i18n.language !== 'fr';

  useEffect(() => {
    if (!bankingCategories.length && !loadingBankCategories) {
      dispatch(fetchBankingCategories());
    }
  }, [bankingCategories, loadingBankCategories]);

  useEffect(() => {
    if (visible && setIsSubCategoryOpen) {
      setIsSubCategoryOpen(false);
    }
  }, [visible]);

  useEffect(() => {
    if (!isSubCategoryOpen) {
      setSubCategories([]);
    }
  }, [isSubCategoryOpen]);

  useEffect(() => {
    if (itemToUpdate) {
      setToUpdate(itemToUpdate);
    }
  }, [itemToUpdate]);

  useEffect(() => {
    if (disabledItems?.length) {
      setSelected(disabledItems);
    }
  }, [disabledItems]);

  const renderCategory = (cat: BankingTransactionCtg, index?: number) => {
    let numOfSelectedSubs = 0;

    if (!isSubCategoryOpen && hasSubCategories) {
      numOfSelectedSubs = selected.filter((i: SplitedCategoryType) => (i.parent === cat.id || i.id === cat.id)).length;
      if (toUpdate?.parent === cat.id || toUpdate?.id === cat.id) numOfSelectedSubs += 1;
    }

    let isSelected = false;
    if ((toUpdate || minNumOfSelection === 1) && isSubCategoryOpen) {
      isSelected = cat.id === toUpdate?.id || (!isSubCategoryOpen && cat.id === toUpdate?.parent);
    } else {
      isSelected = !_.isEmpty(selected.filter((i: SplitedCategoryType) => i.id === cat.id)) || (numOfSelectedSubs > 0);
    }

    let isDisabled = false;
    if ((toUpdate || minNumOfSelection === 1) && isSubCategoryOpen) {
      isDisabled = !_.isEmpty(selected.filter((i: SplitedCategoryType) => i.id === cat.id)) || (numOfSelectedSubs > 0);
    }
    const name = isEn ? cat.english : cat.french;
    return (
      <div
        className={styles.item}
        onClick={() => {
          const newSelected = selected;
          if (hasSubCategories && !_.isEmpty(cat.subCategories) && _.isEmpty(subCategories)) {
            // open sub category page
            const subData = _.cloneDeep(cat.subCategories);
            subData.push(cat);
            setSubCategories(subData);
            setIsSubCategoryOpen && setIsSubCategoryOpen(true);
          } else {
            if (isDisabled) return false;
            if (toUpdate) {
              // update a category
              newSelected.push({
                id: cat.id,
                amount: toUpdate.amount,
                parent: cat.parent ? cat.parent.id : undefined
              });
              onItemPress(newSelected);
            } else {
              // add one category
              if (minNumOfSelection === 1) {
                if (!_.isEmpty(selected)) {
                  newSelected.push({
                    id: cat.id,
                    amount: 0,
                    parent: cat.parent ? cat.parent.id : undefined
                  });
                  onItemPress(newSelected);
                } else {
                  setSelected([{
                    id: cat.id,
                    amount: 0,
                    parent: cat.parent ? cat.parent.id : undefined
                  }]);
                  if (!hasSubCategories) {
                    onItemPress(cat);
                  }
                }
              } else {
              // add multiple categories
                let newSelected = _.cloneDeep(selected);
                if (!isSelected) {
                  newSelected?.push({
                    id: cat.id,
                    amount: 0,
                    parent: cat.parent ? cat.parent.id : undefined
                  });
                } else {
                  newSelected = newSelected.filter((i: SplitedCategoryType) => i.id !== cat.id);
                }
                setSelected(newSelected);
              }
            }
          }
        }}
        key={cat.id}
      >
        <div className={`${styles['emoji-container']} ${isSelected && styles['selected-container']} ${isDisabled && styles['disabled-container']}`}>
          <span className={styles.emoji}>{getTransactionCtgEmojiById(cat.id)}</span>
          {numOfSelectedSubs && !isDisabled ? <span className={styles.count}>{numOfSelectedSubs}</span> : null}
        </div>
        <p className={`${styles.label} ${isSelected && styles['selected-label']}`}>
          {subCategories?.length && (index === subCategories?.length - 1) ? t('manageAccounts:AccountType_UNKNOWN') : name}
        </p>
      </div>
    );
  };

  const onSubmit = () => {
    const newSelected = selected;
    if (toUpdate) {
      newSelected.push(toUpdate);
    }
    onItemPress(newSelected);
  };
  const renderButton = () => {
    if (!hasSubCategories) {
      return;
    }
    if (itemToUpdate || toUpdate || minNumOfSelection === 1) {
      return;
    }
    return (
      <div className={styles['button-container']}>
        <HardbaconButton
          className={styles.button}
          disabled={selected.length < 2 && !toUpdate && minNumOfSelection !== 1}
          name={'accept'}
          onPress={onSubmit}
          type="button"
          title={t('create')} />
          <p>{selected.length >= 2 || toUpdate || minNumOfSelection === 1 ? ' ' : t('chooseAtLeastTwoCategories')}</p>
      </div>
    );
  };

  return (
      <div style={{ position: 'relative', height: '100vh' }}>
        <div className={styles.container}>
          {!hasSubCategories || _.isEmpty(subCategories)
            ? bankingCategories.map((cat: BankingTransactionCtg) => {
              return renderCategory(cat);
            })
            : !_.isEmpty(subCategories) && subCategories
                ? subCategories.map((cat: BankingTransactionCtg, index: number) => {
                  return renderCategory(cat, index);
                })
                : null}
        </div>
        {renderButton()}
      </div>
  );
};

export default AddCategory;
