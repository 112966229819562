import {
  fetchBankingSubscriptions,
  fetchBankingTips,
  getBankingCategories,
  getBankOverviewService,
  getBudgetCategoriesService,
  getFinancialInfo,
  getTopLevelCategoriesTransactionService,
  setFinancialInfoService,
  updateBulkCategoryBudgetService,
  updateFinancialInfoService
} from '../api/BankingService';
import { Dispatch } from 'redux';
import {
  GET_BANKING_CATEGORIES,
  SET_BANKING_CATEGORIES,
  GET_FINANCIAL_INFO,
  UPDATE_FINANCIAL_INFO,
  UPDATE_CATEGORY_BUDGET_SUCCESS,
  GET_CATEGORY_BUDGET,
  GET_BANK_STATS,
  GET_TOP_LEVEL_BANK_CATEGORIES_SUCCESS,
  GET_TOP_LEVEL_BANK_CATEGORIES,
  GET_CATEGORY_BUDGET_SUCCESS,
  GET_BANK_STATS_SUCCESS,
  GET_BANKING_TIPS,
  GET_BANKING_TIPS_SUCCESS,
  GET_BANKING_SUBSCRIPTIONS,
  GET_BANKING_SUBSCRIPTIONS_SUCCESS,
  GET_FINANCIAL_INFO_SUCCESS,
  UPDATE_FINANCIAL_INFO_SUCCESS,
  SET_FINANCIAL_INFO_SUCCESS,
  UPDATE_BUDGET_DATE_PICKER
} from '../types/Banking';
import _ from 'lodash';

export const fetchBankingCategories = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_BANKING_CATEGORIES });
  getBankingCategories().subscribe(
    (res) => {
      dispatch({ type: SET_BANKING_CATEGORIES, payload: res });
    }
  );
};

export const fetchFinancialInfo = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_FINANCIAL_INFO });
  getFinancialInfo().subscribe(
    (res) => {
      const defaultFinancialInfo = { monthlyEarnings: 0, monthlySavings: 0, payrollFrequencyPerYear: 12 };
      dispatch({ type: GET_FINANCIAL_INFO_SUCCESS, payload: _.isEmpty(res) ? defaultFinancialInfo : res[0] });
    }
  );
};

export const updateFinancialInfo = (param: any) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_FINANCIAL_INFO });
  updateFinancialInfoService(param).subscribe(
    (res) => {
      dispatch({ type: UPDATE_FINANCIAL_INFO_SUCCESS, payload: res });
    }
  );
};

export const setFinancialInfo = (param: any) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_FINANCIAL_INFO });
  setFinancialInfoService(param).subscribe(
    (res) => {
      dispatch({ type: SET_FINANCIAL_INFO_SUCCESS, payload: res });
    }
  );
};

export const updateBulkCategoryBudget = (param: any) => (dispatch: Dispatch) => {
  dispatch({ type: GET_TOP_LEVEL_BANK_CATEGORIES });
  updateBulkCategoryBudgetService(param).subscribe(
    (res) => {
      getTopLevelCategoriesTransactionService([]).subscribe(
        (res) => {
          dispatch({ type: GET_TOP_LEVEL_BANK_CATEGORIES_SUCCESS, payload: res });
        }
      );
      dispatch({ type: UPDATE_CATEGORY_BUDGET_SUCCESS });
    }
  );
};

export const getBudgetCategories = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_CATEGORY_BUDGET });
  getBudgetCategoriesService().subscribe(
    (res) => {
      dispatch({ type: GET_CATEGORY_BUDGET_SUCCESS, payload: res });
    }
  );
};

export const getBankOverview = (account: string[]) => (dispatch: Dispatch) => {
  dispatch({ type: GET_BANK_STATS });
  getBankOverviewService(account).subscribe(
    (res) => {
      dispatch({ type: GET_BANK_STATS_SUCCESS, payload: res });
    }
  );
};

export const getTopLevelCategoriesTransaction = (account: string[]) => (dispatch: Dispatch) => {
  dispatch({ type: GET_TOP_LEVEL_BANK_CATEGORIES });
  getTopLevelCategoriesTransactionService(account).subscribe(
    (res: any) => {
      const selectedCategoryPayment: { category: string; amount: any; }[] = [];
      res?.categoryDetails?.forEach((mainCategoryPayment: any) => {
        if (mainCategoryPayment?.category === 'Entertainment') {
          selectedCategoryPayment.push({
            category: 'Entertainment',
            amount: mainCategoryPayment?.totalAmount
          });
        }
        mainCategoryPayment?.categoryDetails?.forEach((subCategoryPayment: any) => {
          if (subCategoryPayment?.category === 'Restaurants') {
            selectedCategoryPayment.push({
              category: 'Restaurants',
              amount: subCategoryPayment?.totalAmount
            });
          }
          if (subCategoryPayment?.category === 'Gas') {
            selectedCategoryPayment.push({
              category: 'Gas',
              amount: subCategoryPayment?.totalAmount
            });
          }

          if (subCategoryPayment?.category === 'Groceries') {
            selectedCategoryPayment.push({
              category: 'Groceries',
              amount: subCategoryPayment?.totalAmount
            });
          }
        });
      });
      localStorage.setItem('UserExpenses', JSON.stringify(selectedCategoryPayment));

      dispatch({ type: GET_TOP_LEVEL_BANK_CATEGORIES_SUCCESS, payload: res });
    }
  );
};

export const getBankingTips = (account: string[]) => (dispatch: Dispatch) => {
  dispatch({ type: GET_BANKING_TIPS });
  fetchBankingTips(account).subscribe(
    (res) => {
      dispatch({ type: GET_BANKING_TIPS_SUCCESS, payload: res });
    }
  );
};

export const getBankingSubscriptions = (account: string[]) => (dispatch: Dispatch) => {
  dispatch({ type: GET_BANKING_SUBSCRIPTIONS });
  fetchBankingSubscriptions(account).subscribe(
    (res) => {
      dispatch({ type: GET_BANKING_SUBSCRIPTIONS_SUCCESS, payload: res });
    }
  );
};

export const updateBudgetDatePicker = (month: Date) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_BUDGET_DATE_PICKER,
    payload: month
  });
};
