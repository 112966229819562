import { Dispatch } from 'redux';
import {
  categorizeTransactionService,
  createManualTransactionService,
  deleteManualTrxService,
  deleteSplitTransactionBtwPeopleService,
  deleteSplitTransactionByCategoryService,
  excludeTransactionService,
  getBankingTransactionsService,
  getTransactionCategoriesService,
  includeTransactionService,
  splitTransactionBetweenPeopleService,
  splitTransactionByCategoryService,
  updateManualTransactionService,
  updateTransactionDescService
} from '../api/TransactionsService';
import {
  GET_BANKING_TRANSACTIONS,
  GET_BANKING_TRANSACTIONS_SUCCESS,
  EXCLUDE_BANKING_TRANSACTION,
  INCLUDE_BANKING_TRANSACTION,
  UPDATE_BANKING_TRANSACTION_ITEM,
  GET_TRANSACTION_CATEGORIES,
  SPLIT_TRANSACTION_BEWTEEN_PEOPLE,
  DELETE_SPLIT_TRANSACTION_BEWTEEN_PEOPLE,
  CATEGORIZE_TRANSACTION,
  SPLIT_TRANSACTION_BY_CATEGORY,
  DELETE_SPLIT_TRANSACTION_BY_CATEGORY,
  SET_TRANSACTION_FILTER,
  UPDATE_TRANSACTION_DATE_PICKER,
  REMOVE_BANKING_TRANSACTION_ITEM,
  CREATE_MANUAL_TRANSACTION_ITEM
} from '../types/Transactions';
import { Dayjs } from 'dayjs';
import { BankingTransaction, SplitByCategoriesType } from '../model/BankingTransaction';
import { TransactionTypeEmun } from '../model/transactions/TransactionTypeEmun';

export const getBankingTransactions = (accounts: string[], from: Dayjs, to: Dayjs) => (dispatch: Dispatch) => {
  dispatch({
    type: GET_BANKING_TRANSACTIONS
  });

  const fromString = from.format('YYYY-MM-DD');
  const toString = to.format('YYYY-MM-DD');

  getBankingTransactionsService(accounts, fromString, toString)
    .subscribe(transactions => dispatch({
      type: GET_BANKING_TRANSACTIONS_SUCCESS,
      payload: { from, to, transactions }
    }));
};

export const updateTransactionItem = (item : {
    bankAccountBackendId: string,
    backendId: string,
    description: string,
    excluded: boolean,
    credit: number,
    debit: number,
    originalAmount: number,
    categoryId: number,
    originalCategoryId: number
    splitByCategories: SplitByCategoriesType
  }) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_BANKING_TRANSACTION_ITEM,
    payload: {
      ...item
    }
  });
};

export const splitTransactionBetweenPeople = (transactionBackendId: string, newAmount: number, originalAmount: number) => (dispatch: Dispatch) => {
  const data = JSON.stringify(
    {
      transactionBackendId,
      newAmount,
      originalAmount
    }
  );
  splitTransactionBetweenPeopleService(data).subscribe(
    (resp: any) => {
      dispatch({
        type: SPLIT_TRANSACTION_BEWTEEN_PEOPLE
      });
    }
  );
};

export const deleteSplitTransactionBtwPeople = (transactionBackendId: string) => (dispatch: Dispatch) => {
  deleteSplitTransactionBtwPeopleService(transactionBackendId).subscribe(
    (resp: any) => {
      dispatch({
        type: DELETE_SPLIT_TRANSACTION_BEWTEEN_PEOPLE
      });
    }
  );
};

export const includeTransaction = (bankAccountBackendId: string, transactionBackendId: string) => (dispatch: Dispatch) => {
  const data = JSON.stringify([
    {
      bankAccountBackendId,
      transactionBackendId
    }
  ]);
  includeTransactionService(data).subscribe(
    (resp: any) => {
      dispatch({
        type: INCLUDE_BANKING_TRANSACTION
      });
    }
  );
};

export const updateTransactionDesc = (transactionBackendId: string, newDescription: string, originalDescription: string) => (dispatch: Dispatch) => {
  const data = JSON.stringify({
    transactionBackendId,
    newDescription,
    originalDescription
  });
  updateTransactionDescService(data).subscribe(
    (resp: any) => {
      dispatch({
        type: INCLUDE_BANKING_TRANSACTION
      });
    }
  );
};

export const excludeTransaction = (bankAccountBackendId: string, transactionBackendId: string) => (dispatch: Dispatch) => {
  const data = JSON.stringify([
    {
      bankAccountBackendId,
      transactionBackendId
    }
  ]);
  excludeTransactionService(data).subscribe(
    (resp: any) => {
      dispatch({
        type: EXCLUDE_BANKING_TRANSACTION
      });
    }
  );
};

export const getTransactionCategories = () => (dispatch: Dispatch) => {
  getTransactionCategoriesService().subscribe(
    (resp: any) => {
      dispatch({
        type: GET_TRANSACTION_CATEGORIES,
        payload: resp
      });
    }
  );
};

export const setTransactionFilter = (ctgs: number[], types: TransactionTypeEmun[]) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_TRANSACTION_FILTER,
    payload: {
      ctgs: ctgs,
      types: types
    }
  });
};

export const categorizeTransaction =
  (transactionId: string, categoryId: number, categorisationType: string, transactionDescription: string) => (dispatch: Dispatch) => {
    const data = JSON.stringify({
      transactionBackendId: transactionId,
      transactionDescription,
      categoryId
    });
    categorizeTransactionService(data, categorisationType).subscribe(
      (resp: any) => {
        dispatch({
          type: CATEGORIZE_TRANSACTION
        });
      }
    );
  };

export const splitTransactionByCategory =
  (originalAmount: number, splitByCategories: SplitByCategoriesType, transactionBackendId: string) => (dispatch: Dispatch) => {
    const data = JSON.stringify({
      transactionBackendId,
      splitByCategories,
      originalAmount
    });
    splitTransactionByCategoryService(data).subscribe(
      (resp: any) => {
        dispatch({
          type: SPLIT_TRANSACTION_BY_CATEGORY
        });
      }
    );
  };

export const deleteSplitTransactionByCategory = (transactionBackendId: string) => (dispatch: Dispatch) => {
  deleteSplitTransactionByCategoryService(transactionBackendId).subscribe(
    (resp: any) => {
      dispatch({
        type: DELETE_SPLIT_TRANSACTION_BY_CATEGORY
      });
    }
  );
};

export const updateTransactionsDatePicker = (start: Dayjs, end: Dayjs) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_TRANSACTION_DATE_PICKER,
    payload: {
      start: start,
      end: end
    }
  });
};

export const deleteManualTransaction =
  (accountId: number, transactionId: number) => (dispatch: Dispatch) => {
    deleteManualTrxService(accountId, transactionId).subscribe(
      (resp: any) => {
        dispatch({
          type: REMOVE_BANKING_TRANSACTION_ITEM,
          payload: {
            accountId,
            transactionId
          }
        });
      }
    );
  };

export const createManualTransaction =
  (accountId: number, data: Partial<BankingTransaction>) => (dispatch: Dispatch) => {
    createManualTransactionService(accountId, data).subscribe(
      (resp: any) => {
        dispatch({
          type: CREATE_MANUAL_TRANSACTION_ITEM,
          payload: resp.data
        });
      }
    );
  };

export const updateManualTransaction =
  (accountId: number, transactionId: number, data: Partial<BankingTransaction>) => (dispatch: Dispatch) => {
    updateManualTransactionService(accountId, transactionId, data).subscribe(
      (resp: any) => {
        dispatch({
          type: UPDATE_BANKING_TRANSACTION_ITEM,
          payload: {
            ...data
          }
        });
      }
    );
  };
