export const GET_CREDIT_CARD_RECOMMENDATION = 'GET_CREDIT_CARD_RECOMMENDATION';
export const GET_CREDIT_CARD_RECOMMENDATION_SUCCESS = 'GET_CREDIT_CARD_RECOMMENDATION_SUCCESS';
export const GET_CREDIT_CARD_RECOMMENDATION_FAILED = 'GET_CREDIT_CARD_RECOMMENDATION_FAILED';
export const GET_BROKER_RECOMMENDATION = 'GET_BROKER_RECOMMENDATION';
export const GET_BROKER_RECOMMENDATION_SUCCESS = 'GET_BROKER_RECOMMENDATION_SUCCESS';
export const GET_BROKER_RECOMMENDATION_FAILED = 'GET_BROKER_RECOMMENDATION_FAILED';
export const GET_SAVING_RECOMMENDATION = 'GET_SAVING_RECOMMENDATION';
export const GET_SAVING_RECOMMENDATION_SUCCESS = 'GET_SAVING_RECOMMENDATION_SUCCESS';
export const GET_SAVING_RECOMMENDATION_FAILED = 'GET_SAVING_RECOMMENDATION_FAILED';
export const GET_CHECKING_RECOMMENDATION = 'GET_CHECKING_RECOMMENDATION';
export const GET_CHECKING_RECOMMENDATION_SUCCESS = 'GET_CHECKING_RECOMMENDATION_SUCCESS';
export const GET_CHECKING_RECOMMENDATION_FAILED = 'GET_CHECKING_RECOMMENDATION_FAILED';
export const GET_ROBO_ADVISOR_RECOMMENDATION = 'GET_ROBO_ADVISOR_RECOMMENDATION';
export const GET_ROBO_ADVISOR_RECOMMENDATION_SUCCESS = 'GET_ROBO_ADVISOR_RECOMMENDATION_SUCCESS';
export const GET_ROBO_ADVISOR_RECOMMENDATION_FAILED = 'GET_ROBO_ADVISOR_RECOMMENDATION_FAILED';
