import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BankingTips } from '../../../model/BankingTips';
import { getBankingTips } from '../../../actions/Banking';
import styles from '../../../assets/styles/modules/insightTipsPanel.module.css';
import HardbaconAmount from '../../../component/HardbaconAmount';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';

const InsightTipsPanel = () => {
  const { t } = useTranslation();
  const { bankingTips, loadingBankingTips }: { bankingTips: BankingTips[], loadingBankingTips: boolean } = useSelector((state: any) => state.banking);
  const { minifiedSelectedAccountIds } = useSelector((state: any) => state.userInstitution);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!bankingTips) {
      dispatch(getBankingTips(minifiedSelectedAccountIds));
    }
  }, [minifiedSelectedAccountIds, bankingTips]);

  const renderTip = (tip: BankingTips) => (
    <div key={tip.type} className={styles.item}>
      <div className={styles.titleContainer}>
        <div className={styles.emoji}>{tip.emoji}</div>
        <div className={styles.title}>{tip.title}</div>
      </div>
      <div className={styles.desc}>{tip.description}</div>
      <div className={styles.save}>
        <div className={styles.saveText}>{t('overview:saveAmount')}</div>
        <div><HardbaconAmount amount={tip.saveAmount} size={16} decimals={0} bold={true}/></div>
      </div>
    </div>);

  const Total = () => {
    const total = bankingTips.map(t => t.saveAmount).reduce((a, b) => a + b, 0);
    return (
      <div className={styles.item} style={{ width: '100%' }}>
        <div className={styles.totalTitle}>{t('overview:potentialSavings')}</div>
        <div className={styles.totalAmount}>
          <HardbaconAmount amount={total} decimals={0} size={40} bold={true}/>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        {loadingBankingTips && (
          <div className={styles.progress}>
            <CircularProgress color={CircularProgressColorEmun.primary}/>
          </div>
        )}
        {!loadingBankingTips && bankingTips && <Total />}
        {!loadingBankingTips && bankingTips && bankingTips.map(tip => renderTip(tip))}
      </div>
    </div>
  );
};

export default InsightTipsPanel;
