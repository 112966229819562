import React from 'react';

interface BudgetInputProps {
  setValue: Function;
  value: number;
}

const BudgetInput = ({ setValue, value }: BudgetInputProps) => {
  return <input
  maxLength={8}
  className={'budget-input' + (!value ? ' empty' : '')}
  type="text"
  name="value"
  value={value}
  onChange={(v) => setValue(parseInt(v.target.value) || 0)}/>;
};

export default BudgetInput;
