import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-circular-progressbar/dist/styles.css';
import Container from '../../../component/Container';
import styles from './../../../assets/styles/modules/budgetCategory.module.css';
import { TransactionCategory } from '../../../model/TransactionCategory';
import { getTransactionCtgNameById, getTransactionCtgEmojiById } from '../../../utils/banking';
import { editIcon } from '../../../assets/images';
import { HardbaconButton } from '../../../component';
import { formatCurrency, formatDate } from '../../../i18n';
import ExpandButton from '../../../component/ExpandButton';
import { isTablet } from '../../../utils/hooks';

interface BudgetCategoryProps {
  item: TransactionCategory;
  expandable?: boolean;
  budgetMonth: Date,
  openEditCategoryBudget?: boolean,
  setOpenEditCategoryBudget: Function,
  setCategorySelected: Function
}

const BudgetCategory = ({ item, expandable = true, budgetMonth, openEditCategoryBudget, setOpenEditCategoryBudget, setCategorySelected }: BudgetCategoryProps) => {
  const [open, setOpen] = useState(false);
  const [mouseTimer, setMouseTimer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  const isIncomeType = item.categoryId === 68;
  const isTabletScreen = isTablet();
  const editAreaWidth = isTabletScreen ? 50 : 100;

  const isCurrentMonthSelected = budgetMonth && formatDate(new Date(budgetMonth), 'MMM yyyy') === formatDate(new Date(), 'MMM yyyy');

  const containerRef = useRef<any>(null);

  useEffect(
    () => {
      const timer = setTimeout(() => setMouseTimer(true), 100);
      return () => {
        clearTimeout(timer);
      };
    },
    []
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsEdit(false);
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  const displaySubs = expandable && item.categoryDetails.filter(sub => sub.periodAmount !== 0).length;

  const renderContent = (i: TransactionCategory, hasSubs: boolean) => {
    const budget = i.budget;
    const currentMonthAmount = i.periodAmount || 0;
    const lastYearAmount = i.totalAmount;
    let budgetRatio = i.budgetRatio;
    const categoryId = i.categoryId;

    let numberOfTallItems = [];
    let numberOfShortItems = [];
    let height = '0';

    if (hasSubs && displaySubs) {
      numberOfTallItems = i.categoryDetails.filter(sub => sub.periodAmount && sub.budget);
      numberOfShortItems = i.categoryDetails.filter(sub => sub.periodAmount && !sub.budget);
      height = `${numberOfTallItems.length * 79 + numberOfShortItems.length * 55}px`;
    }

    if (!isCurrentMonthSelected) {
      budgetRatio = budget ? Math.round(currentMonthAmount / budget * 100) / 100 : 0;
    }

    let initBase = '#74D783';
    if (isCurrentMonthSelected || isIncomeType) {
      if (i.budgetTrend === 'RED') {
        initBase = '#DD5C68';
      } else if (i.budgetTrend === 'YELLOW') {
        initBase = '#EBCB97';
      }
    } else {
      if (currentMonthAmount >= i.budget) {
        initBase = '#DD5C68';
      } else {
        initBase = '#74D783';
      }
    }

    return (currentMonthAmount > 0 || hasSubs) && (
      <div key={categoryId} className={styles.header} style={{ marginTop: hasSubs ? 0 : '10px' }}>
        <div>
          <p className={styles.image}>{getTransactionCtgEmojiById(categoryId)}</p>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div>
            <p className={styles.title}>{getTransactionCtgNameById(categoryId)}</p>
            {hasSubs && <p className={styles['sub-title']}>{formatCurrency(lastYearAmount, 0) + ' ' + t('lastYear')}</p>}
          </div>
          {i.budget > 0 && <div className={styles.base}>
            <div className={styles.bar} style={{ backgroundColor: initBase, width: `${budgetRatio * 100 <= 100 ? budgetRatio * 100 : 100}%` }}/>
          </div>}
          <div className={styles.footer}>
            {hasSubs ? <p className={styles['footer-value']}>{formatCurrency(currentMonthAmount, 0)} {budgetRatio ? ('(' + Math.round(budgetRatio * 100 * 100) / 100) + '%)' : ''}</p> : <p>{formatCurrency(currentMonthAmount, 0)}</p>}
            {i.budget > 0 && <p>{formatCurrency(budget)}</p>}
          </div>
          {hasSubs && <div style={{ transition: 'height 0.3s', overflow: 'hidden', height: open ? height : 0 }}>
            {
              i.categoryDetails.map((sub: TransactionCategory) => {
                return renderContent(sub, false);
              })
            }
          </div>}
        </div>
        {hasSubs && displaySubs && currentMonthAmount
          ? <ExpandButton
          isOpen={open}
          className={styles.expand}
          onClick={() => onConatinerClick()}
        />
          : null}
      </div>
    );
  };

  const onEdit = () => {
    setOpenEditCategoryBudget(true);
    setCategorySelected(item);
    setIsEdit(false);
  };

  const onConatinerClick = () => {
    if (isTabletScreen) {
      setIsEdit(!isEdit);
    }
    displaySubs && setOpen(!open);
  };

  return (
    <div
      key={item.categoryId}
      className={styles.container}
      onMouseEnter={() => {
        if (mouseTimer && expandable && !isTabletScreen) {
          setIsEdit(true);
        }
      }}
      onMouseLeave={() => expandable && !isTabletScreen && setIsEdit(false)}
      style={{ cursor: displaySubs ? 'pointer' : 'unset' }}
      ref={containerRef}
    >
      <div className={styles.edit} style={{ width: isEdit && !openEditCategoryBudget ? editAreaWidth + 'px' : 0 }}>
        <div className={styles['edit-button-container']} onClick={() => onEdit()}>
          <img className={styles['edit-button']} src={editIcon} onClick={() => null}/>
        </div>
      </div>
      <Container onClick={() => onConatinerClick()}>
        <div>
          {renderContent(item, true)}
          {isIncomeType
            ? <div className={styles['edit-center']}>
            <HardbaconButton ghost={true} onPress={() => onEdit()} title={t('edit')} name={'edit'}/>
          </div>
            : null}
        </div>
      </Container>
    </div>
  );
};

export default BudgetCategory;
