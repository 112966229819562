import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../../assets/styles/modules/OffersOverview.module.css';
import CircularProgress, { CircularProgressColorEmun } from '../../component/CircularProgress';
import { getOffers } from '../../actions/Feed';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import OfferItem from '../../component/OfferItem';
import SimplePage from '../../component/Layouts/SimplePage';

const Offers = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user);
  const { offers, isOffersLoading } = useSelector((state: any) => state.feed);

  useEffect(() => {
    if (offers.length === 0) {
      dispatch(getOffers(user.province));
    }
  }, [offers]);

  return (
    <SimplePage>
        {isOffersLoading
          ? <div>
                <CircularProgress color={CircularProgressColorEmun.primary}/>
              </div>
          : <div className={styles.container}>
              {offers.map((offer: any) => {
                return <OfferItem item={offer} key={offer.id}/>;
              })}
          </div>
        }
    </SimplePage>);
};

export default Offers;
