import React, { Component } from 'react';
import ModalState, { modalAnimationDelay } from '../model/ModalState';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReducerNotificationState from '../model/ReducerNotificationState';
import { setNotification } from '../actions/Notification';
import AppNotificationModel from '../model/AppNotification';
import AppNotificationType from '../model/AppNotificationType';
import styles from '../assets/styles/modules/AppNotification.module.css';

type AppNotificationState = {
    modalState: ModalState
    lastAppNotification?: AppNotificationModel
}

type AppNotificationProps = WithTranslation & ReducerNotificationState & {
    setNotification?: (appNotification?: AppNotificationModel) => void;
}

export class AppNotification extends Component<AppNotificationProps, AppNotificationState> {
  constructor (props: AppNotificationProps) {
    super(props);
    this.state = {
      modalState: ModalState.closed,
      lastAppNotification: undefined
    };
  }

  componentDidUpdate (prevProps: Readonly<AppNotificationProps>, prevState: Readonly<AppNotificationState>, snapshot?: any) {
    if (this.props.isAppNotificationVisible && !prevProps.isAppNotificationVisible) {
      this.show();
      this.setState({ lastAppNotification: this.props.appNotificationModel });
      if (this.props.appNotificationModel?.type === AppNotificationType.success) {
        this.clearNotificationTimeout(5000);
      }
    } else if (!this.props.isAppNotificationVisible && prevProps.isAppNotificationVisible) {
      this.hide();
    }
  }

  clearNotificationTimeout (ms: number) {
    setTimeout(() => {
      this.clearNotification();
    }, ms);
  }

  show () {
    this.setState({ modalState: ModalState.opening });
    setTimeout(() => {
      this.setState({ modalState: ModalState.opened });
    }, modalAnimationDelay);
  }

  hide () {
    this.setState({
      modalState: ModalState.closing
    });
    setTimeout(() => {
      this.setState({ modalState: ModalState.closed });
    }, modalAnimationDelay);
  }

  clearNotification () {
        this.props.setNotification!(undefined);
  }

  renderIcon (appNotificationModel?: AppNotificationModel) {
    if (appNotificationModel?.type === AppNotificationType.success) {
      return <div className={styles.iconContainer}>
                <svg style={{ width: '1.5rem', height: '1.5rem', color: '#34D399' }} xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
            </div>;
    } else if (appNotificationModel?.type === AppNotificationType.error) {
      return <div className={styles.iconContainer}>
                <svg style={{ width: '1.5rem', height: '1.5rem', color: '#DC2626' }} xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                </svg>
            </div>;
    } else {
      return <div className={styles.iconContainer}>
                <svg style={{ width: '1.5rem', height: '1.5rem', color: '#FBBF24' }} xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"/>
                </svg>
            </div>;
    }
  }

  render () {
    const { modalState, lastAppNotification } = this.state;
    const { isAppNotificationVisible } = this.props;

    // During closing animation, notificationModel is undefined which remove title, description and display wrong type.
    // To fix this, we use the notificationModel from the last opening of modal when notificationModel is undefined
    const notificationModel = this.props.appNotificationModel ? this.props.appNotificationModel! : lastAppNotification;

    const animationForState = (modalState === ModalState.opened ? 'transform ease-out duration-300 transition translate-y-0 opacity-100 sm:translate-x-0 ' : 'transition ease-in duration-100 opacity-0 ');

    if (!isAppNotificationVisible) {
      return (<></>);
    }

    return (
        <div className={styles.container}>
            <div
                className={animationForState + 'bg-white max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'}>
                <div className={styles.messageContainer}>
                    {this.renderIcon(notificationModel)}
                    <div className={styles.textContainer}>
                        <p className={styles.title}>
                            {notificationModel?.title}
                        </p>
                        {notificationModel?.description && <p className={styles.descripton}>
                            {notificationModel?.description}
                        </p>}
                    </div>
                    <div className={styles.closeButtonContainer}>
                        <button
                            onClick={() => this.clearNotification()}
                            className={styles.closeButton}>
                            <span className="sr-only">Close</span>
                            <svg style={{ width: '1.25rem', height: '1.25rem' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

const mapStateToProps = ({ notification }: { notification: ReducerNotificationState }) => {
  return {
    ...notification
  };
};

export default connect(mapStateToProps, { setNotification })(withTranslation()(AppNotification));
