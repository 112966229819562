import React from 'react';
import styles from '../assets/styles/modules/nav.module.css';

const Nav = (props: any) => {
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push((
        <div
            key={`step-${i}`}
            className={`${styles.dot} ${isActive ? styles.active : ''}`}
            onClick={() => {
              if (props.canClick) {
                props.goToStep(i);
              }
            }}
        >&bull;</div>
    ));
  }

  return (
      <div className={styles['nav-div']} style={{ textAlign: 'center' }}>
        {dots}
      </div>
  );
};

export default Nav;
