import ReducerPayload from '../model/ReducerPayload';
import { SET_SUPPORT_VISIBLE } from '../types/Support';
import ReducerSupportState from '../model/ReducerSupportState';

export const initialState: ReducerSupportState = {
  visible: false
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case SET_SUPPORT_VISIBLE:
      return {
        ...state,
        visible: action.payload
      };
    default:
      return state;
  }
};
