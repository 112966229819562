export const tooltipsEn = {
  accountCurrency: 'The currency in which transactions will be for this account.',
  accountName: 'The name of the account you will use to refer to it.',
  accountType: 'You can chose among chequing, savings, credit card, line of credit, loan, mortgage or other.',
  accountBalance: 'The initial balance of the account at the date of the first transactions you will input.',
  accountDate: 'The initial date when you start creating transactions. You will not be able to create transactions older than this date.',
  accountDateUpdate: "Last operation's date",
  manualAccountBalance:
  'Enter a negative amount for a payment, and a positive one for income or reimbursements. Whenever the amount is modified, any split (by category or person) will be automatically reset.',
  splitTransaction:
  'You can split a transaction two ways: among persons (if you only paid part of the amount) or among several categories.',
  removeFromAnalytics: "This excludes the transaction from all calcultations. It won't show as in your budget, or your insights, or your tips.",
  originalDescription: 'The transaction description as it appears on your bank statement',
  description: 'The cleaned transaction description (updatable). You can always modify this description.',
  transactionDate: 'The date you made the transaction',
  manualTransactionDate: 'It is impossible to create a transaction that occurred before your manual account was created. If you want to assign this date, please change the creation date of your manual account.'
};

export const tooltipsFr = {
  accountCurrency: 'La devise dans laquelle seront faites les transactions sur le compte.',
  accountName: 'Le nom du compte identifié de façon à savoir rapidement de quel compte il s’agit',
  accountType: 'Vous pouvez choisir entre un compte chèques, compte épargnes, carte de crédit, marge de crédit, prêt, hypothèque ou autre. ',
  accountBalance: "Le montant d'argent initial dans ce compte à la date sélectionnée.",
  accountDate: 'La date à laquelle vous débutez la création de vos transactions manuelles. Aucune transaction plus ancienne que cette date ne pourra être ajoutée.',
  accountDateUpdate: 'Date de la dernière opération',
  manualAccountBalance:
  'Entrez un montant négatif pour un paiement, un montant positif pour un revenu ou un remboursement. A chaque modification du montant, le fractionnement par catégorie ou personne est automatiquement réinitialisé.',
  splitTransaction: 'Il vous est possible de séparer la transaction de deux façons : entre plusieurs personnes (si vous avez payé seulement une partie du montant) ou entre plusieurs catégories.',
  removeFromAnalytics:
  "Ceci exclue la transaction de tous les calculs. Elle n'apparaitra pas dans votre budget, vos statistiques ou vos conseils.",
  originalDescription: "La description de la transaction telle qu'affichée sur votre relevé d'opérations",
  description:
    'La description de la transaction nettoyée (modifiable). Vous pouvez toujours modifier cette description.',
  transactionDate: 'La date où vous avez fait la transaction',
  manualTransactionDate: "Il n'est pas possible de créer une transaction avant la date de création de son compte manuel. Si vous voulez attribuer cette date, veuillez changer la date de création du compte manuel."
};
