import ReducerPayload from '../model/ReducerPayload';
import {
  GET_BANK_STATS,
  GET_BANK_STATS_SUCCESS,
  GET_CATEGORY_BUDGET,
  GET_CATEGORY_BUDGET_SUCCESS,
  GET_TOP_LEVEL_BANK_CATEGORIES,
  GET_TOP_LEVEL_BANK_CATEGORIES_FAILED,
  GET_TOP_LEVEL_BANK_CATEGORIES_SUCCESS,
  SET_BANKING_CATEGORIES,
  GET_BANKING_CATEGORIES,
  GET_FINANCIAL_INFO,
  GET_FINANCIAL_INFO_SUCCESS,
  SET_FINANCIAL_INFO_SUCCESS,
  UPDATE_FINANCIAL_INFO,
  UPDATE_FINANCIAL_INFO_SUCCESS,
  UPDATE_CATEGORY_BUDGET_SUCCESS,
  GET_BANKING_TIPS,
  GET_BANKING_TIPS_SUCCESS,
  GET_BANKING_SUBSCRIPTIONS,
  GET_BANKING_SUBSCRIPTIONS_SUCCESS,
  UPDATE_BUDGET_DATE_PICKER
} from '../types/Banking';
import { LOGOUT } from '../types/Session';
import { Category } from '../model/Category';
import { SET_SELECTED_ACCOUNTS } from '../types/UserInstitution';
import dayjs from 'dayjs';
import { BankingCtgEmojiDict, BankingCtgNamesDict, BankingCtgSubCtgDict, BankingTransactionCtg } from '../model/BankingTransaction';

export const initialState = {
  bankingCategories: [] as Category[],
  bankingCtgEmojiDict: {} as BankingCtgEmojiDict,
  bankingCtgSubCtgsDict: {} as BankingCtgSubCtgDict,
  bankingCtgNamesDict: {} as BankingCtgNamesDict,
  loadingBankCategories: undefined,
  financialInfo: undefined,
  loadingFinancialInfo: undefined,
  budget: undefined,
  loadingBudget: undefined,
  bankStats: undefined,
  loadingBankStats: undefined,
  categoriesTransaction: undefined,
  loadingCategoriesTransaction: undefined,
  bankingTips: undefined,
  loadingBankingTips: undefined,
  subscriptions: undefined,
  loadingSubscriptions: undefined,
  budgetMonth: dayjs().startOf('month')
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case GET_BANKING_CATEGORIES:
      return {
        ...state,
        loadingBankCategories: true
      };
    case UPDATE_BUDGET_DATE_PICKER:
      return {
        ...state,
        budgetMonth: action.payload
      };
    case SET_BANKING_CATEGORIES: {
      const ctgs = action.payload;
      const ctgEmojiDict: BankingCtgEmojiDict = {};
      const ctgSubCtgsDict: BankingCtgSubCtgDict = {};
      const ctgNamesDict: BankingCtgNamesDict = {};
      ctgs.map((ctg: BankingTransactionCtg) => {
        ctgEmojiDict[ctg.id] = ctg.emoji;
        ctgNamesDict[ctg.id] = {
          en: ctg.english,
          fr: ctg.french
        };
        const subCtgs: number[] = [];
        ctg.subCategories.map((subCtg: BankingTransactionCtg) => {
          ctgEmojiDict[subCtg.id] = subCtg.emoji;
          subCtgs.push(subCtg.id);
          ctgNamesDict[subCtg.id] = {
            en: subCtg.english,
            fr: subCtg.french
          };
          return subCtg;
        });
        ctgSubCtgsDict[ctg.id] = subCtgs;
        return ctg;
      });
      return {
        ...state,
        loadingBankCategories: false,
        bankingCategories: ctgs,
        bankingCtgEmojiDict: ctgEmojiDict,
        bankingCtgSubCtgsDict: ctgSubCtgsDict,
        bankingCtgNamesDict: ctgNamesDict
      };
    }
    case GET_FINANCIAL_INFO:
      return {
        ...state,
        loadingFinancialInfo: true
      };
    case GET_FINANCIAL_INFO_SUCCESS:
      return {
        ...state,
        financialInfo: action.payload,
        loadingFinancialInfo: false
      };
    case UPDATE_FINANCIAL_INFO:
      return {
        ...state,
        loadingFinancialInfo: true
      };
    case UPDATE_FINANCIAL_INFO_SUCCESS:
      return {
        ...state,
        financialInfo: action.payload,
        loadingFinancialInfo: false
      };
    case SET_FINANCIAL_INFO_SUCCESS:
      return {
        ...state,
        financialInfo: action.payload,
        loadingFinancialInfo: false
      };
    case UPDATE_CATEGORY_BUDGET_SUCCESS:
      return {
        ...state
      };
    case GET_CATEGORY_BUDGET:
      return {
        ...state,
        loadingBudget: true
      };
    case GET_CATEGORY_BUDGET_SUCCESS:
      return {
        ...state,
        budget: action.payload,
        loadingBudget: false
      };
    case LOGOUT:
      return {
        ...initialState
      };
    case GET_BANK_STATS:
      return {
        ...state,
        loadingBankStats: true
      };
    case GET_BANK_STATS_SUCCESS:
      return {
        ...state,
        loadingBankStats: false,
        bankStats: action.payload
      };
    case GET_TOP_LEVEL_BANK_CATEGORIES:
      return {
        ...state,
        loadingCategoriesTransaction: true
      };
    case GET_TOP_LEVEL_BANK_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesTransaction: action.payload.categoryDetails,
        loadingCategoriesTransaction: false
      };
    case SET_SELECTED_ACCOUNTS: {
      // When the account selection changes, reset everything so that it will trigger
      // a new fetch from the pages needing it
      return {
        ...state,
        bankStats: undefined,
        loadingBankStats: false,
        categoriesTransaction: undefined,
        loadingCategoriesTransaction: false,
        bankingTips: undefined,
        loadingBankingTips: false,
        subscriptions: undefined,
        loadingSubscriptions: false
      };
    }
    case GET_TOP_LEVEL_BANK_CATEGORIES_FAILED:
      return {
        ...state,
        categoriesTransaction: [],
        loadingCategoriesTransaction: false
      };
    case GET_BANKING_TIPS:
      return {
        ...state,
        loadingBankingTips: true
      };
    case GET_BANKING_TIPS_SUCCESS: {
      return {
        ...state,
        bankingTips: action.payload,
        loadingBankingTips: false
      };
    }
    case GET_BANKING_SUBSCRIPTIONS :
      return {
        ...state,
        loadingSubscriptions: true
      };
    case GET_BANKING_SUBSCRIPTIONS_SUCCESS :
      return {
        ...state,
        loadingSubscriptions: false,
        subscriptions: action.payload
      };
    default:
      return state;
  }
};
