import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { syncInstitution, updateUserInstitutionDetails, deleteManualAccount } from '../actions/UserInstitution';
import { setNotification } from '../actions/Notification';
import UserInstitution from '../model/UserInstitution';
import { getIconForInstitution } from '../utils';
import { formatCurrency, formatDate } from '../i18n';
import InstitutionAccountDetail from '../model/InstitutionAccountDetail';
import { HardbaconButton, RightPanel } from './index';
import { leftArrow } from '../assets/images';
import AppNotificationType from '../model/AppNotificationType';
import AppNotification from '../model/AppNotification';
import styles from './../assets/styles/modules/AccountDetails.module.css';
import AccountTypeSelector from './AccountTypeSelector';
import EditManualAccount from './EditManualAccount';
import { closePopUp, openPopUp } from '../actions/PopUp';
import CircularProgress, { CircularProgressColorEmun } from './CircularProgress';
import { create } from 'zustand';
import { LocalStorageKeys } from '../common/LocalStorageKeys';

interface InstitutionState {
  institution?: UserInstitution;
  setInstitution: (institution?: UserInstitution) => void;
}

interface ItemToUpdateState {
  itemToUpdate?: InstitutionAccountDetail;
  setItemToUpdate: (itemToUpdate?: InstitutionAccountDetail) => void;
}

interface DisplayAccountTypeSelectorState {
  displayAccountTypeSelector?: InstitutionAccountDetail;
  setDisplayAccountTypeSelector: (DisplayAccountTypeSelector?: InstitutionAccountDetail) => void;
}

export const useInstitutionStore = create<InstitutionState>((set) => ({
  institution: undefined,
  setInstitution: (institution) => set(() => ({ institution: institution }))
}));

const useItemToUpdateStore = create<ItemToUpdateState>((set) => ({
  itemToUpdate: undefined,
  setItemToUpdate: (itemToUpdate) => set(() => ({ itemToUpdate: itemToUpdate }))
}));

const useDisplayAccountTypeSelectorStore = create<DisplayAccountTypeSelectorState>((set) => ({
  displayAccountTypeSelector: undefined,
  setDisplayAccountTypeSelector: (displayAccountTypeSelector) => set(() => ({ displayAccountTypeSelector: displayAccountTypeSelector }))
}));

type AccountDetailsProps = {
  institution?: UserInstitution,
  syncInstitution?: (id: string, institutionSourceId: number) => void,
  getInstitutions?: () => void,
  open: boolean,
  onClose: () => void,
  fixError?: (institution: UserInstitution) => void,
  updateUserInstitutionDetails?: (institutionId: string, institutionSourceId: number, institutionAccountDetails: InstitutionAccountDetail[]) => void,
  setNotification?: (notification?: AppNotification) => void,
}

const AccountDetails = (props: AccountDetailsProps) => {
  const { itemToUpdate, setItemToUpdate } = useItemToUpdateStore((state) => state);
  const { setInstitution } = useInstitutionStore((state) => state);
  const { setDisplayAccountTypeSelector } = useDisplayAccountTypeSelectorStore((state) => state);
  const { t } = useTranslation();

  const isManual = props.institution?.institutionSource.description === 'BANKING' &&
  props.institution?.institutionSource.dataSource === 'DATABASE';

  useEffect(() => {
    if (props.open && props.institution) {
      setInstitution(props.institution);
      setItemToUpdate(undefined);
    }
  }, [props.open, props.institution]);

  const hide = () => {
    setDisplayAccountTypeSelector(undefined);
    setInstitution(undefined);
  };

  return (
    <div>
      <RightPanel
        closeOnClickOutside={true}
        title={isManual ? t('manageAccounts:manualAccount') : ''}
        visible={props.open} onClose={() => {
          hide();
          props.onClose && props.onClose();
        }}
        goBack={itemToUpdate
          ? () => {
              setItemToUpdate(undefined);
            }
          : null}
        renderContent={() => <Content onClose={props.onClose} fixError={props.fixError} />}
      />
    </div>
  );
};

const Content = ({ onClose, fixError }: {onClose: () => void;
  fixError?: (institution: UserInstitution) => void }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { t, i18n } = useTranslation();
  const { itemToUpdate, setItemToUpdate } = useItemToUpdateStore((state) => state);
  const { institution, setInstitution } = useInstitutionStore((state) => state);
  const { displayAccountTypeSelector, setDisplayAccountTypeSelector } = useDisplayAccountTypeSelectorStore((state) => state);
  const dispatch = useDispatch();
  const isManual = institution?.institutionSource.description === 'BANKING' && institution?.institutionSource.dataSource === 'DATABASE';
  const localDailySyncCount = Number(localStorage.getItem(LocalStorageKeys.DailyRefreshCount)) ?? 0;
  const localLastSyncDate = localStorage.getItem(LocalStorageKeys.LastSyncDate) ?? '';
  const [dailySyncCount, setDailyCount] = useState(localDailySyncCount);
  const [lastSyncDate, setLastSyncDate] = useState(localLastSyncDate);
  if (!institution) {
    return null;
  }
  const today = `${new Date().getDate()}`;

  const onMainButtonPress = () => {
    dispatch(closePopUp());
  };

  const hide = () => {
    setDisplayAccountTypeSelector(undefined);
    setInstitution(undefined);
  };

  const onSubButtonPress = () => {
    dispatch(closePopUp());
    itemToUpdate && institution?.institutionSource.id && dispatch(deleteManualAccount(itemToUpdate.manualId, institution?.institutionSource.id));
    onClose();
    hide();
  };

  const sync = async () => {
    if (today === lastSyncDate) {
      setDailyCount((dailyCount) => dailyCount + 1);
      localStorage.setItem(LocalStorageKeys.DailyRefreshCount, `${dailySyncCount + 1}`);
    } else {
      setDailyCount(1);
      localStorage.setItem(LocalStorageKeys.DailyRefreshCount, '1');
    }
    localStorage.setItem(LocalStorageKeys.LastSyncDate, JSON.stringify(dayjs().date()));
    setLastSyncDate(`${dayjs().date()}`);
    await dispatch(syncInstitution(institution!.id, institution!.institutionSource.id));
    await setIsLoading(true);
    setTimeout(async () => {
      await setIsLoading(false);
    }, 15000);
  };

  const openDeletePopUp = () => {
    dispatch(openPopUp({
      closeOnClickOutside: false,
      onMainButtonPress,
      onSubButtonPress,
      emoji: '🚨',
      mainButtonTitle: t('cancel'),
      subButtonTitle: t('manageAccounts:deleteAccountSubButton'),
      isOpen: true,
      title: t('manageAccounts:deleteAccount2Title'),
      desc: t('manageAccounts:deleteAccount2Desc'),
      danger: true,
      showCloseButton: false
    }));
  };

  const renderAccountItem = (accountDetail: InstitutionAccountDetail) => {
    return (<div
      className={styles.accountItemContainer}
      key={accountDetail.id}
      onClick={() => {
        if (isManual) {
          setItemToUpdate(accountDetail);
        } else {
          setDisplayAccountTypeSelector(accountDetail);
        }
      }}>
      <div className={styles.accountNameContainer}>
                <span
                  className={styles.accountName}>{accountDetail.name} - {accountDetail.currency}</span>
        <span
          className={styles.accountType}>{t(`manageAccounts:AccountType_${accountDetail.accountType}`)} - {t(`manageAccounts:AccountSubType_${accountDetail.accountSubType}`)}</span>
      </div>
      <div className={styles.accountBalanceContainer}>
                <span
                  className={(accountDetail.value > 0 ? styles.success : styles.error)}>{formatCurrency(accountDetail.value, 2, accountDetail.currency)}</span>
      </div>
    </div>);
  };

  const updateAccountDetails = (newAccountDetail: InstitutionAccountDetail) => {
    const existingAccountDetails = institution?.institutionAccountDetailDTOs.slice()
      .map(el => el.id === newAccountDetail.id ? newAccountDetail : el) as InstitutionAccountDetail[];
    dispatch(updateUserInstitutionDetails(institution?.id as string, institution?.institutionSource.id as number, existingAccountDetails));
    hide();
    dispatch(setNotification!({
      title: t('saveSuccess'),
      type: AppNotificationType.success
    }));
  };

  const institutionSource = institution.institutionSource;

  const icon = getIconForInstitution(institutionSource.iconName);
  const name = i18n.language === 'fr' ? institutionSource.displayNameFrench : institutionSource.displayNameEnglish;

  const syncInError = institution.syncStatus === 'error';
  const syncing = institution.syncStatus === 'syncing';

  const syncStatusLabel = institution.syncStatus === 'syncing'
    ? t('manageAccounts:synchronizing')
    : dayjs(institution.syncDate).format('DD.MM') !== dayjs().format('DD.MM')
      ? t('manageAccounts:unsynchronized')
      : t('manageAccounts:synchronized');
  const syncStatusClass = institution.syncStatus === 'syncing'
    ? ''
    : dayjs(institution.syncDate).format('DD.MM') !== dayjs().format('DD.MM')
      ? styles.error
      : styles.success;

  const handleSync = () => {
    if (dailySyncCount > 1 && today === lastSyncDate) {
      setIsDisabled(true);
    } else {
      sync();
    }
  };

  if (itemToUpdate) {
    return (
      <EditManualAccount
        onAccountUpdate={(newAccountDetail: InstitutionAccountDetail) => updateAccountDetails(newAccountDetail)}
        setDeleteBankAccountOpen={() => openDeletePopUp()}
        account={itemToUpdate}
        onClose={() => {
          if (onClose) {
            onClose();
          } else {
            hide();
          }
        }}
     />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.backDiv}>
        {displayAccountTypeSelector &&
          <img
            onClick={() => setDisplayAccountTypeSelector(undefined)}
            src={leftArrow} style={{ marginTop: -37 }}
            className={styles.backImg + ' button-hover'} alt={'back'}/>}
      </div>
      <div className={styles.content}>
        <img alt={'institutionSource'} src={icon} className={styles.instImage}/>
        <span
          className={styles.instName}>{displayAccountTypeSelector ? `${displayAccountTypeSelector.name} - ${displayAccountTypeSelector.currency}` : name}</span>
            {isLoading
              ? <CircularProgress color={CircularProgressColorEmun.primary} />
              : (
              <>
                {!displayAccountTypeSelector && !isManual &&
                  <span className={styles.syncStatus + ' small-text ' + syncStatusClass}>
                    <span>{syncStatusLabel}</span>
                  </span>}
                {
                  displayAccountTypeSelector &&
                    <span className={'flex items-center mt-1 text-center text-green-400'}>
                      <span className={'font-gilmerBold ' + (displayAccountTypeSelector.value > 0 ? 'text-green-400' : 'text-red-600')}>{formatCurrency(displayAccountTypeSelector.value, 2, displayAccountTypeSelector.currency)}</span>
                    </span>
                }
              {!displayAccountTypeSelector && institution.syncDate && !isManual &&
                <span className={styles.syncDate + ' small-text font-gilmerMedium'}>
                  {`${t('manageAccounts:lastSync')} : `}
                  <time dateTime={dayjs(institution.syncDate).format('YYYY-MM-DD')}>
                    {formatDate(institution.syncDate!, 'd MMM yyyy HH:mm')}
                  </time>
                </span>
              }
                {!displayAccountTypeSelector && !syncInError && !syncing && !isManual &&
              <HardbaconButton
                name="sync"
                disabled={isDisabled}
                className={styles.actionBtn}
                inverse={true}
                type="button"
                onPress={handleSync}
                title={t('manageAccounts:synchronize')}
                size={'small'}/>}
            {!displayAccountTypeSelector && syncInError &&
              <HardbaconButton
                name="sync"
                className={styles.actionBtn}
                type="button"
                title={t('manageAccounts:fixThis')} size={'small'}
                onPress={() => {
                  fixError!(institution!);
                  hide();
                }}/>}
              </>
                )}
                {isDisabled
                  ? (
            <p className={styles.exceedMessage}>{t('manageAccounts:exceedDailySyncLimit')}</p>
                    )
                  : null}
        <div className={styles.list}>
          {!displayAccountTypeSelector && institution.institutionAccountDetailDTOs.map(item => {
            return (
              renderAccountItem(item)
            );
          })}
        </div>
        {displayAccountTypeSelector &&
          <AccountTypeSelector
            accountDetails={displayAccountTypeSelector}
            showTypeSelector={institution.institutionSource.description === 'BANKING'}
            onAccountTypeChange={(newAccountDetail: InstitutionAccountDetail) => updateAccountDetails(newAccountDetail)}/>}
      </div>
    </div>
  );
};

export default AccountDetails;
