import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

const UnsecuredLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated } = useSelector((store: any) => store.session);
  if (!authenticated && location.pathname === '/') {
    navigate('/sign-in');
  }
  if (authenticated && location.pathname === '/') {
    navigate('/feed');
  }
  return (
    <Outlet/>
  );
};

export default UnsecuredLayout;
