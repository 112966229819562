import React, { useEffect, useState } from 'react';
import styles from './../assets/styles/modules/verticalProgress.module.css';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../i18n';
import { isMobile } from '../utils/hooks';
import ReactTooltip from 'react-tooltip';

interface VerticalProgressProps {
  fullValue: number;
  currentValue: number;
  containerContentRatio: number;
  isColorFixed?: boolean;
  showDifference?: boolean;
  title: string,
  credit: boolean,
}

const VerticalProgress = ({ fullValue, currentValue, containerContentRatio, isColorFixed, showDifference, title, credit }: VerticalProgressProps) => {
  const { t } = useTranslation();
  const [height, setHeight] = useState(0);
  const isMobileScreen = isMobile();

  let initBase = '#B7E0E0';
  let initBar = '#00CCCC';

  if (isColorFixed) {
    initBase = '#BFE0C4';
    initBar = '#74D783';
    if (currentValue / fullValue >= 1) {
      initBase = '#DD5C68';
      initBar = '#DD5C68';
    } else if (currentValue / fullValue > 0.8) {
      initBase = '#E8D8BE';
      initBar = '#F5A623';
    }
  }

  const valueDiff = Math.abs(fullValue - currentValue);
  const fullBarHeight = isMobileScreen ? 140 : 200;

  useEffect(() => {
    const containerHeight = containerContentRatio === 1 ? fullBarHeight : containerContentRatio * fullBarHeight;
    setHeight(containerHeight);
  }, [containerContentRatio, fullBarHeight]);

  return (
    <div className={styles.container}>
      <div className={styles.bar} style={{ height: height + 'px' }} data-tip data-for={valueDiff.toString(10)} >
        <div className={styles.full} style={{ height: height + 'px', backgroundColor: initBase }}>
          <div className={styles.current} style={{ height: height * (currentValue / fullValue) + 'px', backgroundColor: initBar }} />
        </div>
      </div>
      <div className={styles.footer}>
        <span className={styles.value}>{formatCurrency(currentValue, 0)}</span>
        <span className={styles.title}>{title}</span>
      </div>
      <ReactTooltip id={valueDiff.toString(10)} type={'light'} className={styles.tooltip}>
        <div className={styles.tooltipTitle}>
          {title}
        </div>
        <div className={styles.base} style={{ backgroundColor: initBase }}>
          <div className={styles.bar2} style={{ backgroundColor: initBar, width: `${Math.min(currentValue / fullValue * 100, 100)}%` }}/>
        </div>
        <div className={styles.footer2}>
          <div className={styles.footerValue}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {formatCurrency(currentValue, 0)} {showDifference ? ('(' + Math.round(currentValue / fullValue * 100)) + '%)' : ''}</div>
              <p style={{ fontSize: '10px' }}>{credit ? t('earned') : t('spent')}</p>
            </div>
          {showDifference && (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {formatCurrency(fullValue, 0)}
            <p style={{ fontSize: '10px' }}>{t('target')}</p>
          </div>)}
        </div>
        {!credit && valueDiff !== 0 && (<div className={styles.summary}>
          {t(valueDiff < 0 ? 'youAreOverBudget' : 'youAreOk', { amount: formatCurrency(valueDiff, 0) })}
        </div>)}
      </ReactTooltip>
    </div>
  );
};

export default VerticalProgress;
