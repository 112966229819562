import {
  creditCardCategoryIcon,
  bankingCategoryIcon,
  investmentCategoryIcon,
  mortgageCategoryIcon,
  insuranceCategoryIcon,
  loansCategoryIcon,
  cryptoCategoryIcon,
  creditScoreCategoryIcon,
  budgetCategoryIcon,
  fireCategoryIcon,
  planningCategoryIcon,
  debtsCategoryIcon,
  travelCategoryIcon,
  richestCategoryIcon
} from '../assets/images/index';
import i18n from '../i18n';
import Notification, { NotificationType } from '../model/Notification';
import styles from '../assets/styles/modules/NotificationItem.module.css';

export interface NewsCategoryItem {
    frenchId: number;
    englishId: number;
    key: string;
    icon?: string;
    name: string;
    tootlTip: string;
}

export const getCategoriesIds = (userInterests: NewsCategoryItem[], selectedNewsTopics: string[]) => {
  let result = '';
  userInterests?.map((cat: NewsCategoryItem) => {
    if (selectedNewsTopics?.length) {
      selectedNewsTopics.map((i: string) => {
        if (cat.key === i) {
          if (result) {
            result = result + ',' + cat.englishId + ',' + cat.frenchId;
          } else {
            result = result + cat.englishId + ',' + cat.frenchId;
          }
        }
        return i;
      });
    } else {
      if (result) {
        result = result + ',' + cat.englishId + ',' + cat.frenchId;
      } else {
        result = result + cat.englishId + ',' + cat.frenchId;
      }
    }
    return cat;
  });
  return result;
};

export const getNewsCategories: () => NewsCategoryItem[] = () => {
  return [
    {
      frenchId: 61,
      englishId: 192,
      key: 'CREDIT_CARD',
      icon: creditCardCategoryIcon,
      name: i18n.t('feed:creditCard'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.creditCard')
    },
    {
      frenchId: 14,
      englishId: 195,
      key: 'BANKING',
      icon: bankingCategoryIcon,
      name: i18n.t('feed:bank'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.banking')
    },
    {
      frenchId: 9,
      englishId: 193,
      key: 'INVESTMENT',
      icon: investmentCategoryIcon,
      name: i18n.t('feed:investing'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.investment')
    },
    {
      frenchId: 28,
      englishId: 190,
      key: 'MORTGAGE',
      icon: mortgageCategoryIcon,
      name: i18n.t('feed:mortgage'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.mortgage')
    },
    {
      frenchId: 65,
      englishId: 194,
      key: 'INSURANCE',
      icon: insuranceCategoryIcon,
      name: i18n.t('feed:insurance'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.insurance')
    },
    {
      frenchId: 30,
      englishId: 235,
      key: 'LOANS',
      icon: loansCategoryIcon,
      name: i18n.t('feed:loan'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.loans')
    },
    {
      frenchId: 94,
      englishId: 251,
      key: 'CRYPTO',
      icon: cryptoCategoryIcon,
      name: i18n.t('feed:crypto'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.crypto')
    },
    {
      frenchId: 57,
      englishId: 252,
      key: 'CREDIT_SCORE',
      icon: creditScoreCategoryIcon,
      name: i18n.t('feed:creditScore'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.creditScore')
    },
    {
      frenchId: 37,
      englishId: 189,
      key: 'BUDGET',
      icon: budgetCategoryIcon,
      name: i18n.t('feed:budget'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.budget')
    },
    {
      frenchId: 16,
      englishId: 220,
      key: 'FIRE',
      icon: fireCategoryIcon,
      name: i18n.t('feed:fire'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.fire')
    },
    {
      frenchId: 18,
      englishId: 238,
      key: 'PLANNING',
      icon: planningCategoryIcon,
      name: i18n.t('feed:planning'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.planning')
    },
    {
      frenchId: 32,
      englishId: 250,
      key: 'DEBTS',
      icon: debtsCategoryIcon,
      name: i18n.t('feed:debts'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.debts')
    },
    {
      frenchId: 271,
      englishId: 124,
      key: 'TRAVEL',
      icon: travelCategoryIcon,
      name: i18n.t('feed:travel'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.travel')
    },
    {
      frenchId: 248,
      englishId: 53,
      key: 'RICHEST',
      icon: richestCategoryIcon,
      name: i18n.t('feed:richest'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.richest')
    },
    {
      frenchId: 396,
      englishId: 379,
      key: 'CARS',
      name: i18n.t('feed:car'),
      tootlTip: i18n.t('newsFeed.categoriesTooltip.car')
    }
  ];
};

export const getStyleFromNotificationType = (notification: Notification) => {
  switch (notification.notificationType) {
    case NotificationType.BUDGET_OVER_90_PERCENT:
    case NotificationType.BUDGET_TREND_RED:
      return styles.redTitle;
    case NotificationType.BUDGET_TREND_YELLOW:
      return styles.yellowTitle;
    default:
      return '';
  }
};
