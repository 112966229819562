import User from '../model/User';
import { Dispatch } from 'redux';

import { SET_PICTURE, SET_USER } from '../types/User';
import { SET_AUTHENTICATED, SET_INITIAL_AUTHENTICATED_CHECK, LOGOUT } from '../types/Session';
import { SET_EMAIL_ALREADY_USED, SET_REGISTERING } from '../types/SignUp';
import { SET_SIGNING_IN, SET_WRONG_EMAIL_OR_PASSWORD } from '../types/SignIn';
import {
  SET_FORGOT_PASSWORD_SENT,
  SET_RESET_PASSWORD_ERROR,
  SET_RESET_PASSWORD_DONE, SET_FORGOT_PASSWORD_ERROR
} from '../types/ResetPassword';

import {
  authenticate,
  finishForgotPassword,
  initForgotPassword,
  register,
  getAccount,
  logOut,
  getPicture,
  updateUser,
  updateUserPicture,
  deleteAccount
} from '../api/UserService';
import { belly } from '../assets/images';
import { AnalyticsEvent, identify, logEvent } from '../utils/AnalyticsUtils';
import { GET_USER_NEWS_TOPICS_SUCCESS } from '../types/Feed';

export const signUp = (user: User, recaptcha: string) => (dispatch: Dispatch) => {
  dispatch({ type: SET_REGISTERING, payload: true });

  // Register now auto logins the user and therefore, he is authenticated right away
  register(user, recaptcha).subscribe(
    (userRes) => {
      identify(userRes);
      logEvent(AnalyticsEvent.ACCOUNT_CREATED, userRes);
      dispatch({ type: SET_USER, payload: userRes });
      dispatch({ type: SET_AUTHENTICATED, payload: true });
      dispatch({ type: SET_REGISTERING, payload: false });
    },
    error => {
      dispatch({ type: SET_REGISTERING, payload: false });
      if (error.status === 400 && error.errorKey === 'emailexists') {
        dispatch({ type: SET_EMAIL_ALREADY_USED, payload: true });
      }
    }
  );
};

function signIn (login: string | undefined, password: string | undefined, googleIdToken: string | undefined, appleIdToken: string | undefined, fbIdToken: string | undefined, rememberMe: boolean, recaptcha: string, dispatch: Dispatch) {
  dispatch({ type: SET_SIGNING_IN, payload: true });

  authenticate(login, password, googleIdToken, appleIdToken, fbIdToken, rememberMe, recaptcha).subscribe(userToken => {
    identify(userToken.user);
    dispatch({ type: SET_USER, payload: userToken.user });
    dispatch({ type: SET_AUTHENTICATED, payload: true });
    dispatch({ type: SET_SIGNING_IN, payload: false });
  },
  error => {
    dispatch({ type: SET_SIGNING_IN, payload: false });
    if (error.status === 401) {
      dispatch({ type: SET_WRONG_EMAIL_OR_PASSWORD, payload: true });
      dispatch({ type: SET_AUTHENTICATED, payload: false });
    }
  });
}

export const getProfile = (withSubscription: boolean = false) => (dispatch: Dispatch) => {
  getAccount(withSubscription).subscribe(user => {
    identify(user);
    dispatch({ type: SET_USER, payload: user });
    dispatch({ type: GET_USER_NEWS_TOPICS_SUCCESS, payload: user });
    dispatch({ type: SET_INITIAL_AUTHENTICATED_CHECK, payload: true });
  },
  error => {
    dispatch({ type: SET_INITIAL_AUTHENTICATED_CHECK, payload: false });
    if (error.status === 401) {
      dispatch({ type: SET_AUTHENTICATED, payload: false });
    }
  });
};

export const auth = (login: string, password: string, rememberMe: boolean, recaptcha: string) => (dispatch: Dispatch) => {
  signIn(login, password, undefined, undefined, undefined, rememberMe, recaptcha, dispatch);
};

export const googleAuth = (googleIdToken: string, rememberMe: boolean, recaptcha: string) => (dispatch: Dispatch) => {
  signIn(undefined, undefined, googleIdToken, undefined, undefined, rememberMe, recaptcha, dispatch);
};

export const appleAuth = (appleIdToken: string, rememberMe: boolean, recaptcha: string) => (dispatch: Dispatch) => {
  signIn(undefined, undefined, undefined, appleIdToken, undefined, rememberMe, recaptcha, dispatch);
};

export const fbAuth = (fbIdToken: string, rememberMe: boolean, recaptcha: string) => (dispatch: Dispatch) => {
  signIn(undefined, undefined, undefined, undefined, fbIdToken, rememberMe, recaptcha, dispatch);
};

export const sendForgetPassword = (mail: string) => (dispatch: Dispatch) => {
  dispatch({ type: SET_FORGOT_PASSWORD_ERROR, payload: false });

  initForgotPassword(mail).subscribe(res => {
    dispatch({ type: SET_FORGOT_PASSWORD_SENT, payload: mail });
  },
  () => {
    dispatch({ type: SET_FORGOT_PASSWORD_ERROR, payload: true });
  });
};

export const resetPassword = (key: string, password: string, recaptcha: string) => (dispatch: Dispatch) => {
  dispatch({ type: SET_RESET_PASSWORD_ERROR, payload: false });

  finishForgotPassword(key, password, recaptcha).subscribe(res => {
    dispatch({ type: SET_RESET_PASSWORD_DONE, payload: true });
  },
  () => {
    dispatch({ type: SET_RESET_PASSWORD_ERROR, payload: true });
  });
};

export const logout = () => (dispatch: Dispatch) => {
  logOut().subscribe(() => {
    dispatch({ type: SET_USER, payload: undefined });
    dispatch({ type: SET_AUTHENTICATED, payload: false });
    dispatch({ type: SET_PICTURE, payload: belly });
    dispatch({ type: LOGOUT });
  },
  () => {
    dispatch({ type: SET_USER, payload: undefined });
    dispatch({ type: SET_AUTHENTICATED, payload: false });
    dispatch({ type: SET_PICTURE, payload: belly });
    dispatch({ type: LOGOUT });
  });
};

export const fetchPicture = () => (dispatch: Dispatch) => {
  getPicture().subscribe(res => {
    dispatch({ type: SET_PICTURE, payload: res });
  });
};

export const updateAccountInfo = (user: User) => (dispatch: Dispatch) => {
  updateUser(user).subscribe(res => {
    dispatch({ type: SET_USER, payload: user });
  });
};

export const uploadProfilePicture = (image: string, mimeType: string) => (dispatch: Dispatch) => {
  updateUserPicture(image, mimeType).subscribe(res => {
    dispatch({ type: SET_PICTURE, payload: 'data:' + mimeType + ';base64,' + image });
  });
};

export const deleteUserAccount = (reason: string, comment?: string) => (dispatch: Dispatch) => {
  logEvent(AnalyticsEvent.USER_ACCOUNT_DELETED, { reason }, true);
  deleteAccount(reason, comment).subscribe(() => {
    logOut().subscribe(() => {
      dispatch({ type: SET_USER, payload: undefined });
      dispatch({ type: SET_AUTHENTICATED, payload: false });
      dispatch({ type: SET_PICTURE, payload: belly });
      dispatch({ type: LOGOUT });
    });
  });
};
