import React, { useRef, useEffect, useState } from 'react';
import styles from './../assets/styles/modules/Tooltip.module.css';
import { tooltipIcon } from '../assets/images';

interface TooltipProps {
    position?: 'bottom' | 'bottom-right';
    content: string;
  }

const Tooltip = ({ content, position = 'bottom' }: TooltipProps) => {
  const tooltipRef = useRef<HTMLHeadingElement>(null);
  const [tooltipOpen, setTooptipOpen] = useState(false);

  const handleClickOutside = (event: any) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target)
    ) {
      setTooptipOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  });

  const containerClassNames = styles['tooltip-container'] + ' ' + (position === 'bottom-right' ? styles['bottom-right'] : styles.bottom);

  const renderContent = () => {
    return tooltipOpen
      ? (
      <div className={containerClassNames} ref={tooltipRef}>
          <span className={styles.content}>
              {content}
          </span>
        <div className={position === 'bottom-right' ? styles['arrow-bottom-right'] : styles['arrow-bottom']}/>
      </div>
        )
      : null;
  };

  return (
    <div
      onMouseEnter={() => setTooptipOpen(true)}
      onMouseLeave={() => setTooptipOpen(false)}
      className={styles.container}
    >
      <img className={styles.icon} src={tooltipIcon}/>
      {renderContent()}
    </div>
  );
};

export default Tooltip;
