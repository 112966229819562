import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { removeButtonIcon, addButtonIcon } from '../../../assets/images';
import { RightPanel } from '../../../component';
import { getTransactionCtgNameById, getTransactionCtgEmojiById } from '../../../utils/banking';
import { BudgetStatusTypeEmun } from './Onboarding';
import { useDispatch } from 'react-redux';
import { fetchBankingCategories } from '../../../actions/Banking';
import AddCategory from '../../../component/AddCategory';
import { BankingTransactionCtg } from '../../../model/BankingTransaction';

type CategoryType = {
  [key: string]: {
    id: number;
    value: number;
  }
}

interface BudgetReviewProps {
  categories: CategoryType;
  totalBudget: string;
  monthlyIncome: string;
  diffCurrency: string;
  budgetStatus: BudgetStatusTypeEmun;
  setCategoryValue: Function;
  setCategories: Function;
  openAddCategoryPanel: boolean;
  setOpenAddCategoryPanel: Function
}

const BudgetReview = ({ categories, totalBudget, setOpenAddCategoryPanel, monthlyIncome, openAddCategoryPanel, diffCurrency, budgetStatus, setCategoryValue, setCategories }: BudgetReviewProps) => {
  const [openRightPanel, setOpenRightPanel] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchBankingCategories());
  }, []);

  useEffect(() => {
    setOpenRightPanel(openAddCategoryPanel);
  }, [openAddCategoryPanel]);

  const removeCategory = (key: string) => {
    if (Object.keys(categories).length > 1) {
      const newCategories = JSON.parse(JSON.stringify(categories));
      if (newCategories[key]) {
        delete newCategories[key];
        setCategories(newCategories);
      }
    }
  };

  const renderCategory = (cat: {id: number, value: number}, key: string) => {
    return (
      <div className='category' key={key}>
        <div className='left'>
          {Object.keys(categories).length > 1
            ? <img className={'remove-icon'} alt={'Remove'} src={removeButtonIcon} onClick={() => removeCategory(key)}/>
            : <div className={'remove-icon'} />
          }
          <span className='emoji'>{getTransactionCtgEmojiById(cat.id)}</span>
          {getTransactionCtgNameById(cat.id)}
        </div>
        <input
          className='right'
          type="text"
          name="value"
          value={cat.value}
          onChange={(v) => {
            const newAllCategories = JSON.parse(JSON.stringify(categories));
            newAllCategories[key] = { id: cat.id, value: parseInt(v.target.value) || 0 };
            return setCategories(newAllCategories);
          }}
        />
        <span className='dollar-sign'>$</span>
      </div>
    );
  };

  const onAddCategoryPress = (cat: BankingTransactionCtg) => {
    closePanel();
    setCategoryValue(cat.code, cat.id, 0);
  };

  const closePanel = () => {
    setOpenAddCategoryPanel(false);
    setOpenRightPanel(false);
  };

  return (
    <div className={'budget-review-container'}>
      <RightPanel
        title={t('budgetOnboarding:addCategory')}
        visible={openRightPanel} onClose={() => closePanel()}
        renderContent={() => <AddCategory onItemPress={onAddCategoryPress} />}
      />
      {Object.keys(categories).map((key: string) => renderCategory(categories[key], key))}
      <button className='add-category' onClick={() => setOpenRightPanel(true)}>
        <img className={'add-icon'} alt={'Add a category'} src={addButtonIcon} onClick={() => null}/>
        <p>{t('budgetOnboarding:addCategory')}</p>
      </button>
      <div className='divider'/>
      <div className={'summary ' + (budgetStatus === BudgetStatusTypeEmun.perfect ? 'perfect' : budgetStatus === BudgetStatusTypeEmun.exceed ? 'exceed' : 'subceed')}>
        <div>
          <span className='budget'>{totalBudget}</span>
          <span className='income'>{ ' / ' + monthlyIncome}</span>
        </div>
        <div className='sub-summary' onClick={() => setOpenRightPanel(true)}>
          <span>{diffCurrency + ' '}</span>
          <span>{budgetStatus === BudgetStatusTypeEmun.exceed ? t('budgetExceed') : t('available')}</span>
        </div>
      </div>
    </div>
  );
};

export default BudgetReview;
