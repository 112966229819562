import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../assets/styles/modules/formItemContainerProps.module.css';
import _ from 'lodash';
import { TFunctionResult } from 'i18next';
import Tooltip from './Tooltip';

interface FormItemContainerProps {
  withDivider?: boolean;
  withIndent?: boolean;
  left?: string | React.ReactChild | TFunctionResult;
  leftWidth?: string;
  right?: React.ReactChild | FormInputEmun;
  rightWidth?: string;
  value?: number | string;
  onChange?: Function;
  onPress?: Function;
  maxAmount?: number;
  withInputUnderline?: boolean;
  icon?: string
  placeholder?: string;
  id?: string;
  tooltip?: string;
  allowNegativeValue?: boolean;
  autoFocus?: boolean;
}

/* eslint-disable no-unused-vars */
export enum FormInputEmun {
  moneyInput,
  stringInput,
  percentageInput
}
/* eslint-disable no-unused-vars */

const FormItemContainer = ({ id, placeholder = '0', autoFocus, allowNegativeValue, tooltip, maxAmount, left, right, rightWidth, leftWidth, value, onChange, withDivider = true, withIndent = false, withInputUnderline = false, onPress, icon }: FormItemContainerProps) => {
  const { t } = useTranslation();

  const onNumberChange = (v: string, current: number | string | undefined) => {
    const allowNegativePattern = /^-?[0-9]*\.?[0-9]*$/;
    const pattern = /^[0-9]*\.?[0-9]*$/;
    const isValid = allowNegativeValue ? allowNegativePattern.test(v) : pattern.test(v);
    if (!isValid) {
      return (current);
    } else {
      if (maxAmount && (parseFloat(v) > maxAmount)) {
        return maxAmount;
      } else {
        return v;
      }
    }
  };

  const renderMoneyInput = () => {
    return (
      <>
        <input
          id={id}
          autoFocus={autoFocus}
          placeholder={placeholder}
          maxLength={8}
          className={styles.input + ' ' + (onChange ? '' : styles.disabled) + ' ' + (!_.isFunction(onPress) ? '' : styles.button)}
          name="value"
          value={value}
          disabled={!onChange}
          onChange={(v) => onChange ? onChange(onNumberChange(v.target.value, value) || '') : null}
        />
        <span className={onChange ? '' : styles.disabled}>$</span>
      </>
    );
  };

  const renderPercentageInput = () => {
    return (
      <>
        <input
          id={id}
          autoFocus={autoFocus}
          placeholder={placeholder}
          maxLength={5}
          className={styles.input + ' ' + (onChange ? '' : styles.disabled) + ' ' + (!_.isFunction(onPress) ? '' : styles.button)}
          name="value"
          value={value}
          disabled={!onChange}
          onChange={(v) => onChange ? onChange(onNumberChange(v.target.value, value) || '') : null}
        />
        <span className={onChange ? '' : styles.disabled}>%</span>
      </>
    );
  };

  const renderStringInput = () => {
    return (
        <input
          id={id}
          autoFocus={autoFocus}
          placeholder={placeholder}
          maxLength={50}
          className={styles.input + ' ' + (onChange ? '' : styles.disabled) + ' ' + (!_.isFunction(onPress) ? '' : styles.button)}
          name="value"
          value={value}
          disabled={!onChange}
          onChange={(v) => onChange ? onChange(v.target.value) : null}
        />
    );
  };

  const renderRightComponent = () => {
    switch (right) {
      case FormInputEmun.moneyInput:
        return renderMoneyInput();
      case FormInputEmun.stringInput:
        return renderStringInput();
      case FormInputEmun.percentageInput:
        return renderPercentageInput();
      default:
        return renderMoneyInput();
    }
  };

  const toolTipPosition = _.isString(left) && left?.length < 12 ? 'bottom-right' : 'bottom';

  return (
    <div className={styles.container} onClick={() => onPress ? onPress() : null} style={{ cursor: onPress ? 'pointer' : 'initial' }}>
      <div className={styles.labelWrapper}>
        <label htmlFor={id} className={styles.left} style={leftWidth ? { width: leftWidth } : {}}>
          {withIndent && <div className={styles.icon}/>}
          {icon && <img className={styles.icon} src={icon}/>}
          {left}
          {tooltip && <Tooltip content={tooltip} position={toolTipPosition}/>}
        </label>
      </div>
      <div
        className={styles['input-container'] + ' ' + (withInputUnderline ? styles['with-input-underline'] : '')}
        style={rightWidth ? { width: rightWidth } : {}}
      >
        {React.isValidElement(right) ? right : renderRightComponent()}
      </div>
      {withDivider && <div className={styles.divider}/>}
    </div>
  );
};

export default FormItemContainer;
