import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import Container from './Container';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../i18n';
import { getText } from '../utils/comparastors';
import ComparatorType from '../model/ComparatorType';
import ReducerComparatorState from '../model/ReducerComparatorState';
import ProductRecommendation from '../model/comparators/ProductRecommendation';
import creditCardProduct from '../model/comparators/CreditCardProduct';
import savingProduct from '../model/comparators/SavingProduct';
import CircularProgress, { CircularProgressColorEmun } from './CircularProgress';
import { getRecommendations } from '../actions/Comparator';
import { checkingRoute } from '../navigation/Comparator';
import HardbaconButton from './HardbaconButton';
import styles from '../assets/styles/modules/ComparatorSuggestedProduct.module.css';
import ComparatorLoadingState from '../model/ComparatorLoadingState';
import {
  creditcardComparatorIconActive,
  savingComparatorIconActive
} from '../assets/images';
import { formatAmount } from '../utils/amount';

const CreditCard = ({ product }: { product: ProductRecommendation}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const creditCard = product.product as creditCardProduct;
  const displayedLanguage = i18n.language === 'fr' ? 'french' : 'english';
  const language = i18n.language;
  const firstYearRewards: { label: string; value: string }[] | undefined =
    useMemo(() => {
      if (creditCard) {
        const rewardPerCategories = Object.keys(
          creditCard?.result?.firstYear?.rewardPerCategory
        ).map((key) => {
          return {
            label: `${key}`,
            value: `${formatAmount({
              value:
                creditCard?.result?.firstYear?.rewardPerCategory[key] *
                creditCard?.result?.rewardValue,
              options: { language: i18n.language }
            })}`
          };
        });
        const selectedFirstYearRewards = [
          {
            label: 'totalReward',
            value: `${formatAmount({
              value: creditCard?.result?.firstYear?.totalReward,
              options: { language: i18n.language }
            })}`
          },
          ...rewardPerCategories,
          {
            label: 'annualFee',
            value: `${formatAmount({
              value: creditCard?.result?.firstYear?.annualFee,
              options: { language: i18n.language }
            })}`
          }
        ];

        return selectedFirstYearRewards;
      }
    }, [i18n.language, creditCard]);

  const consecutiveYearsRewards:
    | { label: string; value: string }[]
    | undefined = useMemo(() => {
      if (creditCard) {
        const rewardPerCategories = Object.keys(
          creditCard?.result?.consecutiveYears?.rewardPerCategory
        ).map((key) => {
          return {
            label: `${key}`,
            value: `${formatAmount({
            value:
              creditCard?.result?.consecutiveYears?.rewardPerCategory[key] *
              creditCard?.result?.rewardValue,
            options: { language: i18n.language }
          })}`
          };
        });
        const selectedFirstYearRewards = [
          {
            label: 'totalReward',
            value: `${formatAmount({
            value: creditCard?.result?.consecutiveYears?.totalReward,
            options: { language: i18n.language }
          })}`
          },
          ...rewardPerCategories,
          {
            label: 'annualFee',
            value: `${formatAmount({
            value: creditCard?.result?.consecutiveYears?.annualFee,
            options: { language: i18n.language }
          })}`
          }
        ];

        return selectedFirstYearRewards;
      }
    }, [i18n.language, creditCard]);

  return (
    <>

        <div className={styles.tag}>
          <img src={creditcardComparatorIconActive}></img>
          {t('compare:comparatorOption_CREDITCARD')}</div>
        <div className={styles.header}>
          <div className={styles.institutionLogo}>
            <img src={`${process.env.REACT_APP_COMPARATOR_IMAGE_PROVIDER}/${creditCard.institutionId}`} alt={getText(creditCard.institution.name)}/>
          </div>
          <div className={styles.productName}>
            <div>{getText(creditCard.name)}</div>
            <div className={styles.productNameSubText}>{getText(creditCard.institution.name)}</div>
          </div>
        </div>
        <div className={styles.suggestionContent}>
          <div className={styles.creditCardImageContainer}>
            <img className={styles.creditCardImage} src={'https://hardbacon-prod.s3.us-east-1.amazonaws.com/comparators/5f34236b7096af4588b011d7_card'} alt={getText(creditCard.name)}/>
          </div>
          <div className={styles.estimatedBenefits}>
              <button
                type="button"
                className={styles.estimatedBenefitsInfo}
                onClick={() => setIsModalOpen(true)}
              >
                i
              </button>
            <div>{t('compare:estimatedBenefit')}</div>
            <div className={styles.estimatedBenefitsValue}>{formatCurrency(product.product.result.firstYear.totalReward - product.product.result.firstYear.annualFee, 0)}<span className={styles.perYear}>{t('compare:perYear')}</span></div>
            <a target="_blank" href={`https://hardbacon.ca/${language}/${language === 'en' ? 'compare' : 'comparateur'}/${language === 'en' ? 'credit-cards' : 'cartes-de-credit'}/${creditCard?.institution?.slug?.[displayedLanguage]}/${creditCard?.slug?.[displayedLanguage]}/`} className={styles.productContainer} rel="noreferrer">
              <HardbaconButton name={'more'} title={t('viewMore')} size={'small'}/>
            </a>
          </div>
        </div>
        {creditCard.offerText && (<div className={styles.offerContainer} style={{
          backgroundColor: creditCard.institution.color,
          color: creditCard.institution.textColor
        }} dangerouslySetInnerHTML={{ __html: getText(creditCard.offerText) }}></div>)}
      <Modal
        isOpen={!!isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className={styles.modalWrapper}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0, 0.4)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }}
      >
        <div className={styles.modalContainer}>
          <button
            onClick={() => setIsModalOpen(false)}
            className="absolute -top-4 right-0"
          >
            X
          </button>
          {creditCard
            ? (
            <div>
              <div className="flex gap-x-2 mb-2">
                <div className="relative w-16 h-10">
                  <img
                    src={'https://hardbacon-prod.s3.us-east-1.amazonaws.com/comparators/5f34236b7096af4588b011d7_card'}
                    alt={creditCard?.name?.english ?? ''}
                  />
                </div>
                <div>
                  <h3 className="font-medium">
                    {creditCard?.name?.[displayedLanguage]}
                  </h3>
                  <h4 className="text-xs text-[#788EA4]">
                    {creditCard?.institution?.name?.[displayedLanguage]}
                  </h4>
                </div>
              </div>
              <p className="pb-1 text-sm border-b">
                {t('rewardCategories:estimatedReward')}
              </p>
              {firstYearRewards?.length &&
                firstYearRewards?.map((item) => {
                  return (
                    <div className="flex justify-between my-1" key={item.label}>
                      <span className="text-xs text-[#4B5563]">
                        {t(`rewardCategories:${item?.label.toLowerCase()}`)}
                      </span>
                      <span className="text-xs font-serif-bold">
                        {item?.value}
                      </span>
                    </div>
                  );
                })}
              <hr className="my-2" />
              <p className="text-right text-xs font-serif-bold">
                {formatAmount({
                  value:
                    creditCard?.result?.firstYear?.totalReward -
                    creditCard?.result?.firstYear?.annualFee,
                  options: { language }
                })}
              </p>
              <p className="text-right text-xs text-[#4B5563]">
                {t('rewardCategories:total')}
              </p>
              <hr className="-mx-3 my-2" />
              <p className="pb-1 text-sm border-b">
                {t('rewardCategories:estimatedConsecutiveReward')}
              </p>
              {consecutiveYearsRewards?.length &&
                consecutiveYearsRewards?.map((item) => {
                  return (
                    <div className="flex justify-between my-1" key={item.label}>
                      <span className="text-xs text-[#4B5563]">
                        {t(`rewardCategories:${item?.label.toLowerCase()}`)}
                      </span>
                      <span className="text-xs font-serif-bold">
                        {item?.value}
                      </span>
                    </div>
                  );
                })}
              <hr className="my-2" />
              <p className="text-right text-xs font-serif-bold">
                {formatAmount({
                  value:
                    creditCard?.result?.consecutiveYears?.totalReward -
                    creditCard?.result?.consecutiveYears?.annualFee,
                  options: { language }
                })}
              </p>
              <p className="text-right text-xs text-[#4B5563]">
                {t('total')}
              </p>
              <div className="flex justify-center">
                <a target="_blank" href={`https://hardbacon.ca/${language}/${language === 'en' ? 'compare' : 'comparateur'}/${language === 'en' ? 'credit-cards' : 'cartes-de-credit'}/${creditCard?.institution?.slug?.[displayedLanguage]}/${creditCard?.slug?.[displayedLanguage]}/`} className={styles.productContainer} rel="noreferrer">
                  <HardbaconButton name={'more'} title={t('viewMore')} size={'small'}/>
                </a>
              </div>
            </div>
              )
            : null}
        </div>
      </Modal>
    </>
  );
};

const Savings = ({ product }: { product: ProductRecommendation}) => {
  const { t } = useTranslation();
  const savings = product.product as savingProduct;
  const interestRate = savings.interestsMin === savings.interestsMax ? `${savings.interestsMin} %` : `${savings.interestsMin} % - ${savings.interestsMax} %`;

  return (
    <Link to={`${checkingRoute}#${t('compare:suggested')}`} className={styles.productContainer}>
      <div className={styles.tag}>
        <img src={savingComparatorIconActive}></img>
        {t('compare:comparatorOption_SAVING')}</div>
      <div className={styles.header}>
        <div className={styles.institutionLogo}>
          <img src={`${process.env.REACT_APP_COMPARATOR_IMAGE_PROVIDER}/${savings.institutionId}`} alt={getText(savings.institution.name)}/>
        </div>
        <div className={styles.productName}>
          <div>{getText(savings.name)}</div>
          <div className={styles.productNameSubText}>{getText(savings.institution.name)}</div>
        </div>
      </div>
      <div className={styles.suggestionContent}>
        <div className={styles.estimatedBenefits}>
          <div>{t('compare:interestRate')}</div>
          <div className={styles.value}>{interestRate}</div>
        </div>
        {product.estimatedBenefit !== 0 && (<div className={styles.estimatedBenefits}>
          <div>{t('compare:estimatedBenefit')}</div>
          <div className={styles.estimatedBenefitsValue}>{formatCurrency(product.estimatedBenefit, 0)}<span className={styles.perYear}>{t('compare:perYear')}</span></div>
        </div>)}
      </div>
      <HardbaconButton className={styles.buttonContainer} name={'more'} title={t('viewMore')} size={'small'}/>
      {savings.offerText && (<div className={styles.offerContainer} style={{
        backgroundColor: savings.institution.color,
        color: savings.institution.textColor
      }} dangerouslySetInnerHTML={{ __html: getText(savings.offerText) }}></div>)}
    </Link>
  );
};

const ComparatorSuggestedProduct = ({ selectedTypes = [] }: { selectedTypes: ComparatorType[] }) => {
  const comparatorState: ReducerComparatorState = useSelector((state: any) => state.comparator);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useMemo(() => {
    if (comparatorState.checking.status === ComparatorLoadingState.loaded || comparatorState.saving.status === ComparatorLoadingState.loaded) {
      return false;
    }
    return true;
  }, [comparatorState]);

  useEffect(() => {
    const typesToLoad = ['credit-cards', 'savings'] as ComparatorType[];
    typesToLoad.forEach(t => dispatch(getRecommendations(t)));
  }, [dispatch]);

  return (
    <Container header={`${t('compare:suggestedProducts')}`}>
      <div className={styles.productsContainer}>
        {loading && <CircularProgress classNames={styles.loadingIndicator} color={CircularProgressColorEmun.primary} />}
        {comparatorState.creditCard.status === ComparatorLoadingState.loaded && comparatorState?.creditCard?.recommendations?.length && (
          <>
            <CreditCard product={comparatorState.creditCard.recommendations?.find((creditcRecommendation) => creditcRecommendation.product.cardUserTypes.includes('REGULAR')) ?? comparatorState.creditCard.recommendations[0]} />
            <div className={styles.productSeparator}></div>
          </>
        )}
        {comparatorState.saving.status === ComparatorLoadingState.loaded && comparatorState?.saving?.recommendations?.length && (
          <>
            <Savings product={comparatorState?.saving?.recommendations?.find((savingAcc) => savingAcc?.product?.hasOffer) ?? comparatorState?.saving?.recommendations[0]} />
            <div className={styles.productSeparator}></div>
          </>
        )}
      </div>
    </Container>
  );
};

export default ComparatorSuggestedProduct;
