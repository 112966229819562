import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../i18n';
import styles from './../assets/styles/modules/OfferItem.module.css';

interface OfferItemProps {
    item: any,
}

const OfferItem = ({ item }: OfferItemProps) => {
  const { t, i18n } = useTranslation();

  const isEn = i18n.language !== 'fr';

  const desc = isEn ? item.textEn : item.textFr;
  const link = isEn ? item.linkEn : item.linkFr;
  const logo = isEn ? item.logoUrlEn : item.logoUrlFr;
  const image = isEn ? item.imageUrlEn : item.imageUrlFr;
  const startDate = item.startDate;
  const buttonColor = item.buttonColor;
  const buttonTextColor = item.buttonTextColor;

  return (
    <div className={styles.container}>
    <div className={styles.card}>
    <img src={image} alt={desc} className='card-image'/>
    <div className={styles['card-content']}>
      <img src={logo} alt={desc} className={styles['card-logo']}/>
      <p className={styles['card-desc']}>
      {desc}
      </p>
      <div className={styles['card-footer']}>
        <span className={styles.date}>{formatDate(startDate, 'd MMMM yyyy')}</span>
        <a href={link} target="_blank" rel="noreferrer" className={styles['card-link']} >
          <button type="button" className={styles['card-button']} style={{ backgroundColor: buttonColor, color: buttonTextColor }}>
            {t('feed:learnMoreOffer')}
          </button>
        </a>
      </div>
    </div>
  </div>
  </div>
  );
};

export default OfferItem;
