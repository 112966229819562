/* eslint-disable */
enum InstitutionDescription {
  ONLINE_BROKERAGE = 'ONLINE_BROKERAGE',
  FULL_SERVICE_BROKER = 'FULL_SERVICE_BROKER',
  ROBO_ADVISOR = 'ROBO_ADVISOR',
  MUTUAL_FUND = 'MUTUAL_FUND',
  EMPLOYER = 'EMPLOYER',
  DEMO = 'DEMO',
  VIRTUAL = 'VIRTUAL',
  BANKING = 'BANKING',
  OTHER = 'OTHER'
};

export default InstitutionDescription;
