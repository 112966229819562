import React from 'react';
import { useTranslation } from 'react-i18next';
import { HardbaconButton } from '../../component';
import { ellipse } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import { route as downloadAppRoute } from '../../navigation/DownloadApp';

type ComingSoonProps = {
  featureImgFr: any;
  featureImgEn: any;
}

const ComingSoon = (props: ComingSoonProps) => {
  const { t, i18n } = useTranslation(['comingSoon']);
  const navigate = useNavigate();

  const image = i18n.language === 'fr' ? props.featureImgFr : props.featureImgEn;

  return (
    <div className={'coming-soon'}>
      <div className={'left'}>
        <div className={'title'}>{t('title')}</div>
        <div className={'subTitle'}>{t('subTitle')}</div>
        <HardbaconButton title={t('downloadApp')} name={'downloadApp'}
                         onPress={() => navigate(downloadAppRoute)}/>
      </div>
      <div className={'right'} style={{ backgroundImage: `url(${ellipse})` }}>
        <img alt={'feature image in app'} src={image}/>
      </div>
    </div>
  );
};

export default ComingSoon;
