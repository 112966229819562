import React from 'react';
import { useTranslation } from 'react-i18next';
import { IncomeTypeEmun } from './Onboarding';

const IncomeTypeSelector = ({ setType, type }: { setType: (type: IncomeTypeEmun) => any, type: IncomeTypeEmun}) => {
  const { t } = useTranslation();

  return <div className='income-type-selector'>
      <button
        onClick={() => type !== IncomeTypeEmun.weekly && setType(IncomeTypeEmun.weekly)}
        className={'item' + (type === IncomeTypeEmun.weekly ? ' selected' : '')}>
        <span className={'item-text'}>{t('incomeType:weekly')}</span>
      </button>
      <button
        onClick={() => type !== IncomeTypeEmun.biWeekly && setType(IncomeTypeEmun.biWeekly)}
        className={'item' + (type === IncomeTypeEmun.biWeekly ? ' selected' : '')}>
        <span className={'item-text'}>{t('incomeType:biWeekly')}</span>
      </button>
      <button
        onClick={() => type !== IncomeTypeEmun.biMonthly && setType(IncomeTypeEmun.biMonthly)}
        className={'item' + (type === IncomeTypeEmun.biMonthly ? ' selected' : '')}>
        <span className={'item-text'}>{t('incomeType:biMonthly')}</span>
      </button>
      <button
        onClick={() => type !== IncomeTypeEmun.monthly && setType(IncomeTypeEmun.monthly)}
        className={'item' + (type === IncomeTypeEmun.monthly ? ' selected' : '')}>
        <span className={'item-text'}>{t('incomeType:monthly')}</span>
      </button>
        </div>;
};

export default IncomeTypeSelector;
