import ReducerPayload from '../model/ReducerPayload';
import ReducerSessionState from '../model/ReducerSessionState';
import { SET_AUTHENTICATED, SET_INITIAL_AUTHENTICATED_CHECK } from '../types/Session';

export const initialState: ReducerSessionState = {
  authenticated: false,
  initialAuthenticatedCheck: false
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload
      };
    case SET_INITIAL_AUTHENTICATED_CHECK:
      return {
        ...state,
        authenticated: action.payload,
        initialAuthenticatedCheck: true
      };
    default:
      return state;
  }
};
