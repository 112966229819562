import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logout } from '../../actions/User';
import { route } from '../../navigation/SignIn';

const Logout = () => {
  const { t } = useTranslation();
  const { authenticated } = useSelector((state: any) => state.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      navigate(route);
    } else {
      dispatch(logout());
    }
  }, [authenticated]);

  return (
    <div className="flex h-screen">
      <span className={'m-auto extra-large-text text-gray-medium'}>{t('loggingOut')}</span>
    </div>
  );
};

export default Logout;
