import React from 'react';
import { useTranslation } from 'react-i18next';
import { institution } from '../assets/images';
import { HardbaconButton } from './index';

type AddAccountProps = {
  onAddAccount?: () => void;
  title?: string;
  children?: any;
};

const AddAccount = (props: AddAccountProps) => {
  const { t } = useTranslation('manageAccounts');

  const openAddAccountModal = () => {
    props.onAddAccount && props.onAddAccount();
  };

  return (
      <div className={'add-account'}>
        <img alt={'addAccount'} src={institution} className={'bank-img'}/>
        <div style={{ lineHeight: 1 }}
              className={'title'}>{props.title ? props.title : t('addAccount')}</div>
        {props.children
          ? props.children
          : (<div className={'subtitle-div'}>
              <span className={'subtitle'}>{t('addAccountDescription')}</span>
            </div>)}
        <div className={'button-div'}>
          <HardbaconButton onPress={() => openAddAccountModal()} title={t('addAccount')} name={'addAccount'}/>
        </div>
      </div>
  );
};

export default AddAccount;
