import ReducerSignInState from '../model/ReducerSignInState';
import ReducerPayload from '../model/ReducerPayload';
import { SET_SIGNING_IN, SET_WRONG_EMAIL_OR_PASSWORD } from '../types/SignIn';

export const initialState: ReducerSignInState = {
  wrongEmailOrPassword: false,
  signingIn: false
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case SET_WRONG_EMAIL_OR_PASSWORD:
      return {
        ...state,
        wrongEmailOrPassword: action.payload
      };
    case SET_SIGNING_IN:
      return {
        ...state,
        signingIn: action.payload,
        wrongEmailOrPassword: action.payload ? false : state.wrongEmailOrPassword
      };
    default:
      return state;
  }
};
