import AxiosInstance from './index';
import { fromAxiosError } from '../model/APIError';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BankingTransaction } from '../model/BankingTransaction';
import { clearCachedData } from './caching';

export function getBankingTransactionsService (account: string[], from: string, to: string): Observable<BankingTransaction[]> {
  return AxiosInstance.get<BankingTransaction[]>(`/api/banking/transactions?account=${account}&from=${from}&to=${to}`).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError((error: any) => {
      return throwError(fromAxiosError(error));
    }));
}

// export function getInvestTransactionsService (account: string[], from: string, to: string): Observable<TransactionItemType[]> {
//   return AxiosInstance.get<TransactionItemType[]>(`/api/institutions/transactions?account=${account}&from=${from}&to=${to}`).pipe(
//     map(resp => {
//       return resp.data;
//     }),
//     catchError(error => throwError(fromAxiosError(error))));
// }

export function includeTransactionService (data: string): Observable<string> {
  clearCachedData();
  return AxiosInstance.post<string>('/api/banking/transactions/include', data).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function excludeTransactionService (data: string): Observable<string> {
  clearCachedData();
  return AxiosInstance.post<string>('/api/banking/transactions/exclude', data).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function updateTransactionDescService (data: string): Observable<string> {
  clearCachedData();
  return AxiosInstance.post<string>('/api/banking/transactions/description', data).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getTransactionCategoriesService (): Observable<string> {
  return AxiosInstance.get<string>('/api/banking/categories').pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function splitTransactionBetweenPeopleService (data: string): Observable<string> {
  clearCachedData();
  return AxiosInstance.post<string>('/api/banking/transactions/split', data).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function deleteSplitTransactionBtwPeopleService (data: string): Observable<string> {
  clearCachedData();
  return AxiosInstance.delete<string>(`/api/banking/transactions/${data}/split`).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function categorizeTransactionService (data: string, categorisationType: string): Observable<string> {
  clearCachedData();
  return AxiosInstance.post<string>(`/api/banking/transactions/categorize?mode=${categorisationType}`, data).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function splitTransactionByCategoryService (data: string): Observable<string> {
  clearCachedData();
  return AxiosInstance.post<string>('/api/banking/transactions/split-by-categories', data).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function deleteSplitTransactionByCategoryService (transactionBackendId: string): Observable<string> {
  clearCachedData();
  return AxiosInstance.delete<string>(`api/banking/transactions/${transactionBackendId}/split-by-categories`).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function createManualTransactionService (accountId: number, data: Partial<BankingTransaction>): Observable<any> {
  clearCachedData();
  return AxiosInstance.post(`api/banking/manual/accounts/${accountId}/transactions`, data).pipe(
    map(resp => resp),
    catchError(error => throwError(fromAxiosError(error))));
}

export function updateManualTransactionService (accountId: number, transactionId: number, data: Partial<BankingTransaction>): Observable<any> {
  clearCachedData();
  return AxiosInstance.put(`api/banking/manual/accounts/${accountId}/transactions/${transactionId}`, data).pipe(
    map(resp => resp),
    catchError(error => throwError(fromAxiosError(error))));
}

export function deleteManualTrxService (accountId: number, transactionId: number): Observable<any> {
  clearCachedData();
  return AxiosInstance.delete(`api/banking/manual/accounts/${accountId}/transactions/${transactionId}`).pipe(
    map(resp => resp),
    catchError(error => throwError(fromAxiosError(error))));
}
