import React, { ChangeEvent, Component } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-maskedinput';
import I18n from '../i18n';
import Eye from '../assets/images/svg/eye';
import styles from '../assets/styles/modules/HardbaconInput.module.css';

type HardbaconInputState = {
    value: string;
    error: boolean;
    errorLabel: string;
    passwordVisible: boolean;
}

type HardbaconInputProps = {
    name: string;
    regex?: string;
    type?: string;
    placeholder?: string;
    optional?: boolean;
    isSecureTextEntry?: boolean;
    onChange?: (value?: any) => void;
    errorClassName?: string;
    value?: string;
    disabled?: boolean;
    className?: string;
    mask?: string;
    helpLabel?: string;
}

export class HardbaconInput extends Component<HardbaconInputProps, HardbaconInputState> {
    static defaultProps = {
      onChange: () => {},
      className: 'form-input',
      mask: ''
    }

    constructor (props: HardbaconInputProps) {
      super(props);
      this.state = {
        value: props.value!,
        error: false,
        errorLabel: '',
        passwordVisible: false
      };
    }

    isOptional (): boolean {
      if (this.props.optional === undefined) {
        return true;
      } else {
        return this.props.optional;
      }
    }

    setErrorLabel (errorLabel: string) {
      this.setState({ error: true, errorLabel });
    }

    validate (): boolean {
      if (!this.isOptional() && (this.state.value == null || this.state.value.length === 0)) {
        this.setState({ error: true, errorLabel: I18n.t('noEmptyField') });
        return false;
      }
      if (this.state.value !== null && this.state.value !== undefined && this.state.value.length > 0 && this.props.regex) {
        const value = this.state.value || '';
        if (!value.match(this.props.regex)) {
          this.setState({ error: true, errorLabel: I18n.t('invalidRegex') });
          return false;
        }
      }
      this.setState({ error: false, errorLabel: '' });
      return true;
    }

    render () {
      const { error, errorLabel, passwordVisible } = this.state;
      const { value, name, type, onChange, className, placeholder, errorClassName, disabled, mask, isSecureTextEntry, helpLabel } = this.props;

      const actualClassName = className || 'form-input';
      const actualErrorClassName = errorClassName || 'form-input-error';
      const actualType = isSecureTextEntry && !passwordVisible ? 'password' : type || 'text';

      const inputProps = {
        type: actualType,
        name,
        disabled,
        autoComplete: name,
        id: name,
        className: error ? actualErrorClassName : actualClassName,
        value,
        placeholder,
        onChange: (e: ChangeEvent<any>) => { this.setState({ value: e.target.value }); onChange!(e.target.value); }
      };

      // Whenever we have a mask for the input, use a specific input component
      let maskProps: MaskedInputProps = { mask: '' };
      if (mask) {
        maskProps = {
          mask,
          ...inputProps
        };
      }

      return (
        <div className={styles.container}>
          {mask && <MaskedInput {...maskProps}/>}
          {!mask && <input {...inputProps} />}
          {isSecureTextEntry && (
            <div className={styles.eye} onClick={() => this.setState({ passwordVisible: !passwordVisible })}>
              <Eye off={passwordVisible} color={'#00CCCC'}/>
            </div>)}
            {(helpLabel || errorLabel) && (
                <span
                    className={'small-text ml-3 text-right' + (error ? ' text-red-600' : 'text-gray-medium')}>
                    {helpLabel || errorLabel}
                </span>
            )}
        </div>
      );
    }
}

export default HardbaconInput;
