import React from 'react';
import Comparator from '../container/Comparator';
import { Route, useLocation } from 'react-router-dom';
import ComparatorType from '../model/ComparatorType';

export const route = '/compare';
export const creditCardRoute = route + '/credit-cards';
export const creditCardCartTransfer = creditCardRoute + '/cart-transfer';
export const roboAdvisorRoute = route + '/robo-advisors';
export const savingRoute = route + '/savings';
export const brokerRoute = route + '/brokers';
export const checkingRoute = route + '/chequings';
export const mortgageRoute = route + '/mortgage';
export const mortgageCartTransfer = mortgageRoute + '/cart-transfer';
export const cryptoRoute = route + '/crypto-exchanges';

export default function comparatorRoute () {
  const location = useLocation();

  const isCartTransfer = () => {
    const { pathname } = location;
    return pathname === creditCardCartTransfer || pathname === mortgageCartTransfer;
  };

  const isRunSavedSearch = () => {
    const { search } = location;
    const params = new URLSearchParams(search);
    return params.get('runSavedSearch') !== null;
  };

  return (
    <>
      <Route path={route} element={<Comparator selectedType={ComparatorType.creditCard} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
      <Route path={cryptoRoute} element={<Comparator selectedType={ComparatorType.crypto} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
      <Route path={creditCardRoute} element={<Comparator selectedType={ComparatorType.creditCard} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
      <Route path={roboAdvisorRoute} element={<Comparator selectedType={ComparatorType.roboAdvisor} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
      <Route path={savingRoute} element={<Comparator selectedType={ComparatorType.saving} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
      <Route path={brokerRoute} element={<Comparator selectedType={ComparatorType.broker} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
      <Route path={checkingRoute} element={<Comparator selectedType={ComparatorType.checking} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
      <Route path={mortgageRoute} element={<Comparator selectedType={ComparatorType.mortgage} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
      <Route path={creditCardCartTransfer} element={<Comparator selectedType={ComparatorType.creditCard} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
      <Route path={mortgageCartTransfer} element={<Comparator selectedType={ComparatorType.mortgage} isCartTransfer={isCartTransfer()} isRunSavedSearch={isRunSavedSearch()} />} />
    </>
  );
};
