import React from 'react';
import { Route } from 'react-router-dom';
import Feed from '../container/Feed';
import Offers from '../container/Feed/Offers';

export const route = '/feed';
export const articlesRoute = route + '/articles';
export const offersRoute = route + '/offers';

export default function feedRoute () {
  return (
    <>
      <Route path={route} element={<Feed />}/>
      <Route path={articlesRoute} element={<Feed />}/>
      <Route path={offersRoute} element={<Offers />}/>
    </>
  );
}
