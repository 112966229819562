import React, { useState, useEffect } from 'react';
import Container from '../../../component/Container';
import { getTransactionCtgNameById, getTransactionCtgEmojiById, getIconForMerchant } from '../../../utils/banking';
import styles from './../../../assets/styles/modules/transactionItem.module.css';
import { editIcon, multipleCategoriesIcon } from '../../../assets/images';
import { BankingTransaction } from '../../../model/BankingTransaction';
import { formatCurrency } from '../../../i18n';
import { isMobile, isTablet } from '../../../utils/hooks';
import { useSelector } from 'react-redux';
import _ from 'lodash';

interface TransactionItemProps {
  item: BankingTransaction;
  accountName: string;
  openEditItem: boolean;
  setOpenEditItem: Function;
  setOpenItem: Function;
}

const TransactionItem = ({ item, accountName, openEditItem, setOpenEditItem, setOpenItem }: TransactionItemProps) => {
  const [mouseTimer, setMouseTimer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { description, merchantId, categoryId, debit, credit, excluded, splitByCategories } = item;

  const { filteredTrxCategories } = useSelector((state: any) => state.transactions);

  const onEdit = () => {
    setOpenEditItem(true);
    setOpenItem(item);
    setIsEdit(false);
  };

  useEffect(
    () => {
      const timer = setTimeout(() => setMouseTimer(true), 100);
      return () => {
        clearTimeout(timer);
      };
    },
    []
  );
  const isMobileScreen = isMobile();
  const isTabletScreen = isTablet();
  const editAreaWidth = isMobileScreen ? 50 : 100;

  let amount = debit || credit;
  if (!_.isEmpty(splitByCategories) && !_.isEmpty(filteredTrxCategories)) {
    amount = 0;
    filteredTrxCategories.map((i: number) => {
      Object.keys(splitByCategories).map((key) => {
        if (i.toString() === key) {
          amount = amount + splitByCategories[key];
        }
        return key;
      });
      return i;
    });
  }
  const renderCategory = () => {
    return (
      <div className={styles.category}>
      { categoryId === 0 && <img className={styles['splited-icon']} src={multipleCategoriesIcon}/>}
      <span>
        {getTransactionCtgEmojiById(categoryId) + ' ' + getTransactionCtgNameById(categoryId)}
      </span>
    </div>
    );
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => {
        if (!isTabletScreen && mouseTimer) {
          setIsEdit(true);
        }
      }}
      onMouseLeave={() => !isTabletScreen && setIsEdit(false)}
    >
      <div className={styles.edit} style={{ width: isEdit && !openEditItem ? editAreaWidth + 'px' : 0 }}>
        <div className={styles['edit-button-container']} onClick={() => onEdit()}>
          <img className={styles['edit-button']} src={editIcon} onClick={() => null}/>
        </div>
      </div>
      <Container isExcluded={excluded} onClick={() => isTabletScreen && onEdit()}>
        <div className={styles.transaction}>
          <div className={styles.head}>
            <img src={getIconForMerchant(merchantId)} className={styles.icon}/>
            <div>
              <p>{description}</p>
              <p className={styles.desc}>{accountName}</p>
             {isMobileScreen && renderCategory()}
            </div>
          </div>
          {!isMobileScreen && renderCategory()}
          <div>
            <span className={item.credit > 0 ? styles.income : ''}>{ (item.debit > 0 ? '-' : '') + formatCurrency(debit || credit, 2, item.currency)}</span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TransactionItem;
