import { budgetFr } from './budget';
import { categoriesFr } from './categories';
import { errMsgsFr } from './errMsgs';
import { feedFr } from './feed';
import { tooltipsFr } from './tooltips';
import { transactionsFr } from './transactions';

const fr = {
  rewardCategories: {
    estimatedReward: 'Montant estimé des remises la première année',
    estimatedConsecutiveReward: 'Montant estimé des remises pour les années subséquentes',
    totalreward: 'Récompense Totale',
    'air travel': 'Voyages Aériens',
    'rental car & taxi': 'Rental Car & Taxi',
    'additional bonus': 'Location De Voitures et de Taxis',
    gym: 'Gym',
    restaurants: 'Restaurants',
    sports: 'Sports',
    annualfee: 'Cotisation Annuelle',
    'auto & transport': 'Voiture et transport',
    'auto insurance': 'Assurance auto',
    'auto payment': 'Paiement de voiture',
    parking: 'Stationnement',
    'public transportation': 'Transport en commun',
    'service & parts': 'Services et pièces',
    'bills & utilities': 'Factures et services',
    'domain names': 'Noms de domaine',
    'fraud protection': 'Protection contre la fraude',
    'home phone': 'Téléphone résidentiel',
    hosting: 'Hébergement Web',
    internet: 'Internet',
    'mobile phone': 'Téléphone cellulaire',
    television: 'Télévision',
    utilities: 'Électricité et services publics',
    'business services': 'Services aux entreprises',
    advertising: 'Publicité',
    legal: 'Légal',
    'office supplies': 'Fournitures de bureau',
    printing: 'Imprimerie',
    shipping: 'Frais de livraison',
    education: 'Éducation',
    'books & supplies': 'Livres et fournitures de bureau',
    'student loan': 'Prêt étudiant',
    tuition: 'Frais de scolarité',
    entertainment: 'Divertissement',
    amusement: 'Activités',
    arts: 'Arts',
    'movies & dvds': 'Films et Streaming',
    music: 'Musique',
    'newspapers & magazines': 'Journaux et magazines',
    'fees & charges': 'Frais bancaires',
    'atm fee': 'Frais GAB',
    'banking fee': 'Frais bancaires',
    'finance charge': 'Frais financier',
    'late fee': 'Frais de retard',
    'service fee': 'Frais de service',
    'trade commissions': 'Frais de transactions',
    financial: 'Finance',
    'financial advisor': 'Conseiller financier',
    'life insurance': 'Assurance vie',
    'food & dining': 'Alimentation et repas',
    'alcohol & bars': 'Bars et alcools',
    'coffee shops': 'Cafés',
    'fast food': 'Restauration rapide',
    groceries: 'Épicerie',
    'gifts & donations': 'Cadeaux et dons',
    charity: 'Bienfaisance',
    gift: 'Cadeaux',
    'health & fitness': 'Santé et entraînement physique',
    dentist: 'Dentiste',
    doctor: 'Médecin',
    eyecare: 'Soins des yeux',
    'health insurance': 'Assurance maladie',
    pharmacy: 'Pharmacie',
    home: 'Maison',
    furnishings: 'Meubles',
    'home improvement': 'Rénovations',
    'home insurance': 'Assurance habitation',
    'home services': 'Services à domicile',
    'home supplies': 'Produits de consommation courante',
    'lawn & garden': 'Gazon et jardinage',
    'mortgage & rent': 'Hypothèque et loyer',
    income: 'Revenu',
    bonus: 'Bonus',
    'interest income': "Revenu d'intérêts",
    paycheck: 'Chèque de paye',
    reimbursement: 'Remboursement',
    'rental income': 'Revenus de location',
    investments: 'Investissements',
    buy: 'Acheter',
    deposit: 'Dépôt',
    'dividend & cap gains': 'Dividendes et gains en capital',
    sell: 'Vendre',
    withdrawal: 'Retrait',
    kids: 'Activités familiales',
    allowance: 'Allocation',
    'baby supplies': 'Articles pour bébé',
    'babysitter & daycare': 'Gardiennage et garderie',
    'child support': 'Pension alimentaire',
    'kids activities': 'Activités familiales',
    toys: 'Jouets',
    'personal care': 'Soins personnels',
    hair: 'Coiffure',
    laundry: 'Buanderie',
    'spa & massage': 'Spa et massage',
    pets: 'Animaux de compagnie',
    'pet food & supplies': 'Nourriture et articles pour animaux',
    'pet grooming': "Toilettage d'animaux",
    veterinary: 'Vétérinaire',
    shopping: 'Magasinage',
    books: 'Livres',
    clothing: 'Vêtements',
    hobbies: 'Loisirs',
    'sporting goods': 'Articles de sport',
    taxes: 'Taxes',
    'federal tax': 'Impôt fédéral',
    'local tax': 'Taxes',
    'property tax': 'Taxes foncières',
    'sales tax': 'Taxes de vente',
    'state tax': 'Taxes provinciales',
    transfer: 'Transfert',
    'credit card payment': 'Paiement de carte de crédit',
    'transfer for cash spending': 'Transfert afin de dépenser',
    'mortgage payment': 'Paiement hypothécaire',
    travel: 'Voyage',
    hotel: 'Hôtel',
    vacation: 'Vacances',
    cash: 'Argent',
    check: 'Chèque',
    'electronics & software': 'Électronique et Logiciels',
    'returned purchase': "Retours d'achats"
  },
  translation: {
    delete: 'Supprimer',
    create: 'Créer',
    from: 'du',
    to: 'au',
    type: 'Type',
    categories: 'Catégories',
    filter: 'Filtre',
    viewMore: 'Voir plus',
    edit: 'Modifier',
    update: 'Mettre à jour',
    start: 'Commencer',
    finish: 'Terminer',
    continue: 'Continuer',
    comingSoon: 'A venir...',
    simpleFinance: 'Prenez de meilleures décisions financières',
    keepControlOfYourFinance: 'Tous les outils dont vous avez besoin pour planifier, budgéter et investir !',
    peopleWhoWonTheLoto: 'L\'app de finances personnelles la plus complète au Canada.',
    alreadyHaveAccount: 'Vous avez déjà un compte ?',
    signIn: 'Se connecter',
    signInWithGoogle: 'Se connecter avec Google',
    signInWithApple: 'Se connecter avec Apple',
    signInWithFacebook: 'Se connecter avec Facebook',
    signUp: 'S\'inscrire',
    signUpWithGoogle: 'S\'inscrire avec Google',
    signUpWithApple: 'S\'inscrire avec Apple',
    signUpWithFacebook: 'S\'inscrire avec Facebook',
    loggingOut: 'Déconnexion...',
    createAccount: 'Créez un nouveau compte pour commencer',
    firstName: 'Prénom',
    lastName: 'Nom',
    phonePlaceholder: '(514) 123-4567',
    email: 'Courriel',
    password: 'Mot de passe',
    passwordRegex: 'Votre mot de passe doit contenir au moins 10 caractères',
    passwordMatch: 'Votre mot de passe doit correspondre à celui du dessus',
    noEmptyField: 'Ce champ est requis',
    readAndAgreed: 'J\'ai lu, compris et accepté les ',
    termsOfUse: 'Conditions d\'utilisation',
    invalidRegex: 'Veuillez vérifier que cette information est valide',
    emailAlreadyTaken: 'Ce courriel est déjà utilisé. Veuillez en utiliser un autre.',
    dontHaveAccount: 'Vous n\'avez pas de compte ?',
    wrongEmailOrPassword: 'email ou mot de passe invalide.',
    rememberMe: 'Se souvenir de moi',
    forgotPassword: 'Mot de passe oublié ?',
    emailSent: 'Courriel envoyé',
    afterResetText: 'Si un compte existe avec le courriel "{{email}}", des instructions pour réinitialiser le mot de passe y ont été envoyées.',
    afterResetText2: 'Notez que ceci peut prendre un peu de temps. Si vous ne recevez pas le courriel, veuillez vérifier vos spams avant de réessayer.',
    resetDone: 'Mot de passe modifié',
    resetDoneText: 'Votre mot de passe a été modifié avec succès.',
    sendEmail: 'Envoyer un courriel',
    resetPassword: 'Nouveau mot de passe',
    hardbaconLinkTerms: 'https://hardbacon.ca/fr/legal/',
    newPassword: 'Entrez votre nouveau mot de passe',
    confirmPassword: 'Confirmez votre nouveau mot de passe',
    sendNewPassword: 'Réinitialiser le mot de passe',
    confirmPasswordError: 'Le nouveau mot de passe et sa confirmation ne sont pas égaux !',
    resetPasswordInitError: 'Veuillez vérifier votre courriel et réessayer.',
    resetPasswordFinishError: 'Le lien pour réinitialiser votre mot de passe a peut-être expiré. Veuillez ré-essayer.',
    manageInformation: 'Gérer toutes les informations en lien avec votre profil',
    profilePicture: 'Photo de profil',
    phoneNumber: 'Numéro de téléphone',
    birthday: 'Date de naissance',
    change: 'Changer',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    close: 'Fermer',
    chooseFile: 'Choisir un fichier...',
    chooseFile2: '',
    chooseFile3: 'PNG, JPG, GIF jusqu\'à 500KB',
    uploadImageDialogTitle: 'Veuillez sélectionner votre fichier image',
    uploadImageDialogUnableToLoad: 'Impossible de charger l\'image',
    uploadImageDialogUnableToLoadErrorMessage: 'Votre fichier est peut-être trop gros, le maximum autorisé est 500 KO.',
    premium: 'Premium',
    managePremium: 'Gestion d\'abonnement à Hardbacon Premium',
    subscribedFrom: 'Abonné via',
    nextRenewal: 'Prochain renouvellement',
    expirationDate: 'Date d\'expiration',
    appstore: 'App Store',
    playstore: 'Play Store',
    webSubscription: 'Web',
    couponSubscription: 'Coupon promotionnel',
    cancelSubscription: 'Annuler mon abonnement',
    cancelLinkAndroid: 'https://hardbacon.ca/fr/assistance/desabonnement/#1591903058665-c912928e-fe43',
    cancelLinkIOS: 'https://hardbacon.ca/fr/assistance/desabonnement/#1591903323107-08c81da8-7b9d',
    cancelWebSubscriptionTitle: 'Vous souhaitez annuler votre abonnement ?',
    cancelWebSubscriptionDescription: 'Pour annuler votre abonnement, veuillez envoyer un message à support@hardbacon.com',
    iUnderstand: 'J\'ai compris',
    subscriptionModalTitle: 'S\'abonner à Hardbacon Premium',
    subscription_freeTrial: 'Essayez gratuitement pendant 30 jours',
    subscription_freeTrialSub: 'Vous ne serez pas facturés durant l\'essai gratuit et vous pouvez annuler à tout moment.',
    subscription_price: '{{ price }} $',
    subscription_month: 'mois',
    subscription_year: 'an',
    subscription_MonthlyPlan: 'Plan mensuel',
    subscription_AnnualPlan: 'Plan annuel',
    subscription_ChangePlan: 'Changer de plan',
    subscription_name: 'Nom sur la carte',
    subscription_cardInfo: 'Informations de carte',
    subscription_iHaveACoupon: 'J\'ai un coupon',
    subscription_proceed: 'Commencer mon essai gratuit de 30 jours',
    subscription_wait: 'Veuillez patienter...',
    subscription_thanks_title: 'Merci',
    subscription_thanks_subtitle1: 'Vous faites maintenant partie de la famille',
    subscription_thanks_subtitle2: 'Nous vous avons envoyé le lien pour installer l\'application,\nvous pouvez également télécharger l\'app directement :',
    subscription_thanks_help: 'Si vous avez déjà l\'application, assurez de vous déconnecter puis de vous reconnecter pour profiter des fonctionnalités premium.',
    subscription_thanks_TPS: 'TPS : {{ TPS }} $',
    subscription_thanks_TVQ: 'TVQ : {{ TVQ }} $',
    subscription_thanks_total: 'Total : {{ total }} $',
    subscription_t1: 'Essayez Hardbacon Premium',
    subscription_t1b: 'gratuitement',
    subscription_t1c: '\npendant 30 jours',
    subscription_t2: 'Seulement 12,99$ par mois par la suite ou 99,99$ par année',
    subscription_t3: 'Essayez Premium',
    subscription_t4: 'Surveillez vos investissements comme jamais auparavant',
    subscription_t5: 'Hardbacon vous présente toutes les données dont avez besoin pour prendre de meilleures décisions d\'investissement, peu importe si vous êtes investisseurs autonomes ou travaillez avec un conseiller financier. Et en prenant de meilleures décisions, vous devriez pouvoir atteindre vos objectifs plus rapidement.',
    subscription_i1: 'Tout sous le même toit',
    subscription_i2: 'Rapport et score de portefeuille',
    subscription_i3: 'Diagnostic',
    subscription_i4: 'Répartition d\'actifs',
    subscription_i5: 'Explorer',
    subscription_i6: 'Compte de pratique',
    subscription_i7: 'Données financières premium',
    subscription_i8: 'Historique de vos transactions bancaires et boursières',
    saveSuccess: 'Sauvegarde réussie',
    updatedAccountDescription: 'Votre profil a été mis à jour',
    updatedAccountDetailsDescription: 'Vos informations de compte ont été mises à jour',
    or: 'Ou',
    iAlreadyHaveAnAccount: 'J\'ai déjà un compte (Se connecter)',
    iDontHaveAnAccount: 'Je n\'ai pas encore de compte (S\'inscrire)',
    seeMore: ' See more',
    pageNotFound: 'Page introuvable',
    budgetExceedOverlayTitle: 'Voulez-vous vraiment dépenser plus que ce que vous gagnez ?',
    budgetExceedOverlaySubTitle: "Nous vous recommandons d'éviter de budgéter des dépenses supérieures à vos revenus",
    budgetExceedOverlayConfirm: 'Ne pas dépenser plus',
    budgetExceedOverlayCancel: 'Je veux dépenser plus',
    budgetSubceedOverlayTitle1: 'Voulez-vous ajouter les ',
    budgetSubceedOverlayTitle2: ' restant dans la catégorie épargne ?',
    budgetSubceedOverlaySubTitle1: 'Il vous reste ',
    budgetSubceedOverlaySubTitle2: ' à budgéter pour donner rôle à 100% de vos dollars.',
    budgetSubceedOverlayConfirm1: 'Épargner ',
    budgetSubceedOverlayConfirm2: ' par mois',
    budgetSubceedOverlayCancel: 'Ne pas ajouter',
    budgetSubceedOverlay2Title1: 'Voulez-vous ajouter les ',
    budgetSubceedOverlay2Title2: ' restant dans une nouvelle catégorie ?',
    budgetSubceedOverlay2SubTitle: 'Faites travailler votre argent',
    budgetSubceedOverlay2Confirm: 'Ajouter une catégorie ',
    budgetSubceedOverlay2Cancel: 'Ne pas budgéter les ',
    timePeriod_12: '1a',
    timePeriod_6: '6m',
    timePeriod_3: '3m',
    timePeriod_1: '1m',
    ...budgetFr,
    ...categoriesFr,
    ...transactionsFr,
    ...errMsgsFr
  },
  feed: {
    ...feedFr
  },
  grantAccess: {
    grantAccessRequestTitle: 'Demande d\'accès à vos données',
    grantAccessRequest: 'Vous voyez cette page car vous avez demandé de l\'aide à notre équipe support et celle-ci a besoin d\'accéder à vos données financières pour vous aider.',
    grantAccessRequestDesc: 'En approuvant cette demande, vous leur permettez de consulter (en lecture seule) vos données financières, pour une durée limitée.',
    grantAccess: 'Approuver l\'accès',
    grantAccessWaiting: 'Veuillez patienter pendant que nous vérifions votre approbation d\'accès...',
    grantAccessVerified: 'Merci pour votre confiance. Vos données sont en sécurité avec nous.',
    grantAccessVerified2: 'Notre équipe support est maintenant en mesure d\'investiguer avec vos données jusqu\'au {{ validUntil }}.'
  },
  tooltips: {
    ...tooltipsFr
  },
  manageAccounts: {
    exceedDailySyncLimit: 'Vous avez atteint votre limite quotidienne de 2 synchronisations forcées',
    manualAccountAmount: 'Montant',
    addManualAccount: 'Créer un compte manuel',
    deleteAccount1Title: 'Do you really want to delete this account?',
    deleteAccount1MainButton: 'No, keep it',
    deleteAccountSubButton: 'Supprimer ce compte',
    deleteAccount2Title: 'Voulez-vous vraiment supprimer ce compte?',
    deleteAccount2Desc: 'En le supprimant, TOUTES ses transactions seront supprimées. Cette action ne peut pas être annulée. ',
    manualAccount: 'Compte manuel',
    title: 'Mes comptes',
    All: 'Tous',
    updateMyAccounts: 'Mettre à jour',
    save: 'Sauvegarder',
    synchronized: 'Synchronisé',
    unsynchronized: 'Non Synchronisé',
    synchronizing: 'En cours...',
    notConnected: 'Échec de la dernière synchronisation',
    close: 'Fermer',
    lastSync: 'Dernière MAJ',
    synchronize: 'Synchroniser',
    disconnectAccount: 'Supprimer',
    disconnectAccountModalTitle: 'Important',
    disconnectAccountModalRemoveIt: 'Oui, supprimez-le',
    disconnectAccountModalKeepIt: 'Non, ne rien faire',
    disconnectAccountModalDescription: 'Êtes-vous sur de vouloir supprimer ce compte ?',
    disconnectAccountModalDescriptionSubText: 'Cette action est irréversible',
    addAccount: 'Connecter mon compte',
    addAccountDescription: 'Ajoutez vos comptes et ayez un aperçu global de vos finances au même endroit.',
    manageMyAccounts: 'Gérer mes comptes',
    manageMyAccountsDescription: 'Ajouter vos comptes de banque',
    bankLevelSecurity: 'Sécurité de niveau bancaire',
    bankLevelSecurityLink: 'https://hardbacon.ca/fr/securite/',
    addAccountTitle: 'Quel type de compte voulez-vous connecter ?',
    addingMyAccount: 'Connecter mon account',
    addBankAccount: 'Bancaire',
    addInvestmentAccount: 'Investissement',
    AccountType_CHECKING: 'Chèques',
    AccountType_SAVINGS: 'Épargnes',
    AccountType_CREDIT_CARD: 'Carte de crédit',
    AccountType_LINE_OF_CREDIT: 'Marge de crédit',
    AccountType_INVESTMENT: 'Investissement',
    AccountType_MARGIN: 'Marge',
    AccountType_LOAN: 'Prêt',
    AccountType_MORTGAGE: 'Hypothèque',
    AccountType_PROPERTY: 'Propriété',
    AccountType_INSURANCE: 'Assurance',
    AccountType_PREPAID: 'Prépayé',
    AccountType_ANY: 'Tous',
    AccountType_CASH: 'Argent',
    AccountType_UNKNOWN: 'Autre',
    AccountSubType_TFSA: 'CELI',
    AccountSubType_RRSP: 'REER',
    AccountSubType_LIRA: 'CRI',
    AccountSubType_RESP: 'REEE',
    AccountSubType_MARGIN: 'Margin',
    AccountSubType_UNKNOWN: 'Autre',
    accountType: 'Type de compte',

    InstitutionType_ONLINE_BROKERAGE: 'Leurs clients transigent eux-mêmes sur le marché boursier via Internet.',
    InstitutionType_FULL_SERVICE_BROKER: 'Leurs clients transigent par l\'entremise d\'un conseiller en placement.',
    InstitutionType_ROBO_ADVISOR: 'Leurs clients investissent dans des portefeuilles modèles sans passer par un conseiller.',
    InstitutionType_MUTUAL_FUND: 'Leurs clients investissent dans des fonds communs de placement par l\'entremise d\'un conseiller.',
    InstitutionType_BANK_ACCOUNT: 'C\'est là que vous déposez votre chèque de paye et payez vos factures.',
    InstitutionType_EMPLOYER: 'Compte d\'investissement administré par un employeur',
    InstitutionType_DEMO: 'Compte d\'investissement utilisé à fin de démonstration.',
    InstitutionType_OTHER: 'Comptes d\'investissement autres.',
    InstitutionType_CUSTODY: 'Offre des services de garde de valeurs et de courtage à des firmes d\'investissements indépendantes.',
    InstitutionType_DISCRETIONARY: 'Service de gestion d\'actifs discriétionnaire intégré à des services de planification financière et successorale.',
    InstitutionType_HIGH_WORTH: 'Offre des services bancaires et de gestion de patrimoine à des clients à haute valeur nette.',
    InstitutionType_ACCESS: 'Leurs clients transigent eux-mêmes sur le marché boursier via Internet, mais peuvent obtenir de l\'aide d\'un conseiller en placement.',
    InstitutionType_WEALTH_MANAGEMENT: 'Offre des services de gestion de patrimoine à des clients à haute valeur nette.',
    InstitutionType_BANKING: 'Compte chèque',

    // For group in picker
    Group_nbc: 'Banque Nationale',
    Group_desjardins: 'Desjardins',
    Group_td: 'TD',
    Group_tangerine: 'Tangerine',
    Group_scotia: 'Scotia',
    Group_rbc: 'RBC',
    Group_manulife: 'Manulife',
    Group_laurentian: 'Banque Laurentienne',
    Group_hsbc: 'HSBC',
    Group_fidelity: 'Fidelity',
    Group_eqbank: 'Banque EQ',
    Group_cibc: 'CIBC',
    Group_bmo: 'BMO',
    Group_demo: 'Démo',
    LoginIdentifier_mxbmo: 'Numéro de carte',
    LoginIdentifier_mxcibc: 'Numéro de carte',
    LoginIdentifier_mxdesjardins: 'Numéro de carte',
    LoginIdentifier_mxnational: 'Courriel',
    LoginIdentifier_mxeqbank: 'Courriel',
    LoginIdentifier_mxrbc: 'N° de carte-client',
    LoginIdentifier_mxtd: 'Nom d\'utilisateur ou carte Accès',
    LoginIdentifier_mxamexca: 'CODE D\'UTILISATEUR',
    LoginIdentifier_mxatb: 'Identifiant',
    LoginIdentifier_mxcanadiantire: 'Nom d\'utilisateur',
    LoginIdentifier_mxcapitalone: 'Nom d\'utilisateur',
    LoginIdentifier_mxcoastcapitalsavings: 'Nom d\'utilisateur',
    LoginIdentifier_mxfirstnationalfi: 'ENTREZ VOTRE NOM D\'UTILISATEUR',
    LoginIdentifier_mxhsbc: 'Entrer votre nom d\'utilisateur',
    LoginIdentifier_mxlaurentian: 'Code d\'accès',
    LoginIdentifier_mxmainstreet: 'OnlineAccess ID',
    LoginIdentifier_mxmbna: 'Nom d\'utilisateur',
    LoginIdentifier_mxmeridian: 'Courriel ou identifiant',
    LoginIdentifier_mxnorthern: 'Nom d\'utilisateur',
    LoginIdentifier_mxprospera: 'Nom d\'utilisateur',
    LoginIdentifier_mxscotia: 'Nom d\'utilisateur ou numéro de carte',
    LoginIdentifier_mxsimplii: 'Numéro de carte',
    LoginIdentifier_mxtangerine: 'N° de Client, N° de Carte ou identifiant',
    LoginIdentifier_mxvancity: 'MEMBER CARD #',
    // credentials fields
    computershareemp_username: 'Nom d\'Utilisateur',
    username: 'Identifiant',
    employer: 'Nom de l\'Employeur ou ID',
    code: 'Code',
    token: 'Clé',
    _key: 'Clé',
    secret: 'Secret',
    LOGIN: 'Identifiant',
    PASSWORD: 'Mot de passe',
    next: 'Suivant',
    securityFAQ: 'FAQ sur la sécurité',
    Partner_WEALTHICA: 'Propulsé par notre partenaire de confiance Wealthica',
    Partner_MX: 'Propulsé par notre partenaire de confiance MX',
    Partner_PLAID: 'Propulsé par notre partenaire de confiance PLAID',

    route_sign_up: 'Hardbacon - S\'inscrire',
    route_sign_up_description: 'Créer un compte Hardbacon',
    route_sign_in: 'Hardbacon - Connexion',
    route_sign_in_description: 'Se connecter à son compte Hardbacon',
    route_forgot_password: 'Hardbacon - Mot de passe oublié',
    route_forgot_password_description: 'Formulaire de réinitialisation de mot de passe oublié',
    route_logout: 'Hardbacon - Déconnexion',
    route_logout_description: 'Déconnexion de son compte Hardbacon',
    route_home: 'Hardbacon - Accueuil',
    route_profile: 'Hardbacon - Mon compte',
    route_plan: 'Hardbacon - Planifier',
    route_budget: 'Hardbacon - Budgeter',
    route_investment: 'Hardbacon - Investir',
    route_compare: 'Hardbacon - Comparer',
    securityLink: 'https://hardbacon.ca/fr/securite/',
    answerQuestion: 'Veuillez répondre à cette question de sécurité',
    send: 'Envoyer',
    addAccountGenericError: 'Une erreur s\'est produite',
    addAccountGenericErrorDescription: 'Veuillez réeesayer ou contacter le support si ca ne fonctionne toujours pas.',
    addAccountLoginFailedError: 'Vos informations d\'authentification sont incorrectes. Veuillez réessayer',
    addAccountSecurityQuestionError: 'Veuillez répondre à la question de sécurité liée à votre compte bancaire.',
    addAccountSecurityAnswerFailedError: 'Votre réponse à la question de sécurité est incorrecte. Voulez-vous réessayer ?',
    fixThis: 'Réparer',
    retry: 'Réessayer',
    polling: 'Connexion en cours...',
    pollingDone: 'Connexion réussie',
    connexionInsideOtherWebsite: 'Veuillez terminer la connexion à votre compte dans la page qui doit s\'ouvrir automatiquement.',
    connexionInsideOtherWebsiteDescription: 'Une fois la connexion établie, vous serez automatiquement redirigé vers Hardbacon.',
    searchInstitution: 'Rechercher une institution...',
    institutionNotFound: 'Vous ne trouvez pas votre institution financière ?',
    institutionNotFoundLink: 'Faire une demande',
    accountName: 'Nom du compte',
    initialBalance: 'Solde',
    initialDate: 'Date',
    currency: 'Devise'
  },
  header: {
    feed: 'Actualité',
    plan: 'Planifier',
    budget: 'Budgéter',
    transactions: 'Transactions',
    overview: 'Aperçu',
    invest: 'Investir',
    compare: 'Comparer',
    premium: 'Premium',
    manageMyAccounts: 'Gérer mes comptes',
    viewProfile: 'Mon profil',
    profile: 'Profil',
    signOut: 'Se déconnecter',
    otherLang: 'English',
    otherLangCode: 'en',
    viewSupport: 'Obtenir de l\'aide',
    hideSupport: 'Cacher l\'aide'
  },
  downloadApp: {
    title: 'Téléchargez Hardbacon sur votre téléphone intelligent et prenez le contrôle de vos finances',
    subTitle: 'Envoyez le lien de téléchargement à votre mobile, ou téléchargez l\'appli directement dans l\'',
    or: ' ou ',
    phonePlaceholder: 'Numéro de téléphone',
    send: 'Envoyer le lien',
    sent: 'Lien envoyé !',
    appStoreLink: 'https://apps.apple.com/ca/app/hardbacon-my-financial-coach/id1313964435?l=fr',
    playStoreLink: 'https://play.google.com/store/apps/details?id=ca.hardbacon.hardbacon&hl=fr_CA',
    whenClicking: 'En cliquant "Envoyer le lien", vous acceptez les '
  },
  comingSoon: {
    title: 'Cette fonctionnalité arrive bientôt !',
    subTitle: 'Elle est déjà disponible sur notre application - vous pouvez la télécharger dès maintenant !',
    downloadApp: 'Télécharger l\'application'
  },
  compare: {
    rewardCategories: {
      'personal care': 'Soins personnels',
      withdrawal: 'Retrait',
      'business services': 'Les services aux entreprises',
      'health & fitness': 'Health & Fitness',
      'food & dining': 'Santé et Forme',
      'home improvement': "Amélioration de l'habitat",
      'bills & utilities': 'Factures et services publics',
      'public transportation': 'Transport public',
      'fees & charges': 'Frais et charges',
      charity: 'Charité',
      'interest income': "Le revenu d'intérêts",
      estimatedReward: 'Montant estimé des remises la première année',
      estimatedConsecutiveReward: 'Montant estimé des remises pour les années subséquentes',
      'air travel': 'Voyage en avion',
      'rental car & taxi': 'Location de voiture et taxi',
      gym: 'Gym',
      sports: 'Des sports',
      groceries: 'Epicerie',
      pharmacy: 'Pharmacie',
      vacation: 'Vacances',
      moviesDvds: 'Films et DVDs',
      gas: 'Essence',
      mobilePhone: 'Téléphone cellulaire',
      shipping: 'Livraison',
      restaurant: 'Restaurant',
      parking: 'Stationnement',
      internet: 'Internet',
      autoTransport: 'Auto et transport',
      shopping: 'Magasinage',
      totalreward: 'Total des récompenses',
      entertainment: 'Divertissement',
      restaurants: 'Restaurant',
      'additional bonus': 'Bonus supplémentaire',
      other: 'Autres achats',
      annualfee: 'Frais annuels'
    },
    estimatedRewards: 'Récompenses estimées',
    fieldKey: {
      annualFee: 'Frais annuels',
      purchaseInterestRate: "Taux d'intérêt sur les achats",
      rewardTypes: 'Type de récompense',
      cardCategories: 'Catégories de cartes',
      cardUserTypes: 'Type de carte',
      cashAdvance: 'Avance de fonds',
      balanceTransfer: 'Transfert de solde',
      rewards: 'Récompenses',
      estimatedRewards: 'Récompenses estimées',
      requiredDeposit: 'Dépôt requis',
      requiredIncome: 'Revenu minimal admissible',
      requiredFamilialIncome: 'Revenu du ménage',
      requiredCreditScore: 'Cote de crédit recommandé',
      minimumExpenses: 'Dépenses minimum',
      creditLimit: 'Limite de crédit',
      insurance: 'Assurances',
      insurances: 'Assurances',
      legalNote: 'Note légale',
      additionalCard: 'Carte additionnelle',
      estimatedBenefit: 'Épargne estimée',
      specialFeatures: 'Caractéristique Unique',
      rewardRate: 'Récompenses'
    },

    fieldValues: {
      BASIC: 'Carte basique',
      US_DOLLARS: 'Dollar US',
      REWARD: 'Récompenses',
      REWARDS: 'Récompense',
      BEST_OFFER: 'Meilleure offre',
      CASHBACK: 'Remises en argent',
      BALANCE_TRANSFER: 'Transfert de solde',
      LOW_INTEREST_RATE: "Bas taux t'intérêt",
      SECURED: 'Sécurisée',
      PREPAID: 'Prépayée',
      CREDIT_LINE: 'Marge de crédit',
      VIRTUAL: 'Virtuelle',
      TOP_RATED: 'Mieux notées',
      REGULAR: 'Particulier',
      BUSINESS: 'Entreprise',
      STUDENT: 'Étudiant',
      NEWCOMER: 'Nouvel arrivant',
      CREDIT_BUILDER: 'Bâtisseur de crédit'
    },
    titleDescriptionValues: {
      CAR_RENTAL_COVERAGE: 'Location de voiture',
      CAR_RENTAL_COVERAGE_emoji: '\uD83D\uDE97',
      PURCHASE_SECURITY: 'Couverture achat',
      PURCHASE_SECURITY_emoji: '\uD83D\uDECD',
      EXTENDED_WARRANTY: 'Garantie prolongée',
      EXTENDED_WARRANTY_emoji: '\uD83D\uDCC6',
      PHONE_INSURANCE: 'Perte ou vol de cellulaire',
      PHONE_INSURANCE_emoji: '\uD83D\uDCF5',
      TRAVEL_INTERRUPTION: 'Interruption de voyage',
      TRAVEL_INTERRUPTION_emoji: '⚡️',
      TRAVEL_ACCIDENT: 'Accident de voyage',
      TRAVEL_ACCIDENT_emoji: '\uD83E\uDDB7',
      TRAVEL_DELAY: 'Retard de vol',
      TRAVEL_DELAY_emoji: '⏱',
      TRAVEL_CANCELLATION: 'Annulation de voyage',
      TRAVEL_CANCELLATION_emoji: '❌',
      EMERGENCY_PURCHASES: 'Achats de première nécessité',
      EMERGENCY_PURCHASES_emoji: '\uD83E\uDE74',
      HOTEL_BURGLARY: 'Cambriolage à l’hôtel',
      HOTEL_BURGLARY_emoji: '\uD83D\uDCA8',
      MEDICAL_EMERGENCY: 'Soins médicaux d’urgence',
      MEDICAL_EMERGENCY_emoji: '\uD83C\uDFE5',
      BAGGAGE_INSURANCE: 'Bagages perdus ou volés',
      BAGGAGE_INSURANCE_emoji: '\uD83E\uDDF3'
    },
    estimatedReward: {
      description: 'based on your spending taking into account annual fees. After the first year, rewards will be ',
      descriptionContinue: 'taking into account annual fees.'
    },
    applyNow: 'Demander maintenant',
    learnMore: 'En savoir plus',
    suggested: 'Recommandations',
    suggestedProducts: 'Produits recommandés',
    all: 'Tout',
    compare: 'Comparer',
    weAnalysisYourAccount: 'Nous analyserons vos comptes et transactions pour trouver',
    findBestProduct: 'les meilleurs produits financiers',
    forYourNeed: 'qui correspondent à votre profil.',
    startAnalysis: 'Démarrer l\'analyse',
    products: 'Produits',
    comparatorOption_CREDITCARD: 'Cartes de crédit',
    comparatorOption_SAVING: 'Comptes d\'épargne',
    comparatorOption_BROKER: 'Courtiers en ligne',
    comparatorOption_CHECKING: 'Comptes chèques',
    comparatorOption_ROBOADVISOR: 'Robots-conseillers',
    comparatorOption_MORTGAGE: 'Hypothèques',
    comparatorOption_CRYPTO: 'Plateformes de cryptomonnaies',
    'connectMycredit-cards': 'Connecter ma carte de crédit',
    connectMysavings: 'Connecter mon compte d\'épargne',
    connectMycheckings: 'Connecter mon compte chèque',
    connectMybrokers: 'Connecter mon coutier en ligne',
    'connectMyrobo-advisors': 'Connecter mon robot-conseiller',
    analysing: 'Analyse en cours...',
    analysingDescription: 'Nous sommes en train d\'analyser vos données pour trouver les meilleurs produits financiers correspondants à votre profil. Ceci peut prendre jusqu\'à 2 minutes.',
    bestProductTitle: 'Félicitations',
    bestProductDescription: 'Nous avons analysé vos données et n\'avons pas trouvé de meilleure alternative pour optimiser votre budget',
    estimatedBenefit: 'Épargne estimée',
    cantRecommendTitle: 'Aucune recommandation',
    cantRecommendDescription: 'Nous n\'avons pas trouvé de recommandations pour vous, vous avez peut être déjà le meilleur produit !',
    noRecommendationButton: 'Voir tous les produits',
    saveUpTo: 'Épargnez jusqu\'à',
    refresh: 'rafraîchir',
    basedOnYourExpense: 'Basé sur vos habitudes d\'achat et votre situation financière, vous pourriez économiser jusqu\'à {{amount}} par an en changeant vos produits financiers dans les verticaux suivants',
    listOfRecommendations: 'Voici la liste des principaux produits vous permettant d\'économiser de l\'argent dans cette catégorie :',
    monthlyFee: 'Frais mensuels',
    interestRate: 'Taux d\'intérêts',
    perYear: ' /an'
  },
  welcome: {
    title: 'Félicitations ! Votre compte Hardbacon est créé ! 😎',
    description: 'Pour obtenir des recommendations personnalisées, connectez votre institution financière!',
    connectLater: 'Connecter plus tard'
  },
  deleteAccount: {
    deleteMyAccount: 'Supprimer mon compte',
    skip: 'Passer',
    contactSupport: 'Contacter le support',
    cancel: 'Annuler',
    unsubscribe: 'Me désabonner',
    step1: {
      title: 'Nous sommes tristes de vous voir partir...',
      text1: 'En supprimant votre compte, toutes vos informations seront effacées de notre système.',
      text2: 'Cliquez-ici',
      text2Link: 'https://intercom.help/hardbacon/fr/articles/5820550-decouvrez-ce-qui-se-passe-lorsque-vous-supprimez-votre-compte-hardbacon',
      text3: 'pour en savoir plus.'
    },
    step2: {
      title: 'Pourquoi désirez-vous supprimer votre compte ?',
      reason1: 'J\'ai des problèmes de connexion avec mes comptes',
      reason2: 'Mon institution n\'est pas supportée',
      reason3: 'Je ne veux plus payer',
      reason4: 'Une autre raison'
    },
    step3: {
      titleReason1: 'Contactez-nous pour résoudre ces problèmes de connexion',
      titleReason2: 'Nous pouvons possiblement ajouter votre institution financière',
      titleReason3: 'Vous pouvez utiliser la version gratuite de l\'application',
      titleReason4: 'Pourriez-vous nous en dire plus ?',
      titleReason5: 'Pourriez-vous nous en dire plus ?',
      yourComment: 'Commentaire',
      send: 'Envoyer',
      error: 'Écrivez-vos commentaires 😊',
      addMyInstitution: 'Ajouter mon institution'
    },
    step4: {
      title: 'Êtes-vous certain(e) de vouloir supprimer votre compte Hardbacon ?',
      typeDelete: 'Écrivez "supprimer" pour confirmer la suppression de votre compte',
      delete: 'supprimer',
      confirm: 'Confirmer la suppression'
    }
  },
  budgetOnboarding: {
    step1: {
      title: "C'est le temps de faire un budget !",
      desc:
        "Saviez-vous que ceux qui font un budget sont deux fois moins nombreux à faire des paiements en retard, remboursent leurs dettes plus rapidement et épargnent plus que ceux qui n'en font pas ?",
      button: 'Créer mon budget',
      subButton: 'Plus tard'
    },
    step2: {
      title: 'Connectez votre compte bancaire',
      desc:
          'En connectant votre compte et vos cartes de crédit, vos transactions seront entrées et catégorisées pour vous.'
    },
    step3: {
      title: 'Quel est votre revenu net ?',
      desc: "Il s'agit de votre revenu après impôts et autres déductions."
    },
    step4: {
      title: "Combien d'argent par mois dépensez-vous pour vous loger ?"
    },
    step5: {
      title: "Combien d'argent par mois dépensez-vous pour vous nourrir ?"
    },
    step6: {
      title: "Combien d'argent par mois dépensez-vous pour vous déplacer ?"
    },
    budgetState: {
      perfect: {
        title: 'Wow ! Votre budget est parfait ! 🤑',
        subTitle: 'Tous vos dollars ont un rôle ! Félicitations !'
      },
      exceed: {
        title: 'Vos dépenses dépassent vos revenus 😨',
        subTitle1: 'Réduisez vos dépenses de ',
        subTitle2: ' pour équilibrer votre budget'
      },
      subceed: {
        title1: 'Il vous reste ',
        title2: ' à budgéter ! 😇',
        subTitle: 'Faites travailler chaque dollar !'
      }
    },
    addCategory: 'Ajouter une catégorie'
  },
  incomeType: {
    weekly: 'Semaine',
    biWeekly: '2 semaines',
    monthly: 'Mensuel',
    biMonthly: 'Bi-mensuel'
  },
  connect: {
    connectYourInstitution: 'Connexion à votre compte'
  },
  patience: {
    bePatient: 'Soyez patient',
    bePatientSubtext: 'Le processus de connexion à votre compte va commencer dans quelques secondes...',
    quote0Text: "Soyez patient 🙂. Le processus de connexion peut prendre jusqu'à 5 minutes.",
    quote1Text: '"Patience, persévérance et sueur forment une combinaison imbattable pour le succès."',
    quote1Author: '🤓 - Napoleon Hill',
    quote2Text: '"La patience est une vertu, et j\'apprends la patience. C\'est une leçon difficile."',
    quote2Author: '🤓 - Elon Musk',
    quote3Text: '"Un homme qui est maître de la patience est maître de tout le reste."',
    quote3Author: '🤓 - George Savile',
    quote4Text: '"La patience est l\'art de dissimuler son impatience."',
    quote4Author: '🤓 - Guy Kawasaki',
    quote5Text: '"Je suis extraordinairement patiente, à condition que je finisse par obtenir ce que je veux."',
    quote5Author: '🤓 - Margaret Thatcher',
    quote6Text: '"L\'intuition est une suspension de la logique due à l\'impatience."',
    quote6Author: '🤓 - Rita Mae Brown',
    quote7Text: '"Je suis patiente avec la stupidité mais pas avec ceux qui en sont fiers."',
    quote7Author: '🤓 - Edith Sitwell',
    quote8Text: '"Sage pour résoudre, et patient pour exécuter."',
    quote8Author: '🤓 -  Homer',
    quote9Text: '"Non, je serai le modèle de toute patience; je ne dirai rien."',
    quote9Author: '🤓 - William Shakespeare'
  },
  plaid: {
    waitForPlaid: 'Prêt !',
    waitForPlaidSubText: 'Le widget de connexion de notre partenaire est maintenant visible. Veuillez suivre les instructions pour connecter votre compte.',
    waitForSync: 'Finalisation...',
    waitForSyncSubText: 'Nous sommes en train de finaliser la connextion, encore quelques secondes et ce sera terminé...'
  },
  notifications: {
    title: 'Notifications',
    DIVIDEND: '',
    TRANSFER: '',
    PRICE_ABOVE: '',
    PRICE_UNDER: '',
    CASH: '',
    AGGREGATION_BANKING: '',
    AGGREGATION_INVESTMENT: '',
    AGGREGATION_ERROR: '',
    BUDGET_OVER_90_PERCENT: 'Alerte de budget !',
    BUDGET_TREND_YELLOW: 'Alerte de budget !',
    BUDGET_TREND_RED: 'Alerte de budget !',
    createABudgetTitle: 'Créer votre budget !',
    createABudgetDescription: 'Coupez vos dépenses inutiles et épargnez',
    createABudgetButton: 'Créer'
  },
  overview: {
    currentBalance: 'Solde actuel',
    tips: 'Conseils',
    potentialSavings: 'Économies potentielles',
    report: 'Rapport',
    recurringPayments: 'Paiements récurrents',
    mostAmountMerchant: 'Où vous avez dépensé le plus',
    mostFrequentMerchant: 'Dépenses les plus fréquentes',
    averageSavingsCapacity: 'Capacité d\'épargne moy.',
    saveAmount: 'Économisez',
    reportIncome: 'Revenus',
    reportExpenses: 'Dépenses',
    total: 'Total'
  }
};
export default fr;
