import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { formatDateDMY } from '../i18n';
import Notification from '../model/Notification';
import { getTransactionCtgEmojiById } from '../utils/banking';
import Container from './Container';
import styles from '../assets/styles/modules/Greetings.module.css';
import NotificationStyles from '../assets/styles/modules/NotificationItem.module.css';
import { getStyleFromNotificationType } from '../utils/feed';
import { LoadMore } from './LoadMore';
import { setNotificationsPanelOpen } from '../actions/UserInstitution';

interface GreetingsProps {
    withAlert?: boolean
}

const Greetings = ({ withAlert }: GreetingsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user);
  const { budgetNotifications } = useSelector((state: any) => state.notification);

  const today = new Date();
  const curHr = today.getHours();
  let greetings = '';
  const name = user.firstName;
  const alert: Notification = budgetNotifications[0];

  if (curHr < 12 && curHr > 5) {
    greetings = t('morning', { name });
  } else if (curHr < 17) {
    greetings = t('afternoon', { name });
  } else {
    greetings = t('evening', { name });
  }

  const renderItem = () => {
    return (
        <div>
            <div className={styles.container}>
                <div>
                    {getTransactionCtgEmojiById(alert.categoryId!)}
                </div>
                <div>
                    <p className={`${NotificationStyles.title} ${getStyleFromNotificationType(alert)}`}>
                        {t('notifications:' + alert.notificationType)}
                    </p>
                    <p className={NotificationStyles.description}>
                        {alert.notificationText}
                    </p>
                    <p className={styles.date}>
                        {formatDateDMY(alert.notificationDateTime)}
                    </p>
                </div>
            </div>
            <LoadMore
                isLoading={false}
                loadMore={() => dispatch(setNotificationsPanelOpen(true))}
                moreToLoad={true}
            />
        </div>
    );
  };

  return (
      <Container header={greetings + ' 🤗'}>
        <div>
            {withAlert && alert ? renderItem() : null}
        </div>
      </Container>);
};

export default Greetings;
