import AxiosInstance from './index';
import { fromAxiosError } from '../model/APIError';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export function updateUserNewsTopicsService (topics: string[]): Observable<string> {
  return AxiosInstance.post<string>('api/account/interests', topics).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getOffersService (province: string): Observable<string> {
  return AxiosInstance.get<string>('api/hardbacon-exclusive-offers' + (province ? `?province=${province}` : '')).pipe(
    map(resp => {
      return resp.data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}
