import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeleteAccountFlow,
  HardbaconButton,
  HardbaconImageUpload,
  HardbaconInput
} from '../../../component';
import { updateAccountInfo, uploadProfilePicture } from '../../../actions/User';
import { setNotification } from '../../../actions/Notification';
import { useDispatch, useSelector } from 'react-redux';
import AppNotificationType from '../../../model/AppNotificationType';
import SimplePage from '../../../component/Layouts/SimplePage';

const Information = () => {
  const lastNameInput = useRef<HardbaconInput>(null);
  const firstNameInput = useRef<HardbaconInput>(null);
  const birthdayInput = useRef<HardbaconInput>(null);
  const emailInput = useRef<HardbaconInput>(null);
  const phoneNumberInput = useRef<HardbaconInput>(null);
  const [imageUploadVisible, setImageUploadVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState(undefined);
  const [deleteAccountFlow, setDeleteAccountFlow] = useState(false);

  const { t } = useTranslation();

  const { user, picture } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      return;
    }
    let pn = user.phoneNumber && user.phoneNumber.length > 10 ? user.phoneNumber.substr(user.phoneNumber.length - 10, user.phoneNumber.length) : user.phoneNumber;
    if (pn?.length === 10) {
      pn = `(${pn?.substr(0, 3)}) ${pn?.substr(3, 3)}-${pn?.substr(6, 4)}`;
      setPhoneNumber(phoneNumber);
    }
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setBirthday(user.birthday);
  }, [user]);

  const saveInformationImage = (imageAsBas64: string, imageContentType: string) => {
    dispatch(uploadProfilePicture(imageAsBas64, imageContentType));
  };

  const saveInformation = () => {
    const lastNameValidation = lastNameInput.current?.validate();
    const firstNameValidation = lastNameInput.current?.validate();
    const birthdayValidation = lastNameInput.current?.validate();
    const phoneNumberValidation = lastNameInput.current?.validate();
    if (firstNameValidation && lastNameValidation && birthdayValidation && phoneNumberValidation) {
      dispatch(updateAccountInfo({
        ...user,
        firstName,
        lastName,
        birthday: birthday ?? user.birthday,
        phoneNumber: phoneNumber ?? user.phoneNumber
      }));
      dispatch(setNotification({
        title: t('saveSuccess'),
        description: t('updatedAccountDescription'),
        type: AppNotificationType.success
      }));
    }
  };

  if (deleteAccountFlow) {
    return <DeleteAccountFlow cancel={() => setDeleteAccountFlow(false)}/>;
  }

  return (
    <SimplePage>
      <form id={'form-profile-information'} className="form-container">
        <div className="form-section">
          <div>
            <div className="flex justify-between content-center mb-8">
              <div>
                <h3 className="form-section-title">
                  {t('Information')}
                </h3>
                <p className="form-section-subtitle">
                  {t('manageInformation')}
                </p>
              </div>
              <HardbaconButton title={t('save')} name={'update-account-button'} type={'button'}
                               onPress={() => saveInformation()}
              />
            </div>
            <div
              className="form-row">
              <label htmlFor="photo" className="form-label">
                {t('profilePicture')}
              </label>
              <div className="form-input-container">
                <div className="flex items-center">
                              <span className="form-small-round-picture">
                               <img className={'object-cover w-12 h-12 rounded-full'} alt={'Information'}
                                    src={picture}/>
                              </span>
                  <HardbaconImageUpload visible={imageUploadVisible}
                                        onSave={saveInformationImage}
                                        onClose={() => setImageUploadVisible(false)}/>
                  <HardbaconButton
                    className={'ml-2'}
                    name={'changeProfile'}
                    title={t('change')}
                    inverse={true}
                    size={'small'}
                    onPress={() => setImageUploadVisible(true)}/>
                </div>
              </div>
            </div>

            <div
              className="form-row">
              <label htmlFor="first_name"
                     className="form-label">
                {t('firstName')}
              </label>
              <div className="form-input-container">
                <HardbaconInput ref={firstNameInput} optional={false} type="text"
                                name="first_name" className="form-input"
                                errorClassName={'form-input-error'}
                                value={user?.firstName}
                                onChange={(value) => setFirstName(value)}/>
              </div>
            </div>

            <div
              className="form-row">
              <label htmlFor="last_name"
                     className="form-label">
                {t('lastName')}
              </label>
              <div className="form-input-container">
                <HardbaconInput ref={lastNameInput} optional={false} type="text"
                                name="last_name" className="form-input" errorClassName={'form-input-error'}
                                value={user?.lastName}
                                onChange={(value) => setLastName(value)}/>
              </div>
            </div>

            <div
              className="form-row">
              <label htmlFor="email" className="form-label">
                {t('email')}
              </label>
              <div className="form-input-container">
                <HardbaconInput ref={emailInput} disabled={true} type="email"
                                name="email" className="form-input" errorClassName={'form-input-error'}
                                value={user?.login}/>
              </div>
            </div>

            <div
              className="form-row">
              <label htmlFor="birthday"
                     className="form-label">
                {t('birthday')}
              </label>
              <div className="form-input-container">
                <HardbaconInput ref={birthdayInput} optional={true} type="date"
                                name="birthday"
                                className="form-input" errorClassName={'form-input-error'}
                                value={birthday}
                                onChange={(value) => {
                                  setBirthday(value);
                                }}/>
              </div>
            </div>

            <div
              className="form-row">
              <label htmlFor="phone_number"
                     className="form-label">
                {t('phoneNumber')}
              </label>
              <div className="form-input-container">
                <HardbaconInput mask={'(111) 111-1111'} regex={'[(]+[0-9]{3}[)]+ [0-9]{3}[-]+[0-9]{4}'}
                                ref={phoneNumberInput} optional={true} type="text"
                                name={'phoneNumber'} className="form-input"
                                errorClassName={'form-input-error'}
                                value={user?.phoneNumber ? user?.phoneNumber : undefined}
                                onChange={(value) => setPhoneNumber(value)}/>
              </div>
            </div>
            <div
              className="form-row">
              <a className={'light-link'}
                 onClick={() => setDeleteAccountFlow(true)}>{t('deleteAccount:deleteMyAccount')}</a>
            </div>
          </div>
        </div>

      </form>
    </SimplePage>
  );
};

export default Information;
