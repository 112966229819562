
/* eslint-disable no-unused-vars */
export enum NotificationType {
  DIVIDEND = 'DIVIDEND',
  TRANSFER = 'TRANSFER',
  PRICE_ABOVE = 'PRICE_ABOVE',
  PRICE_UNDER = 'PRICE_UNDER',
  CASH = 'CASH',
  AGGREGATION_BANKING = 'AGGREGATION_BANKING',
  AGGREGATION_INVESTMENT = 'AGGREGATION_INVESTMENT',
  AGGREGATION_ERROR = 'AGGREGATION_ERROR',
  BUDGET_OVER_90_PERCENT = 'BUDGET_OVER_90_PERCENT',
  BUDGET_TREND_YELLOW = 'BUDGET_TREND_YELLOW',
  BUDGET_TREND_RED = 'BUDGET_TREND_RED'
}

interface Notification {
  id: number,
  notificationType: NotificationType,
  notificationDateTime: string,
  notificationTitle: string,
  notificationText: string,
  unread: boolean,
  amount?: number,
  currency?: string,
  securityId?: 0,
  securityName?: string,
  securitySymbol?: string,
  accountBackendId?: string,
  accountName?: string,
  threshold?: number,
  number?: number,
  categoryId?: number,
  userId: number,
  userLogin: string,
  alertId?: number
}

export default Notification;
