/* eslint-disable no-unused-vars */
enum ComparatorType {
    creditCard = 'credit-cards',
    broker = 'brokers',
    saving = 'savings',
    roboAdvisor = 'robo-advisors',
    checking = 'checkings',
    mortgage = 'mortgages',
    crypto = 'crypto-exchanges'
}

export default ComparatorType;
