import { Dispatch } from 'redux';
import ReducerPopUpState from '../model/ReducerPopUpState';
import { CLOSE_POPUP, OPEN_POPUP } from '../types/PopUp';

export const openPopUp = (props?: ReducerPopUpState) => (dispatch: Dispatch) => {
  dispatch({ type: OPEN_POPUP, payload: props });
};

export const closePopUp = () => (dispatch: Dispatch) => {
  dispatch({ type: CLOSE_POPUP });
};
