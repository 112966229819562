import React from 'react';
import { apphardbacon, hardbaconLogo } from '../assets/images';
import { HardbaconFooter } from './index';
import { useTranslation } from 'react-i18next';

type HardbaconLeftPanelProps = {
}

export const HardbaconLeftPanel = (props: HardbaconLeftPanelProps) => {
  const { t } = useTranslation();

  return (
        <div className={'flex-1 p-4 md:p-6 lg:p-16 bg-hardbacon flex flex-col justify-between'}>
            <div className={'cursor-pointer'} onClick={() => {
              window.location.href = process.env.REACT_APP_BASE_PATH!;
            }}>
                <img alt={'hardbacon logo'} src={hardbaconLogo} className={'w-24 h-auto'}/>
            </div>
            <div className={'w-12/12 flex flex-col'}>
                <div>
                    <img alt={'hardbacon app preview'} src={apphardbacon}
                         className={'w-24 sm:w-48 md:w-80 h-auto mx-auto'}/>
                </div>
                <div className={'text-center mt-2 sm:mt-4'}>
                    <span className={'extra-large-text text-white'}>{t('simpleFinance')}</span>
                </div>
                <div className={'text-center mt-2 sm:mt-4'}>
                    <span className={'medium-text text-white'}>{t('keepControlOfYourFinance')}</span>
                </div>
            </div>
            <div className={'mt-4'} style={{ paddingBottom: '0px !important' }}>
                <HardbaconFooter dark={false}/>
            </div>
        </div>
  );
};

export default HardbaconLeftPanel;
