import React, { useState, useEffect } from 'react';
import RightPanel from './RightPanel';
import { useTranslation } from 'react-i18next';
import { BankingTransaction, BankingTransactionStatus, SplitedCategoryType } from '../model/BankingTransaction';
import AddCategory from './AddCategory';
import _ from 'lodash';
import UserInstitution from '../model/UserInstitution';
import HardbaconButton from './HardbaconButton';
import { useSelector, useDispatch } from 'react-redux';
import InstitutionAccountDetail from '../model/InstitutionAccountDetail';
import { setAddInstitutionPanelOpen } from '../actions/UserInstitution';
import { addButtonIcon, descIcon, initalBalanceIcon, initalDateIcon, multipleCategoriesIcon, removeFromAnalyticsIcon, removeIcon, splitIcon } from '../assets/images';
import styles from './../assets/styles/modules/transactionItemDetail.module.css';
import FormItemContainer, { FormInputEmun } from './FormItemContainer';
import SplitedCatgoriesDetail from './SplitedCatgoriesDetail';
import colors from '../colors/colors';
import HardbaconToggle from './HardbaconToggle';
import ExpandButton from './ExpandButton';
import { formatCurrency, formatDate, formatDateDMY, stringToDate } from '../i18n';
import { getTransactionCtgNameById, getTransactionCtgEmojiById, getIconForMerchant, getSplitByCategoriesObject, getTransactionParentCtgById } from '../utils/banking';
import Calendar from 'react-calendar';
import { categorizeTransaction, createManualTransaction, deleteManualTransaction, deleteSplitTransactionBtwPeople, deleteSplitTransactionByCategory, excludeTransaction, getTransactionCategories, includeTransaction, splitTransactionBetweenPeople, splitTransactionByCategory, updateManualTransaction, updateTransactionDesc, updateTransactionItem } from '../actions/Transactions';
import { TransactionCategorisation } from '../model/transactions/TransactionCategorisation';
import { closePopUp, openPopUp } from '../actions/PopUp';
import dayjs from 'dayjs';

interface TransactionItemDetailPanelProps {
  visible: boolean;
  onClose: Function;
  selectedTransaction: BankingTransaction,
  onItemPress: Function,
  onManualAccountPress?: Function,
  manualAccount?: InstitutionAccountDetail,
}

const TransactionItemDetailPanel = ({ visible, onManualAccountPress, onClose, manualAccount, selectedTransaction, onItemPress }: TransactionItemDetailPanelProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [openSplitBetweenPeople, setOpenSplitBetweenPeople] = useState(false);
  const [openSplitCategories, setOpenSplitCategories] = useState(false);
  const [openSplitSelection, setOpenSplitSelection] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const [shouldGoBack, setShouldGoBack] = useState(false);
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [newCategoryId, setNewCategoryId] = useState<number>(selectedTransaction?.categoryId || 0);
  const [updateCategory, setUpdateCategory] = useState(false);
  const [splitedCategories, setSplitedCategories] = useState<SplitedCategoryType[]>();
  const [itemCategoryToChange, setItemCategoryToChange] = useState<SplitedCategoryType>();
  const [openSelections, setOpenSelections] = useState<boolean>(!!onManualAccountPress);

  const [desc, setDesc] = useState<string>(selectedTransaction?.description || '');
  const [removeFromAnalytics, setRemoveFromAnalytics] = useState(!!selectedTransaction?.excluded);
  const [balance, setBalance] = useState(0);
  const [accountDate, setAccountDate] = useState(new Date());
  const [splitAmount, setSplitAmount] = useState<string | number>('');

  const [title, setTitle] = useState(t('Transaction'));
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [splitPercentage, setSplitPercentage] = useState<string | number>('');
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);

  const { institutions } = useSelector((state: any) => state.userInstitution);
  const { transactionCategories } = useSelector((state: any) => state.transactions);

  const {
    description,
    bankAccountBackendId,
    backendId,
    excluded,
    originalDescription,
    date,
    merchantId,
    categoryId,
    debit,
    credit,
    category,
    splitByCategories,
    originalAmount,
    originalCategoryId,
    bankAccountId,
    id,
    currency
  } = selectedTransaction;

  const manualAccounts: InstitutionAccountDetail[] = [];
  institutions.map((institution: UserInstitution) => {
    const isManual = institution?.institutionSource.description === 'BANKING' &&
    institution?.institutionSource.dataSource === 'DATABASE';
    if (isManual && institution.institutionAccountDetailDTOs) {
      institution.institutionAccountDetailDTOs.map((i : InstitutionAccountDetail) => {
        manualAccounts.push(i);
        return i;
      });
    }
    return institution;
  });

  const currentAccount = manualAccounts.find((i:InstitutionAccountDetail) => selectedTransaction && (i.id === selectedTransaction.bankAccountBackendId)) || manualAccount;
  const isManual = !!manualAccount || !!currentAccount;
  const isCreateManualTrx = isManual && !!manualAccount;
  const isUpdateManualTrx = isManual && !manualAccount;

  const amount = selectedTransaction ? (selectedTransaction.credit > 0 ? selectedTransaction.credit : selectedTransaction.debit) : 0;

  useEffect(() => {
    if (isManual && date) {
      setAccountDate(_.isString(date) ? stringToDate(date) : new Date(date));
    }
  }, [isManual, date]);

  useEffect(() => {
    if (selectedTransaction) {
      setDesc(selectedTransaction.description);
      setRemoveFromAnalytics(selectedTransaction.excluded);
      setBalance(amount);
      setIsCalenderOpen(false);
    }
  }, [selectedTransaction]);

  useEffect(() => {
    if (transactionCategories.length === 0) {
      dispatch(getTransactionCategories());
    }
  }, [description, excluded]);

  const originalSplitedCategories: SplitedCategoryType[] = [];
  Object.keys(splitByCategories).map((key: string) =>
    originalSplitedCategories.push({
      id: parseInt(key, 10),
      amount: splitByCategories[key]
    })
  );
  transactionCategories.map((main: any) => {
    return main.subCategories.map((sub: any) => {
      return originalSplitedCategories.find((i: any) => {
        if (i.id === sub.id) {
          return i.parent = main.id;
        } else if (i.id === main.id) {
          return i.parent = main.id;
        }
        return null;
      });
    });
  });

  useEffect(() => {
    if (categoryId === 0) {
      setSplitedCategories(originalSplitedCategories);
      setIsSplit(!!splitByCategories);
      setSplitAmount(amount);
    } else if (
      (debit > 0 && originalAmount > 0 && originalAmount !== debit) ||
      (credit > 0 && originalAmount > 0 && originalAmount !== credit)
    ) {
      setIsSplit(true);
      setSplitAmount(amount);
      setSplitPercentage(Math.round(((amount * 100) / originalAmount) * 100) / 100);
    }
  }, [categoryId, category, splitByCategories, debit, credit, originalAmount]);

  const didSplitByCtgsChange = _.isEmpty(splitedCategories) && _.isEmpty(splitByCategories) ? false : !_.isEqual(splitedCategories, originalSplitedCategories);

  useEffect(() => {
    if (isManual) {
      if (!(desc && (Math.abs(balance) > 0))) {
        setShouldUpdate(false);
        return;
      }
      if ((removeFromAnalytics !== excluded) || didSplitByCtgsChange || desc !== description || balance !== amount || newCategoryId !== categoryId || !dayjs(accountDate).isSame(dayjs(new Date(stringToDate(date))))) {
        setShouldUpdate(true);
        return;
      }
      setShouldUpdate(false);
      return;
    }

    const didAmountChange = isSplit && splitAmount !== amount;
    if (didSplitByCtgsChange || (newCategoryId !== categoryId) || (removeFromAnalytics !== excluded) || (desc !== description) || didAmountChange) {
      setShouldUpdate(true);
    } else {
      setShouldUpdate(false);
    }
  }, [didSplitByCtgsChange, accountDate, date, description, excluded, removeFromAnalytics, desc, splitAmount, amount, isSplit, balance, newCategoryId, categoryId]);

  useEffect(() => {
    if (!visible) {
      setOpenSplitBetweenPeople(false);
      setOpenSplitCategories(false);
      setIsSplit(false);
      setOpenSplitSelection(false);
      setAddCategoryOpen(false);
      setAddCategory(false);
      setUpdateCategory(false);
      setItemCategoryToChange(undefined);
      setSplitedCategories(undefined);
    }
    if (selectedTransaction?.categoryId && _.isEmpty(splitedCategories)) {
      setNewCategoryId(selectedTransaction?.categoryId);
    };
    if (visible && !!onManualAccountPress) {
      setOpenSelections(true);
    }
  }, [visible]);

  useEffect(() => {
    if (selectedTransaction?.categoryId && _.isEmpty(splitedCategories)) {
      setNewCategoryId(selectedTransaction?.categoryId);
    } else if (!_.isEmpty(splitedCategories)) {
      setNewCategoryId(0);
    }
  }, [selectedTransaction, splitedCategories]);

  useEffect(() => {
    if ((openSplitBetweenPeople || openSplitCategories || openSplitSelection || addCategoryOpen || updateCategory)) {
      setShouldGoBack(true);
    } else {
      setShouldGoBack(false);
    }
    if (manualAccount && !openSelections) {
      setShouldGoBack(true);
    }
    if (openSplitCategories) {
      setTitle(t('splitBetweenCategories'));
    } else if (openSplitBetweenPeople) {
      setTitle(t('splitBetweenPeople'));
    } else if (openSplitSelection) {
      setTitle(t('splitTransaction'));
    } else if (openSelections) {
      setTitle(t('addManualTrx'));
    } else {
      setTitle(t('Transaction'));
    }
  }, [openSplitBetweenPeople, openSelections, openSplitCategories, openSplitSelection, addCategoryOpen, updateCategory]);

  const onGoBack = () => {
    if (addCategoryOpen || itemCategoryToChange || addCategory || updateCategory) {
      if (isSubCategoryOpen) {
        setIsSubCategoryOpen(false);
      } else {
        setUpdateCategory(false);
        setAddCategory(false);
        setIsSubCategoryOpen(false);
        setItemCategoryToChange(undefined);
      }
    } else if ((itemCategoryToChange || addCategory || updateCategory) && openSplitCategories && !isSubCategoryOpen) {
      setItemCategoryToChange(undefined);
      setAddCategory(false);
      setUpdateCategory(false);
    } else if (manualAccount && !openSelections) {
      setOpenSelections(true);
    } else {
      setOpenSplitBetweenPeople(false);
      setOpenSplitCategories(false);
      setOpenSplitSelection(false);
      setAddCategoryOpen(false);
      setAddCategory(false);
      setUpdateCategory(false);
    }
  };

  const onAccountPress = (i: InstitutionAccountDetail) => {
    onManualAccountPress && onManualAccountPress(i);
    setOpenSelections(false);
  };

  const renderSelections = () => {
    return (
      <div className={styles.selections}>
        {
          manualAccounts.map((i: InstitutionAccountDetail) => {
            return (
              <div key={i.id}>
                <HardbaconButton name={i.name} title={i.name + ' - ' + i.currency} className={styles['select-button']} onPress={() => onAccountPress(i)}/>
              </div>
            );
          })
        }
        <HardbaconButton icon={addButtonIcon} inverse={true} name={t('manageAccounts:addManualAccount')} title={t('manageAccounts:addManualAccount')} className={styles['select-button']} onPress={() => {
          onClose();
          dispatch(setAddInstitutionPanelOpen(true, { createManualAccount: true }));
        }}/>
      </div>
    );
  };

  const rendeSplitSelection = () => {
    return (
      <div className={styles['button-group']}>
        <HardbaconButton
          name={t('splitBetweenCategories')}
          onPress={() => {
            setAddCategoryOpen && setAddCategoryOpen(true);
            setOpenSplitSelection && setOpenSplitSelection(false);
          }}
          type="button"
          title={t('splitBetweenCategories')}/>
        {!isManual && <HardbaconButton
          name={t('splitBetweenPeople')}
          onPress={() => {
            setOpenSplitBetweenPeople && setOpenSplitBetweenPeople(true);
            setOpenSplitSelection && setOpenSplitSelection(false);
          }}
          type="button"
          title={t('splitBetweenPeople')}/>}
      </div>
    );
  };

  const renderSplitedByPeopleDetail = () => {
    let maxAmount = 0;
    if (selectedTransaction) {
      maxAmount = selectedTransaction.originalAmount || selectedTransaction.debit || selectedTransaction.credit;
    }
    return (
      <div className={styles['inner-container']}>
        <FormItemContainer
          id='myPart'
          left={`${t('myPart')}`}
          right={FormInputEmun.percentageInput}
          value={splitPercentage || ''}
          withDivider={false}
          maxAmount={100}
          onChange={(v: string | number) => {
            const value = v + '';
            setSplitPercentage(v);
            const newAmount = Math.round(maxAmount * (parseFloat(value) / 100)) || 0;
            setSplitAmount(newAmount > maxAmount ? maxAmount : newAmount);
          }}
        />
        <FormItemContainer
          id='totalYouPaid'
          left={`${t('totalYouPaid')}`}
          right={FormInputEmun.moneyInput}
          value={splitAmount || ''}
          maxAmount={maxAmount}
          withDivider={false}
          onChange={(v: string | number) => {
            const value = v + '';
            setSplitAmount(v);
            const newPctg = Math.round((parseFloat(value) / maxAmount) * 100) || 0;
            setSplitPercentage(newPctg > 100 ? 100 : newPctg);
          }}
        />
        <HardbaconButton
          className={styles.button}
          disabled={splitAmount <= 0 || splitAmount >= maxAmount || splitPercentage === 100}
          name={'accept'}
          onPress={() => {
            setOpenSplitBetweenPeople(false);
            setIsSplit(true);
          }}
          type="button"
          title={t('update')}/>
      </div>
    );
  };

  const renderSplitedCatgoriesDetail = () => {
    return setAddCategory && setItemCategoryToChange && setSplitedCategories && selectedTransaction && <SplitedCatgoriesDetail
      setAddCategory={setAddCategory}
      setItemCategoryToChange={setItemCategoryToChange}
      splitedCategories={splitedCategories}
      setSplitedCategories={setSplitedCategories}
      originalAmount={selectedTransaction.originalAmount || amount || balance}
      onClose={() => {
        setOpenSplitCategories && setOpenSplitCategories(false);
      }}
    />;
  };

  const renderSplitedCatgory = (item: SplitedCategoryType, index: number) => {
    return (
      <FormItemContainer
        id={getTransactionCtgNameById(item.id)}
        leftWidth={'70%'}
        rightWidth={'30%'}
        withIndent={true}
        left={getTransactionCtgEmojiById(item.id) + ' ' + getTransactionCtgNameById(item.id)}
        right={FormInputEmun.moneyInput}
        value={item.amount}
        withDivider={splitedCategories && ((splitedCategories?.length - 1) === index)}
      />
    );
  };

  const renderSplitedByPeople = () => {
    return (
      <div>
        <FormItemContainer
          id="splitBetweenPeople"
          withIndent={true}
          withDivider={false}
          left={`${t('splitBetweenPeople')}`}
          right={FormInputEmun.stringInput}
          value={`${t('edit')}`}
          onPress={() => setOpenSplitBetweenPeople && setOpenSplitBetweenPeople(true)}
        />
        <FormItemContainer
          id="myPart"
          withIndent={true}
          left={`${t('myPart')}`}
          right={FormInputEmun.stringInput}
          value={splitPercentage + '%'}
          withDivider={false}
        />
        <FormItemContainer
          id="totalYouPaid"
          withIndent={true}
          left={`${t('totalYouPaid')}`}
          right={FormInputEmun.moneyInput}
          value={splitAmount}
        />
      </div>
    );
  };

  const updateAnalytics = () => {
    if (removeFromAnalytics !== excluded) {
      if (removeFromAnalytics) {
        dispatch(excludeTransaction(bankAccountBackendId, backendId));
      } else {
        dispatch(includeTransaction(bankAccountBackendId, backendId));
      }
    }
  };

  const submit = () => {
    let newSplitByCategories = splitedCategories ? getSplitByCategoriesObject(splitedCategories, 'id') : splitByCategories;
    if (!isSplit && !_.isEmpty(newSplitByCategories)) {
      newSplitByCategories = {};
    }

    let newCredit = credit;
    let newDebit = debit;
    let newOriginalAmount = originalAmount;
    if (debit > 0) {
      newOriginalAmount = originalAmount > debit ? originalAmount : debit;
    } else {
      newOriginalAmount = originalAmount > credit ? originalAmount : credit;
    }

    // Manual transaction
    if (isCreateManualTrx && currentAccount) {
      const transaction: Partial<BankingTransaction> = {
        bankAccountId: currentAccount.manualId,
        categoryName: getTransactionCtgNameById(newCategoryId),
        category: getTransactionCtgNameById(newCategoryId),
        categoryId: newCategoryId,
        debit: balance < 0 ? Math.abs(balance) : 0,
        date: accountDate.toISOString().substring(0, 10),
        credit: balance > 0 ? Math.abs(balance) : 0,
        description: desc,
        bankAccountBackendId: manualAccount.id,
        excluded: false,
        status: BankingTransactionStatus.POSTED,
        splitByCategories: {},
        originalAmount: Math.abs(parseInt(balance + '')),
        originalCategoryId: newCategoryId
      };
      dispatch(createManualTransaction(currentAccount.manualId, transaction));
      onItemPress();
      return;
    }

    if (isUpdateManualTrx) {
      updateAnalytics();
      const transaction = {
        bankAccountId: bankAccountId,
        bankAccountBackendId: bankAccountBackendId,
        id: id,
        backendId: backendId,
        categoryName: getTransactionCtgNameById(newCategoryId),
        category: getTransactionCtgNameById(newCategoryId),
        categoryId: newCategoryId,
        debit: balance < 0 ? Math.abs(balance) : 0,
        date: accountDate.toISOString().substring(0, 10),
        credit: balance > 0 ? Math.abs(balance) : 0,
        description: desc,
        splitByCategories: newSplitByCategories,
        originalCategoryId: originalCategoryId || categoryId,
        excluded: removeFromAnalytics
      };
      if (didSplitByCtgsChange) {
        if (!_.isEmpty(newSplitByCategories)) {
          dispatch(splitTransactionByCategory(newOriginalAmount, newSplitByCategories, backendId));
        } else {
          dispatch(deleteSplitTransactionByCategory(backendId));
        }
      }
      dispatch(updateManualTransaction(bankAccountId, id, transaction));
      onItemPress();
      return;
    }

    // Non-manual transaction
    updateAnalytics();

    if (desc !== description) {
      setTimeout(() => dispatch(updateTransactionDesc(backendId, desc, originalDescription)), 2000);
    }

    if (newCategoryId !== categoryId) {
      setTimeout(() => dispatch(categorizeTransaction(backendId, newCategoryId, TransactionCategorisation.one, desc)), 4000);
    }

    if (!isSplit || !_.isEmpty(splitedCategories)) {
      if (debit > 0) {
        newDebit = newOriginalAmount;
      } else {
        newCredit = newOriginalAmount;
      }
      setTimeout(() => dispatch(deleteSplitTransactionBtwPeople(backendId)), 6000);
    } else {
      if (splitAmount && splitAmount !== amount) {
        setTimeout(() => dispatch(splitTransactionBetweenPeople(backendId, parseFloat(`${splitAmount}`), newOriginalAmount)), 6000);
        if (debit > 0) {
          newDebit = parseFloat(`${splitAmount}`);
        } else {
          newCredit = parseFloat(`${splitAmount}`);
        }
      }
    }

    if (!isSplit && !_.isEmpty(newSplitByCategories)) {
      newSplitByCategories = {};
      setTimeout(() => dispatch(deleteSplitTransactionByCategory(backendId)), 8000);
    } else {
      if (newSplitByCategories !== splitByCategories) {
        setTimeout(() => dispatch(splitTransactionByCategory(newOriginalAmount, newSplitByCategories, backendId)), 8000);
      }
    }

    dispatch(updateTransactionItem(
      {
        bankAccountBackendId,
        backendId: backendId,
        description: desc,
        excluded: removeFromAnalytics,
        credit: newCredit,
        debit: newDebit,
        originalAmount: newOriginalAmount,
        categoryId: !_.isEmpty(newSplitByCategories) ? 0 : newCategoryId,
        splitByCategories: newSplitByCategories,
        originalCategoryId: originalCategoryId || categoryId
      }
    ));
    onItemPress();
  };

  const minDate = currentAccount ? stringToDate(currentAccount.openingDate) : undefined;

  const renderContent = () => {
    return (
      <div>
        <div className={styles.header}>
          {!isCreateManualTrx && <img src={getIconForMerchant(merchantId)} className={styles.icon}/>}
          <span className={styles.name}>{desc}</span>
          <span className={styles.desc}>
              { isManual
                ? (balance < 0 ? '- ' : '+ ') + formatCurrency(Math.abs(balance), 0, currency)
                : formatCurrency(isSplit && !_.isElement(splitedCategories) ? parseFloat(`${splitAmount}`) || amount : originalAmount || amount, 2, currency)}
          </span>
          <div className={styles['category-button']} onClick={() => {
            if (newCategoryId) {
              setItemCategoryToChange && setItemCategoryToChange({ id: newCategoryId, amount: amount, parent: getTransactionParentCtgById(newCategoryId) });
              setUpdateCategory && setUpdateCategory(true);
            }
          }
            }>
            { newCategoryId === 0 && <img className={styles['splited-icon']} src={multipleCategoriesIcon}/>}
            <span
              className={styles.category}>{(newCategoryId ? getTransactionCtgEmojiById(newCategoryId) + ' ' : '') + getTransactionCtgNameById(newCategoryId)}</span>
              <ExpandButton
                isOpen={false}
                size={15}
              />
          </div>
        </div>
        <div className={styles.content}>
          <FormItemContainer
            id="description"
            left={t('description')}
            tooltip={t('tooltips:description')}
            right={FormInputEmun.stringInput}
            value={desc}
            onChange={setDesc}
            icon={descIcon}
            placeholder={t('description')}
          />
          {isManual && (
            <FormItemContainer
            id="manualAccountAmount"
            tooltip={t('tooltips:manualAccountBalance')}
            icon={initalBalanceIcon}
            allowNegativeValue={true}
            left={t('manageAccounts:manualAccountAmount')}
            right={FormInputEmun.moneyInput}
            value={balance}
            onChange={(v: number) => setBalance(v)}
        />
          )}
          {originalDescription && !isManual && <FormItemContainer
            id="originalDescription"
            left={t('originalDescription')}
            tooltip={t('tooltips:originalDescription')}
            right={FormInputEmun.stringInput}
            value={originalDescription}
            icon={descIcon}
          />}

          {currentAccount?.name && <FormItemContainer
            id="accountName"
            left={t('manageAccounts:accountName')}
            tooltip={t('tooltips:accountName')}
            right={FormInputEmun.stringInput}
            value={currentAccount?.name}
            icon={descIcon}
          />}

          {isManual
            ? (
            <FormItemContainer
            id="initialDate"
            tooltip={isManual ? t('tooltips:manualTransactionDate') : t('tooltips:transactionDate')}
            icon={initalDateIcon}
            left={t('manageAccounts:initialDate')}
            right={FormInputEmun.stringInput}
            value={formatDateDMY(accountDate)}
            withDivider={!isCalenderOpen}
            onPress={() => setIsCalenderOpen(!isCalenderOpen)}
        />
              )
            : (
              <FormItemContainer
              id="date"
              tooltip={t('tooltips:transactionDate')}
              icon={initalDateIcon}
              left={t('date') + ''}
              right={FormInputEmun.stringInput}
              value={formatDate(date, 'd MMM yyyy')}
          />
              )}
          <div className={styles.calendar} style={{ height: isCalenderOpen ? '370px' : '0px', opacity: isCalenderOpen ? '1' : '0' }}>
            <Calendar
              maxDate={new Date()}
              minDate={minDate}
              locale={i18n.language}
              onChange={(v: Date) => setAccountDate(v)}
              value={accountDate}
              selectRange={false}
            />
          </div>
          {!isCreateManualTrx && <FormItemContainer
            id="splitTransaction"
            icon={splitIcon}
            withDivider={!isSplit}
            tooltip={t('tooltips:splitTransaction')}
            left={`${t('splitTransaction')}`}
            right={<HardbaconToggle value={isSplit} onCheck={(v) => {
              if (isSplit) {
                setIsSplit(false);
              }
              if (!v) {
                setSplitedCategories && setSplitedCategories(undefined);
                setNewCategoryId && setNewCategoryId(originalCategoryId || categoryId);
              }
              return setOpenSplitSelection && setOpenSplitSelection(v);
            }
            }/>}
          />}
          {
            isSplit && splitedCategories
              ? (
                <div>
                  <FormItemContainer
                    id="TransactionCategory_0"
                    withIndent={true}
                    withDivider={false}
                    left={`${t('TransactionCategory_0')}`}
                    right={FormInputEmun.stringInput}
                    value={`${t('edit')}`}
                    onPress={() => setOpenSplitCategories && setOpenSplitCategories(true)}
                  />
                  {splitedCategories.map((i: SplitedCategoryType, index: number) => {
                    return (
                      <div key={index}>
                        {renderSplitedCatgory(i, index)}
                      </div>
                    );
                  })}
                </div>
                )
              : isSplit && splitAmount && splitPercentage ? renderSplitedByPeople() : null
          }
          {!isCreateManualTrx && <FormItemContainer
            id="removeFromAnalytics"
            icon={removeFromAnalyticsIcon}
            left={t('removeFromAnalytics')}
            tooltip={t('tooltips:removeFromAnalytics')}
            right={<HardbaconToggle value={removeFromAnalytics} onCheck={setRemoveFromAnalytics}/>}
            withDivider={false}
          />}
          {isUpdateManualTrx && <FormItemContainer
            id="delete"
            icon={removeIcon}
            left={<span style={{ color: colors.accentWarning100 }}>{t('delete')}</span>}
            right={<div/>}
            onPress={onDeleteButtonPress}
            withDivider={false}
          />}
        </div>
        <div className={styles['float-button-container']}>
          <HardbaconButton
            className={styles['float-button']}
            disabled={!shouldUpdate}
            name={'accept'}
            onPress={() => submit()}
            type="button"
            title={isCreateManualTrx ? t('create') : t('update')}/>
        </div>
      </div>
    );
  };

  const onMainButtonPress = () => {
    dispatch(closePopUp());
  };
  const onSubButtonPress = () => {
    dispatch(closePopUp());
    dispatch(deleteManualTransaction(bankAccountId, id));
    onItemPress();
  };

  const onDeleteButtonPress = () => {
    dispatch(openPopUp({
      closeOnClickOutside: false,
      onMainButtonPress,
      onSubButtonPress,
      mainButtonTitle: t('cancel'),
      subButtonTitle: t('deleteManualTrx'),
      isOpen: true,
      title: t('deleteManualTrxPopUp'),
      danger: true,
      showCloseButton: false,
      emoji: '🚨'
    }));
  };

  const renderTransactionItemContent = (item: BankingTransaction) => {
    return (
        <div className={styles.container}>
          {openSplitSelection && rendeSplitSelection()}
          {openSplitBetweenPeople && renderSplitedByPeopleDetail()}
          {openSplitCategories && renderSplitedCatgoriesDetail()}
          {!openSplitBetweenPeople && !openSplitCategories && !openSplitSelection && !addCategoryOpen && renderContent()}
        </div>
    );
  };

  return (
    <RightPanel
      title={title.toString()}
      visible={visible}
      onClose={() => {
        onClose();
      }}
      closeOnClickOutside={true}
      goBack={shouldGoBack ? onGoBack : undefined}
      renderContent={() => {
        if (openSelections) {
          return renderSelections();
        }

        if (addCategoryOpen || itemCategoryToChange || addCategory || updateCategory) {
          return (
            <AddCategory
            visible={visible}
            onItemPress={(v: SplitedCategoryType[]) => {
              if (updateCategory) {
                setNewCategoryId(v[0].id);
                setUpdateCategory(false);
                setAddCategory(false);
                setIsSubCategoryOpen(false);
                setItemCategoryToChange(undefined);
              } else {
                setSplitedCategories(v);
                if (itemCategoryToChange) {
                  setItemCategoryToChange(undefined);
                } else if (addCategory) {
                  setAddCategory(false);
                } else {
                  setIsSplit(true);
                  setAddCategoryOpen(false);
                  setIsSubCategoryOpen(false);
                  setOpenSplitCategories(true);
                }
              }
            }}
            hasSubCategories={true}
            isSubCategoryOpen={isSubCategoryOpen}
            setIsSubCategoryOpen={setIsSubCategoryOpen}
            itemToUpdate={itemCategoryToChange}
            disabledItems={itemCategoryToChange || addCategory ? splitedCategories?.filter((i: SplitedCategoryType) => i.id !== itemCategoryToChange?.id) : [] as SplitedCategoryType[]}
            minNumOfSelection={addCategory || updateCategory ? 1 : 2}/>
          );
        }

        if (selectedTransaction) {
          return renderTransactionItemContent(selectedTransaction);
        }
      }}
  />);
};

export default TransactionItemDetailPanel;
