import { Dispatch } from 'redux';
import {
  ADD_USER_INSTITUTION,
  ADD_USER_INSTITUTION_FAILED,
  ADD_USER_INSTITUTION_SUCCESS,
  CLEAR_ADD_USER_INSTITUTION,
  CLEAR_ERROR_USER_INSTITUTION,
  CLEAR_POLL_USER_INSTITUTION,
  DELETE_USER_INSTITUTION,
  DELETE_USER_INSTITUTION_FAILED,
  DELETE_USER_INSTITUTION_SUCCESS,
  GET_USER_INSTITUTION,
  GET_USER_INSTITUTION_SUCCESS,
  POLL_USER_INSTITUTION,
  POLL_USER_INSTITUTION_FAILED,
  POLL_USER_INSTITUTION_SUCCESS,
  SET_ACCOUNT_SELECTOR_PANEL_OPEN,
  SET_ADD_INSTITUTION_PANEL_OPEN,
  SET_SELECTED_ACCOUNTS,
  SET_PRELOADED,
  CREATE_MANUAL_ACCOUNT,
  DELETE_MANUAL_ACCOUNT,
  SET_NOTIFICATIONS_PANEL_OPEN
} from '../types/UserInstitution';
import {
  addUserInstitution,
  createManualAccountService,
  deleteManualAccountService,
  disconnectUserInstitution,
  getUserInstitution,
  getUserInstitutions,
  syncUserInstitution,
  updateInstitutionDetails,
  updateSecurityAnswer
} from '../api/UserInstitutionService';
import UserInstitution from '../model/UserInstitution';
import InstitutionAccountDetail from '../model/InstitutionAccountDetail';
import { AddAccountParameter } from '../component/AddAccountRightPanel';
import { AnalyticsEvent, logEvent } from '../utils/AnalyticsUtils';
import InstitutionDescription from '../model/InstitutionDescription';
import InstitutionSource from '../model/InstitutionSource';
import { setNotification } from './Notification';
import AppNotificationType from '../model/AppNotificationType';
import i18n from '../i18n';

export const getInstitutions = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_USER_INSTITUTION });
  getUserInstitutions().subscribe(
    (userInstitutions) => {
      dispatch({ type: GET_USER_INSTITUTION_SUCCESS, payload: userInstitutions });
      // setTimeout(() => dispatch({ type: GET_USER_INSTITUTION_SUCCESS, payload: userInstitutions }), 1000);
    }
  );
};

export const updateUserInstitutionDetails = (institutionId: string, institutionSourceId: number, institutionAccountDetails: InstitutionAccountDetail[]) => (dispatch: Dispatch) => {
  updateInstitutionDetails(institutionId, institutionSourceId, institutionAccountDetails)
    .subscribe(
      (resp: any) => {
        dispatch({ type: GET_USER_INSTITUTION_SUCCESS, payload: resp });
      }
    );
};

export const addInstitution = (name: string, credentials: object, institutionSource: InstitutionSource, userInstitution?: UserInstitution) => (dispatch: Dispatch) => {
  dispatch({ type: ADD_USER_INSTITUTION });
  addUserInstitution(name, credentials, institutionSource, undefined, userInstitution).subscribe(
    (userInstitution) => {
      dispatch({ type: ADD_USER_INSTITUTION_SUCCESS, payload: userInstitution });
    },
    (error) => {
      dispatch({ type: ADD_USER_INSTITUTION_FAILED, payload: error });
    }
  );
};

export const addExistingInstitution = (institutionSource: InstitutionSource, institutionId: string) => (dispatch: Dispatch) => {
  addUserInstitution(undefined, undefined, institutionSource, institutionId).subscribe();
};

export const pollInstitution = (institutionId: string, institutionSourceId: number) => (dispatch: Dispatch) => {
  dispatch({ type: POLL_USER_INSTITUTION });
  getUserInstitution(institutionId, institutionSourceId).subscribe(
    (userInstitution) => {
      dispatch({ type: POLL_USER_INSTITUTION_SUCCESS, payload: userInstitution });
      if (userInstitution.syncStatus === 'ok') {
        const event = userInstitution.institutionSource.description === InstitutionDescription.BANKING ? AnalyticsEvent.INSTITUTION_BANKING_CONNECTED : AnalyticsEvent.INSTITUTION_INVESTMENT_CONNECTED;
        logEvent(event, { institution: userInstitution.institutionSource.code });
      }
      // DEBUG PURPOSE
      // dispatch({type: POLL_USER_INSTITUTION_SUCCESS, payload: {...userInstitutions, syncError: {name: 'SecurityAnswerFailedError', message: 'hardcoded Question for test', options: []}}});
    },
    (error) => {
      dispatch({ type: POLL_USER_INSTITUTION_FAILED, payload: error });
    }
  );
};

export const sendSecurityAnswer = (name: string, answer: string | number, id: string | number, institutionSourceId: number) => (dispatch: Dispatch) => {
  dispatch({ type: ADD_USER_INSTITUTION });
  updateSecurityAnswer(name, answer, id, institutionSourceId).subscribe(
    (userInstitution) => {
      dispatch({ type: ADD_USER_INSTITUTION_SUCCESS, payload: userInstitution });
    },
    (error) => {
      dispatch({ type: ADD_USER_INSTITUTION_FAILED, payload: error });
    }
  );
};

export const syncInstitution = (id: string, institutionSourceId: number) => (dispatch: any) => {
  dispatch({ type: POLL_USER_INSTITUTION });
  syncUserInstitution(id, institutionSourceId).subscribe(
    (userInstitutions) => {
      setTimeout(() => {
        getUserInstitution(id, institutionSourceId).subscribe(
          (userInstitution) => {
            dispatch({ type: POLL_USER_INSTITUTION_SUCCESS, payload: userInstitution });
            dispatch(setNotification({
              title: i18n.t('successTitle'),
              description: i18n.t('syncInstitutionSuccess'),
              type: AppNotificationType.success
            }));
          },
          (error) => {
            dispatch({ type: POLL_USER_INSTITUTION_FAILED, payload: error });
          }
        );
      }, 5000);
    }
  );
};

export const clearPollInstitution = () => (dispatch: Dispatch) => {
  dispatch({ type: CLEAR_POLL_USER_INSTITUTION });
};

export const clearAddInstitution = () => (dispatch: Dispatch) => {
  dispatch({ type: CLEAR_ADD_USER_INSTITUTION });
};

export const clearErrorInstitution = () => (dispatch: Dispatch) => {
  dispatch({ type: CLEAR_ERROR_USER_INSTITUTION });
};

export const disconnectInstitution = (id: string, institutionSourceId: number) => (dispatch: Dispatch) => {
  dispatch({ type: DELETE_USER_INSTITUTION });
  disconnectUserInstitution(id, institutionSourceId).subscribe(
    () => {
      dispatch({ type: DELETE_USER_INSTITUTION_SUCCESS, payload: id });
    },
    (error) => {
      dispatch({ type: DELETE_USER_INSTITUTION_FAILED, payload: error });
    }
  );
};

export const setAddInstitutionPanelOpen = (isOpen: boolean, parameters?: AddAccountParameter) => (dispatch: Dispatch) => {
  dispatch({ type: SET_ADD_INSTITUTION_PANEL_OPEN, payload: { isOpen, parameters } });
};

export const setAccountSelectorPanelOpen = (isOpen: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: SET_ACCOUNT_SELECTOR_PANEL_OPEN, payload: isOpen });
};

export const setNotificationsPanelOpen = (isOpen: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: SET_NOTIFICATIONS_PANEL_OPEN, payload: isOpen });
};

export const setSelectedAccounts = (selectedAccounts: string[]) => ({
  type: SET_SELECTED_ACCOUNTS,
  payload: selectedAccounts
});

export const setPreloaded = (preloaded: boolean) => ({
  type: SET_PRELOADED,
  payload: preloaded
});

export const createManualAccount =
  (name: string, type: string, subType: string, currency: string, openingDate: Date, balance: number) => (dispatch: Dispatch) => {
    const data = {
      name,
      type,
      subType,
      currency,
      openingDate,
      balance
    };
    createManualAccountService(data).subscribe(
      (resp: any) => {
        dispatch({
          type: CREATE_MANUAL_ACCOUNT
        });
        dispatch({ type: GET_USER_INSTITUTION_SUCCESS, payload: resp });
      }
    );
  };

export const deleteManualAccount =
  (id: number, sourceId: number) => (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_MANUAL_ACCOUNT,
      payload: {
        id,
        sourceId
      }
    });
    deleteManualAccountService(id).subscribe(
      (resp: any) => {
        dispatch({ type: GET_USER_INSTITUTION_SUCCESS, payload: resp });
      }
    );
  };
