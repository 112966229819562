import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReducerUserInstitutionState from '../../model/ReducerUserInstitutionState';
import {
  AccountDetails,
  AddAccount,
  HardbaconButton,
  HardbaconModal,
  InstitutionRow
} from '../../component';
import UserInstitution from '../../model/UserInstitution';
import ReducerInstitutionState from '../../model/ReducerInstitutionState';
import InstitutionSource from '../../model/InstitutionSource';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  getInstitutions,
  pollInstitution,
  disconnectInstitution,
  setAddInstitutionPanelOpen,
  addExistingInstitution,
  addInstitution,
  clearPollInstitution,
  clearAddInstitution
} from '../../actions/UserInstitution';
import { getAvailableInstitutionSource } from '../../actions/Institution';
import { HardbaconModalStyle } from '../../component/HardbaconModal';
import { AddAccountParameter } from '../../component/AddAccountRightPanel';
import { withRouter } from '../../hooks/withRouter';
import SimplePage from '../../component/Layouts/SimplePage';
import { PlaidConnectWidget } from '../../component/PlaidConnectWidget';
import InstitutionFilterModel from '../../model/InstitutionFilterModel';

type ManageAccountState = {
  addingInstitution: boolean,
  addingInstitutionCredentials?: object,
  addingInstitutionCode?: string,
  disconnectAccountModalOpened: boolean,
  disconnectAccountModalPayload?: UserInstitution,
  accountDetailsOpen: boolean,
  institution?: UserInstitution,
  showPlaid: boolean;
}

type ManageAccountProps = WithTranslation & {
  location?: Location,
  institutions?: UserInstitution[],
  availableInstitution?: InstitutionSource[],
  userInstitutionState?: ReducerUserInstitutionState;
  getInstitutions?: () => void,
  pollInstitution: (id: string, institutionSourceId: number) => void,
  getAvailableInstitutionSource?: () => void,
  disconnectInstitution?: (id: string, institutionSourceId: number) => void,
  setAddInstitutionPanelOpen?: (isOpen: boolean, parameters?: AddAccountParameter) => void
  addExistingInstitution: (institutionSource: InstitutionSource, institutionId: string) => void;
  addInstitution: (name: string, credentials: object, institutionSource: InstitutionSource, userInstitution?: UserInstitution) => void;
  clearPollInstitution: () => void;
  clearAddInstitution: () => void;
}

class ManageAccount extends Component<ManageAccountProps, ManageAccountState> {
  private accountDetailsModal?: typeof AccountDetails;

  constructor (props: ManageAccountProps) {
    super(props);

    this.state = {
      accountDetailsOpen: false,
      addingInstitution: false,
      disconnectAccountModalOpened: false,
      showPlaid: false
    };
  }

  openAddAccountModal () {
    const modalParameter: AddAccountParameter = {
      addInstitutionOnOpening: this.state.addingInstitution
    };

    if (this.state.addingInstitution) {
      modalParameter.institutionSourceToAddOnOpening = this.props.availableInstitution!.find(item => item.code === this.state.addingInstitutionCode);
      modalParameter.institutionCredentialToAddOnOpening = this.state.addingInstitutionCredentials;
    }

    this.props.setAddInstitutionPanelOpen!(true, modalParameter);

    if (this.state.addingInstitution) {
      this.setState({
        addingInstitution: false,
        addingInstitutionCode: undefined,
        addingInstitutionCredentials: undefined
      });
    }
  }

  showDisconnectAccountModal (institution: UserInstitution) {
    this.setState({ disconnectAccountModalOpened: true, disconnectAccountModalPayload: institution });
  }

  disconnectAccount (institution: UserInstitution) {
    this.props.disconnectInstitution!(institution.id, institution.institutionSource.id);
    this.setState({ disconnectAccountModalOpened: false, disconnectAccountModalPayload: undefined });
  }

  openAccountDetailsModal (institution: UserInstitution) {
    if (institution.institutionSource.dataSource === 'DATABASE' && institution.institutionSource.description !== 'BANKING') {
      return;
    }
    if (institution.syncError || institution.syncErrorName === 'LoginFailedError') {
      this.fixInstitutionError(institution);
    } else {
      this.setState({ institution: institution, accountDetailsOpen: true });
    }
  }

  fixInstitutionError (institution: UserInstitution) {
    this.props.pollInstitution!(institution.id, institution.institutionSource.id);
    this.openAddAccountModal();
  }

  addAnInstitution = (selectedInstitutionSource?: InstitutionSource, institutionId?: string, _credentials?: Map<string, string>) => {
    if (selectedInstitutionSource === undefined) {
      return;
    }
    // If the institution was already added (ie MX Connect Widget), we already have an institutionId
    if (institutionId !== undefined) {
      this.props.addExistingInstitution(selectedInstitutionSource, institutionId);
      return;
    }

    let allInputsAreValid: undefined | boolean;
    selectedInstitutionSource.credentials!.forEach(key => {
      const input = _credentials?.get(key);
      const isInputValid = !!input;
      if (allInputsAreValid === undefined) {
        allInputsAreValid = isInputValid;
      } else {
        allInputsAreValid = allInputsAreValid && isInputValid;
      }
    });

    if (allInputsAreValid) {
      // If pollingInstitution is not undefined, then an update will be called instead of a create
      const credentialsAsObject = {};
      _credentials?.forEach(function (val, key) {
        // @ts-ignore
        credentialsAsObject[key] = val;
      });
      this.props.addInstitution(selectedInstitutionSource.name, credentialsAsObject, selectedInstitutionSource, this.props.userInstitutionState?.pollingInstitution);
    }
  };

  hide = () => {
    this.setState({ showPlaid: false });
    this.props.clearPollInstitution();
    this.props.clearAddInstitution();
  }

  componentDidMount () {
    this.props.getInstitutions!();
    this.props.getAvailableInstitutionSource!();
  }

  componentDidUpdate (prevProps: Readonly<ManageAccountProps>, prevState: Readonly<ManageAccountState>) {
    const prevInstitution = prevProps.institutions?.find((prevInstitution) => prevInstitution.id === this.state.institution?.id);
    const currentInstitution = this.props.institutions?.find((currInstitution) => currInstitution.id === this.state.institution?.id);
    const prevLastDate = prevInstitution ? new Date(prevInstitution.syncDate).getTime() : null;
    const currLastDate = currentInstitution ? new Date(currentInstitution.syncDate).getTime() : null;
    if (currLastDate !== prevLastDate) {
      this.setState({
        institution: currentInstitution
      });
    }

    if (this.props.availableInstitution && this.props.availableInstitution.length > 0 && (!prevProps.availableInstitution || prevProps.availableInstitution.length === 0)) {
      const params = new URLSearchParams(this.props.location?.search ?? '');
      const code = params.get('code');
      const b64EncodedStateFromWealthica = params.get('state');
      if (b64EncodedStateFromWealthica) {
        const stateFromWealthica: { sub: string, path: string, institution: string } = JSON.parse(atob(decodeURIComponent(b64EncodedStateFromWealthica!)));
        const institution = stateFromWealthica.institution;
        if (code && institution) {
          this.setState({
            addingInstitution: true,
            addingInstitutionCredentials: { code: code },
            addingInstitutionCode: institution
          });
          setTimeout(() => {
            this.openAddAccountModal();
          }, 1500);
        }
      }
    }
  }

  render () {
    const { institutions, t } = this.props;

    return (
      <SimplePage>
        <HardbaconModal
          open={this.state.disconnectAccountModalOpened}
          payload={this.state.disconnectAccountModalPayload}
          title={t('disconnectAccountModalTitle')}
          positiveText={t('disconnectAccountModalRemoveIt')}
          negativeText={t('disconnectAccountModalKeepIt')}
          positiveAction={(payload: any) => this.disconnectAccount(payload as UserInstitution)}
          dismissAction={() => this.setState({ disconnectAccountModalOpened: false })}
          style={HardbaconModalStyle.action}>
          <div className={'mt-8 mb-10 flex flex-row'}>
            <div
              className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                   viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
              </svg>
            </div>
            <div className={'text-center flex-1'}>
              <p>{t('disconnectAccountModalDescription')}</p>
              <p className={'text-gray-medium small-text'}>{t('disconnectAccountModalDescriptionSubText')}</p>
            </div>
          </div>
        </HardbaconModal>
        <AccountDetails
          open={this.state.accountDetailsOpen}
          institution={this.state.institution}
          fixError={(institution) => this.fixInstitutionError(institution)}
          onClose={() => this.setState({ accountDetailsOpen: false })}/>
        <div className={'flex flex-row'}>
          <div className={'mt-4 flex-1'}>
            <p className="form-section-subtitle">
              {t('manageMyAccountsDescription')}
            </p>
          </div>
          <div className={'flex-1 flex justify-end items-center mt-4'}>
            <div>
              <HardbaconButton onPress={() => this.setState({ showPlaid: true })} title={t('addAccount')} name={'addAccount'}/>
            </div>
          </div>
        </div>
        <div className="bg-white overflow-hidden mt-4">
          {institutions && institutions.length > 0
            ? <ul className="divide-y divide-gray-200">
              {institutions?.filter(i => i.institutionSource.code !== 'practice').filter((institution) => !institution?.institutionAccountDetailDTOs.some(a => a.accountType === InstitutionFilterModel.investment)).map(item => {
                return <InstitutionRow
                  key={item.id}
                  institution={item}
                  disconnect={(institution: UserInstitution) => this.showDisconnectAccountModal(institution)}
                  onClick={() => this.openAccountDetailsModal(item)}/>;
              })}
            </ul>
            : <AddAccount onAddAccount={() => this.setState({ showPlaid: true })}/>
          }
        </div>
        {
          this.state.showPlaid && (
            <div className={'mt-4'}>
              <PlaidConnectWidget
                hideLoading
                institutionId={this.props?.userInstitutionState?.pollingInstitution && this.props?.userInstitutionState.pollingInstitution.id}
                onInstitutionAdded={(institutionProviderCode: string, publicToken: string) => {
                  const is = { providerCode: institutionProviderCode, credentials: ['token'], dataSource: 'PLAID' } as InstitutionSource;
                  const tokenCredential = new Map();
                  tokenCredential.set('token', publicToken);
                  this.addAnInstitution(is, undefined, tokenCredential);
                }}
                onConnectSuccess={(institutionProviderCode: string, institutionId: string) => {
                  const is = this.props.userInstitutionState?.pollingInstitution?.institutionSource;
                  this.props.pollInstitution(institutionId, is!.id);
                }}
              onExit={success => {
                this.hide();
              }}/>
            </div>
          )
        }
      </SimplePage>
    );
  }
}

const mapStateToProps = ({
  userInstitution,
  institution
}: { userInstitution: ReducerUserInstitutionState, institution: ReducerInstitutionState }) => {
  const { institutions } = userInstitution;
  const { availableInstitution } = institution;
  return {
    institutions,
    availableInstitution
  };
};

const withRouterComponent = withRouter(ManageAccount);

export default connect(mapStateToProps, {
  getInstitutions,
  getAvailableInstitutionSource,
  disconnectInstitution,
  pollInstitution,
  setAddInstitutionPanelOpen,
  addExistingInstitution,
  addInstitution,
  clearPollInstitution,
  clearAddInstitution
})(withTranslation('manageAccounts')(withRouterComponent));
