import React, { useEffect, useState } from 'react';
import Container from '../../../component/Container';
import VerticalProgress from '../../../component/VerticalProgress';
import styles from './../../../assets/styles/modules/budget.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { CategoryBudget } from '../../../model/CategoryBudget';
import { fetchFinancialInfo, getBankOverview, getBudgetCategories, getTopLevelCategoriesTransaction } from '../../../actions/Banking';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';
import { BankingTransaction } from '../../../model/BankingTransaction';
import dayjs from 'dayjs';

interface IncomeExpenseViewProps {
  dates?: Date | Date[]
}

const IncomeExpenseView = ({ dates }: IncomeExpenseViewProps) => {
  const [hasDateFilter, setHasDateFilter] = useState(false);
  const [income, setIncome] = useState({ limit: 0, actual: 0 });
  const [expenses, setExpenses] = useState({ limit: 0, actual: 0 });
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    bankStats,
    financialInfo,
    categoriesTransaction,
    budget,
    loadingFinancialInfo,
    loadingCategoriesTransaction,
    loadingBankStats,
    loadingBudget
  } = useSelector((state: any) => state.banking);
  const { minifiedSelectedAccountIds } = useSelector((state: any) => state.userInstitution);
  const {
    bankingTransactions
  } = useSelector((state: any) => state.transactions);

  useEffect(() => {
    if (!loadingFinancialInfo && !financialInfo) {
      dispatch(fetchFinancialInfo());
    }
  }, [financialInfo, loadingFinancialInfo]);

  useEffect(() => {
    if (!budget && !loadingBudget) {
      dispatch(getBudgetCategories());
    }
  }, [budget, loadingBudget]);

  useEffect(() => {
    if (!bankStats && !loadingBankStats) {
      dispatch(getBankOverview(minifiedSelectedAccountIds));
    }
    if (!categoriesTransaction && !loadingCategoriesTransaction) {
      dispatch(getTopLevelCategoriesTransaction(minifiedSelectedAccountIds));
    }
  }, [bankStats, loadingBankStats, categoriesTransaction, loadingCategoriesTransaction, minifiedSelectedAccountIds]);

  useEffect(() => {
    setLoading(loadingFinancialInfo || loadingCategoriesTransaction || loadingBankStats);
  }, [loadingFinancialInfo, loadingCategoriesTransaction, loadingBankStats]);

  useEffect(() => {
    let _hasDateFilter = false;
    const _income = { limit: 0, actual: 0 };
    const _expenses = { limit: 0, actual: 0 };

    if (dates) {
      // @ts-ignore
      const [first, last] = dates;
      const firstDay = dayjs(first);
      const lastDay = last ? dayjs(last) : firstDay.endOf('month').startOf('day');
      const currentMonthFirstDay = dayjs().startOf('month');
      const currentMonthLastDay = dayjs().endOf('month').startOf('day');

      if ((firstDay.isSame(currentMonthFirstDay) && lastDay.isSame(currentMonthLastDay))) {
        _hasDateFilter = false;
      } else {
        _hasDateFilter = true;
      }

      if (!_.isEmpty(bankingTransactions)) {
        bankingTransactions
          .filter((tr: BankingTransaction) => !tr.excluded)
          .filter((tr: BankingTransaction) => dayjs(tr.date).isBetween(firstDay, lastDay, null, '[]'))
          .forEach((tr: BankingTransaction) => {
            if (tr.credit) {
              _income.actual += tr.credit * tr.userExchangeRate;
            } else if (tr.debit) {
              _expenses.actual += tr.debit * tr.userExchangeRate;
            }
          });
      } else if (bankStats) {
        _income.actual = bankStats?.lastPeriodTotalDeposit;
        _expenses.actual = bankStats?.lastPeriodTotalWithdrawal;
      }

      if (!_hasDateFilter) {
        if (budget && budget.length) {
          _expenses.limit = budget.filter((b: CategoryBudget) => b.categoryId !== 68 && b.parentId !== 68).map((i: any) => i.amount).reduce((a: number, b: number) => a + b, 0);
          _expenses.limit = _expenses.limit ?? _expenses.actual;
        }
        _income.limit = financialInfo?.monthlyEarnings || _income.actual;
      } else {
        _income.limit = _income.actual;
        _expenses.limit = _expenses.actual;
      }
    }

    setIncome(_income);
    setExpenses(_expenses);
    setHasDateFilter(_hasDateFilter);
  }, [dates, bankingTransactions, budget, financialInfo]);

  return (
    <Container header={`${t('income')} vs ${t('outcome')}`}>
      {loading
        ? (
          <div className={styles['progress-container']}>
            <CircularProgress classNames={styles['budget-progress']} color={CircularProgressColorEmun.primary}/>
          </div>
          )
        : (
        <div className={styles['income-expense-chart']}>
        {!_.isEmpty(bankStats) && <VerticalProgress
          containerContentRatio={income.limit > expenses.limit ? 1 : income.limit / expenses.limit}
          fullValue={income.limit}
          currentValue={income.actual}
          showDifference={!hasDateFilter}
          title={t('income')}
          credit={true}
        />}
        {!_.isEmpty(bankStats) && <VerticalProgress
          containerContentRatio={income.limit <= expenses.limit ? 1 : expenses.limit / income.limit}
          fullValue={expenses.limit}
          currentValue={expenses.actual}
          isColorFixed={true}
          showDifference={!hasDateFilter}
          title={t('outcome')}
          credit={false}
        />}
      </div>
          )}
    </Container>
  );
};

export default IncomeExpenseView;
