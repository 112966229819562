import React, { useState, useEffect } from 'react';
import { HardbaconButton, HardbaconModal } from '../../../component';
import { HardbaconModalStyle } from '../../../component/HardbaconModal';
import { BudgetStatusTypeEmun } from './Onboarding';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './../../../assets/styles/modules/budgetInfoPopUpProp.module.css';
import { getBudgetCategories } from '../../../actions/Banking';
import { useNavigate } from 'react-router-dom';
import { budgetRoute } from '../../../navigation/Banking';

interface BudgetInfoPopUpProp {
  budgetStatus: BudgetStatusTypeEmun;
  diffCurrency: string;
  openAlert: boolean;
  completeBudget: Function;
  setOpenAlert: Function;
  setOpenAddCategoryPanel: Function
}

const BudgetInfoPopUp = ({ budgetStatus, setOpenAddCategoryPanel, diffCurrency, openAlert, completeBudget, setOpenAlert }: BudgetInfoPopUpProp) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsCompleted(budgetStatus === BudgetStatusTypeEmun.perfect);
    if (budgetStatus === BudgetStatusTypeEmun.perfect && openAlert) {
      completeBudget();
    }
  }, [budgetStatus, openAlert]);

  const getAlertInfo = () => {
    const perfectInfo = {
      emoji: '🎉',
      title: t('budgetCompleted'),
      confirmButtonTitle: t('start'),
      onConfrim: () => {
        dispatch(getBudgetCategories());
        setTimeout(() => {
          navigate(budgetRoute);
        }, 500);
      }
    };
    if (isCompleted) {
      return perfectInfo;
    }
    switch (budgetStatus) {
      case BudgetStatusTypeEmun.exceed:
        return {
          emoji: '🤔',
          title: t('budgetExceedOverlayTitle'),
          subTitle: t('budgetExceedOverlaySubTitle'),
          confirmButtonTitle: t('budgetExceedOverlayConfirm'),
          concelButtonTitle: t('budgetExceedOverlayCancel'),
          onConfrim: () => setOpenAlert(false),
          onCancel: () => {
            setIsCompleted(true);
            completeBudget();
          }
        };
      case BudgetStatusTypeEmun.subceed:
        return {
          emoji: '🤓',
          title: t('budgetSubceedOverlay2Title1') + diffCurrency + t('budgetSubceedOverlay2Title2'),
          subTitle: t('budgetSubceedOverlay2SubTitle'),
          confirmButtonTitle: t('budgetSubceedOverlay2Confirm'),
          concelButtonTitle: t('budgetSubceedOverlay2Cancel') + ' ' + diffCurrency,
          onConfrim: () => {
            setOpenAddCategoryPanel(true);
            setOpenAlert(false);
          },
          onCancel: () => {
            setIsCompleted(true);
            completeBudget();
          }
        };
      default:
        return perfectInfo;
    }
  };

  const alertInfo: any = getAlertInfo();

  return <HardbaconModal
  style={HardbaconModalStyle.none}
  open={openAlert}
  negativeText={t('cancel')}
  positiveText={t('save')}
>
  <div className={styles.container}>
    <span className={styles.emoji}>{alertInfo.emoji}</span>
    {alertInfo?.title && <p className={styles.title}>{alertInfo.title}</p>}
    {alertInfo?.subTitle && <p className={styles['sub-title']}>{alertInfo.subTitle}</p>}
    <div className={styles.buttonGroup}>
      {
        alertInfo.onConfrim && <HardbaconButton
        className={styles.button}
        onPress={() => alertInfo.onConfrim() }
        name={'tryPremium'}
        title={alertInfo.confirmButtonTitle}/>
      }
      {
        alertInfo.onCancel && <HardbaconButton
        className={styles.button}
        inverse={true}
        onPress={() => alertInfo.onCancel() }
        name={'tryPremium'}
        title={alertInfo.concelButtonTitle}/>
      }
     </div>
  </div>
</HardbaconModal>;
};

export default BudgetInfoPopUp;
