import UserInstitution from '../model/UserInstitution';
import InstitutionAccountDetail from '../model/InstitutionAccountDetail';

const InstitutionFilterModel = {
  investment: 'INVESTMENT',
  banking: 'BANKING',
  all: 'ALL'
};

export const getAccountBackendId = (institution: UserInstitution, account: InstitutionAccountDetail) => {
  return `${institution.id}:${account.id}`;
};

export const shouldIncludeInstitutionDetail = (accountTypefilter: string, institutionDetail: InstitutionAccountDetail) => {
  let compute = false;
  if (accountTypefilter === InstitutionFilterModel.all) {
    compute = true;
  }

  // Filter on institution and account subtype for investment in banking accounts
  if (
    accountTypefilter === InstitutionFilterModel.investment &&
    (!institutionDetail.accountType ||
      institutionDetail.accountType === InstitutionFilterModel.investment ||
      institutionDetail.accountSubType === InstitutionFilterModel.investment)
  ) {
    compute = true;
  }

  // Filter on institution and account subtype for banking
  if (
    accountTypefilter === InstitutionFilterModel.banking &&
    institutionDetail.accountType &&
    institutionDetail.accountType !== InstitutionFilterModel.investment
  ) {
    compute = true;
  }

  return compute;
};

/**
 * Remove account detail ID when all accounts inside institution are present
 * @param accountsId
 * @param institutions
 */
export const minifyAccountIds = (accountsId: string[], institutions: UserInstitution[]) => {
  let minifiedIds: string[] = [];

  // Check for account ids and whehter all account ids are present
  for (let i = 0; i < institutions.length; i++) {
    const allAccountsForInstitution = institutions[i].institutionAccountDetailDTOs.map(account =>
      getAccountBackendId(institutions[i], account)
    );
    const allSelected = allAccountsForInstitution.every(id => accountsId.some(iid => iid === id));

    if (allSelected) {
      minifiedIds.push(institutions[i].id);
    } else {
      minifiedIds.push(...allAccountsForInstitution.filter(id => accountsId.some(iid => iid === id)));
    }
  }

  // check for institution Ids now
  const selectedInstitutionIds = institutions.map(i => i.id).filter(id => accountsId.some(iid => iid === id));
  minifiedIds.push(...selectedInstitutionIds);

  // At last check if all institutions are selected : which would mean all accounts and therefore, no need for ids
  const allInstitutionIds = institutions.map(i => i.id);
  const allSelected = allInstitutionIds.every(id => minifiedIds.some(iid => iid === id));

  if (allSelected) {
    minifiedIds = [];
  }

  return minifiedIds;
};
