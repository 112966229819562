import React from 'react';
import {
  Route
} from 'react-router-dom';
import SignUp from '../container/SignUp';

export const route = '/sign-up';

export default function SignUpRoute () {
  return <Route path={route} element={<SignUp/>} />;
}
