import React, { useMemo } from 'react';
import { getOffer } from '../api/ComparatorService';
import { useTranslation } from 'react-i18next';
import { ICreditCard } from '../model/comparators/CreditCard';

export type ICreditCardDetailProps = {
  creditCard: ICreditCard;
  onPress: (card: ICreditCard) => void;
};
export enum IDisplayedRows {
  annualFee = 'annualFee',
  additionalCard = 'additionalCard',
  purchaseInterestRate = 'purchaseInterestRate',
  cashAdvance = 'cashAdvance',
  specialFeatures = 'specialFeatures',
  balanceTransfer = 'balanceTransfer',
  cardCategories = 'cardCategories',
  cardUserTypes = 'cardUserTypes',
  requiredIncome = 'requiredIncome',
  requiredCreditScore = 'requiredCreditScore',
  categoryRewards = 'categoryRewards',
  insurances = 'insurances',
  insurance = 'insurance',
  rewardRate = 'rewardRate',
  legalNote = 'legalNote',
}

export type ICreditCardRowProps = {
  creditCard: ICreditCard;
  fieldName: IDisplayedRows;
};

const TD_CANADA_TRUST = '5f072fcefb87bc0436fbfcd6';
const disabledInstitutions = [TD_CANADA_TRUST];

export const formatAmount = ({
  value,
  options = { language: 'en' }
}: any) => {
  if (!value) {
    value = 0;
  }

  const { language, fractionDigit } = options;

  if (language === 'en') {
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      signDisplay: 'never',
      maximumFractionDigits: fractionDigit
    }).format(+value);
  } else {
    return new Intl.NumberFormat('fr-CA', {
      style: 'currency',
      currency: 'CAD',
      signDisplay: 'never',
      maximumFractionDigits: fractionDigit
    }).format(+value);
  }
};

const CreditCardRow = ({ creditCard, fieldName }: ICreditCardRowProps) => {
  const { t, i18n } = useTranslation('compare');
  const displayedLanguage = i18n.language === 'fr' ? 'french' : 'english';

  switch (fieldName) {
    case IDisplayedRows.annualFee:
    case IDisplayedRows.additionalCard:
    case IDisplayedRows.purchaseInterestRate:
    case IDisplayedRows.cashAdvance:
    case IDisplayedRows.balanceTransfer:
    case IDisplayedRows.requiredIncome:
    case IDisplayedRows.requiredCreditScore:
    case IDisplayedRows.rewardRate:
    case IDisplayedRows.insurance:
    case IDisplayedRows.legalNote:
      return (
        <>
          {creditCard?.[fieldName]?.[displayedLanguage]
            ? (
            <div className="py-3 border-b w-full flex justify-between">
              <span className="font-gilmerRegular text-sm text-hb-keygray mb-2 text-left block">
                {t(`fieldKey.${fieldName}`)}
              </span>
              <span
                className="font-gilmerRegular text-sm text-black text-right"
                dangerouslySetInnerHTML={{
                  __html: creditCard?.[fieldName]?.[displayedLanguage]
                }}
              ></span>
            </div>
              )
            : null}
        </>
      );
    case IDisplayedRows.cardCategories:
    case IDisplayedRows.cardUserTypes:
      return (
        <>
          {creditCard?.[fieldName]?.length
            ? (
            <div className="py-3 border-b w-full flex flex-row justify-between">
              <span className="font-gilmerRegular text-sm text-hb-keygray mb-2 text-left block">
                {t(`fieldKey.${fieldName}`)}
              </span>
              <span
                className="font-gilmerRegular text-sm text-black text-right"
                dangerouslySetInnerHTML={{
                  __html: creditCard?.[fieldName]
                    ?.map((item) => t(`fieldValues.${item}`))
                    .join(', ')
                }}
              ></span>
            </div>
              )
            : null}
        </>
      );
    case IDisplayedRows.insurances:
      return (
        <>
          {creditCard?.[fieldName]?.length
            ? (
            <div className="py-3 border-b w-full flex justify-between">
              <span className="font-gilmerRegular text-sm text-hb-keygray mb-2 text-left">
                {t(`fieldKey.${fieldName}`)}
              </span>
              <div className="w-full">
                {creditCard?.[fieldName]?.map((item) => (
                  <span
                    key={item.title}
                    className="font-gilmerRegular text-sm text-black text-right block my-1"
                  >
                    {`${t(`titleDescriptionValues.${item.title}_emoji`)} ${t(
                      `titleDescriptionValues.${item.title}`
                    )}`}
                  </span>
                ))}
              </div>
            </div>
              )
            : null}
        </>
      );
    default:
      return null;
  }
};

export const CreditCardDetailComparator = ({
  creditCard,
  onPress
}: ICreditCardDetailProps) => {
  const { t, i18n } = useTranslation('compare');
  const displayedLanguage = i18n.language === 'fr' ? 'french' : 'english';
  const isDetailLinkDisabled = disabledInstitutions.includes(
    creditCard?.institutionId
  );
  const firstYearReward = useMemo(
    () =>
      creditCard?.result?.firstYear?.totalReward -
      creditCard?.result?.firstYear?.annualFee,
    [
      creditCard?.result?.firstYear?.annualFee,
      creditCard?.result?.firstYear?.totalReward
    ]
  );
  const consecutiveYearReward = useMemo(
    () =>
      creditCard?.result?.consecutiveYears?.totalReward -
      creditCard?.result?.consecutiveYears?.annualFee,
    [
      creditCard?.result?.consecutiveYears?.annualFee,
      creditCard?.result?.consecutiveYears?.totalReward
    ]
  );
  const productDetailUrl = useMemo(() => {
    if (creditCard) {
      const url =
      i18n.language === 'en'
        ? `/compare/credit-cards/${creditCard.institution.slug.english}/${creditCard.slug.english}`
        : `/comparateur/cartes-de-credit/${creditCard.institution.slug.french}/${creditCard.slug.french}`;
      return url;
    } else {
      return '';
    }
  }, [creditCard, i18n.language]);

  const displayedRows: Array<{ title: IDisplayedRows }> = useMemo(() => {
    const rows = [
      { title: IDisplayedRows.annualFee },
      { title: IDisplayedRows.insurance },
      { title: IDisplayedRows.additionalCard },
      { title: IDisplayedRows.purchaseInterestRate },
      { title: IDisplayedRows.cashAdvance },
      { title: IDisplayedRows.specialFeatures },
      { title: IDisplayedRows.balanceTransfer },
      { title: IDisplayedRows.cardCategories },
      { title: IDisplayedRows.cardUserTypes },
      { title: IDisplayedRows.requiredIncome },
      { title: IDisplayedRows.requiredCreditScore },
      { title: IDisplayedRows.categoryRewards },
      { title: IDisplayedRows.rewardRate },
      { title: IDisplayedRows.insurances },
      { title: IDisplayedRows.legalNote }
    ];

    return rows;
  }, []);

  const handleApply = async () => {
    const url = await getOffer(
      'credit-cards',
      creditCard.id,
      i18n.language
    ) as unknown as string;
    window.location.href = url;
  };

  return (
    <>
      <div className="shadow border bg-white p-3 rounded-lg relative mb-3">
        <div className="flex md:justify-between">
          <div className="flex gap-x-2 w-full">
            <div className="relative flex justify-center items-center w-1/5 px-4">
              <a href={productDetailUrl}>
                <img
                  className='w-full max-h-24'
                  src={`https://hardbacon-prod.s3.us-east-1.amazonaws.com/comparators/${creditCard.institutionId}`}
                  alt={creditCard?.institution?.name?.[displayedLanguage]}
                />
              </a>
            </div>
            <a className="w-4/5" href={productDetailUrl}>
              <div>
                <h3 className="text-left text-lg leading-none font-gilmerMedium">
                  {creditCard?.name?.[displayedLanguage]}
                </h3>
                <span className="text-sm text-hb-darkgray text-left block font-gilmerRegular">
                  {creditCard?.institution?.name?.[displayedLanguage]}
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center my-2 md:flex-row md:items-start">
          <div className="flex flex-col items-center w-1/5 mr-2">
            <div className="relative w-36 h-24 my-3">
              <img
                src={`https://hardbacon-prod.s3.us-east-1.amazonaws.com/comparators/${creditCard.id}_card`}
                alt={creditCard?.name?.[displayedLanguage]}
              />
            </div>
            <p className="relative">
              <span className="text-lg text-[#28CD41] font-gilmerBold leading-5 align-middle py-1">
                {formatAmount({
                  value: firstYearReward,
                  options: { language: i18n.language }
                }) ?? `${i18n.language === 'en' ? '$ 0' : '0 $'}`}
              </span>
              <span className="text-xs font-gilmerRegular-light text-hb-darkgray leading-5 align-middle">
                / {t('year')}
              </span>
              <button
                onClick={() => onPress(creditCard)}
                type="button"
                className="absolute font-gilmerRegular text-6px text-hb-default border border-hb-default rounded-full w-3 h-3"
              >
                i
              </button>
            </p>
            <p className="text-center font-gilmerRegular text-hb-default">
              {t('estimatedRewards')}
            </p>
            <p className="text-10px text-hb-darkgray font-gilmerRegular mb-4">
              {`${t('estimatedReward.description')}`}
              <strong className="font-gilmerBold">
                {formatAmount({
                  value: consecutiveYearReward,
                  options: { language: i18n.language }
                })}
              </strong>
              {` ${t('estimatedReward.descriptionContinue')}`}
            </p>
          </div>
          <div className="flex flex-col items-center w-4/5">
            {displayedRows?.map((row) => (
              <CreditCardRow
                key={row.title}
                creditCard={creditCard}
                fieldName={row.title}
              />
            ))}
            <div className="flex justify-around py-2 gap-x-2">
              <button
                onClick={handleApply}
                className="bg-hb-default rounded-xl px-3 py-2  text-white text-xs font-gilmerMedium border border-hb-default"
              >
                {t('applyNow')}
              </button>
              {!isDetailLinkDisabled
                ? (
                <a
                  href={productDetailUrl}
                  className="bg-[#b7e0e0] rounded-xl px-3 py-2 text-[#008d8d] text-xs font-gilmerMedium border border-[#008d8d]"
                >
                  {t('learnMore')}
                </a>
                  )
                : null}
            </div>
          </div>
        </div>
        {creditCard?.bannerText?.[displayedLanguage] ||
        creditCard?.offerTitle?.[displayedLanguage]
          ? (
          <div className="w-full border border-[#008d8d] flex justify-between p-3 items-center">
            <span className="text-[#666] text-sm font-gilmerBold w-3/4 text-left">
              {creditCard?.bannerText?.[displayedLanguage] ||
                creditCard?.offerTitle?.[displayedLanguage]}
            </span>
            <img
              width={40}
              height={40}
              alt={`${creditCard?.institution?.name?.[displayedLanguage]} logo`}
              src={`https://hardbacon-prod.s3.us-east-1.amazonaws.com/comparators/${creditCard?.institutionId}_offer_logo_${i18n.language}`}
            />
          </div>
            )
          : null}
      </div>
    </>
  );
};
