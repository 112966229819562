import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { route as feedRoute } from './Feed';

export const route = '/';

export default function homeRoute () {
  return (
    <Route path={route} element={<Navigate to={feedRoute}/>}/>
  );
}
