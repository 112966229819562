import AxiosInstance from './index';
import { fromAxiosError } from '../model/APIError';
import UserInstitution from '../model/UserInstitution';
import { catchError, map, concatAll } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import InstitutionAccountDetail from '../model/InstitutionAccountDetail';
import { clearCachedData } from './caching';
import i18n from '../i18n';
import InstitutionSource from '../model/InstitutionSource';

export function getUserInstitutions (): Observable<UserInstitution[]> {
  return AxiosInstance.get<UserInstitution[]>('api/institutions').pipe(
    map(resp => {
      const data = resp.data;
      data.forEach(inst => {
        inst.institutionAccountDetailDTOs.forEach((a, i) => {
          if (!a.name) {
            a.name = `${inst.name} #${i + 1}`;
          }
        });
      });
      return data;
    }),
    catchError(error => throwError(fromAxiosError(error))));
}

export function updateInstitutionDetails (institutionId: string, institutionSourceId: number, institutionAccountDetails: InstitutionAccountDetail[]): Observable<UserInstitution[]> {
  const data = {
    institutionAccountDetails: institutionAccountDetails
  };
  return AxiosInstance.put(`api/institutions/${institutionSourceId}/${institutionId}`, data).pipe(
    map(resp => {
      return getUserInstitutions();
    }),
    concatAll(),
    catchError(error => throwError(fromAxiosError(error))));
}

/**
 *
 * @param name
 * @param credentials
 * @param institutionSource
 * @param userInstitution optional. If userInstitution is not undefined, the API call will be an update instead of create
 */
export function addUserInstitution (name?: string, credentials?: object, institutionSource?: InstitutionSource, institutionBackendId?: string, userInstitution?: UserInstitution): Observable<UserInstitution> {
  const data = {
    name,
    credentials,
    institutionSourceId: institutionSource?.id,
    institutionSourceProviderCode: institutionSource?.providerCode,
    institutionSourceDataSource: institutionSource?.dataSource,
    institutionBackendId
  };
  if (userInstitution && userInstitution?.id) {
    return AxiosInstance.put<UserInstitution>('api/institutions/' + userInstitution!.id, data).pipe(
      map(resp => resp.data),
      catchError(error => throwError(fromAxiosError(error))));
  } else {
    return AxiosInstance.post<UserInstitution>('api/institutions', data).pipe(
      map(resp => resp.data),
      catchError(error => throwError(fromAxiosError(error))));
  }
}

export function getUserInstitution (institutionId: string, institutionSourceId: number): Observable<UserInstitution> {
  return AxiosInstance.get(`api/institutions/${institutionSourceId}/${institutionId}`).pipe(
    map(resp => resp.data),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getInstitutionLink (institutionSourceId: number, institutionId?: string): Observable<string> {
  let apiUrl = `api/institutions/connect/${institutionSourceId}`;
  if (institutionId) {
    apiUrl = `${apiUrl}/${institutionId}`;
  }
  return AxiosInstance.get(apiUrl).pipe(
    map(resp => resp.data),
    catchError(error => throwError(fromAxiosError(error))));
}

export function getPlaidToken (institutionId?: string): Observable<string> {
  let apiUrl = 'api/institutions/plaid/get-token';
  if (institutionId) {
    apiUrl += `/${institutionId}`;
  }
  return AxiosInstance.get<string>(apiUrl, { params: { language: i18n.language } }).pipe(
    map(resp => resp.data),
    catchError(error => throwError(fromAxiosError(error))));
}

export function updateSecurityAnswer (name: string, answer: string | number, id: string | number, institutionSourceId: number): Observable<UserInstitution> {
  const data = {
    name,
    securityAnswer: answer,
    institutionSourceId
  };

  return AxiosInstance.put<UserInstitution>(`api/institutions/${id}`, JSON.stringify(data)).pipe(
    map(resp => resp.data),
    catchError(error => throwError(fromAxiosError(error)))
  );
}

export function syncUserInstitution (id: string | number, institutionSourceId: number): Observable<UserInstitution> {
  return AxiosInstance.post(`api/institutions/${institutionSourceId}/${id}/sync`).pipe(
    map(resp => resp.data),
    catchError(error => throwError(fromAxiosError(error))));
}

export function disconnectUserInstitution (id: string, institutionSourceId: number): Observable<any> {
  return AxiosInstance.delete(`api/institutions/${institutionSourceId}/${id}`).pipe(
    map(resp => resp),
    catchError(error => throwError(fromAxiosError(error))));
}

export function createManualAccountService (data: any): Observable<UserInstitution[]> {
  clearCachedData();
  return AxiosInstance.post('api/banking/manual/accounts', JSON.stringify(data)).pipe(
    map(resp => {
      return getUserInstitutions();
    }),
    concatAll(),
    catchError(error => throwError(fromAxiosError(error))));
}

export function deleteManualAccountService (id: number): Observable<UserInstitution[]> {
  clearCachedData();
  return AxiosInstance.delete(`api/banking/manual/accounts/${id}`).pipe(
    map(resp => {
      return getUserInstitutions();
    }),
    concatAll(),
    catchError(error => throwError(fromAxiosError(error))));
}
