import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BankingTips } from '../../../model/BankingTips';
import { getBankingTips } from '../../../actions/Banking';
import styles from '../../../assets/styles/modules/insightTips.module.css';
import HardbaconAmount from '../../../component/HardbaconAmount';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';

interface InsightTipsProps {
  seeMore?: () => void;
}

const InsightTips = (props: InsightTipsProps) => {
  const { t } = useTranslation();
  const { bankingTips, loadingBankingTips }: { bankingTips: BankingTips[], loadingBankingTips: boolean } = useSelector((state: any) => state.banking);
  const { minifiedSelectedAccountIds } = useSelector((state: any) => state.userInstitution);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!bankingTips) {
      dispatch(getBankingTips(minifiedSelectedAccountIds));
    }
  }, [minifiedSelectedAccountIds, bankingTips]);

  const renderTip = (tip: BankingTips) => (
    <div key={tip.type} className={styles.tip}>
      <div className={styles.emoji}>{tip.emoji}</div>
      <div className={styles.content}>
        <div className={styles.title}>{tip.title}</div>
        <div className={styles.desc}>{tip.description}</div>
      </div>
      <div className={styles.save}>
        <div className={styles.saveText}>{t('overview:saveAmount')}</div>
        <div><HardbaconAmount amount={tip.saveAmount} size={14} decimals={0}/></div>
      </div>
    </div>);

  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        {loadingBankingTips && (
          <div className={styles.progress}>
            <CircularProgress color={CircularProgressColorEmun.primary}/>
          </div>
        )}
        {!loadingBankingTips && bankingTips && bankingTips.slice(0, 3).map(tip => renderTip(tip))}
      </div>
      {!loadingBankingTips && props.seeMore && <div className={styles.seeMore} onClick={() => props.seeMore!()}>{t('seeMore')}</div>}
    </div>
  );
};

export default InsightTips;
