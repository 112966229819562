import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SimplePage from '../../component/Layouts/SimplePage';
import { grantAccess } from '../../api/UserService';
import styles from '../../assets/styles/modules/grantDataAccess.module.css';
import { loadingPiggyBank } from '../../assets/images';
import { HardbaconButton } from '../../component';
import ShieldCheck from '../../assets/images/svg/shieldCheck';
import colors from '../../colors';
import LockClosed from '../../assets/images/svg/lockClosed';
import { Dayjs } from 'dayjs';
import { formatDateDMY } from '../../i18n';

const GrantDataAccess = () => {
  const { t } = useTranslation('grantAccess');
  const location = useLocation();
  const [granted, setGranted] = useState<Dayjs | undefined>(undefined);
  const [granting, setGranting] = useState(false);
  const [token, setToken] = useState<string>();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const _token = params.get('token');
    if (_token) {
      setToken(_token);
    }
  }, [location]);

  const acceptGrant = useCallback(() => {
    setGranting(true);
    if (token && token.length > 0) {
      grantAccess(token).subscribe(valid => {
        setGranted(valid);
        setToken(undefined);
        setGranting(false);
      });
    }
  }, [token]);

  return (
    <SimplePage>
      {granted && (
        <div className={styles.container}>
          <div className={styles.progress}>
            <ShieldCheck fill={colors.white} stroke={colors.primary} />
          </div>
          <div className={styles.title}>{t('grantAccessVerified')}</div>
          <div className={styles.subTitle}>{t('grantAccessVerified2', { validUntil: formatDateDMY(granted) })}</div>
        </div>
      )}
      {!granted && (
        <div className={styles.container}>
          <div className={styles.progress}>
            <LockClosed fill={colors.white} stroke={colors.primary} />
          </div>
          <div className={styles.title}>{t('grantAccessRequestTitle')}</div>
          <div className={styles.subTitle}>{t('grantAccessRequest')}</div>
          <div className={styles.subTitle}>{t('grantAccessRequestDesc')}</div>
          <div className={styles.buttonGrant}>
            {!granting && (<HardbaconButton disabled={!token} name={'grantAccess'} title={t('grantAccess')} onPress={() => acceptGrant()}/>)}
            {granting && (
              <div>
                <div className={styles.progress}>
                  <img className={styles.piggy} src={loadingPiggyBank}/>
                </div>
                <div className={styles.subTitle}>{t('grantAccessWaiting')}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </SimplePage>
  );
};

export default GrantDataAccess;
