import ReducerPayload from '../model/ReducerPayload';
import ReducerPopUpState from '../model/ReducerPopUpState';
import { OPEN_POPUP, CLOSE_POPUP } from '../types/PopUp';

export const initialState: ReducerPopUpState = {
  isOpen: false,
  showCloseButton: true,
  title: '',
  desc: '',
  emoji: '',
  mainButtonTitle: '',
  subButtonTitle: '',
  danger: false,
  onMainButtonPress: () => null,
  onSubButtonPress: () => null,
  closeOnClickOutside: false
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case OPEN_POPUP:
      return {
        ...state,
        ...action.payload
      };
    case CLOSE_POPUP:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
