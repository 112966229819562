import React, { useEffect } from 'react';
import HardbaconNavigation from './navigation';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from './actions/User';
import { BrowserRouter } from 'react-router-dom';
import {
  GoogleOAuthProvider
} from '@react-oauth/google';

const App = () => {
  const session = useSelector((state: any) => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile(true));
  }, []);

  if (!session.initialAuthenticatedCheck) {
    return (<></>);
  }

  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID}`}>
      <BrowserRouter>
        <HardbaconNavigation/>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
