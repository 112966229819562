import React from 'react';
import Notification, { NotificationType } from '../model/Notification';
import styles from '../assets/styles/modules/NotificationItem.module.css';
import { useTranslation } from 'react-i18next';
import { getTransactionCtgNameById, getTransactionCtgEmojiById } from '../utils/banking';
import { getStyleFromNotificationType } from '../utils/feed';

const NotificationItem = ({ notification }: {notification: Notification}) => {
  const { t } = useTranslation();
  const notificationDetail = () => {
    switch (notification.notificationType) {
      case NotificationType.BUDGET_OVER_90_PERCENT:
      case NotificationType.BUDGET_TREND_RED:
      case NotificationType.BUDGET_TREND_YELLOW:
        return <div className={styles.category}>{getTransactionCtgEmojiById(notification.categoryId!)} {notification.categoryId && getTransactionCtgNameById(notification.categoryId)}</div>;
      default:
        return '';
    }
  };

  return (
    <div className={styles.container + ' hb-shadow'}>
      {notification.unread && <div className={styles.newNotificationRedDot}/>}
      <div className={`${styles.title} ${getStyleFromNotificationType(notification)}`}>{t('notifications:' + notification.notificationType)}</div>
      <div className={styles.description}>{notification.notificationText}</div>
      {notificationDetail()}
    </div>
  );
};

export default NotificationItem;
