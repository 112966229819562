/* eslint-disable no-unused-vars */
export enum BankingTransactionStatus {
  PENDING = 'PENDING',
  POSTED = 'POSTED',
}

export interface SplitByCategoriesType {
  [key: string]: any
}

export interface SplitedCategoryType {
  id: number,
  amount: number | string,
  parent?: number
}

export interface BankingTransactionCtg {
  id: number;
  code: string;
  emoji: string;
  english: string;
  french: string;
  parent: BankingTransactionCtg;
  subCategories: BankingTransactionCtg[]
}

export interface BankingCtgEmojiDict {
  [id: number]: string;
}

export interface BankingCtgSubCtgDict {
  [id: number]: number[];
}

export interface BankingCtgNamesDict {
  [id: number]: {
    en: string,
    fr: string
  };
}

export interface BankingTransaction {
  id: number;
  backendId: string;
  date: string;
  description: string;
  originalDescription: string;
  debit: number;
  credit: number;
  balance: number;
  originalAmount: number;
  originalCategory: string;
  originalCategoryId: number;
  splitByCategories: SplitByCategoriesType;
  bankAccountBackendId: string;
  bankAccountId: number;
  iconName: string;
  excluded: boolean;
  status: BankingTransactionStatus;
  category: string;
  categoryId: number;
  categoryName: string;
  topLevelCategory: string;
  topLevelCategoryId: number;
  merchantCategoryCode: number;
  merchantId: string;
  income: boolean;
  currency: string;
  userExchangeRate: number;
}
