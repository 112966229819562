import AppNotification from '../model/AppNotification';
import {
  SET_NOTIFICATION,
  FETCH_NEXT_NOTIFICATIONS,
  NOTIFICATIONS_LOADING,
  MARK_NOTIFICATIONS_AS_READ
} from '../types/Notification';
import { Dispatch } from 'redux';
import { getNotifications, markAsRead } from '../api/NotificationService';

export const setNotification = (notification?: AppNotification) => (dispatch: Dispatch) => {
  dispatch({ type: SET_NOTIFICATION, payload: notification });
};

export const fetchNotifications = (pageNum: number) => (dispatch: Dispatch) => {
  dispatch({ type: NOTIFICATIONS_LOADING, payload: true });
  getNotifications(pageNum)
    .subscribe(notifications => {
      dispatch({ type: FETCH_NEXT_NOTIFICATIONS, payload: { notifications, pageNum } });
    });
};

export const markNotificationsAsRead = (ids: number[]) => (dispatch: Dispatch) => {
  markAsRead(ids)
    .subscribe(() => {
      dispatch({ type: MARK_NOTIFICATIONS_AS_READ, payload: ids });
    });
};
