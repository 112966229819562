import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface BudgetCategoryInputProps {
  setValue: Function;
  value: number;
  emoji: string;
  isOverBudget: boolean;
  diffCurrency: string;
  diff: number;
  percentage: string;
  valueCurrency: string;
  incomeCurrency: string;
  budgetPercentage: string
}

const BudgetCategoryInput = ({ setValue, value, emoji, isOverBudget, diffCurrency, diff, percentage, valueCurrency, incomeCurrency, budgetPercentage }: BudgetCategoryInputProps) => {
  const pathColor = isOverBudget ? '#E2B7BB' : '#B7E0E0';

  const { t } = useTranslation();

  return (
    <div className={'budget-category-input'}>
      <CircularProgressbarWithChildren
        styles={buildStyles({
          trailColor: '#F5FAFE',
          pathColor: pathColor
        })}
        strokeWidth={6}
        value={parseInt(percentage, 10)}
      >
        <div className={'content'}>
          <span className={'emoji'}>{emoji}</span>
          <input
          maxLength={8}
          className={'budget-input' + (!value ? ' empty' : '') + (isOverBudget ? ' over-budget' : '')}
          type="text"
          name="value"
          value={value}
          onChange={(v) => setValue(parseInt(v.target.value) || 0)}/>
          <span className={'sub-desc'}>{t('perMonth')}</span>
          <span className={'sub-desc ' + (isOverBudget ? 'over-budget' : 'available-budget')}>
            {diffCurrency + ' ' + (diff >= 0 ? t('available') : t('budgetExceed'))}
          </span>
        </div>
      </CircularProgressbarWithChildren>
      <div>
        <p className={'percentage-desc main' + (isOverBudget ? ' warning' : '')}>{t('budgetPercentage')}</p>
        <p className={'percentage-desc sub'}>{budgetPercentage + '% (' + valueCurrency + ` ${t('of')} ` + incomeCurrency + ')' }</p>
      </div>
    </div>
  );
};

export default BudgetCategoryInput;
