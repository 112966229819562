import React, { useState } from 'react';
import { searchIcon } from '../assets/images';
import styles from './../assets/styles/modules/SearchInput.module.css';

interface SearchInputProps {
    value: string;
    placeholder: string;
    onChange: Function
  }

export const SearchInput = ({ onChange, placeholder, value }: SearchInputProps) => {
  const [inputValue, setInputValue] = useState<string>(value);
  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <img src={searchIcon} className={styles.icon} />
        <input
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange && onChange(e.target.value);
          }}
          placeholder={placeholder}
          value={inputValue}
          className={styles.input}
        />
      </div>
    </div>
  );
};
