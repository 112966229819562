export const errMsgsEn = {
  httpErr500: 'Our engineers will be looking into it!',
  httpErr503and504: 'A temporary error occurred, please try again later.',
  httpErr401: 'It seems you were no longer authenticated, please sign in again.',
  httpErrGeneralTitle: 'Oops, something wrong happened!',
  httpErrGeneralDesc: 'Our engineers will be looking into it!'
};

export const errMsgsFr = {
  httpErr500: 'Notre équipe va investiguer le problème !',
  httpErr503and504: 'Il s’agit d’une erreur temporaire, veuillez réessayer plus tard.',
  httpErr401: 'Il semble que vous ne soyez plus authentifié, veuillez vous identifier à nouveau.',
  httpErrGeneralTitle: 'Oops, une erreur s’est produite !',
  httpErrGeneralDesc: 'Notre équipe va investiguer le problème !'
};
