import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  fetchBankingCategories,
  fetchFinancialInfo,
  getBankOverview,
  getBudgetCategories,
  getTopLevelCategoriesTransaction
} from '../../../actions/Banking';
import styles from './../../../assets/styles/modules/budget.module.css';
import SimplePage from '../../../component/Layouts/SimplePage';
import BudgetCategory from './BudgetCategory';
import { addButtonIcon, calenderIcon } from '../../../assets/images';
import { RightPanel } from '../../../component';
import AddBudgetCategory from '../../../component/AddBudgetCategory';
import { TransactionCategory } from '../../../model/TransactionCategory';
import { CategoryBudget } from '../../../model/CategoryBudget';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';
import { formatDate, formatDateDMY } from '../../../i18n';
import { usePrevious } from '../../../hooks';
import ReducerUserInstitutionState from '../../../model/ReducerUserInstitutionState';
import NoBankingAccountConnected from '../../../component/NoBankingAccountConnected';
import { useNavigate } from 'react-router-dom';
import { budgetOnBoardingRoute } from '../../../navigation/Banking';
import IncomeExpenseView from './IncomeExpenseView';
import { pollInstitution, setAccountSelectorPanelOpen, setAddInstitutionPanelOpen } from '../../../actions/UserInstitution';
import BudgetDatePicker from './BudgetDatePicker';
import { isTablet } from '../../../utils/hooks';
import { getAmountByMonth } from '../../../utils/banking';
import UpdateIncome from '../../../component/UpdateIncome';
import AddSelectedBudgetCategory from '../../../component/AddSelectedBudgetCategory';
import UserInstitution from '../../../model/UserInstitution';
import { PlaidConnectWidget } from '../../../component/PlaidConnectWidget';
import InstitutionSource from '../../../model/InstitutionSource';

const resetCategory: TransactionCategory = {
  amountPerMonth: {},
  budget: -1,
  budgetDisplayHome: false,
  budgetDisplayTrend: false,
  budgetRatio: -1,
  budgetTrend: 'GREEN',
  category: '',
  categoryDetails: [{}],
  categoryId: -1,
  isBudgetDisplayHome: false,
  isBudgetDisplayTrend: false,
  periodAmount: -1,
  topLevelCategory: '',
  topLevelCategoryId: -1,
  totalAmount: -1,
  topLevelCategoryEnglish: '',
  topLevelCategoryFrench: '',
  topLevelCategoryEmoji: '',
  categoryEnglish: '',
  categoryFrench: '',
  categoryEmoji: ''
};

const RightSection = ({ setOpenEditCategoryBudget, categories, openEditCategoryBudget, budgetMonth, setCategorySelected }: {categories?: Array<TransactionCategory>, openEditCategoryBudget: boolean; budgetMonth: Date; setCategorySelected: Function; setOpenEditCategoryBudget: Function}) => {
  const { t } = useTranslation();
  const [showPlaid, setShowPlaid] = useState(false);
  const dispatch = useDispatch();
  const { institutions } = useSelector((state: any) => state.userInstitution);
  const hasInstitution = institutions.length > 0;
  const hasNotSyncInstitution = institutions.some((institution: UserInstitution) => institution.syncStatus === 'error');
  const notSyncedInstitution = institutions.find((institution: UserInstitution) => institution.syncStatus === 'error');
  const lastCreatedConnectedTime = localStorage.getItem('connectedTime');

  const isAccountConnectedInHour = useMemo(() => {
    const difference = dayjs().diff(dayjs(lastCreatedConnectedTime), 'hours');
    return difference < 1;
  }, [lastCreatedConnectedTime]);

  const fixInstitutionError = () => {
    dispatch(pollInstitution(notSyncedInstitution.id, notSyncedInstitution.institutionSource.id));
    setShowPlaid(true);
    // this.openAddAccountModal();
  };

  return (
    <section className={styles.section + ' ' + styles.right}>
       {showPlaid && (
        <PlaidConnectWidget
          hideLoading
          institutionId={notSyncedInstitution && notSyncedInstitution.id}
          onInstitutionAdded={(institutionProviderCode: string, publicToken: string) => {
            const is = { providerCode: institutionProviderCode, credentials: ['token'], dataSource: 'PLAID' } as InstitutionSource;
            const tokenCredential = new Map();
            tokenCredential.set('token', publicToken);
            setShowPlaid(false);
            // this.addAnInstitution(is, undefined, tokenCredential);
          }}
          onConnectSuccess={(institutionProviderCode: string, institutionId: string) => {
            const now = new Date();
            const is = notSyncedInstitution.institutionSource;
            dispatch(pollInstitution(institutionId, is!.id));
            setShowPlaid(false);
            localStorage.setItem('connectedTime', `${now}`);
          }}
          onExit={success => {
            setShowPlaid(false);
          }}
        />
       )}

      {institutions.length === 1 && hasNotSyncInstitution
        ? <NoBankingAccountConnected
                title={t('reconnectAccountTitle')}
                description={t('reconnectAccountDescription')}
                primaryButtonTitle={t('reconnectAccountButton')}
                onPressPrimaryButton={() => fixInstitutionError()} />
        : !_.isEmpty(categories)
            ? (<div>
              {categories && categories
                .map((item: TransactionCategory) => <BudgetCategory
                budgetMonth={budgetMonth}
                key={item.categoryId}
                openEditCategoryBudget={openEditCategoryBudget}
                setCategorySelected={setCategorySelected}
                setOpenEditCategoryBudget={setOpenEditCategoryBudget}
                item={item}/>)
              }
            </div>)
            : isAccountConnectedInHour
              ? <NoBankingAccountConnected
          title={t('noTransactionInfo')}
          description={t('reconnectInAnHour')} />
              : !hasInstitution
                  ? (
            <NoBankingAccountConnected
              title={t('addYourTransactions')}
              description={t('addYourTransactionsDescription')}
              primaryButtonTitle={t('manageAccounts:addAccount')}
              onPressPrimaryButton={() => dispatch(setAddInstitutionPanelOpen(true))} />
                    )
                  : (
                <NoBankingAccountConnected
                  title={t('noTransactionInfo')}
                  description={t('noTransactionInfoDesc')}
                  primaryButtonTitle={t('noTransactionInfoAction')}
                  onPressPrimaryButton={() => dispatch(setAccountSelectorPanelOpen(true))}
                  secondaryButtonTitle={t('noTransactionInfoAction2')}
                  onPressSecondaryButton={() => dispatch(setAddInstitutionPanelOpen(true))} />
                    )
          }
  </section>
  );
};

const Budget = () => {
  const [openAddBudget, setOpenAddBudget] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [categories, setCategories] = useState<TransactionCategory[]>();
  const [openEditCategoryBudget, setOpenEditCategoryBudget] = useState<boolean>(false);
  const [categorySelected, setCategorySelected] = useState<TransactionCategory>(resetCategory);

  const dispatch = useDispatch();
  const {
    financialInfo,
    loadingFinancialInfo,
    budget,
    bankStats,
    bankingCategories,
    categoriesTransaction,
    loadingBankCategories,
    loadingBudget,
    loadingBankStats,
    loadingCategoriesTransaction,
    budgetMonth
  } = useSelector((state: any) => state.banking);
  const { minifiedSelectedAccountIds, hasBankingAccounts } = useSelector((state: any) => state.userInstitution);
  const { shouldUpdateBudget }: { shouldUpdateBudget: boolean } = useSelector((state: any) => state.transactions);
  const isTabletScreen = isTablet();
  const previousMinifiedSelectedAccountIds = usePrevious<ReducerUserInstitutionState>(minifiedSelectedAccountIds);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const date = new Date();
  const currentMonthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const prevMonthFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);

  const isCurrentMonth = formatDateDMY(budgetMonth) === formatDateDMY(currentMonthFirstDay);
  const isPrevMonth = formatDateDMY(budgetMonth) === formatDateDMY(prevMonthFirstDay);
  const isCustomeDate = !isCurrentMonth && !isPrevMonth;

  useEffect(() => {
    if (!loadingFinancialInfo && !financialInfo) {
      dispatch(fetchFinancialInfo());
    }
  }, [financialInfo, loadingFinancialInfo]);

  useEffect(() => {
    if (!bankingCategories.length && !loadingBankCategories) {
      dispatch(fetchBankingCategories());
    }
    if (!budget && !loadingBudget) {
      dispatch(getBudgetCategories());
    }
  }, [budget, loadingBudget, bankingCategories, loadingBankCategories]);

  useEffect(() => {
    if (budget && budget.length === 0) {
      navigate(budgetOnBoardingRoute);
    }
  }, [budget]);

  useEffect(() => {
    if (shouldUpdateBudget) {
      dispatch(getTopLevelCategoriesTransaction(minifiedSelectedAccountIds));
    }
    // Refetch the data when the account selection changes
    if (!minifiedSelectedAccountIds || JSON.stringify(previousMinifiedSelectedAccountIds) === JSON.stringify(minifiedSelectedAccountIds)) {
      return;
    }
    if (!bankStats && !loadingBankStats) {
      dispatch(getBankOverview(minifiedSelectedAccountIds));
    }
    if (!categoriesTransaction && !loadingCategoriesTransaction) {
      dispatch(getTopLevelCategoriesTransaction(minifiedSelectedAccountIds));
    }
  }, [shouldUpdateBudget, bankStats, loadingBankStats, categoriesTransaction, loadingCategoriesTransaction, minifiedSelectedAccountIds]);

  useEffect(() => {
    setLoading(loadingBankCategories !== false || loadingBudget !== false || loadingBankStats !== false || loadingCategoriesTransaction !== false);
  }, [loadingBankCategories, loadingBudget, loadingBankStats, loadingCategoriesTransaction]);

  useEffect(() => {
    if (categoriesTransaction) {
      let _categories = categoriesTransaction.filter((item: TransactionCategory) => item.categoryId !== 68);
      _categories = _categories && budgetMonth && _categories.map((i: TransactionCategory) => {
        const newItem = i;
        newItem.periodAmount = getAmountByMonth(i.amountPerMonth, budgetMonth);
        if (newItem.categoryDetails.length > 0) {
          newItem.categoryDetails.map((sub: TransactionCategory) => {
            const newSub = sub;
            newSub.periodAmount = getAmountByMonth(sub.amountPerMonth, budgetMonth);
            return newSub;
          });
        }
        return newItem;
      });
      _categories && _categories.sort((a: TransactionCategory, b: TransactionCategory) => {
        const budgetDiff = b.budget - a.budget;
        const amountDiff = b.periodAmount - a.periodAmount;
        if (budgetDiff === 0) {
          return amountDiff;
        } else {
          return budgetDiff;
        }
      });
      setCategories(_categories);
    }
  }, [categoriesTransaction, budgetMonth]);

  const renderBudgetHeader = () => {
    return (
      <header className={styles.header}>
        <p className={styles.date}>{formatDate(budgetMonth, 'MMMM yyyy')}</p>
        <div className={styles['button-container']}>
        <div className={styles['date-button']} onClick={() => setOpenDatePicker(!openDatePicker)}>
            <img src={calenderIcon}
                className={styles['button-icon'] + ' ' + styles['small-icon']}
                alt={t('date')}/>
              {isCustomeDate
                ? (
                <p className={styles['date-text']}>
                  <span>
                      <span>
                          {formatDate(budgetMonth, 'MMM yyyy')}
                      </span>
                  </span>
                </p>
                  )
                : (
                <p className={styles['date-text']}>
                  <span>
                      {isCurrentMonth && t('currentMonth')}
                      {isPrevMonth && t('prevMonth')}
                  </span>
                </p>
                  )}
          </div>
          <img className={styles['add-button']} src={addButtonIcon} onClick={() => setOpenAddBudget(!openAddBudget)}
               alt={t('addCategory')}/>
        </div>
      </header>
    );
  };

  let monthlyBudget = 0;
  if (budget && budget.length) {
    budget.filter((b: CategoryBudget) => b.categoryId !== 68 && b.parentId !== 68).map((i: any) => monthlyBudget = monthlyBudget + i.amount);
  }

  const renderBudgetContent = useCallback(() => {
    const noBudgetCategories = categoriesTransaction?.filter((i: TransactionCategory) => i.budget === 0 && i.categoryId !== 68);
    const incomeCategory = categoriesTransaction?.filter((i: TransactionCategory) => i.categoryId === 68)[0];
    let monthlyBudget = 0;
    if (budget && budget.length) {
      budget.filter((b: CategoryBudget) => b.categoryId !== 68 && b.parentId !== 68).map((i: any) => monthlyBudget = monthlyBudget + i.amount);
    }

    return (
      <main>
        <BudgetDatePicker
          isVisible={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
        />
        <RightPanel
          title={t('budgetOnboarding:addCategory')}
          closeOnClickOutside={true}
          visible={openAddBudget} onClose={() => setOpenAddBudget(false)}
          renderContent={() => <AddBudgetCategory onPress={() => setOpenAddBudget(false)}
                                                  categories={noBudgetCategories}/>}
        />
        {renderBudgetHeader()}
        <div className={styles.main}>
          <section className={styles.section + ' ' + styles.left}>
            <div className={styles['top-two']}>
              {incomeCategory && <BudgetCategory
              openEditCategoryBudget={openEditCategoryBudget}
              setCategorySelected={setCategorySelected}
              setOpenEditCategoryBudget={setOpenEditCategoryBudget}
              budgetMonth={budgetMonth}
              item={incomeCategory}
              expandable={false}
              />}
              <IncomeExpenseView dates={[budgetMonth]}/>
            </div>
            {isTabletScreen
              ? (<RightSection
                  categories={categories}
                  openEditCategoryBudget={openEditCategoryBudget}
                  budgetMonth={budgetMonth}
                  setCategorySelected={setCategorySelected}
                  setOpenEditCategoryBudget={setOpenEditCategoryBudget} />
                )
              : null}
          </section>
          {!isTabletScreen
            ? (<RightSection
                  categories={categories}
                  openEditCategoryBudget={openEditCategoryBudget}
                  budgetMonth={budgetMonth}
                  setCategorySelected={setCategorySelected}
                  setOpenEditCategoryBudget={setOpenEditCategoryBudget} />
              )
            : null}
        </div>
      </main>
    );
  }, [categoriesTransaction, categories, openAddBudget, financialInfo, budget, bankStats, hasBankingAccounts, openDatePicker, isTabletScreen]);
  return (
    <SimplePage>
      {!loading && (renderBudgetContent())}
      <RightPanel
        closeOnClickOutside={true}
        title={t('budgetOnboarding:addCategory')}
        visible={openEditCategoryBudget}
        onClose={() => setOpenEditCategoryBudget(false)}
        renderContent={() => categorySelected.categoryId === 68
          ? <UpdateIncome onPress={() => setOpenEditCategoryBudget(false)} item={categorySelected}/>
          : <AddSelectedBudgetCategory onPress={() => setOpenEditCategoryBudget(false)} item={categorySelected}/>}
      />
      {loading && (
        <div className={styles.progress}>
          <CircularProgress color={CircularProgressColorEmun.primary}/>
        </div>
      )}
    </SimplePage>);
};

export default Budget;
