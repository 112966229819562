import React, { ReactNode } from 'react';

interface SimplePageProps {
  children?: ReactNode
}

const SimplePage = (props: SimplePageProps) => {
  return (
    <div className={'page-children'}>
      {props.children}
    </div>
  );
};

export default SimplePage;
