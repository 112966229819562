import React from 'react';
import { radioCheckIcon, radioUncheckIcon } from '../assets/images';
import { useTranslation } from 'react-i18next';
import styles from './../assets/styles/modules/RadioButton.module.css';

interface RadioButtonProps {
    check: boolean,
    onClick?: Function
}

const RadioButton = ({ check, onClick }: RadioButtonProps) => {
  const { t } = useTranslation();

  return (
    <div onClick={() => onClick && onClick()} className={styles.container}>
        <img
            src={check ? radioCheckIcon : radioUncheckIcon}
            className={styles.icon}
            alt={t('select')}
        />
    </div>
  );
};

export default RadioButton;
