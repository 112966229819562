import ReducerSignUpState from '../model/ReducerSignUpState';
import ReducerPayload from '../model/ReducerPayload';
import { SET_EMAIL_ALREADY_USED, SET_REGISTERING } from '../types/SignUp';

export const initialState: ReducerSignUpState = {
  emailAlreadyUsed: false,
  registering: false
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case SET_EMAIL_ALREADY_USED:
      return {
        ...state,
        emailAlreadyUsed: action.payload
      };
    case SET_REGISTERING:
      return {
        ...state,
        registering: action.payload,
        emailAlreadyUsed: action.payload ? false : state.emailAlreadyUsed
      };
    default:
      return state;
  }
};
