import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import HardbaconButton from './HardbaconButton';
import styles from '../assets/styles/modules/ConnectYourBankAccount.module.css';
import institution from '../assets/images/files/institution.svg';
import { setAddInstitutionPanelOpen } from '../actions/UserInstitution';
import { useNavigate } from 'react-router-dom';
import { budgetRoute } from '../navigation/Banking';

const ConnectYourBankAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img width={'90%'} src={institution} alt={'no bank account'} />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{t('connectYourBankAccount')}</p>
        <p className={styles.desc}>{t('connectYourBankAccountDesc')}</p>
        <HardbaconButton
          className="button"
          onPress={() => dispatch(setAddInstitutionPanelOpen(true))}
          name={'connect'}
          title={t('manageAccounts:addAccount')} />
        <HardbaconButton
          gray={true}
          ghost={true}
          className="button"
          onPress={() => navigate(budgetRoute)}
          name={'connect'}
          style={{ marginTop: '12px' }}
          title={t('welcome:connectLater')} />
      </div>
    </div>
  );
};

export default ConnectYourBankAccount;
