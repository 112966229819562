import ReducerPayload from '../model/ReducerPayload';
import { GET_HARDBACON_NEWS, GET_HARDBACON_NEWS_FAILED, GET_HARDBACON_NEWS_SUCCESS, GET_OFFERS, GET_OFFERS_SUCCESS, GET_USER_NEWS_TOPICS_SUCCESS, UPDATE_USER_NEWS_TOPICS_STARTS, UPDATE_USER_NEWS_TOPICS_SUCCESS } from '../types/Feed';

export const initialState = {
  selectedNewsTopics: [
    'CREDIT_CARD',
    'BANKING',
    'INVESTMENT',
    'MORTGAGE',
    'INSURANCE',
    'LOANS',
    'CRYPTO',
    'CREDIT_SCORE',
    'RICHEST',
    'BUDGET',
    'FIRE',
    'PLANNING',
    'DEBTS',
    'TRAVEL'
  ],
  isNewsFeedLoading: false,
  newsFeed: [],
  newsFeedPageNum: 1,
  canLoadMoreNews: true,
  newsMediaFiles: {},
  isUserNewsTopicsUpdating: false,
  offers: [],
  isOffersLoading: false
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case GET_USER_NEWS_TOPICS_SUCCESS: {
      const topics = action.payload.topics;
      let categoriesKeys: string[] = [];
      if (topics && topics.length !== 0) {
        topics.map((cat: any) => {
          categoriesKeys.push(cat.topic);
          return cat;
        });
      } else {
        categoriesKeys = state.selectedNewsTopics;
      }
      return {
        ...state,
        selectedNewsTopics: categoriesKeys
      };
    }
    case UPDATE_USER_NEWS_TOPICS_STARTS: {
      return {
        ...state,
        isUserNewsTopicsUpdating: true
      };
    }
    case UPDATE_USER_NEWS_TOPICS_SUCCESS: {
      return {
        ...state,
        selectedNewsTopics: action.payload
      };
    }
    case GET_HARDBACON_NEWS:
      return {
        ...state,
        isNewsFeedLoading: true
      };
    case GET_HARDBACON_NEWS_SUCCESS:
      return {
        ...state,
        newsFeed: action.payload.pageNum > 1 ? state.newsFeed.concat(action.payload.data) : action.payload.data,
        newsFeedPageNum: action.payload.pageNum,
        isNewsFeedLoading: false,
        isUserNewsTopicsUpdating: false,
        canLoadMoreNews: action.payload.canLoadMore
      };
    case GET_HARDBACON_NEWS_FAILED:
      return {
        ...state,
        isNewsFeedLoading: false,
        canLoadMoreNews: false
      };
    case GET_OFFERS: {
      return {
        ...state,
        isOffersLoading: true
      };
    }
    case GET_OFFERS_SUCCESS: {
      return {
        ...state,
        isOffersLoading: false,
        offers: action.payload
      };
    }
    default:
      return state;
  }
};
