import React, { FormEvent, useEffect, useRef, useState } from 'react';
import {
  HardbaconButton,
  HardbaconInput,
  SocialLoginApple,
  SocialLoginGoogle
} from '../../component';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { CredentialResponse } from '@react-oauth/google';
import { useLocation, useNavigate } from 'react-router-dom';

import i18n from '../../i18n';
import User from '../../model/User';
import { route as signInRoute } from '../../navigation/SignIn';
import { HardbaconLeftPanel } from '../../component/HardbaconLeftPanel';
import { signUp } from '../../actions/User';
import { budgetOnBoardingRoute } from '../../navigation/Banking';

const SignUp = () => {
  const firstNameInput = useRef<HardbaconInput>(null);
  const lastNameInput = useRef<HardbaconInput>(null);
  const emailInput = useRef<HardbaconInput>(null);
  const passwordInput = useRef<HardbaconInput>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { authenticated } = useSelector((state: any) => state.session);
  const { emailAlreadyUsed, registering } = useSelector((state: any) => state.signUp);

  useEffect(() => {
    if (location.state && (location.state as any).appleRes) {
      setTimeout(() => {
        // @ts-ignore
        this.responseApple((location.state as any).appleRes);
      }, 500);
    }
  }, [location]);

  useEffect(() => {
    if (authenticated) {
      if (location.state) {
        const {
          from,
          redirectAfterLogin
        } = location.state as { from?: string, redirectAfterLogin?: boolean };
        if (redirectAfterLogin && from) {
          navigate(from, { state: { redirectAfterLogin: false } });
          return;
        }
      }
      navigate(budgetOnBoardingRoute);
    }
  }, [authenticated, location]);

  useEffect(() => {
    if (emailAlreadyUsed) {
      emailInput.current?.setErrorLabel(t('emailAlreadyTaken'));
    }
  }, [emailAlreadyUsed]);

  const createAccount = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const firstNameValidation = firstNameInput.current?.validate();
    const lastNameValidation = lastNameInput.current?.validate();
    const emailValidation = emailInput.current?.validate();
    const passwordValidation = passwordInput.current?.validate();

    if (firstNameValidation && lastNameValidation && emailValidation && passwordValidation && checked) {
      const user: User = {
        login: emailInput.current?.state.value!,
        firstName: firstNameInput.current?.state.value!,
        lastName: lastNameInput.current?.state.value!,
        password: passwordInput.current?.state.value!,
        langKey: i18n.language
      };
      callRegisterUser(user);
    }
  };

  const responseGoogle = (googleRes: CredentialResponse) => {
    if (googleRes && googleRes.credential) {
      const googleUser: User = {
        firstName: 'No',
        lastName: 'Thing',
        login: 'nothinghere@mail.com',
        password: 'nothingthere',
        googleIdToken: googleRes.credential
      };
      callRegisterUser(googleUser);
    } else {
      // nothing to do
    }
  };

  const responseGoogleFailure = (error: any) => {
    // nothing to do
    console.warn(error);
  };

  const responseApple = (appleRes: any) => {
    if (appleRes.user !== undefined) {
      const appleUser: User = {
        firstName: appleRes.user.name.firstName,
        lastName: appleRes.user.name.lastName,
        login: appleRes.user.email,
        password: 'nothingthere',
        appleIdToken: appleRes.authorization.id_token
      };
      callRegisterUser(appleUser);
    } else {
      // this is a login
      navigate(signInRoute, { state: { appleIdToken: appleRes } });
    }
  };

  const callRegisterUser = (user: User) => {
    if (!user) {
      return;
    }
    // Call recaptcha and join the token to the request
    recaptchaRef.current?.executeAsync().then((token) => {
      if (token) {
        dispatch(signUp(user, token));
      }
    })
      .finally(() => recaptchaRef.current?.reset());
  };

  return (
    <div className={'flex flex-col md:flex-row flex-1 w-full'}>
      <HardbaconLeftPanel/>
      <div className={'flex-1 p-4 md:p-8 lg:p-20 flex-col flex bg-white'}>
        <div onClick={(e) => {
          i18n.changeLanguage(t('header:otherLangCode'));
        }} className={'flex w-full justify-end button-hover cursor-pointer'}>
          <span className={'small-bold-text text-gray-medium text-right'}>{t('header:otherLang')}</span>
        </div>
        <form id={'form-sign-up'} className={'flex flex-1 justify-center flex-col lg:w-96 self-center'}
              onSubmit={(e) => createAccount(e)}>
          <h1 className={'large-text text-black'}>{i18n.t('signUp')}</h1>
          <span className={'medium-regular-text text-gray-medium'}>{i18n.t('createAccount')}</span>
          <SocialLoginGoogle
            type={'signUp'}
            onSuccess={(res) => responseGoogle(res)}
            onFailure={responseGoogleFailure}
          />
          <SocialLoginApple
            type={'signUp'}
            onSuccess={(res) => responseApple(res)}
            onFailure={() => undefined}
          />
          <div className={'flex justify-center items-center mt-4'}>
            <hr className={'flex-1'}/>
            <span className={'text-sm text-gray-medium mx-2'}>{t('or')}</span>
            <hr className={'flex-1'}/>
          </div>

          <div className="mt-4">
            <HardbaconInput optional={false}
                            ref={firstNameInput}
                            name="firstName"
                            placeholder={t('firstName')}/>
          </div>
          <div className="mt-4">
            <HardbaconInput optional={false}
                            ref={lastNameInput}
                            name={'lastName'}
                            placeholder={t('lastName')}/>
          </div>
          <div className="mt-4">
            <HardbaconInput regex={'\\S+@\\S+\\.\\S+'}
                            optional={false}
                            ref={emailInput}
                            name={'email'} type={'email'}
                            placeholder={t('email')}/>
          </div>
          <div className="mt-4">
            <HardbaconInput regex={'^.{10,50}$'} optional={false}
                            ref={passwordInput}
                            name={'password'}
                            isSecureTextEntry={true}
                            placeholder={t('password')}
                            helpLabel={t('passwordRegex')}/>
          </div>

          <div className="flex items-center mt-4 mb-8">
            <input id="termsAndCondition" name="termsAndCondition" type="checkbox"
                   className="h-4 w-4 text-hardbacon focus:ring-hb-default border-gray-300 rounded"
                   checked={checked}
                   onChange={(e) => setChecked(e.target.checked) }/>
            <label htmlFor="termsAndCondition" className="ml-2 block small-text text-gray-dark">
              <span className={'small-text text-gray-dark'}>
                {t('readAndAgreed')}
                <span onClick={(e) => {
                  const newWindow = window.open(t('hardbaconLinkTerms'), '_blank', 'noopener,noreferrer');
                  if (newWindow) {
                    // Clear reference for security
                    newWindow.opener = null;
                  }
                }}
                className={'small-bold-text text-hardbacon button-hover cursor-pointer'}>{t('termsOfUse')}</span>
              </span>
            </label>
          </div>

          <ReCAPTCHA
            ref={recaptchaRef}
            hl={i18n.language}
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
            size="invisible"
          />

          <HardbaconButton
            name={'signUp'}
            type={'submit'}
            title={t('signUp')}
            disabled={registering}
            loading={registering}
          />

          <hr className={'my-6'}/>

          <HardbaconButton
            name={'signIn'}
            onPress={() => {
              navigate(signInRoute);
            }}
            type={'button'}
            inverse={true}
            title={t('iAlreadyHaveAnAccount')}
          />

        </form>
      </div>
    </div>
  );
};

export default SignUp;
