import { useLocation } from 'react-router-dom';

export const withRouter = (Component: any) => {
  const Wrapper = (props: any) => {
    const location = useLocation();

    return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <Component
        location={location}
        {...props}
      />
    );
  };

  return Wrapper;
};
