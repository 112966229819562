export type IUseFormatAmountOptions = {
  shouldDisplayCurrency?: boolean;
  language?: string;
};

export type IUseFormatAmountParams = {
  options?: IUseFormatAmountOptions;
  value?: string | number;
};

export enum ILanguage {
  'en',
  'fr',
}

export const formatAmount = ({
  value,
  options = { language: 'en' }
}: IUseFormatAmountParams) => {
  if (!value) {
    value = 0;
  }

  const { language } = options;

  if (language === 'en') {
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      signDisplay: 'never'
    }).format(+value);
  } else {
    return new Intl.NumberFormat('fr-CA', {
      style: 'currency',
      currency: 'CAD',
      signDisplay: 'never'
    }).format(+value);
  }
};
