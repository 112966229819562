import ReducerPayload from '../model/ReducerPayload';
import ReducerUserState from '../model/ReducerUserState';
import { SET_USER, SET_PICTURE } from '../types/User';
import { belly } from '../assets/images';

export const initialState: ReducerUserState = {
  user: undefined,
  picture: belly
};

export default function reducer (state = initialState, action: ReducerPayload) {
  switch (action.type) {
    case SET_USER: {
      const statusReason = state.user?.statusReason ? state.user?.statusReason : action.payload?.statusReason;
      const daysToPeriod = state.user?.daysToPeriod ? state.user?.daysToPeriod : action.payload?.daysToPeriod;
      const expirationDate = state.user?.expirationDate ? state.user?.expirationDate : action.payload?.expirationDate;
      return {
        ...state,
        user: { ...action.payload, statusReason, daysToPeriod, expirationDate }
      };
    }
    case SET_PICTURE:
      return {
        ...state,
        picture: action.payload
      };
    default:
      return state;
  }
};
