import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HardbaconAmount from '../../../component/HardbaconAmount';
import styles from '../../../assets/styles/modules/insightRecurringPanel.module.css';
import { getIconForMerchant } from '../../../utils/banking';
import { BankingFrequentTransaction } from '../../../model/BankingFrequentTransaction';
import { BankingOverviewStats } from '../../../model/BankingOverviewStats';
import { formatCurrency } from '../../../i18n';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';

const InsightMostOftenPanel = () => {
  const { t } = useTranslation();
  const { bankStats, loadingBankStats }: { bankStats: BankingOverviewStats, loadingBankStats: boolean } = useSelector((state: any) => state.banking);

  const renderFrequent = (frequentTransaction: BankingFrequentTransaction) => (
    <div key={frequentTransaction.description} className={`${styles.item} ${styles.recurringItem}`}>
      <div className={styles.recurringMerchant}>
        <img src={getIconForMerchant(frequentTransaction.merchantId)} width={40}/>
      </div>
      <div className={styles.middle}>
        <div className={styles.title}>{frequentTransaction.description} <sup className={styles.exp}>x{frequentTransaction.frequence}</sup></div>
        <div className={styles.desc}>{formatCurrency(frequentTransaction.totalAmount)} {t('lastYear')}</div>
      </div>
      <div className={styles.end}>
        <HardbaconAmount amount={frequentTransaction.periodAmount} color={'#788EA4'} size={14} decimals={2} />
        <div>{t('thisMonth')}</div>
      </div>
    </div>);

  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        {loadingBankStats && (
          <div className={styles.progress}>
            <CircularProgress color={CircularProgressColorEmun.primary}/>
          </div>
        )}
        {!loadingBankStats && bankStats && bankStats.frequentTransactions.map(tr => renderFrequent(tr))}
      </div>
    </div>
  );
};

export default InsightMostOftenPanel;
