import React, { ReactNode, useRef } from 'react';
import { externalLinkIcon } from '../assets/images';
import CircularProgress from './CircularProgress';

type HardbaconButtonProps = {
  title?: string;
  name: string;
  type?: 'submit' | 'reset' | 'button';
  size?: 'normal' | 'small';
  onPress?: () => void;
  disabled?: boolean;
  loading?: boolean;
  inverse?: boolean;
  ghost?: boolean;
  gray?: boolean;
  danger?: boolean;
  redirect?: boolean;
  href?: string,
  icon?: string;
  className?: string;
  children?: ReactNode | undefined;
  style?: React.CSSProperties;
}

export const HardbaconButton = ({
  title,
  name,
  type = 'button',
  size = 'normal',
  onPress,
  disabled = false,
  loading = false,
  inverse = false,
  ghost = false,
  gray = false,
  danger = false,
  redirect = false,
  href,
  icon,
  className,
  children,
  style
}: HardbaconButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const renderLoadingSvg = (
      <CircularProgress />
  );

  let baseStyle = 'default';
  if (inverse) {
    baseStyle = 'inverse';
  }
  if (ghost) {
    baseStyle = 'ghost';
  }
  if (gray) {
    baseStyle += ' gray';
  }
  if (danger) {
    baseStyle += ' danger';
  }

  if (disabled) {
    baseStyle += ' disabled';
  }

  const styled = `hardbacon-button ${baseStyle} ${size} ${className}`;

  return (
      <button
          ref={buttonRef}
          id={name}
          name={name}
          className={styled}
          onClick={onPress}
          disabled={disabled}
          type={type}
          style={style}>
        {children || (loading
          ? (renderLoadingSvg)
          : (<div className={'button-content'}>
              {icon && !redirect && (
                  <div className={'button-icon'}>
                    <img className={'button-icon-img'} src={icon}/>
                  </div>
              )}
              <div>{title}</div>
              {redirect && (
                  <div className={'button-icon'}>
                    <img className={'button-icon-img'} src={externalLinkIcon}/>
                  </div>
              )}
            </div>))}
      </button>
  );
};

export default HardbaconButton;
