import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from '../assets/styles/modules/HardbaconToggle.module.css';

interface HardbaconToggleProps {
  value?: boolean;
  onCheck?: (value: boolean) => void;
}

const HardbaconToggle = (props: HardbaconToggleProps) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (props.value !== undefined) {
      setChecked(props.value);
    }
  }, [props.value]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setChecked(newValue);
    if (props.onCheck) {
      props.onCheck(newValue);
    }
  };

  return (
    <label className={styles.switch}>
      <input checked={checked} type="checkbox" onChange={(event => onChange(event))}/>
      <span className={styles.slider}/>
    </label>
  );
};

export default HardbaconToggle;
