import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BankingOverviewStats } from '../../../model/BankingOverviewStats';
import styles from '../../../assets/styles/modules/insightCurrentBalance.module.css';
import HardbaconAmount from '../../../component/HardbaconAmount';
import CircularProgress, { CircularProgressColorEmun } from '../../../component/CircularProgress';

const InsightCurrentBalance = () => {
  const { t } = useTranslation('overview');
  const { bankStats, loadingBankStats }: { bankStats: BankingOverviewStats, loadingBankStats: boolean } = useSelector((state: any) => state.banking);
  return (
    <div className={styles.currentBalance}>
      {loadingBankStats && (
        <div className={styles.progress}>
          <CircularProgress color={CircularProgressColorEmun.primary}/>
        </div>
      )}
      {!loadingBankStats && bankStats && (<div className={styles.balance}>
        <HardbaconAmount amount={bankStats?.balance} />
      </div>)}
      {!loadingBankStats && bankStats && (<div className={styles.savingsCapacity}>
        <div><HardbaconAmount amount={bankStats?.averageInvestmentCapacity} decimals={0} size={16} /></div>
        <div>{t('averageSavingsCapacity')}</div>
      </div>)}
    </div>
  );
};

export default InsightCurrentBalance;
