import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInstitutionLink } from '../api/UserInstitutionService';
import { loadingPiggyBank } from '../assets/images';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import HardbaconCarousel, { HardbaconCarouselItem } from './HardbaconCarousel';
import colors from '../colors';
import styles from './../assets/styles/modules/mXConnectWidget.module.css';

type MXConnectWidgetProps = {
  institutionSourceId: number;
  institutionId?: string;
  onInstitutionAdded?: (memberGuid: string) => void;
  onConnectSuccess: (memberGuid: string) => void;
  onDelete: (memberGuid: string) => void;
};

export const MXConnectWidget = (props: MXConnectWidgetProps) => {
  const [widgetUrl, setWidgetUrl] = useState<string>();
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [counter, setCounter] = useState<any>();

  const { t } = useTranslation(['patience', 'connect']);

  useEffect(() => {
    getInstitutionLink(props.institutionSourceId, props.institutionId)
      .subscribe(link => setWidgetUrl(link));
  }, []);

  useEffect(() => {
    if (!widgetUrl) {
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://atrium.mx.com/connect.js';
    script.id = 'hardbacon-mx-widget-script';
    const scriptInDom = document.body.appendChild(script);

    // Track progress to 100
    setProgress(100);

    setTimeout(() => {
      // @ts-ignore
      if (!window.MXConnect) {
        return;
      }

      // @ts-ignore
      new window.MXConnect({
        id: 'connect-widget',
        iframeTitle: 'Connect',
        url: widgetUrl,
        height: '100%',
        width: '100%',
        /**
         * Callback that for handling all events within Connect.
         * Only called in  ui_message_version 4 or higher.
         *
         * The events called here are the same events that come through post
         * messages.
         */
        onEvent: function (type: any, payload: any) {
          if (type === 'mx/connect/loaded') {
            console.debug('loaded');
          } else if (type === 'mx/connect/memberStatusUpdate') {
            props.onInstitutionAdded && props.onInstitutionAdded!(payload.member_guid);
          } else if (type === 'mx/connect/memberConnected') {
            props.onConnectSuccess(payload.member_guid);
          } else if (type === 'mx/connect/memberDeleted') {
            props.onDelete(payload.member_guid);
          }
        },
        config: {
          ui_message_version: 4
        },
        targetOrigin: '*'
      }).load();

      setWidgetLoaded(true);
    }, 1000);

    return () => scriptInDom.remove();
  }, [widgetUrl]);

  useEffect(() => {
    const id = setInterval(() => setProgress((oldCount) => oldCount + 45 * 0.01), 1000);
    setCounter(id);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (progress >= 100 && counter) {
      clearInterval(counter);
    }
  }, [progress, counter]);

  const renderWaitingView = () => {
    return (
      <div className={styles.waiting}>
        <div className={styles.progressContainer}>
          <CircularProgressbarWithChildren
            styles={buildStyles({
              trailColor: colors.white,
              pathColor: colors.primary,
              pathTransitionDuration: 0.5
            })}
            minValue={0}
            maxValue={100}
            className={styles.progress}
            strokeWidth={6}
            value={progress}
          >
            <div className={styles.progress}>
              <div className={styles.piggyText}>{`${progress.toFixed()} %`}</div>
              <img className={styles.piggy} src={loadingPiggyBank}/>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className={styles.bePatient}>{t('bePatient')}</div>
        <div className={styles.bePatientSubText}>{t('bePatientSubtext')}</div>
      </div>
    );
  };

  const quotes = [
    {
      key: 0,
      text: t('quote0Text'),
      author: ''
    },
    {
      key: 1,
      text: t('quote1Text'),
      author: t('quote1Author')
    },
    {
      key: 2,
      text: t('quote2Text'),
      author: t('quote2Author')
    },
    {
      key: 3,
      text: t('quote3Text'),
      author: t('quote3Author')
    },
    {
      key: 4,
      text: t('quote4Text'),
      author: t('quote4Author')
    },
    {
      key: 5,
      text: t('quote5Text'),
      author: t('quote5Author')
    },
    {
      key: 6,
      text: t('quote6Text'),
      author: t('quote6Author')
    },
    {
      key: 7,
      text: t('quote7Text'),
      author: t('quote7Author')
    },
    {
      key: 8,
      text: t('quote8Text'),
      author: t('quote8Author')
    },
    {
      key: 9,
      text: t('quote9Text'),
      author: t('quote9Author')
    }
  ];

  return (
    <div className={styles.containerM}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {t('connect:connectYourInstitution')}
        </div>
      </div>
      <div id={'connect-widget'}
           className={styles.mxWidgetIframe}>
        {renderWaitingView()}
      </div>
      {widgetLoaded &&
        (<div className={styles.quotesContainer}>
          <HardbaconCarousel autoplay={true} interval={7000}>
            {quotes.map(quote => (
              <HardbaconCarouselItem key={quote.key}>
                <div className={styles.quoteContainer}>
                  <div className={styles.quote}>
                    <span className={styles.quoteText}>{quote.text}</span>
                    <span className={styles.quoteAuthor}>{quote.author}</span>
                  </div>
                </div>
              </HardbaconCarouselItem>
            ))}
          </HardbaconCarousel>
        </div>
        )}
    </div>
  );
};
