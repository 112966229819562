import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InstitutionAccountDetail from '../model/InstitutionAccountDetail';
import styles from '../assets/styles/modules/AccountTypeSelector.module.css';

type AccountTypeSelectorProps = {
    accountDetails?: InstitutionAccountDetail,
    showTypeSelector: boolean,
    onAccountTypeChange: Function;
}

const AccountTypeSelector = (props: AccountTypeSelectorProps) => {
  const { accountDetails, showTypeSelector, onAccountTypeChange } = props;
  const [typeSelector, setTypeSelector] = useState(accountDetails?.accountType === 'INVESTMENT' ? 'INVESTMENT' : 'BANKING');
  const [type, setType] = useState(accountDetails?.accountType || '');
  const { t } = useTranslation('manageAccounts');

  const bankingSubTypeData = [
    {
      label: 'CHECKING',
      emoji: '💵'
    },
    {
      label: 'SAVINGS',
      emoji: '💵'
    },
    {
      label: 'CREDIT_CARD',
      emoji: '💳'
    },
    {
      label: 'LINE_OF_CREDIT',
      emoji: '💳'
    },
    {
      label: 'LOAN',
      emoji: '🏦'
    },
    {
      label: 'MORTGAGE',
      emoji: '🏠'
    },
    {
      label: 'UNKNOWN',
      emoji: '🤑'
    }
  ];
  const investmentSubTypeData = [
    {
      label: 'TFSA',
      emoji: '💸'
    },
    {
      label: 'RRSP',
      emoji: '🌴'
    },
    {
      label: 'LIRA',
      emoji: '🏖'
    },
    {
      label: 'RESP',
      emoji: '🎓'
    },
    {
      label: 'UNKNOWN',
      emoji: '🤑'
    }
  ];

  const isInvestment = typeSelector === 'INVESTMENT';
  const subTypeData = isInvestment ? investmentSubTypeData : bankingSubTypeData;

  const updateAccountType = (accountType: string, accountSubType: string) => {
    if (accountType === 'SAVINGS' && accountSubType === 'UNKNOWN') {
      setType('SAVINGS');
      setTypeSelector('INVESTMENT');
    } else {
      if (onAccountTypeChange && accountDetails) {
        onAccountTypeChange({
          ...accountDetails,
          accountType: accountType,
          accountSubType: accountSubType
        });
      } else {
        onAccountTypeChange && onAccountTypeChange(accountType, accountSubType);
      }
    }
  };

  return (
        <div className={styles.container}>
            {showTypeSelector && type !== 'SAVINGS' && (<div className={styles.selectorContainer}>
                  <button className={styles.button + ' ' + (typeSelector === 'INVESTMENT' ? styles['button-selected'] : '')} onClick={() => setTypeSelector('INVESTMENT')}>
                    <span>{t('manageAccounts:addInvestmentAccount')}</span>
                  </button>
                  <button className={styles.button + ' ' + (typeSelector === 'BANKING' ? styles['button-selected'] : '')} onClick={() => setTypeSelector('BANKING')}>
                    <span>{t('manageAccounts:addBankAccount')}</span>
                  </button>
            </div>)}
            <div className={styles.itemList}>
                {subTypeData.map(item => {
                  return (
                        <div key={item.label} className={styles.category}
                            onClick={() => updateAccountType(
                              isInvestment ? (type === 'SAVINGS' ? 'SAVINGS' : 'INVESTMENT') : item.label,
                              isInvestment ? item.label : 'UNKNOWN')}>
                            <div className={styles.categoryBackground}>
                                <div className={styles.categoryImg}>{item.emoji}</div>
                            </div>
                            <div className={styles.categoryLabel}>
                                {t(`${isInvestment ? 'AccountSubType' : 'AccountType'}_${item.label}`)}
                            </div>
                        </div>
                  );
                })}
            </div>
        </div>
  );
};

export default AccountTypeSelector;
