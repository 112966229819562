import { Dispatch } from 'redux';
import i18n from '../i18n';
import { GET_AVAILABLE_INSTITUTION, GET_AVAILABLE_INSTITUTION_SUCCESS } from '../types/Institution';
import { getAvailableInstitution } from '../api/InstitutionService';

export const getAvailableInstitutionSource = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_AVAILABLE_INSTITUTION });
  getAvailableInstitution().subscribe(
    (userInstitutions) => {
      userInstitutions.forEach(i => i.name = i18n.language === 'en' ? i.displayNameEnglish : i.displayNameFrench);
      dispatch({ type: GET_AVAILABLE_INSTITUTION_SUCCESS, payload: userInstitutions });
    }
  );
};
