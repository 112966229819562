import { combineReducers } from 'redux';
import Session from './Session';
import SignUp from './SignUp';
import SignIn from './SignIn';
import User from './User';
import ResetPassword from './ResetPassword';
import UserInstitution from './UserInstitution';
import Institution from './Institution';
import Notification from './Notification';
import Comparator from './Comparator';
import Support from './Support';
import Banking from './Banking';
import Transactions from './Transactions';
import PopUp from './PopUp';
import Feed from './Feed';

export default combineReducers({
  session: Session,
  signUp: SignUp,
  signIn: SignIn,
  user: User,
  resetPassword: ResetPassword,
  userInstitution: UserInstitution,
  institution: Institution,
  notification: Notification,
  comparator: Comparator,
  support: Support,
  banking: Banking,
  transactions: Transactions,
  popUp: PopUp,
  feed: Feed
});
