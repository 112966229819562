import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../i18n';

interface HardbaconAmountProps {
  amount?: number;
  color?: string;
  size?: number;
  decimals?: number;
  bold?: boolean;
}

const HardbaconAmount = ({
  amount = 0,
  color = '', // if not set, text color will be inherited
  size = 40,
  decimals = 2,
  bold = false
}: HardbaconAmountProps) => {
  const { i18n } = useTranslation();
  const dec = Math.abs(((amount - Math.trunc(amount)) * Math.pow(10, decimals))).toFixed(0).padStart(decimals, '0');
  const decimalSize = Math.trunc(size * 0.66);
  const isFr = i18n.language === 'fr';
  const decimalSeparator = i18n.language === 'fr' ? ',' : '.';

  return (
    <div style={{ color: color, whiteSpace: 'nowrap', fontFamily: bold ? 'Gilmer-Medium' : 'Gilmer-Regular' }}>
      {!isFr && <span style={{ fontSize: `${size}px` }}>$ </span>}
      <span style={{ fontSize: `${size}px` }}>{formatAmount(amount, 0)}</span>
      {decimals > 0 && (<span style={{ fontSize: `${decimalSize}px` }}>{decimalSeparator}{dec}</span>)}
      {isFr && <span style={{ fontSize: `${decimals > 0 ? decimalSize : size}px` }}> $</span>}
    </div>
  );
};

export default HardbaconAmount;
