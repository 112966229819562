import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import HardbaconHeader from '../../component/HardbaconHeader';
import { useSelector } from 'react-redux';
import { route as signInRoute } from '../../navigation/SignIn';

const SecuredLayout = ({ displayHeader = false }: { displayHeader: boolean }) => {
  const { authenticated } = useSelector((store: any) => store.session);
  const navigate = useNavigate();
  const location = useLocation();

  if (!authenticated) {
    return <Navigate to={signInRoute} state={{ from: location, redirectAfterLogin: true }} replace/>;
  }
  if (location.pathname === '/') {
    navigate('/feed');
  }

  let header = <></>;

  if (displayHeader) {
    header = <HardbaconHeader/>;
  }

  return (
    <div className={'hardbacon-page'}>
      <div className={'header'}>
        <div className={'header-content'}>
          {header}
        </div>
      </div>
      <main className={'page big-header'}>
        <div className={'page-content'}>
          <Outlet/>
        </div>
      </main>
    </div>
  );
};

export default SecuredLayout;
