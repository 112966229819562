import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../../../assets/styles/modules/TransactionDatePicker.module.css';
import { calenderIcon } from '../../../assets/images';
import { HardbaconButton, RightPanel } from '../../../component';
import { useSelector, useDispatch } from 'react-redux';
import { updateTransactionsDatePicker } from '../../../actions/Transactions';
import _ from 'lodash';
import Calendar from 'react-calendar';
import { formatDateDMY } from '../../../i18n';
import RadioButton from '../../../component/RadioButton';
import { DateSelectionEmun } from '../../../model/DateSelectionEmun';
import dayjs from 'dayjs';

interface TransactionDatePickerProps {
  isVisible: boolean;
  onClose: Function;
}

const TransactionDatePicker = ({ isVisible, onClose }: TransactionDatePickerProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [value, setValue] = useState<Date[] | Date>(new Date());
  const [dateSelection, setDateSelection] = useState<DateSelectionEmun>(DateSelectionEmun.none);

  const { transactionsStartDate, transactionsEndDate } = useSelector((state: any) => state.transactions);

  const date = new Date();
  const currentMonthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const currentMonthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const prevMonthFirstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const prevMonthLastDay = new Date(date.getFullYear(), date.getMonth(), 0);

  useEffect(() => {
    if (isVisible) {
      if (transactionsEndDate && transactionsStartDate) {
        if (formatDateDMY(transactionsStartDate) === formatDateDMY(currentMonthFirstDay) && formatDateDMY(transactionsEndDate) === formatDateDMY(currentMonthLastDay)) {
          setDateSelection(DateSelectionEmun.current);
        } else if (formatDateDMY(transactionsStartDate) === formatDateDMY(prevMonthFirstDay) && formatDateDMY(transactionsEndDate) === formatDateDMY(prevMonthLastDay)) {
          setDateSelection(DateSelectionEmun.prev);
        } else {
          setValue([new Date(transactionsStartDate), new Date(transactionsEndDate)]);
          setDateSelection(DateSelectionEmun.custom);
        }
      } else {
        setDateSelection(DateSelectionEmun.current);
      }
    }
  }, [transactionsStartDate, transactionsEndDate, isVisible]);

  useEffect(() => {
    if (_.isArray(value) && formatDateDMY(value[0]) === formatDateDMY(currentMonthFirstDay) && formatDateDMY(value[0]) === formatDateDMY(currentMonthLastDay)) {
      setDateSelection(DateSelectionEmun.current);
    } else if (_.isArray(value) && formatDateDMY(value[0]) === formatDateDMY(prevMonthFirstDay) && formatDateDMY(value[0]) === formatDateDMY(prevMonthLastDay)) {
      setDateSelection(DateSelectionEmun.prev);
    } else if (_.isArray(value) && formatDateDMY(value[0]) !== formatDateDMY(currentMonthFirstDay) && formatDateDMY(value[0]) !== formatDateDMY(prevMonthFirstDay)) {
      setDateSelection(DateSelectionEmun.custom);
    }
  }, [value]);

  useEffect(() => {
    if (dateSelection === DateSelectionEmun.none && _.isArray(value) && value.length > 1) {
      setDateSelection(DateSelectionEmun.current);
    } else if (dateSelection === DateSelectionEmun.current) {
      setValue([currentMonthFirstDay, currentMonthLastDay]);
    } else if (dateSelection === DateSelectionEmun.prev) {
      setValue([prevMonthFirstDay, prevMonthLastDay]);
    }
  }, [dateSelection]);

  const lang = i18n.language;

  const onChange = useCallback(
    (val) => {
      setValue(val);
    },
    [setValue]
  );

  const onSubmit = () => {
    if (value && _.isArray(value) && value.length > 0) {
      dispatch(updateTransactionsDatePicker(dayjs(value[0]), dayjs(value[1])));
    } else {
      dispatch(updateTransactionsDatePicker(dayjs().startOf('month'), dayjs().endOf('month').startOf('day')));
    }
    onClose();
  };

  const onRadioClick = (type: DateSelectionEmun) => {
    if (dateSelection === type) {
      setDateSelection(DateSelectionEmun.none);
    } else {
      setDateSelection(type);
    }
  };

  const isSubmitDisabled = () => {
    if (dateSelection === DateSelectionEmun.none) {
      return false;
    }
    return !(_.isArray(value) && value.length > 1);
  };

  const renderContent = () => {
    return (
          <div className={styles.container}>
            <div className={styles.header} onClick={() => onRadioClick(DateSelectionEmun.current)}>
                <RadioButton check={dateSelection === DateSelectionEmun.current}/>
                <p className={styles.title}>{t('currentMonth')}</p>
            </div>
            <div className={styles.header} onClick={() => onRadioClick(DateSelectionEmun.prev)}>
                <RadioButton check={dateSelection === DateSelectionEmun.prev} />
                <p className={styles.title}>{t('prevMonth')}</p>
            </div>
            <div className={styles.header + ' ' + styles.last}>
                <div className={styles['custom-button']} onClick={() => onRadioClick(DateSelectionEmun.custom)}>
                  <RadioButton check={dateSelection === DateSelectionEmun.custom} />
                  <p className={styles.title}>{t('custom')}</p>
                </div>
                {_.isArray(value) && <div className={styles['date-button']}>
                    <img src={calenderIcon}
                        className={styles['button-icon']}
                        alt={t('addCategory')}/>
                    <p className={styles['date-text']}>
                        {(value[0] && (
                            <span>
                                {' ' + t('from') + ' '}
                                <span className={styles.text}>
                                    {formatDateDMY(value[0])}
                                </span>
                            </span>
                        ))}
                        {(value[1] && (
                            <span>
                                {' ' + t('to') + ' '}
                                <span className={styles.text}>
                                    {formatDateDMY(value[1])}
                                </span>
                            </span>
                        ))}
                    </p>
                </div>}
            </div>
            <Calendar
                maxDate={currentMonthLastDay}
                locale={lang}
                onChange={onChange}
                // type Date
                value={value}
                selectRange={true}
                minDetail={'decade'}
                allowPartialRange={true}
             />
            <div className={styles['button-container']}>
                <HardbaconButton
                    disabled={isSubmitDisabled()}
                    className={styles.button}
                    name={'accept'}
                    onPress={onSubmit}
                    type="button"
                    title={t('update')}/>
            </div>
            </div>
    );
  };

  return (
        <RightPanel
            title={t('timePeriod')}
            visible={isVisible}
            onClose={onClose}
            closeOnClickOutside={true}
            renderContent={renderContent}
        />

  );
};

export default TransactionDatePicker;
