import React, { UIEventHandler, useEffect, useRef, useState } from 'react';
import { leftArrow } from '../assets/images';
import ModalState from '../model/ModalState';
import styles from './../assets/styles/modules/rightPanel.module.css';
import { useSelector } from 'react-redux';

interface RightPanelProps {
  visible: boolean;
  onClose: Function;
  title: string;
  renderContent: Function;
  onScroll?: UIEventHandler<any> | undefined;
  closeOnClickOutside?: boolean;
  goBack?: Function | null;
}

const RightPanel = ({ visible, onClose, title, renderContent, onScroll, closeOnClickOutside, goBack }: RightPanelProps) => {
  const [modalState, setModalState] = useState(ModalState.closed);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { popUp } = useSelector((state: any) => state);

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (closeOnClickOutside && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (closeOnClickOutside && !popUp.isOpen) {
      document.addEventListener('mousedown', handleClickOutside, true);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside, true);
      };
    }
  }, [closeOnClickOutside, popUp.isOpen]);

  useEffect(() => {
    if (visible) {
      show();
    } else {
      hide();
    }
  }, [visible]);

  useEffect(() => {
    let mounted = true;
    if (modalState === ModalState.opening) {
      setTimeout(() => {
        if (mounted) {
          setModalState(ModalState.opened);
        }
      }, 300);
    }
    if (modalState === ModalState.closing) {
      setTimeout(() => {
        if (mounted) {
          setModalState(ModalState.closed);
        }
      }, 500);
    }
    return () => { mounted = false; };
  }, [modalState]);

  const show = () => {
    setModalState(ModalState.opening);
  };

  const hide = () => {
    setModalState(ModalState.closing);
  };

  if (modalState === ModalState.closed) {
    return <></>;
  }

  return (
      <div className={'fixed inset-0 overflow-hidden z-10 ' + (modalState === ModalState.opened ? styles.background : '')}>
        <div className="absolute inset-0 overflow-hidden">
          <section className="absolute inset-y-0 right-0 max-w-full flex"
                   aria-labelledby="slide-over-heading">
            <div ref={wrapperRef}
                className={(modalState === ModalState.opened ? 'translate-x-0 translate-x-0' : 'translate-x-full translate-x-0') + ' transform transition ease-in-out duration-500 sm:duration-500 w-screen max-w-md'}>
              <div className="h-full flex flex-col bg-white shadow-xl overflow-x-auto" onScroll={onScroll}>
                <div className={styles.header}>
                  {goBack
                    ? (
                    <button
                      onClick={() => {
                        if (goBack) {
                          goBack();
                        }
                      }}
                      className="rounded-md text-gray-400 hover:text-gray-500 outline-none">
                        <img src={leftArrow} style={{ width: '24px' }} alt={'back'}/>
                  </button>
                      )
                    : (
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth="0" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                      )}
                  <p className={styles['header-title']}>{title}</p>
                  <button
                      onClick={() => {
                        if (onClose) {
                          onClose();
                        } else {
                          hide();
                        }
                      }}
                      className="rounded-md text-gray-400 hover:text-gray-500 outline-none">
                    <span className="sr-only">Close panel</span>
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                  </button>
                </div>
                <div className="relative flex-1 px-4 sm:px-6">
                  <div className="absolute inset-0 add-account-right-panel">
                      {renderContent()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
  );
};

export default RightPanel;
