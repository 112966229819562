export const GET_USER_INSTITUTION = 'GET_USER_INSTITUTION';
export const GET_USER_INSTITUTION_SUCCESS = 'GET_USER_INSTITUTION_SUCCESS';
export const ADD_USER_INSTITUTION = 'ADD_USER_INSTITUTION';
export const ADD_USER_INSTITUTION_SUCCESS = 'ADD_USER_INSTITUTION_SUCCESS';
export const ADD_USER_INSTITUTION_FAILED = 'ADD_USER_INSTITUTION_FAILED';
export const POLL_USER_INSTITUTION = 'POLL_USER_INSTITUTION';
export const POLL_USER_INSTITUTION_SUCCESS = 'POLL_USER_INSTITUTION_SUCCESS';
export const POLL_USER_INSTITUTION_FAILED = 'POLL_USER_INSTITUTION_FAILED';
export const CLEAR_ADD_USER_INSTITUTION = 'CLEAR_ADD_USER_INSTITUTION';
export const CLEAR_POLL_USER_INSTITUTION = 'CLEAR_POLL_USER_INSTITUTION';
export const CLEAR_ERROR_USER_INSTITUTION = 'CLEAR_ERROR_USER_INSTITUTION';
export const DELETE_USER_INSTITUTION = 'DELETE_USER_INSTITUTION';
export const DELETE_USER_INSTITUTION_SUCCESS = 'DELETE_USER_INSTITUTION_SUCCESS';
export const DELETE_USER_INSTITUTION_FAILED = 'DELETE_USER_INSTITUTION_FAILED';
export const SET_ADD_INSTITUTION_PANEL_OPEN = 'SET_ADD_INSTITUTION_PANEL_OPEN';
export const SET_ACCOUNT_SELECTOR_PANEL_OPEN = 'SET_ACCOUNT_SELECTOR_PANEL_OPEN';
export const SET_SELECTED_ACCOUNTS = 'SET_SELECTED_ACCOUNTS';
export const SET_PRELOADED = 'SET_PRELOADED';
export const CREATE_MANUAL_ACCOUNT = 'CREATE_MANUAL_ACCOUNT';
export const DELETE_MANUAL_ACCOUNT = 'DELETE_MANUAL_ACCOUNT';
export const SET_NOTIFICATIONS_PANEL_OPEN = 'SET_NOTIFICATIONS_PANEL_OPEN';
