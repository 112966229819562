import i18n, { formatDate } from '../i18n';
import transaction from '../assets/images/svg/transaction.svg';
import InstitutionSource from '../model/InstitutionSource';
import { Dayjs } from 'dayjs';
import { BankingTransactionCtg, SplitedCategoryType } from '../model/BankingTransaction';
import store from '../store';
import { TransactionCategory } from '../model/TransactionCategory';

function isTransaction (ctg: BankingTransactionCtg | TransactionCategory) {
  return (ctg as TransactionCategory).categoryEnglish !== undefined;
}

export const getTransactionCtgName = (ctg: BankingTransactionCtg | TransactionCategory) => {
  let name = '';
  const isFr = i18n.language === 'fr';
  if (isTransaction(ctg)) {
    name = isFr ? (ctg as TransactionCategory).categoryFrench : (ctg as TransactionCategory).categoryEnglish;
  } else {
    name = isFr ? (ctg as BankingTransactionCtg).french : (ctg as BankingTransactionCtg).english;
  }
  return name;
};

export const getTransactionCtgEmojiById = (id: number) => {
  const state: any = store.getState();

  const emojisDict = state.banking?.bankingCtgEmojiDict;

  return emojisDict[id] || '';
};

export const getIconForMerchant = (merchantId: string) => {
  if (!merchantId) {
    return transaction;
  }
  return `${process.env.REACT_APP_MX_URL_MERCHANT_LOGO_PREFIX}${merchantId}.png`;
};

export const getNameForInstitution = (institutionSource: InstitutionSource) => {
  return i18n.language === 'fr' ? institutionSource.displayNameFrench : institutionSource.displayNameEnglish;
};

export const getTransactionSubCtgsById = (id: number) => {
  const state: any = store.getState();
  const ctgSubCtgsDict = state.banking?.bankingCtgSubCtgsDict;
  return ctgSubCtgsDict[id] || [];
};

export const getTransactionCtgNameById = (id: number) => {
  const state: any = store.getState();
  const ctgNamesDict = state.banking?.bankingCtgNamesDict;
  let name = '';
  if (ctgNamesDict[id]) {
    name = i18n.language === 'fr' ? ctgNamesDict[id].fr : ctgNamesDict[id].en;
  }
  return name;
};

export const getTransactionParentCtgById = (id: number) => {
  const state: any = store.getState();
  const ctgSubCtgsDict = state.banking?.bankingCtgSubCtgsDict;
  const ids: {parent: number, subs: number[]}[] = [];
  Object.keys(ctgSubCtgsDict).forEach((key: string) => {
    ids.push({
      parent: key as unknown as number,
      subs: ctgSubCtgsDict[key]
    });
  }); ;
  let parentId = -1;
  ids.map((i: {parent: number, subs: number[]}) => {
    if (i.subs.indexOf(id) >= 0) {
      parentId = i.parent;
    }
    return i;
  });
  return parentId;
};

export const formatAmountPerMonth = (amountPerMonth: Record<string, number>) => {
  const result: any = {};
  for (const key in amountPerMonth) {
    result[formatDate(key, 'MMM yyyy')] = amountPerMonth[key];
  }
  return result;
};

export const getAmountByMonth = (amountPerMonth: Record<string, number>, date: Dayjs) => {
  return date && (formatAmountPerMonth(amountPerMonth)[formatDate(date, 'MMM yyyy')] || 0);
};

export const getSplitByCategoriesObject = (array: SplitedCategoryType[], key: string) => {
  const initialValue = {};
  return array.reduce((obj: any, item: any) => {
    return {
      ...obj,
      [item[key]]: parseFloat(`${item.amount}`)
    };
  }, initialValue);
};
